import { post } from "shared/service"
import UrlService from "shared/service/url.service"
import { AvailableFactorsResponseDTO, OtpFormResponseDTO, OtpSetupResponseFormDTO } from "generated/models"

class AUTHENTICATOR {
    static verifyCode = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/authenticator/verify-code", { code, rememberMe })

    static setupSecret = (): Promise<OtpSetupResponseFormDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/authenticator/setup", {})

    static confirmSetup = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/authenticator/confirm-setup", { code, rememberMe })
}

class EMAIL {
    static requestCode = (): Promise<OtpFormResponseDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/email/request-code", {})

    static verifyCode = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/email/verify-code", { code, rememberMe })

    static initiateSetup = (): Promise<OtpFormResponseDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/email/setup", {})

    static confirmSetup = (code: string, rememberMe: boolean): Promise<OtpFormResponseDTO> =>
        post(UrlService.getUserServiceApiUrl() + "/mfa/otp/email/confirm-setup", { code, rememberMe })
}

const availableFactors = (): Promise<AvailableFactorsResponseDTO> => {
    return post(UrlService.getUserServiceApiUrl() + "/mfa/available-factors", {})
}

const mfaService = {
    AUTHENTICATOR,
    EMAIL,
    availableFactors,
}

export default mfaService
