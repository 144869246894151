import React from "react"
import { ColumnConfigurator } from "domain/ColumnConfigurator/components/ColumnConfigurator"
import { CircularProgress, Dialog, DialogContent } from "@mui/material"
import { styled } from "@mui/material/styles"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& *": { fontSize: "13px" },
    "& svg": { fontSize: "20px" },
    "& .MuiDialog-container": {},
    "& .MuiDialog-paper": {
        height: "1200px",
        width: "1300px",
        minHeight: "600px",
        maxWidth: "1600px",
        backgroundColor: theme.palette.pageBackgroundColor,
        // [theme.breakpoints.down('lg')]: {
        //     minWidth: 'unset',
        //     minHeight: 'unset',
        // }
    },
    "& .MuiDialogContent-root": {
        display: "flex",
    },
    "& .MuiDialogActions-root": {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
}))

export const ColumnConfiguratorDialog = (): JSX.Element => {
    const { dialogState, close, applyColumnConfiguratorSettings, isSelectedStateValid } =
        ColumnConfiguratorContextSlices.useWidgetState()
    const { initialized } = useReportingConfigurationContext()

    return (
        <StyledDialog className={"column-configurator-dialog"} onClose={close} open={dialogState === "open"}>
            {!initialized && (
                <div className="screen-centered">
                    <CircularProgress />
                </div>
            )}
            {initialized && (
                <React.Fragment>
                    <DialogContent className={"dialog-content"}>
                        <ColumnConfigurator />
                    </DialogContent>
                    <DialogActions className={"dialog-actions"}>
                        <Button size={"large"} color="info" variant="outlined" onClick={close}>
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            size={"large"}
                            variant="contained"
                            disabled={!isSelectedStateValid()}
                            onClick={applyColumnConfiguratorSettings}
                        >
                            Apply
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </StyledDialog>
    )
}
