import * as React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Layout, Menu } from "antd"
import {
    ASONewUiMenuLeafDTO,
    ClassicUiEmbeddedMenuLeafDTO,
    LegacyDashboardsEmbeddedMenuLeafDTO,
    MenuDTO,
    MenuNodeDTO,
    NewUiMenuLeafDTO,
} from "generated/models"
import MenuUtil, { isNode } from "shared/service/MenuUtil"

const SubMenu = Menu.SubMenu

type ArticleMenuProps = {
    inlineIndent?: number
    showLogo: boolean
    firstEntryPath: string
    entries: Array<
        | ASONewUiMenuLeafDTO
        | ClassicUiEmbeddedMenuLeafDTO
        | LegacyDashboardsEmbeddedMenuLeafDTO
        | MenuNodeDTO
        | NewUiMenuLeafDTO
    >
    onMenuClick: (path: string) => void
}

type ArticleMenuState = {
    collapsed: boolean
    selected: string
    open: string[]
}

export const ArticleMenu: React.FC<ArticleMenuProps> = (props: ArticleMenuProps) => {
    const [state, setState] = useState<ArticleMenuState>({ collapsed: false, selected: "", open: [] })

    const getNodePath = (articleIdentifier: string) => {
        return MenuUtil.getMenuNodeEndingWith(articleIdentifier, { root: props.entries })?.path
    }

    useEffect(() => {
        const selected = props.firstEntryPath
        setState({
            collapsed: false,
            selected: selected ? selected : undefined,
            open: selected ? getOpenPaths(selected) : [],
        })
    }, [props.firstEntryPath])

    const getOpenPaths = (selectedPath: string): string[] => {
        const subPaths = selectedPath ? selectedPath.split("/") : undefined
        const [_, ...consideredSubPaths] = subPaths.filter((p) => p.length > 0).reverse()
        consideredSubPaths.reverse()
        return consideredSubPaths.map(
            (p, i) =>
                `/${[consideredSubPaths.filter((_, ii) => ii < i).join("/"), p]
                    .filter((pp) => pp.length > 0)
                    .join("/")}`,
        )
    }

    const onCollapse = (collapsed: boolean) => {
        setState((prev) => ({ ...prev, collapsed: collapsed }))
    }

    const gotoHome = () => {
        const { entries } = props
        setState((prevState) => ({
            ...prevState,
            selected:
                entries.length && isNode(entries[0]) && entries[0].items.length
                    ? `${entries[0].path}${entries[0].items[0].path}`
                    : undefined,
            open: entries.length ? [entries[0].path] : [],
        }))
    }

    const onSelect = (param) => {
        setState((prevState) => ({
            ...prevState,
            selected: param.key,
        }))
    }

    const onOpenChange = (openKeys: string[]) => {
        const latestOpenKey = openKeys.find((key) => state.open.indexOf(key) === -1)
        const hierarchyOpenKeys = latestOpenKey ? state.open.filter((key) => latestOpenKey.startsWith(key)) : []

        setState((prevState) => ({
            ...prevState,
            open: latestOpenKey ? hierarchyOpenKeys.concat([latestOpenKey]) : openKeys,
        }))
    }

    const getMenuItem = (item: MenuDTO) => (
        <Menu.Item key={`${item.path}`}>
            <Link to={`${item.path}`} onClick={clickOnLink}>
                {item.title}
            </Link>
        </Menu.Item>
    )

    const getSubMenu = (entry: MenuNodeDTO, level: number) => {
        const title = entry.icon ? (
            <span>
                <div className={`icon icon-${entry.icon}`} />
                {!state.collapsed && entry.title}
            </span>
        ) : (
            <span>{!state.collapsed && entry.title}</span>
        )

        const menuItems = getItem(entry, level)

        return level === 1 ? (
            <SubMenu className={"firstLevel"} title={title} key={entry.path}>
                {menuItems}
            </SubMenu>
        ) : (
            <SubMenu title={title} key={entry.path}>
                {menuItems}
            </SubMenu>
        )
    }

    const getItem = (entry: MenuNodeDTO, level) => entry.items.map((item) => mapItem(item, level))

    const mapItem = (item: MenuDTO, level) =>
        isNode(item) && item.items.length ? getSubMenu(item, level + 1) : getMenuItem(item)

    const { entries, showLogo, inlineIndent } = props
    const { Sider } = Layout
    const { selected, open } = state

    const clickOnLink = (event) => {
        event.preventDefault()
        const selected = getNodePath(event.target.href.split("/").pop())
        props.onMenuClick(selected)

        setState((prevState) => ({
            ...prevState,
            selected: selected ? selected : undefined,
            open: selected ? getOpenPaths(selected) : [],
        }))

        return false
    }

    return (
        // currently collapsible:false due to various bug in the collapsed layout
        <Sider
            style={{ paddingTop: "20px" }}
            width={250}
            className={"main-sidebar"}
            theme={"light"}
            collapsible={false}
            collapsed={state.collapsed}
            onCollapse={onCollapse}
        >
            {showLogo && (
                <Link to={"/"} onClick={gotoHome}>
                    <div id="logo" className={state.collapsed ? "small" : "wide"}>
                        <span className={"company"}>exactag</span>
                        <span className={"subtitle"}>Support</span>
                    </div>
                </Link>
            )}
            <div className={"menu-wrapper"}>
                <Menu
                    mode="inline"
                    className="menu"
                    style={{ border: "unset" }}
                    inlineIndent={inlineIndent || 25}
                    selectedKeys={[selected]}
                    openKeys={open}
                    onClick={onSelect}
                    onOpenChange={onOpenChange}
                >
                    {entries &&
                        entries.map((entry) =>
                            isNode(entry) && entry.items.length ? getSubMenu(entry, 1) : getMenuItem(entry),
                        )}
                </Menu>
            </div>
        </Sider>
    )
}
