import { ColumnUniqueName } from "domain/ColumnConfigurator/types"
import { createContext } from "use-context-selector"
import {
    ConversionListColumns,
    ConversionListType,
    QueryConfig,
    SearchConfig,
    TimeRange,
    TouchpointPropertyFilter,
    TouchpointPropertyFilterDialogMode,
} from "domain/ConversionList/domain/domain"
import { ConversionTypeSelection } from "domain/ConversionList/domain/conversionType"
import { FullOrSoftConversionSelection } from "domain/ConversionList/domain/fullOrSoftConversion"
import { CustomerJourneyConfig } from "domain/ConversionList/domain/customerJourneyConfig"
import { TrackingStateSelection } from "domain/ConversionList/domain/trackingState"
import { useSafeContextSelector } from "shared/hooks"
import { ConversionStateSelection } from "domain/ConversionList/domain/conversionState"
import { ConsentStateSelection } from "domain/ConversionList/domain/consentState"
import { SortSettingsDTO } from "generated/models"

export type ConversionListContextProps = Readonly<{
    dataManagerIdentifier: string
    currentQueryConfig: QueryConfig

    hasUnappliedChanges: boolean
    applyChanges: () => void

    propertyFilterDialogOpen: boolean
    propertyFilterDialogMode: TouchpointPropertyFilterDialogMode
    propertyFilterDialogId: string
    openPropertyFilterDialog: (mode: TouchpointPropertyFilterDialogMode) => void
    closePropertyFilterDialog: () => void

    type: ConversionListType

    columns: ConversionListColumns

    selectedColumns: ReadonlyArray<string>
    updateSelectedColumns: (selectedColumns: ReadonlyArray<string>) => void
    leftPinnedColumns: ReadonlyArray<ColumnUniqueName>
    updateLeftPinnedColumns: (leftPinnedColumns: ReadonlyArray<ColumnUniqueName>) => void

    sortSettings: SortSettingsDTO
    setSortSettings: (sortSettings: SortSettingsDTO) => void

    timeRange: TimeRange
    updateTimeRange: (timeRange: TimeRange) => void

    conversionTypeSelection: ConversionTypeSelection
    updateConversionTypeSelection: (conversionTypeSelection: ConversionTypeSelection) => void

    fullOrSoftConversionSelection: FullOrSoftConversionSelection
    updateFullOrSoftConversionSelection: (fullOrSoftConversionSelection: FullOrSoftConversionSelection) => void

    trackingStateSelection: TrackingStateSelection
    updateTrackingStateSelection: (trackingStateSelection: TrackingStateSelection) => void

    customerJourneyConfig: CustomerJourneyConfig
    updateCustomerJourneyConfig: (customerJourneyConfig: CustomerJourneyConfig) => void

    touchpointPropertyFilters: ReadonlyArray<TouchpointPropertyFilter>
    updateTouchpointPropertyFilters: (touchpointPropertyFilters: ReadonlyArray<TouchpointPropertyFilter>) => void

    resetGridColumnState: () => void
    searchConfig: SearchConfig
    updateSearchConfig: (searchConfig: SearchConfig) => void

    conversionStateSelection: ConversionStateSelection
    updateConversionStateSelection: (conversionState: ConversionStateSelection) => void

    consentStateSelection: ConsentStateSelection
    updateConsentStateSelection: (consentState: ConsentStateSelection) => void
}>

const ConversionListContext = createContext<ConversionListContextProps | undefined>(undefined)
export const Provider = ConversionListContext.Provider

export const useConversionListContextSelector = <Selected,>(
    selector: (value: ConversionListContextProps) => Selected,
) => {
    return useSafeContextSelector<ConversionListContextProps, Selected>(ConversionListContext, selector)
}
