import { post } from "shared/service"
import UrlService from "shared/service/url.service"
import { UiConsentFormDTO } from "generated/models"

const submitConsent = (privacyPolicyAccepted: boolean): Promise<UiConsentFormDTO> =>
    post(UrlService.getUserServiceApiUrl() + "/consent", { privacyPolicyAccepted } as UiConsentFormDTO)

const getConsentState = (): Promise<UiConsentFormDTO> => post(UrlService.getUserServiceApiUrl() + "/consent/state", {})

const consentService = {
    submitConsent,
    getConsentState,
}

export default consentService
