import { CustomDialog } from "layout/components/CustomDialog"
import { Alert, DialogContentText } from "@mui/material"
import React from "react"

type AuthLoginErrorDialogProps = {
    errorState: "start" | "first-error" | "further-error"
    navigateToPasswordReset: () => void
    onClose: () => void
    open: boolean
}

export const PasswordExpiredLoginErrorDialog = ({
    errorState,
    navigateToPasswordReset,
    onClose,
    open,
}: AuthLoginErrorDialogProps): JSX.Element => {
    return (
        <CustomDialog
            header={"Expired Password"}
            content={
                <DialogContentText>
                    <div className={"login-form-error"}>
                        Your password has expired. In order to log in, you will have to{" "}
                        <a onClick={navigateToPasswordReset}>reset your password</a>.
                        {errorState === "further-error" && (
                            <>
                                <br />
                                <br />
                                <Alert severity="warning">
                                    Please be aware that your account will be locked temporarily after repeated login
                                    failures.
                                </Alert>
                            </>
                        )}
                    </div>
                </DialogContentText>
            }
            footer={{
                kind: "yesNoButton",
                noText: "Back To Login",
                yesText: "Reset Password Now",
                onYesButtonClick: navigateToPasswordReset,
                onNoButtonClick: onClose,
                submitting: false,
            }}
            onClose={onClose}
            open={open}
        />
    )
}
