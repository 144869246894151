import { ACTIONS_ID_FIELD } from "domain/datagrid/component/DataGrid"
import React from "react"
import { ColumnConfiguratorContextProvider } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextProvider"
import {
    ColumnConfiguratorLabels,
    ColumnUniqueName,
    DataGroup,
    DataGroupUniqueName,
    Metric,
    MetricsFrontendGroup,
    MetricsFrontendGroupUniqueName,
} from "domain/ColumnConfigurator/types"
import { ReportingConfigurationContextProvider } from "domain/reporting/ReportingConfigurationContext"
import { ColumnConfigDTO } from "generated/models"

export type GenericDataGridColumnConfiguratorAdapterProps = Readonly<{
    columnConfigs: ColumnConfigDTO[]
    children: React.ReactNode
}>

export const GENERIC_DATA_GRID_DATA_GROUP = "generic-data-grid-data-group"
export const GENERIC_DATA_GRID_FRONTEND_GROUP = "generic-data-grid-frontend-group"

export const GenericDataGridColumnConfiguratorAdapter = ({
    columnConfigs,
    children,
}: GenericDataGridColumnConfiguratorAdapterProps) => {
    const reportingConfigurationContextDataSupplier = React.useCallback(() => {
        const metrics: Map<ColumnUniqueName, Metric> = new Map()
        for (const columnConfig of columnConfigs) {
            // We don't want to show the "actions" column in CoCo
            // However, note that we need to make sure we don't lose it after saving the columns from CoCo
            if (columnConfig.columnIdentifier === ACTIONS_ID_FIELD) {
                continue
            }

            const metric: Metric = {
                columnType: "metric",
                displayName: columnConfig.gridColumnProperties.columnHeader,
                displayNameBaseMetric: columnConfig.gridColumnProperties.columnHeader,
                uniqueName: columnConfig.columnIdentifier,
                round: 2,
            }

            metrics.set(columnConfig.columnIdentifier, metric)
        }

        const dataGroups: Map<DataGroupUniqueName, DataGroup> = new Map()
        dataGroups.set(GENERIC_DATA_GRID_DATA_GROUP, {
            uniqueName: GENERIC_DATA_GRID_DATA_GROUP,
            description: GENERIC_DATA_GRID_DATA_GROUP,
            dimensions: new Set(),
            metrics: new Set(metrics.keys()),
        })

        const metricsFrontendGroups: Map<MetricsFrontendGroupUniqueName, MetricsFrontendGroup> = new Map()
        metricsFrontendGroups.set(GENERIC_DATA_GRID_FRONTEND_GROUP, {
            uniqueName: GENERIC_DATA_GRID_FRONTEND_GROUP,
            displayName: "Columns",
            metrics: new Set(metrics.keys()),
            sortOrder: 0,
        })

        const dataDefinitions = {
            dimensions: new Map(),
            metrics: metrics,
            dataGroups: dataGroups,
            metricsFrontendGroups: metricsFrontendGroups,
            infoTexts: new Map(),
        }

        return Promise.resolve(dataDefinitions)
    }, [columnConfigs])

    return (
        <ReportingConfigurationContextProvider loadDataDefinitions={reportingConfigurationContextDataSupplier}>
            <ColumnConfiguratorContextProvider labels={labels}>{children}</ColumnConfiguratorContextProvider>
        </ReportingConfigurationContextProvider>
    )
}

const labels: ColumnConfiguratorLabels = {
    metric: "column",
    metricPlural: "columns",
}
