/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
/**
 *
 * @export
 * @interface CampaignSettingsDTO
 */
export interface CampaignSettingsDTO {
    /**
     *
     * @type {string}
     * @memberof CampaignSettingsDTO
     */
    currency: string
    /**
     *
     * @type {string}
     * @memberof CampaignSettingsDTO
     */
    locale: string
}

/**
 * Check if a given object implements the CampaignSettingsDTO interface.
 */
export function instanceOfCampaignSettingsDTO(value: object): value is CampaignSettingsDTO {
    if (!("currency" in value) || value["currency"] === undefined) return false
    if (!("locale" in value) || value["locale"] === undefined) return false
    return true
}

export function CampaignSettingsDTOFromJSON(json: any): CampaignSettingsDTO {
    return CampaignSettingsDTOFromJSONTyped(json, false)
}

export function CampaignSettingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignSettingsDTO {
    if (json == null) {
        return json
    }
    return {
        currency: json["currency"],
        locale: json["locale"],
    }
}

export function CampaignSettingsDTOToJSON(value?: CampaignSettingsDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        currency: value["currency"],
        locale: value["locale"],
    }
}
