import { createSvgIcon } from "@mui/material"
import React from "react"

const SmallErrorIcon = createSvgIcon(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.53125 2.53125L6 6M6 6L9.46875 9.46875M6 6L9.46875 2.53125M6 6L2.53125 9.46875"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>,
    "Error",
)

export default SmallErrorIcon
