import {
    DataGroup,
    DataGroups,
    DataGroupUniqueName,
    Dimension,
    Dimensions,
    MetricsFrontendGroup,
    MetricsFrontendGroups,
    MetricsFrontendGroupUniqueName,
    Metric,
    Metrics,
    ColumnUniqueName,
    ColumnInfoTexts,
    ColumnInfoText,
} from "domain/ColumnConfigurator/types"
import { TagsUtil } from "domain/ColumnConfigurator/TagsUtil"
import {
    DashboardsDimensionsResponseDTO,
    DashboardsMetricsResponseDTO,
    DataGroupsResponseDTO,
    FrontendGroupsResponseDTO,
    InfoTextsResponseDTO,
} from "generated/models"

export const convertDimensions = (dimensionsResponseDTO: DashboardsDimensionsResponseDTO): Dimensions => {
    const dimensions: Map<ColumnUniqueName, Dimension> = new Map()

    dimensionsResponseDTO.dimensions.forEach((dimensionDTO) => {
        dimensions.set(dimensionDTO.identifier, {
            columnType: "dimension",
            uniqueName: dimensionDTO.identifier,
            displayName: dimensionDTO.descriptionPlural,
        })
    })

    return dimensions
}

export const convertMetrics = (metricsResponseDTO: DashboardsMetricsResponseDTO): Metrics => {
    const metrics: Map<ColumnUniqueName, Metric> = new Map()

    metricsResponseDTO.metrics.forEach((metricDTO) => {
        metrics.set(metricDTO.identifier, {
            columnType: "metric",
            uniqueName: metricDTO.identifier,
            displayName: metricDTO.description,
            round: metricDTO.round,
            displayNameBaseMetric: TagsUtil.getBaseMetricDisplayName(metricDTO.description),
            tags: new Set(TagsUtil.getTags(metricDTO.identifier)),
        })
    })

    return metrics
}

export const convertDataGroups = (dataGroupsResponseDTO: DataGroupsResponseDTO): DataGroups => {
    const dataGroups: Map<DataGroupUniqueName, DataGroup> = new Map()

    for (const dataGroupDTO of dataGroupsResponseDTO.data.dataGroups) {
        dataGroups.set(dataGroupDTO.uniqueName, {
            uniqueName: dataGroupDTO.uniqueName,
            description: dataGroupDTO.description,
            dimensions: new Set(dataGroupDTO.dimensions),
            metrics: new Set(dataGroupDTO.dataColumns),
        })
    }

    return dataGroups
}

export const convertMetricsFrontendGroups = (
    frontendGroupsResponseDTO: FrontendGroupsResponseDTO,
): MetricsFrontendGroups => {
    const frontendGroups: Map<MetricsFrontendGroupUniqueName, MetricsFrontendGroup> = new Map()

    for (const frontendGroupDTO of frontendGroupsResponseDTO.data.kpiGroups) {
        frontendGroups.set(frontendGroupDTO.uniqueName, {
            uniqueName: frontendGroupDTO.uniqueName,
            displayName: frontendGroupDTO.description,
            metrics: new Set(frontendGroupDTO.dataColumns),
            sortOrder: frontendGroupDTO.sortOrder,
        })
    }

    return frontendGroups
}
export const convertInfoTexts = (infoTextsResponseDTO: InfoTextsResponseDTO): ColumnInfoTexts => {
    return new Map<ColumnUniqueName, ColumnInfoText>(
        infoTextsResponseDTO.data.data.map((infoText) => [
            infoText.identifier,
            { uniqueName: infoText.identifier, infoText: infoText.infoText },
        ]),
    )
}
