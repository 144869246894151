import { Box, CircularProgress, IconButton, Menu, MenuItem } from "@mui/material"
import React from "react"
import DownloadIcon from "shared/component/icons/DownloadIcon"
import { useDashboardLayout } from "./DashboardLayoutProvider"

export const DownloadButton = () => {
    const {
        htmlToImage: { ref, mutation },
    } = useDashboardLayout()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>()
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(undefined)
    }

    return (
        <>
            <IconButton
                onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                }}
            >
                <DownloadIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={async () => {
                        const el = ref.current
                        if (!el || mutation.isPending) return
                        const newElement = document.createElement("div")
                        newElement.innerHTML = el.outerHTML
                        newElement.style.width = `${el.clientWidth}px`
                        newElement.style.position = "fixed"
                        newElement.style.right = "100%"
                        newElement.style.padding = "32px"
                        newElement.classList.add("printing")
                        document.body.appendChild(newElement)
                        try {
                            await mutation.mutateAsync({
                                type: "image",
                                element: newElement,
                            })
                        } finally {
                            document.body.removeChild(newElement)
                            handleClose()
                        }
                    }}
                >
                    Download as PNG
                </MenuItem>
                <MenuItem onClick={handleClose}>Download as Excel</MenuItem>
                {mutation.isPending && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            pointerEvents: "all",
                            background: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Menu>
        </>
    )
}
