import React, { useContext } from "react"
import { Drawer } from "antd"
import { WidgetContext } from "domain/legacy/widget/WidgetContext"
import _cloneDeep from "lodash/cloneDeep"
import { QueryWidgetSettingsDTO } from "domain/types/backend/widget.types"
import { v1 as uuid } from "uuid"
import { ContainerSizeContext } from "domain/legacy/widget/ContainerSizeContext"

type Props = {
    children: React.ReactNode
}

export const WidgetSettingsPanel = (props: Props) => {
    const containerSizeContext = useContext(ContainerSizeContext)
    const widgetDataContext = useContext(WidgetContext)
    const { widgetSettings, updateWidgetSettings } = widgetDataContext

    const updateSettingsPanelVisible = (isVisible: boolean) => {
        const newSettings: QueryWidgetSettingsDTO = _cloneDeep(widgetSettings)
        newSettings.settingsPanelVisible = isVisible
        updateWidgetSettings(newSettings)
    }

    const onClose = () => {
        updateSettingsPanelVisible(false)
    }

    const hiddenClassName = "drawer-hidden"

    const id = "widget-drawer-" + uuid()
    const className = "widget-drawer " + (widgetSettings.settingsPanelVisible ? "open" : `closed ${hiddenClassName}`)
    return (
        <div className={className} id={id}>
            <Drawer
                placement="right"
                closable={false} // this will be managed by the checkbox in the dashboards settings toolbar
                destroyOnClose={true}
                onClose={onClose}
                visible={widgetSettings.settingsPanelVisible}
                afterVisibleChange={(visible) => {
                    // we need an additional class to completely hide the drawer when the animation finished; if it was not hidden then e.g.
                    // tooltips won't work anymore; if we hide it immediately then there would be no slide in/out animation
                    const rootEl = document.body.querySelector("#" + id)
                    if (!visible) {
                        rootEl.classList.add(hiddenClassName)
                    } else {
                        rootEl.classList.remove(hiddenClassName)
                    }
                }}
                getContainer={false}
                style={{ position: "absolute" }}
                width={
                    containerSizeContext.containerSize.width > 420 ? 380 : containerSizeContext.containerSize.width - 20
                }
            >
                {props.children}
            </Drawer>
        </div>
    )
}
