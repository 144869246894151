import { GridConfigDTO, PathsDTO } from "generated/models"
import { ReactElement } from "react"

export type MappedGridConfig = GridConfigDTO & {
    paths: PathsDTO
}

export type DataManagerIdentifier = string

export enum ToolbarButtonType {
    ICON = "ICON",
    BUTTON = "BUTTON",
}

export type Formatter = (value: any) => ReactElement

/**
 * Column type that is used for ant tables
 */
export type AntTableColumn = {
    className: string
    title: any
    dataIndex: string
    isSortable: boolean
    width?: number | string
    sorter?: (a, b) => number
    key?: string
    render?: Formatter
    shouldCellUpdate: (record, prevRecord) => boolean
    onHeaderCell: (column) => any
    ellipsis: boolean | { showTitle?: boolean }
}
