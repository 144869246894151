import React, { useEffect, useState } from "react"
import { RendererType } from "shared/component/renderers/renderers"
import { DataGrid } from "domain/datagrid/component/DataGrid"
import { Alert } from "antd"
import { capitalizeEnum } from "shared/util/util"
import { useDataGridContext } from "domain/legacy/widget/generic/DataGridContext"
import { ReportingDataSetDTO } from "generated/models"
import DimensionService from "domain/dimension/service/DimensionService"
import {
    ColumnValueType,
    ComparisonRequestDataDTO,
    GoodChangeDirection,
    ScenarioColumnConfig,
    ScenarioComparisonData,
} from "domain/types"

const columnConfig = (
    optimizationLevel: string,
    scenario1Name: string,
    scenario2Name: string,
): ScenarioColumnConfig[] => {
    const prefix = optimizationLevel.toLowerCase()
    const optimizationLevelHeader = capitalizeEnum(optimizationLevel)

    return [
        {
            columnIdentifier: `${prefix}.name`,
            prefix: "",
            postfix: "",
            gridColumnProperties: {
                columnHeader: optimizationLevelHeader,

                renderer: { type: RendererType.NAME, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isMetric: false,
                isFixed: false,
                postfix: "",
            },
            valueType: ColumnValueType.STRING,
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn("adspend_costs"),
            gridColumnProperties: {
                columnHeader: "Budget",

                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isFixed: false,
                isMetric: false,
                postfix: "",
            },
            valueType: ColumnValueType.CURRENCY,
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: GoodChangeDirection.HAS_NO_DIRECTION,
            postfix: "",
            prefix: "",
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn("adspend_conversions"),
            gridColumnProperties: {
                columnHeader: "Conversions",

                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isFixed: false,
                isMetric: false,
                postfix: "",
            },
            valueType: ColumnValueType.NUMBER,
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: GoodChangeDirection.UP,
            postfix: "",
            prefix: "",
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn("adspend_total_price"),
            gridColumnProperties: {
                columnHeader: "Total Price",
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isFixed: false,
                isMetric: false,
                postfix: "",
            },
            valueType: ColumnValueType.CURRENCY,
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: GoodChangeDirection.UP,
            postfix: "",
            prefix: "",
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn("adspend_roas"),
            gridColumnProperties: {
                columnHeader: "ROAS",
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isFixed: false,
                isMetric: false,
                postfix: "",
            },
            valueType: ColumnValueType.NUMBER,
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 1,
            goodChangeDirection: GoodChangeDirection.UP,
            postfix: "",
            prefix: "",
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn("adspend_cpo"),
            gridColumnProperties: {
                columnHeader: "CPO",
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isFixed: false,
                isMetric: false,
                postfix: "",
            },
            valueType: ColumnValueType.NUMBER,
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 1,
            goodChangeDirection: GoodChangeDirection.DOWN,
            postfix: "",
            prefix: "",
        },
        {
            columnIdentifier: DimensionService.getDimensionValueColumn("adspend_roi"),
            gridColumnProperties: {
                columnHeader: "ROI",
                renderer: { type: RendererType.VALUE_COMPARISON, cssClasses: new Set() },
                sortable: false,
                editable: false,
                isFixed: false,
                isMetric: false,
                postfix: "",
            },
            valueType: ColumnValueType.PERCENT,
            comparisonItem1Name: scenario1Name,
            comparisonItem2Name: scenario2Name,
            round: 0,
            goodChangeDirection: GoodChangeDirection.UP,
            postfix: "",
            prefix: "",
        },
    ]
}

const mergeRows = (
    columnConfigs: ScenarioColumnConfig[],
    data: ReportingDataSetDTO,
    optimizationLevel: string,
): ReportingDataSetDTO => {
    const { rows, totals } = data
    const prefix = optimizationLevel.toLowerCase()
    const mergedRows = []
    const mergedTotals = {}

    const columns: string[] = Object.keys(rows[0].slices.data[0].data)

    rows.forEach((row, index) => {
        const mergedRow = {}
        columns.forEach((column: string) => {
            const config = columnConfigs.find(
                (c) => c.columnIdentifier === DimensionService.getDimensionValueColumn(column),
            )

            mergedRow[column] = {
                values: [row.slices.data[0].data[column]?.value, row.slices.data[1].data[column]?.value],
                change: {
                    absolute: row.compareValues.data.absoluteChange[column],
                    relative: row.compareValues.data.relativeChange[column],
                },
                comparisonItem1Name: config.comparisonItem1Name,
                comparisonItem2Name: config.comparisonItem2Name,
                goodChangeDirection: config.goodChangeDirection || GoodChangeDirection.UP,
                label: config.gridColumnProperties.columnHeader,
                valueType: config.valueType,
                round: config.round,
            } as ScenarioComparisonData
        })
        mergedRow[prefix] = { name: row[prefix]["name"] }
        mergedRow["comparison_grid_row_index"] = index
        mergedRows.push(mergedRow)
    })

    if (totals && Object.keys(totals).length > 0) {
        columns.forEach((column: string) => {
            const config = columnConfigs.find(
                (c) => c.columnIdentifier === DimensionService.getDimensionValueColumn(column),
            )
            mergedTotals[column] = {
                values: [totals.slices.data[0].data[column]?.value, totals.slices.data[1].data[column]?.value],
                change: {
                    absolute: totals.compareValues.data.absoluteChange[column],
                    relative: totals.compareValues.data.relativeChange[column],
                },
                comparisonItem1Name: config.comparisonItem1Name,
                comparisonItem2Name: config.comparisonItem2Name,
                goodChangeDirection: config.goodChangeDirection || GoodChangeDirection.UP,
                label: config.gridColumnProperties.columnHeader,
                round: config.round,
                valueType: config.valueType,
            } as ScenarioComparisonData
        })
        mergedTotals[prefix] = { name: `Total rows: ${mergedRows.length}` }
    }

    return { rows: mergedRows, totals: mergedTotals }
}

type ScenarioComparisonGridProps = {
    data: ReportingDataSetDTO
    requestData: ComparisonRequestDataDTO
    optimizationLevel: string
    scenario1Name: string
    scenario2Name: string
}

type GridState = {
    error: boolean
    showFooter: boolean
}

const ScenarioComparisonGrid: React.FC<ScenarioComparisonGridProps> = (
    props: ScenarioComparisonGridProps,
): JSX.Element => {
    const { updateRows } = useDataGridContext()
    const [gridState, setGridState] = useState<GridState>({ error: false, showFooter: false })

    const columnConfigs = columnConfig(props.optimizationLevel, props.scenario1Name, props.scenario2Name)

    useEffect(() => {
        try {
            const mergedData = mergeRows(columnConfigs, props.data, props.optimizationLevel)

            updateRows(mergedData)
            setGridState({ error: false, showFooter: Object.keys(mergedData.totals).length > 0 })
        } catch (e) {
            console.error(e)
            setGridState({ error: true, showFooter: false })
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    return (
        <React.Fragment>
            {!gridState.error && <DataGrid columns={columnConfigs} />}

            {gridState.error && <Alert message={"An error occurred loading the scenario comparison data"} />}
        </React.Fragment>
    )
}

export default ScenarioComparisonGrid
