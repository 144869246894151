import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import LogoXIcon from "shared/component/icons/LogoXIcon"
import React from "react"
import { DynamicIcon } from "shared/component/mui/DynamicIcon"
import { useUiConfigQuery } from "shared/uiconfig/useUiConfigQuery"

export const BackendProvidedIcon = () => {
    // Step 1: Is the user logged in via second factor? We can't get custom theme config before that.
    const loggedInViaSecondFactor = useSelector((state: RootState) => state.authentication.loggedInViaSecondFactor)
    const skipUiConfigQuery = !loggedInViaSecondFactor

    // Step 2: Get the config from the backend if the user is logged in.
    const uiConfig = useUiConfigQuery({ skip: skipUiConfigQuery })

    if (!uiConfig.isSuccess) {
        return <LogoXIcon />
    }
    return <DynamicIcon svgString={uiConfig.data.topToolbarLogo} />
}
