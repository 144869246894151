/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { GridColumnStateSettingsValueDTO } from "./GridColumnStateSettingsValueDTO"
import {
    instanceOfGridColumnStateSettingsValueDTO,
    GridColumnStateSettingsValueDTOFromJSON,
    GridColumnStateSettingsValueDTOFromJSONTyped,
    GridColumnStateSettingsValueDTOToJSON,
} from "./GridColumnStateSettingsValueDTO"
import type { SelectedNodeUserSettingsValueDTO } from "./SelectedNodeUserSettingsValueDTO"
import {
    instanceOfSelectedNodeUserSettingsValueDTO,
    SelectedNodeUserSettingsValueDTOFromJSON,
    SelectedNodeUserSettingsValueDTOFromJSONTyped,
    SelectedNodeUserSettingsValueDTOToJSON,
} from "./SelectedNodeUserSettingsValueDTO"

/**
 * @type UserSettingsDTOValue
 *
 * @export
 */
export type UserSettingsDTOValue = GridColumnStateSettingsValueDTO | SelectedNodeUserSettingsValueDTO

export function UserSettingsDTOValueFromJSON(json: any): UserSettingsDTOValue {
    return UserSettingsDTOValueFromJSONTyped(json, false)
}

export function UserSettingsDTOValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsDTOValue {
    if (json == null) {
        return json
    }
    if (instanceOfGridColumnStateSettingsValueDTO(json)) {
        return GridColumnStateSettingsValueDTOFromJSONTyped(json, true)
    }
    if (instanceOfSelectedNodeUserSettingsValueDTO(json)) {
        return SelectedNodeUserSettingsValueDTOFromJSONTyped(json, true)
    }
}

export function UserSettingsDTOValueToJSON(value?: UserSettingsDTOValue | null): any {
    if (value == null) {
        return value
    }

    if (instanceOfGridColumnStateSettingsValueDTO(value)) {
        return GridColumnStateSettingsValueDTOToJSON(value as GridColumnStateSettingsValueDTO)
    }
    if (instanceOfSelectedNodeUserSettingsValueDTO(value)) {
        return SelectedNodeUserSettingsValueDTOToJSON(value as SelectedNodeUserSettingsValueDTO)
    }

    return {}
}
