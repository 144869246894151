import React, { ReactNode, useContext } from "react"
import { NavigateFunction, useLocation, useNavigate, Location } from "react-router-dom"

export type NavigationContextProperties = {
    children?: ReactNode
}

export type NavigationContextProviderProperties = {
    children?: ReactNode
    redirectToLogin?: () => void
    redirectToReferrer?: (withTimeout: boolean) => void
    redirectToUrlPath?: () => void
    navigate?: NavigateFunction
    location?: Location
}
export const NavigationContext = React.createContext<NavigationContextProviderProperties>({})
export const NavigationContextProvider = (props: NavigationContextProperties) => {
    const location = useLocation()
    const navigate = useNavigate()

    const context = React.useMemo(() => {
        return {
            redirectToReferrer: (withTimeout: boolean) => {
                // redirect to the referrer after 2 sec delay
                const referrer = location.state?.referrer
                setTimeout(
                    () => {
                        navigate("/ui/logon", { state: { referrer: referrer || "/ui" } })
                    },
                    withTimeout ? 2000 : 0,
                )
            },
            redirectToLogin: () => {
                navigate("/ui/login", { state: { referrer: location.state?.referrer || "/ui" } })
            },
            redirectToUrlPath: () => {
                navigate(`/ui/logon`, { state: { referrer: location.pathname } })
            },
            navigate: navigate,
            location: location,
        }
    }, [location, navigate])

    return <NavigationContext.Provider value={context}>{props.children}</NavigationContext.Provider>
}

export const useNavigationContext = (): NavigationContextProviderProperties => {
    const context = useContext(NavigationContext)
    if (!context || Object.keys(context).length === 0) {
        throw new Error("Missing NavigationContext in its parent.")
    }

    return context
}
