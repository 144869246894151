import { createSvgIcon } from "@mui/material"
import React from "react"

const TrashIcon = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3333 5.66659V4.99992C13.3333 4.0665 13.3333 3.59979 13.1517 3.24327C12.9919 2.92966 12.7369 2.6747 12.4233 2.51491C12.0668 2.33325 11.6001 2.33325 10.6667 2.33325H9.33333C8.39991 2.33325 7.9332 2.33325 7.57668 2.51491C7.26308 2.6747 7.00811 2.92966 6.84832 3.24327C6.66667 3.59979 6.66667 4.0665 6.66667 4.99992V5.66659M8.33333 10.2499V14.4166M11.6667 10.2499V14.4166M2.5 5.66659H17.5M15.8333 5.66659V14.9999C15.8333 16.4001 15.8333 17.1001 15.5608 17.6349C15.3212 18.1053 14.9387 18.4878 14.4683 18.7274C13.9335 18.9999 13.2335 18.9999 11.8333 18.9999H8.16667C6.76654 18.9999 6.06647 18.9999 5.53169 18.7274C5.06129 18.4878 4.67883 18.1053 4.43915 17.6349C4.16667 17.1001 4.16667 16.4001 4.16667 14.9999V5.66659"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>,
    "Trash",
)

export default TrashIcon
