import { createSvgIcon } from "@mui/material"
import React from "react"

const CircleCheckIcon = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.14583 10.6875L8.64583 13.1875L13.6458 8.18746M18.2292 10.6875C18.2292 15.2898 14.4982 19.0208 9.89583 19.0208C5.29346 19.0208 1.5625 15.2898 1.5625 10.6875C1.5625 6.08509 5.29346 2.35413 9.89583 2.35413C14.4982 2.35413 18.2292 6.08509 18.2292 10.6875Z"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>,
    "Circle Check",
)

export default CircleCheckIcon
