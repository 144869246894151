import React, { useEffect, useState } from "react"
import TabList from "@mui/lab/TabList"
import TabContext from "@mui/lab/TabContext"
import TabPanel from "@mui/lab/TabPanel"
import Tab from "@mui/material/Tab"
import LayoutElement from "shared/component/layout/LayoutElement"
import { FooterState, useRootElementContext } from "shared/component/layout/context/RootElementContext"
import { TabElementDTO } from "generated/models"
import { TabPaneContextProvider } from "shared/component/layout/context/TabPaneContextProvider"
import { Box } from "@mui/material"
import { TabLayoutConfigDTO } from "domain/types"
import { useAnalytics } from "shared/analytics/AnalyticsContext"

type Props = {
    renderSettings?: any
    layoutConfig?: TabLayoutConfigDTO
    children: TabElementDTO[]
}

export type TabState = {
    actualTabIndex: number
    previousTabIndex: number
}

const TabsRenderer: React.FC<Props> = (props: Props): JSX.Element => {
    const { updateFooter } = useRootElementContext()
    const analyticsService = useAnalytics()

    const [tabState, setTabState] = useState({ actualTabIndex: 0, previousTabIndex: 0 } as TabState)

    /**
     * Update the footer based on the settings for tab at index [tabIndex]
     * @param key should be equal to tab index so that we can easily get the tab config
     */
    const onTabChange = (key: string) => {
        const tabConfig = props.children[+key]

        const tabTitle = tabConfig?.title ? tabConfig.title : `Tab ${+key + 1}`
        analyticsService.trackCustomEvent("Tab Change", { tabTitle: tabTitle })

        // update state with some delay to have more smooth tab change
        setTimeout(() => {
            setTabState((prev) => {
                return { actualTabIndex: +key, previousTabIndex: prev.actualTabIndex } as TabState
            })
        }, 200)

        if (tabConfig) {
            if (tabConfig.hideFooter) {
                updateFooter(FooterState.HIDE_FOOTER)
            } else {
                updateFooter(FooterState.SHOW_DEFAULT_FOOTER)
            }
        } else {
            updateFooter(FooterState.SHOW_DEFAULT_FOOTER)
        }
    }

    useEffect(() => {
        //this method is called when the component is rendered for the first time. We use it to set the footer state to the state
        //that is defined by the config of the first tab (because that tab is visible per default)
        onTabChange("0")
        // Missing deps are intentional here, we want to run this only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <TabContext value={tabState.actualTabIndex.toString()}>
            <Box className={"layout-tabs-container"}>
                <TabList className={"tab-list"} onChange={(event, value) => onTabChange(value)}>
                    {props.children.map((child, index) => {
                        const tabTitle = child.title ? child.title : `Tab ${index + 1}`
                        const tabDisabled = child.disabled

                        let tabContent
                        if (tabDisabled && child.disabledTooltip) {
                            tabContent = (
                                <div
                                    data-tip={child.disabledTooltip}
                                    data-tip-classname={"long-text-tip"}
                                    data-force-tooltip={true}
                                >
                                    {tabTitle}
                                </div>
                            )
                        } else {
                            tabContent = tabTitle
                        }

                        return (
                            <Tab
                                disabled={tabDisabled}
                                label={tabContent}
                                value={index.toString()}
                                key={index.toString()}
                                className={"layout-element layout-element-tabs"}
                            />
                        )
                    })}
                </TabList>
                {props.children.map((child, index) => {
                    return (
                        <TabPanel key={index.toString()} value={index.toString()}>
                            <TabPaneContextProvider tabState={tabState} tabIndex={index}>
                                <LayoutElement layoutElementConfig={child} />
                            </TabPaneContextProvider>
                        </TabPanel>
                    )
                })}
            </Box>
        </TabContext>
    )
}

export default TabsRenderer
