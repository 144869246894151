/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
/**
 *
 * @export
 * @interface OptionDTO
 */
export interface OptionDTO {
    /**
     *
     * @type {string}
     * @memberof OptionDTO
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof OptionDTO
     */
    value: string
}

/**
 * Check if a given object implements the OptionDTO interface.
 */
export function instanceOfOptionDTO(value: object): value is OptionDTO {
    if (!("name" in value) || value["name"] === undefined) return false
    if (!("value" in value) || value["value"] === undefined) return false
    return true
}

export function OptionDTOFromJSON(json: any): OptionDTO {
    return OptionDTOFromJSONTyped(json, false)
}

export function OptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTO {
    if (json == null) {
        return json
    }
    return {
        name: json["name"],
        value: json["value"],
    }
}

export function OptionDTOToJSON(value?: OptionDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        name: value["name"],
        value: value["value"],
    }
}
