import { ActionDTO, DimensionDTO } from "generated/models"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GridDataRowDTO } from "domain/types"

export type ActionModalConfigState = {
    actionModalConfig: ActionModalConfig
}
export type ActionModalConfig = {
    action: ActionDTO
    selectedItems: GridDataRowDTO[]
    mainDimension: DimensionDTO
    apiPath: string
}

const initialState: ActionModalConfigState = {
    actionModalConfig: undefined,
}

const slice = createSlice({
    name: "actionModals",
    initialState,
    reducers: {
        openActionModal(state: ActionModalConfigState, action: PayloadAction<ActionModalConfig>): void {
            state.actionModalConfig = action.payload
        },
        closeActionModal(state: ActionModalConfigState): void {
            state.actionModalConfig = undefined
        },
    },
})

export const { openActionModal: openActionModal, closeActionModal: closeActionModal } = slice.actions

export const actionModalSliceReducer = slice.reducer
