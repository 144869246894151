import React from "react"
import Checkbox from "@mui/material/Checkbox"
import Typography from "@mui/material/Typography"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, useTheme } from "@mui/material"
import TextField from "@mui/material/TextField"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { ColumnUtil } from "domain/ColumnConfigurator/components/ColumnUtil"
import Grid from "@mui/material/Unstable_Grid2"
import { run } from "shared/util/FunctionUtil"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

export const DimensionSelectionPanel = (): JSX.Element => {
    const {
        dataDefinitions: { dimensions },
    } = useReportingConfigurationContext()

    const { onColumnSelectAction } = ColumnConfiguratorContextSlices.useColumnSettingsChangeHandlers()
    const selectedDimensions = ColumnConfiguratorContextSlices.useSelectedDimensions()
    const { supportedDimensions } = ColumnConfiguratorContextSlices.useSupportedColumns()
    const widgetStaticConfiguration = ColumnConfiguratorContextSlices.useWidgetStaticConfiguration()

    const theme = useTheme()

    if (!widgetStaticConfiguration.areDimensionsSupported()) {
        return <React.Fragment />
    }

    const selectedDimensionIdentifiers = ColumnUtil.getIdentifiers(selectedDimensions)

    const sortedDimensions = Array.from(dimensions.entries())
        .filter(([dimensionName]) => supportedDimensions.has(dimensionName))
        .sort((a, b) => a[1].displayName.localeCompare(b[1].displayName))

    const options = sortedDimensions.map(([dimensionName, dimension]) => {
        return { id: dimensionName, title: dimension.displayName }
    })
    const initiallySelected = selectedDimensionIdentifiers.map((dimensionId) => {
        const dimension = dimensions.get(dimensionId)
        return { id: dimensionId, title: dimension?.displayName ?? "N/A" }
    })

    const onChange = (
        _event: React.SyntheticEvent,
        _value: any,
        _reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<{ id: string; title: string }>,
    ) => {
        const id = details?.option?.id
        if (id !== undefined) {
            onColumnSelectAction(id, "toggle")
        }
    }

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    const isMaxDimensionsLimitReached = selectedDimensionIdentifiers.length >= widgetStaticConfiguration.maxDimensions
    const isAtLeastOneDimensionSelected = selectedDimensionIdentifiers.length > 0

    const dimensionSelectionHint = run(() => {
        if (isMaxDimensionsLimitReached) {
            return `Maximum of ${widgetStaticConfiguration.maxDimensions} dimensions reached`
        }
        if (!isAtLeastOneDimensionSelected) {
            return "Please select at least one dimension"
        }
        return undefined
    })
    const dimensionSelectionHintBox = dimensionSelectionHint ? (
        <Box sx={{ color: "error.main" }}>{dimensionSelectionHint}</Box>
    ) : (
        <React.Fragment />
    )

    return (
        <Box className={"dimension-selection-panel"} sx={{ paddingBottom: theme.spacing(2) }}>
            <Grid container sx={{ paddingBottom: "10px" }}>
                <Grid sx={{ paddingBottom: "5px" }}>
                    <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                        Select Dimensions:
                    </Typography>
                </Grid>
                <Grid container alignItems="center" sx={{ paddingLeft: "10px" }}>
                    {dimensionSelectionHintBox}
                </Grid>
            </Grid>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                getOptionDisabled={() => isMaxDimensionsLimitReached}
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(option) => option.title}
                defaultValue={initiallySelected}
                size={"small"}
                renderOption={(props, option) => {
                    const checked = selectedDimensionIdentifiers.includes(option.id)

                    return (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={checked}
                            />
                            {option.title}
                        </li>
                    )
                }}
                value={initiallySelected}
                onChange={onChange}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} placeholder="" />}
            />
        </Box>
    )
}
