import moment, { Moment } from "moment"

export type TimeRange = TimeRangePreset | CustomTimeRange

export type TimeRangePreset = {
    type: TimeRangePresetType
}

export type CustomTimeRange = {
    type: "custom"
    dates: TimeRangeDates
}

export type TimeRangeDates = [start: Moment, end: Moment]

export type TimeRangePresetType =
    | "yesterday"
    | "current-quarter"
    | "current-month"
    | "current-week"
    | "previous-quarter"
    | "previous-month"
    | "previous-week"

export const getDatesFromTimeRange = (timeRange: TimeRange): TimeRangeDates => {
    if (timeRange.type === "custom") {
        return timeRange.dates
    }

    return timeRangePresets[timeRange.type].getValue()
}

export const timeRangePresets: {
    [key in TimeRangePresetType]: {
        label: string
        getValue: () => TimeRangeDates
    }
} = {
    yesterday: {
        label: "Yesterday",
        getValue: () => {
            const yesterday = moment().subtract(1, "day")
            return [yesterday.clone(), yesterday.clone()]
        },
    },
    "current-quarter": {
        label: "This quarter",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("quarter"), today.clone().endOf("quarter")]
        },
    },
    "current-month": {
        label: "This month",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("month"), today.clone().endOf("month")]
        },
    },
    "current-week": {
        label: "This Week",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("week"), today.clone().endOf("week")]
        },
    },
    "previous-quarter": {
        label: "Previous quarter",
        getValue: () => {
            const previousQuarter = moment().subtract(1, "quarter")
            return [previousQuarter.clone().startOf("quarter"), previousQuarter.clone().endOf("quarter")]
        },
    },
    "previous-month": {
        label: "Previous month",
        getValue: () => {
            const previousMonth = moment().subtract(1, "month")
            return [previousMonth.clone().startOf("month"), previousMonth.clone().endOf("month")]
        },
    },
    "previous-week": {
        label: "Previous week",
        getValue: () => {
            const previousWeek = moment().subtract(1, "week")
            return [previousWeek.clone().startOf("week"), previousWeek.clone().endOf("week")]
        },
    },
}

export const getLabelFromDateRange = (start: Moment, end: Moment) => {
    for (const item of Object.values(timeRangePresets)) {
        const [rangeStart, rangeEnd] = item.getValue()

        if (!rangeStart || !rangeEnd) {
            continue
        }

        if (start.isSame(rangeStart, "day") && end.isSame(rangeEnd, "day")) {
            return item.label
        }
    }

    return `${start.format("DD.MM.YYYY")} - ${end.format("DD.MM.YYYY")}`
}

export const getPreviousPeriod = (timeRange: TimeRange): CustomTimeRange => {
    const [start, end] = getDatesFromTimeRange(timeRange)
    switch (timeRange.type) {
        case "current-quarter": {
            return getPreviousPeriodFromCustomTimeRange(start, end.clone().endOf("quarter"))
        }
        case "current-month": {
            return getPreviousPeriodFromCustomTimeRange(start, end.clone().endOf("month"))
        }
        case "current-week": {
            return getPreviousPeriodFromCustomTimeRange(start, end.clone().endOf("week"))
        }
        default: {
            return getPreviousPeriodFromCustomTimeRange(start, end)
        }
    }
}

export const getPreviousPeriodFromCustomTimeRange = (start: Moment, end: Moment): CustomTimeRange => {
    const periodUnits = ["year", "quarter", "month", "week"] as const
    let previousStartDate, previousEndDate

    /**
     * Check periods first because just jumping back the same period duration
     * can lead to a few inconsistencies because of leap years and February being 28 days
     **/
    for (const unit of periodUnits) {
        if (start.isSame(start.clone().startOf(unit), "day") && end.isSame(end.clone().endOf(unit), "day")) {
            previousStartDate = start.clone().subtract(1, unit).startOf(unit)
            previousEndDate = previousStartDate.clone().endOf(unit)
            return { type: "custom", dates: [previousStartDate, previousEndDate] }
        }
    }

    return getPreviousPeriodExact(start, end)
}

export const getPreviousPeriodExact = (start: Moment, end: Moment): CustomTimeRange => {
    const periodLengthInDays = end.diff(start, "days") + 1
    const previousStartDate = start.clone().subtract(periodLengthInDays, "days")
    const previousEndDate = end.clone().subtract(periodLengthInDays, "days")
    return { type: "custom", dates: [previousStartDate, previousEndDate] }
}
