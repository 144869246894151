import * as React from "react"
import _assignIn from "lodash/assignIn"
import queryString from "query-string"
import { AppContextDTO } from "generated/models"
import { log } from "shared/util/log"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { storeAppContextSuccess } from "domain/core/redux/appcontext.slice"

interface Props extends DispatchProps {
    children: React.ReactNode
    storeAppContext: (appContext: AppContextDTO) => any
}

export const AppContextFromQuery: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
    const location = useLocation()

    useEffect(() => {
        const appContext: AppContextDTO = { advertiserId: -1, advertiserName: "" }

        if (!location || !location.search) {
            return
        }

        const query = queryString.parse(location.search)
        if (!query.appContextDTO) {
            return
        }

        try {
            // @ts-expect-error TODO fix me
            _assignIn(appContext, JSON.parse(query.appContextDTO))
        } catch (err) {
            log.error(`Error during parsing query string ${location.search}`, err)
        }

        props.storeAppContext(appContext)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{props.children}</>
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        storeAppContext: (appContext: AppContextDTO) => dispatch(storeAppContextSuccess({ appContext })),
    }
}

type DispatchProps = typeof mapDispatchToProps

export default connect(null, mapDispatchToProps)(AppContextFromQuery)
