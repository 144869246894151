import React from "react"

import Grid from "@mui/material/Unstable_Grid2"
import { Box, GridProps } from "@mui/material"

export const SideImage = (props: GridProps) => {
    return (
        <Grid
            {...props}
            className={"side-image"}
            sx={{
                backgroundImage: "url(/ui/side-image.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <Box
                sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    padding: "50px",
                    "@media (max-width:1024px)": {
                        padding: "20px",
                    },
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                }}
            >
                {props.children}
            </Box>
        </Grid>
    )
}

export default SideImage
