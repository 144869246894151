import shajs from "sha.js"

const PasswordService = {
    //FIXME this simulates the MySQL `PASSWORD` function and must be improved once the passwords are stored differently
    hash: (password: string): string => {
        const hash1 = shajs("sha1")
        hash1.update(password)
        const hash2 = shajs("sha1")
        hash2.update(hash1.digest())

        return "*" + hash2.digest("hex").toUpperCase()
    },
}

export default PasswordService
