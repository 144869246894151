declare const gatewayUrl: string
declare const uiServiceApiUrl: string
declare const uiContextPath: string
declare const filterServiceApiUrl: string
declare const userServiceApiUrl: string
declare const reportingServiceApiUrl: string
declare const dashboardApiUrl: string
declare const classicUiUrl: string

const UrlService = {
    getGatewayUrl: () => {
        return gatewayUrl
    },

    getUiServiceApiUrl: () => {
        return uiServiceApiUrl
    },

    getUiContextPath: () => {
        return uiContextPath
    },

    getFilterServiceApiUrl: () => {
        return filterServiceApiUrl
    },

    getUserServiceApiUrl: () => {
        return userServiceApiUrl
    },

    getReportingServiceApiUrl: () => {
        return reportingServiceApiUrl
    },

    getDashboardApiUrl: () => {
        return dashboardApiUrl
    },

    getClassicUiUrl: () => {
        return classicUiUrl
    },
}

export default UrlService
