import widgetSettingsReducer from "domain/legacy/widget/redux/widget.slice"
import { combineReducers } from "redux"
import modalsReducer from "shared/component/modals/redux/modals.slice"
import * as env from "shared/util/env"
import { configureStore } from "@reduxjs/toolkit"
import authenticationReducer from "domain/authentication/redux/authentication.slice"
import appContextReducer from "domain/core/redux/appcontext.slice"
import userReducer from "domain/user/redux/user.slice"
import { useDispatch } from "react-redux"
import { userSwitchApi } from "domain/authentication/service/userswitch.service"
import { notificationsSliceReducer } from "domain/notification/redux/notification.slice"
import { passwordExpirationSliceReducer } from "domain/password/redux/password.expiration.slice"
import { actionModalSliceReducer } from "shared/component/modals/action/ActionModal.slice"

const rootReducer = combineReducers({
    widgetSettingsState: widgetSettingsReducer,
    authentication: authenticationReducer,
    appContext: appContextReducer,
    user: userReducer,
    modals: modalsReducer,
    notifications: notificationsSliceReducer,
    passwordExpiration: passwordExpirationSliceReducer,
    actionModal: actionModalSliceReducer,
    [userSwitchApi.reducerPath]: userSwitchApi.reducer,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: !env.isProd(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(userSwitchApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
