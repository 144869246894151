import React from "react"
import { MenuItem, Select, SelectChangeEvent } from "@mui/material"

import {
    TOUCHPOINT_FILTER_DIMENSIONS,
    TouchpointFilterDimension,
} from "domain/ConversionList/domain/touchpointFilterDimension"

export interface TouchpointDimensionFilterSelectProps {
    selectedTouchpointFilterDimension: TouchpointFilterDimension
    updateSelectedTouchpointFilterDimension: (newTouchpointFilterDimension: TouchpointFilterDimension) => void
}

export const TouchpointDimensionFilterSelect = ({
    selectedTouchpointFilterDimension,
    updateSelectedTouchpointFilterDimension,
}: TouchpointDimensionFilterSelectProps) => {
    const onChange = (event: SelectChangeEvent<TouchpointFilterDimension>) => {
        updateSelectedTouchpointFilterDimension(event.target.value as TouchpointFilterDimension)
    }

    return (
        <Select value={selectedTouchpointFilterDimension} onChange={onChange}>
            {Object.entries(TOUCHPOINT_FILTER_DIMENSIONS).map(([dimension, props]) => {
                return (
                    <MenuItem key={dimension} value={dimension}>
                        {props.displayName}
                    </MenuItem>
                )
            })}
        </Select>
    )
}
