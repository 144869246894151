import React from "react"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"

export interface InfoTextContentProps {
    columnUniqueName: string
    columnDisplayName: string
}

export const InfoTextContent = ({ columnUniqueName, columnDisplayName }: InfoTextContentProps): JSX.Element => {
    const {
        dataDefinitions: { infoTexts },
    } = useReportingConfigurationContext()

    const infoText = infoTexts.get(columnUniqueName)?.infoText ?? ""

    // break up tooltip text on <br> tags from the database
    const infoTextFormatted = infoText
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/"/g, "&quot;") // escape double quotes
        .replace(/'/g, "&apos;") // escape single quotes

    return (
        <div className="info-text-content">
            <h3>{columnDisplayName}</h3>
            {infoTextFormatted && <div dangerouslySetInnerHTML={{ __html: infoTextFormatted }} />}
        </div>
    )
}
