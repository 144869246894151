import { Navigate } from "react-router-dom"
import React from "react"

export interface RedirectToProps {
    predicate?: boolean
    successPath: string
    errorPath?: string
    saveReferrer?: boolean
}

/**
 * Redirects to `successPath` if `predicate` is true, else redirects to `errorPath`.
 */
export const RedirectTo = ({
    predicate = true,
    successPath,
    errorPath = successPath,
    saveReferrer = true,
}: RedirectToProps) => {
    const path = predicate ? successPath : errorPath
    const state = saveReferrer
        ? { referrer: [location.pathname, location.search].filter((val) => val).join("") }
        : undefined

    return <Navigate to={path} replace state={state} />
}
