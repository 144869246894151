import React, { useEffect } from "react"
import {
    ColumnInfoTexts,
    ColumnUniqueName,
    DataColumn,
    DataGroup,
    DataGroups,
    DataGroupUniqueName,
    Dimension,
    Dimensions,
    Metric,
    Metrics,
    MetricsFrontendGroup,
    MetricsFrontendGroups,
    MetricsFrontendGroupUniqueName,
} from "domain/ColumnConfigurator/types"
import { log } from "shared/util/log"
import { AppContextState } from "domain/core/redux/appcontext.slice"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import { ReportingService } from "domain/reporting/ReportingService"
import { AppContextDTO } from "generated/models"

/*
 * Context shape definition
 */
export interface ReportingConfigurationContextProps {
    readonly dataDefinitions: DataDefinitions
    readonly helpers: DataDefinitionsHelpers
    readonly initialized: boolean
}

export interface DataDefinitions {
    readonly dimensions: Dimensions
    readonly metrics: Metrics
    readonly dataGroups: DataGroups
    readonly metricsFrontendGroups: MetricsFrontendGroups
    readonly infoTexts: ColumnInfoTexts
}

const emptyDataDefinitions: DataDefinitions = {
    dimensions: new Map(),
    metrics: new Map(),
    dataGroups: new Map(),
    metricsFrontendGroups: new Map(),
    infoTexts: new Map(),
}

export interface DataDefinitionsHelpers {
    readonly getColumn: (columnUniqueName: ColumnUniqueName) => DataColumn | undefined
    readonly getMetric: (columnUniqueName: ColumnUniqueName) => Metric | undefined

    readonly findDimensions: (predicate: (dimension: Dimension) => boolean) => ReadonlySet<ColumnUniqueName>
    readonly findMetrics: (predicate: (metric: Metric) => boolean) => ReadonlySet<ColumnUniqueName>
}

const ReportingConfigurationContext = React.createContext<ReportingConfigurationContextProps | undefined>(undefined)

export const useReportingConfigurationContext = (): ReportingConfigurationContextProps => {
    const context = React.useContext(ReportingConfigurationContext)

    if (context === undefined) {
        throw new Error("useReportingConfigurationContext must be used within a ReportingConfigurationContextProvider")
    }

    return context
}

/*
 * Context provider
 */

export interface ReportingConfigurationContextProviderProps {
    readonly loadDataDefinitions?: (appContext: AppContextDTO) => Promise<DataDefinitions>
}

export const ReportingConfigurationContextProvider = ({
    loadDataDefinitions = ReportingService.loadDataDefinitions,
    children,
}: React.PropsWithChildren<ReportingConfigurationContextProviderProps>): JSX.Element => {
    const [initialized, setInitialized] = React.useState<boolean>(false)
    const [dataDefinitions, setDataDefinitions] = React.useState<DataDefinitions>(undefined)
    const appContextState: AppContextState = useSelector((state: RootState) => state.appContext)

    useEffect(() => {
        setInitialized(false)
        if (appContextState.appContext.advertiserId) {
            loadDataDefinitions(appContextState.appContext).then((dataDefinitions) => {
                setDataDefinitions(dataDefinitions)
                setInitialized(true)
            })
        }
    }, [appContextState.appContext, loadDataDefinitions])

    const filteredDataDefinitions = React.useMemo(() => {
        return dataDefinitions ? ensureInvariants(dataDefinitions) : emptyDataDefinitions
    }, [dataDefinitions])

    const getColumn = React.useCallback(
        (columnUniqueName: ColumnUniqueName): DataColumn | undefined => {
            return (
                filteredDataDefinitions.dimensions.get(columnUniqueName) ||
                filteredDataDefinitions.metrics.get(columnUniqueName)
            )
        },
        [filteredDataDefinitions],
    )

    const getMetric = React.useCallback(
        (columnUniqueName: ColumnUniqueName): Metric | undefined => {
            return filteredDataDefinitions.metrics.get(columnUniqueName)
        },
        [filteredDataDefinitions],
    )

    const findDimensions = React.useCallback(
        (predicate: (dimension: Dimension) => boolean): ReadonlySet<ColumnUniqueName> => {
            const dimensions = Array.from(filteredDataDefinitions.dimensions.values())
            return new Set(dimensions.filter(predicate).map((dimension) => dimension.uniqueName))
        },
        [filteredDataDefinitions],
    )

    const findMetrics = React.useCallback(
        (predicate: (metric: Metric) => boolean): ReadonlySet<ColumnUniqueName> => {
            const metrics = Array.from(filteredDataDefinitions.metrics.values())
            return new Set(metrics.filter(predicate).map((metric) => metric.uniqueName))
        },
        [filteredDataDefinitions],
    )

    const helpers = React.useMemo<DataDefinitionsHelpers>(() => {
        return {
            getColumn: getColumn,
            getMetric: getMetric,
            findDimensions: findDimensions,
            findMetrics: findMetrics,
        }
    }, [getColumn, getMetric, findDimensions, findMetrics])

    const contextValue: ReportingConfigurationContextProps = React.useMemo(
        () => ({
            dataDefinitions: filteredDataDefinitions,
            helpers: helpers,
            initialized: initialized,
        }),
        [filteredDataDefinitions, helpers, initialized],
    )

    return (
        <ReportingConfigurationContext.Provider value={contextValue}>{children}</ReportingConfigurationContext.Provider>
    )
}

const ensureInvariants = (dataDefinitions: DataDefinitions): DataDefinitions => {
    const { dimensions, metrics, dataGroups, metricsFrontendGroups, infoTexts } = dataDefinitions

    // Filter frontend groups to only include metrics that are actually defined
    const filteredMetricsFrontendGroups = new Map<MetricsFrontendGroupUniqueName, MetricsFrontendGroup>()
    for (const [frontendGroupName, frontendGroup] of metricsFrontendGroups) {
        const filteredMetrics = new Set<ColumnUniqueName>()
        for (const metricName of frontendGroup.metrics) {
            if (metrics.has(metricName)) {
                filteredMetrics.add(metricName)
            } else {
                log.debug(`Metric ${metricName} is referenced in frontend group ${frontendGroupName} but not defined`)
            }
        }
        filteredMetricsFrontendGroups.set(frontendGroupName, { ...frontendGroup, metrics: filteredMetrics })
    }

    // Filter data groups to only include dimensions and metrics that are actually defined
    const filteredDataGroups = new Map<DataGroupUniqueName, DataGroup>()
    for (const [dataGroupName, dataGroup] of dataGroups) {
        const filteredDimensions = new Set<ColumnUniqueName>()
        const filteredMetrics = new Set<ColumnUniqueName>()
        for (const dimensionName of dataGroup.dimensions) {
            if (dimensions.has(dimensionName)) {
                filteredDimensions.add(dimensionName)
            } else {
                log.debug(`Column ${dimensionName} is referenced in data group ${dataGroupName} but not defined`)
            }
        }
        for (const metricName of dataGroup.metrics) {
            if (metrics.has(metricName)) {
                filteredMetrics.add(metricName)
            } else {
                log.debug(`Column ${metricName} is referenced in data group ${dataGroupName} but not defined`)
            }
        }
        filteredDataGroups.set(dataGroupName, {
            ...dataGroup,
            dimensions: filteredDimensions,
            metrics: filteredMetrics,
        })
    }

    return {
        ...dataDefinitions,
        metricsFrontendGroups: filteredMetricsFrontendGroups,
        dataGroups: filteredDataGroups,
    }
}
