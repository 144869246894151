/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import { FailedLoginResponseDTOFromJSONTyped } from "./FailedLoginResponseDTO"
import { PasswordExpiredLoginResponseDTOFromJSONTyped } from "./PasswordExpiredLoginResponseDTO"
import { RedirectLoginResponseDTOFromJSONTyped } from "./RedirectLoginResponseDTO"
import { SuccessfulLoginResponseDTOFromJSONTyped } from "./SuccessfulLoginResponseDTO"
/**
 *
 * @export
 * @interface LoginResponseDTO
 */
export interface LoginResponseDTO {
    /**
     *
     * @type {string}
     * @memberof LoginResponseDTO
     */
    type: LoginResponseDTOTypeEnum
}

/**
 * @export
 */
export const LoginResponseDTOTypeEnum = {
    SUCCESSFUL_LOGIN_RESPONSE_DTO: "SuccessfulLoginResponseDTO",
    FAILED_LOGIN_RESPONSE_DTO: "FailedLoginResponseDTO",
    PASSWORD_EXPIRED_LOGIN_RESPONSE_DTO: "PasswordExpiredLoginResponseDTO",
    REDIRECT_LOGIN_RESPONSE_DTO: "RedirectLoginResponseDTO",
} as const
export type LoginResponseDTOTypeEnum = (typeof LoginResponseDTOTypeEnum)[keyof typeof LoginResponseDTOTypeEnum]

/**
 * Check if a given object implements the LoginResponseDTO interface.
 */
export function instanceOfLoginResponseDTO(value: object): value is LoginResponseDTO {
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function LoginResponseDTOFromJSON(json: any): LoginResponseDTO {
    return LoginResponseDTOFromJSONTyped(json, false)
}

export function LoginResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponseDTO {
    if (json == null) {
        return json
    }
    if (!ignoreDiscriminator) {
        if (json["type"] === "FailedLoginResponseDTO") {
            return FailedLoginResponseDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "PasswordExpiredLoginResponseDTO") {
            return PasswordExpiredLoginResponseDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "RedirectLoginResponseDTO") {
            return RedirectLoginResponseDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "SuccessfulLoginResponseDTO") {
            return SuccessfulLoginResponseDTOFromJSONTyped(json, true)
        }
    }
    return {
        type: json["type"],
    }
}

export function LoginResponseDTOToJSON(value?: LoginResponseDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        type: value["type"],
    }
}
