import React from "react"
import { SelectedColumnsSidebarList } from "./SelectedColumnsSidebarList/SelectedColumnsSidebarList"
import { useTheme } from "@mui/material"
import Paper from "@mui/material/Paper"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"

export const SelectedColumnsSidebar = (): JSX.Element => {
    const { onColumnSelectAction, onColumnSettingsChanged, onColumnMoved } =
        ColumnConfiguratorContextSlices.useColumnSettingsChangeHandlers()
    const selectedDimensions = ColumnConfiguratorContextSlices.useSelectedDimensions()
    const selectedMetrics = ColumnConfiguratorContextSlices.useSelectedMetrics()
    const { leftPinnedMetrics } = ColumnConfiguratorContextSlices.usePinnedMetrics()
    const widgetStaticConfiguration = ColumnConfiguratorContextSlices.useWidgetStaticConfiguration()
    const labels = ColumnConfiguratorContextSlices.useLabels()

    const theme = useTheme()

    const getHeaderText = (typeName: string, typeNamePlural: string, count: number) => {
        const word = count === 1 ? typeName : typeNamePlural
        return count === 0 ? `No ${word} selected` : `${count} ${word} selected:`
    }

    return (
        <Paper
            className={"selected-column-sidebar"}
            sx={{
                backgroundColor: theme.palette.pageBackgroundColor,
                boxShadow: "inset 4px 5px 10px rgba(0,0,0,0.03)",
                borderRadius: 0,
                borderLeft: "1px solid " + theme.palette.border.subtle,
                padding: theme.spacing(2),
                height: "100%",
                "& h6": {
                    opacity: "0.7",
                    textTransform: "uppercase",
                    fontSize: "11px",
                    margin: 0,
                },
                "& .draggable-item:first-of-type .element": {
                    borderTopWidth: "1px",
                },
                "& .draggable-item:last-of-type .element": {
                    marginBottom: theme.spacing(2),
                },
                "& .is-dragging .element": {
                    borderWidth: "1px",
                    "& *": {
                        opacity: 0,
                    },
                },
                "& .element": {
                    background: theme.palette.background.default,
                    border: "1px solid " + theme.palette.divider,
                    borderTopWidth: 0,
                    margin: "0",
                    padding: "2px 0",
                    "& .hover-hide-block-content": {
                        transition: "opacity 0.1s ease-in-out",
                        opacity: 0,
                    },
                    "&:hover": {
                        "& .tools": {
                            background: theme.palette.background.default,
                        },
                        "& .hover-hide-block .hover-hide-block-content": {
                            opacity: 1,
                        },
                        "& .drag-indicator": {
                            opacity: 1,
                        },
                    },
                    "& .incompatible-metric .column-description": {
                        opacity: 0.3,
                    },
                    "& .drag-indicator": {
                        transition: "opacity 0.1s ease-in-out",
                        opacity: 0.3,
                        height: "18px",
                        width: "18px",
                        marginTop: "1px",
                    },
                    "& .MuiListItemIcon-root": {
                        minWidth: "20px",
                    },
                    "& .MuiListItem-root": {
                        alignItems: "start",
                        marginBottom: "1px",
                        paddingLeft: "2px",
                    },
                    [theme.breakpoints.down("md")]: {},
                    "& .tools": {
                        background: "transparent",
                        transition: "all 0.1s ease-in-out",
                        right: 0,
                        top: 0,
                        position: "absolute",
                        "& .MuiSvgIcon-root": {
                            height: "18px",
                            width: "18px",
                        },
                        "& .MuiButtonBase-root": {
                            padding: "3px 0",
                        },
                    },
                },
            }}
        >
            {widgetStaticConfiguration.areDimensionsSupported() && (
                <>
                    <h6>{getHeaderText("dimension", "dimensions", selectedDimensions.length)}</h6>
                    <SelectedColumnsSidebarList
                        selectedColumns={selectedDimensions}
                        onSelectedColumnsChanged={onColumnSelectAction}
                        leftPinnedColumns={[]}
                        onColumnSettingsChanged={onColumnSettingsChanged}
                        onColumnMoved={onColumnMoved}
                    />
                </>
            )}
            <h6>{getHeaderText(labels.metric, labels.metricPlural, selectedMetrics.length)}</h6>
            <SelectedColumnsSidebarList
                selectedColumns={selectedMetrics}
                onSelectedColumnsChanged={onColumnSelectAction}
                leftPinnedColumns={leftPinnedMetrics}
                onColumnSettingsChanged={onColumnSettingsChanged}
                onColumnMoved={onColumnMoved}
            />
        </Paper>
    )
}
