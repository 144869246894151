import { FormElementDTO, FormElementProperties } from "domain/types"
import React from "react"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"
import {
    FormElementValidatorType,
    MaxValueFormElementValidationDTO,
    MinValueFormElementValidationDTO,
} from "shared/util/validation"
import { TextField } from "./primitives/TextField"
import { FormElementValidationDTO } from "generated/models"

export const FormElementNumberInput = ({
    layoutElementConfig,
    editable,
    isRequiredField,
}: FormElementProperties): JSX.Element => {
    const { updateElementSettings } = useRootElementContext()

    const { useAsSetting, formFieldConfig } = layoutElementConfig

    const maxValue = getValidationValue(
        FormElementValidatorType.MAX_VALUE_FORM_ELEMENT_VALIDATION_DTO,
        layoutElementConfig,
    )
    const minValue = getValidationValue(
        FormElementValidatorType.MIN_VALUE_FORM_ELEMENT_VALIDATION_DTO,
        layoutElementConfig,
    )

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (useAsSetting) {
            updateElementSettings({ key: formFieldConfig.dimensionIdentifier, value: event.target.value })
        }
    }

    return (
        <TextField
            textFieldType={{
                type: "number",
                max: maxValue ? (maxValue as MaxValueFormElementValidationDTO).maxValue : Number.MAX_SAFE_INTEGER,
                min: minValue ? (minValue as MinValueFormElementValidationDTO).minValue : Number.MIN_SAFE_INTEGER,
            }}
            name={formFieldConfig.dimensionIdentifier}
            label={formFieldConfig.displayName}
            onChange={onChange}
            disabled={!editable}
            required={isRequiredField}
        />
    )
}

/**
 * Searches for the validation type and if found, then returns the validation value
 *
 * @param type
 * @param layoutElementConfig
 */
const getValidationValue = (
    type: FormElementValidatorType,
    layoutElementConfig: FormElementDTO,
): FormElementValidationDTO => {
    return Array.from(layoutElementConfig.formFieldConfig.validation || [])?.find(
        (validationConfig) => validationConfig.type === type,
    )
}
