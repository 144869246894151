import { useConversionListContextSelector } from "domain/ConversionList/context/ConversionListContext"
import {
    GetDimensionValuesHookParams,
    LoadCustomerJourneyHookParams,
    LoadCustomerJourneyPageConfigHookParams,
    LoadDataHookParams,
    LoadPixelHookParams,
    useConversionListExternalDependencyContextSelector,
} from "domain/ConversionList/context/ConversionListExternalDependencyContext"

const useCurrentQueryConfig = () => useConversionListContextSelector((ctx) => ctx.currentQueryConfig)
const useHasUnappliedChanges = () => useConversionListContextSelector((ctx) => ctx.hasUnappliedChanges)
const useApplyChanges = () => useConversionListContextSelector((ctx) => ctx.applyChanges)
const useConversionListType = () => useConversionListContextSelector((ctx) => ctx.type)
const useColumns = () => useConversionListContextSelector((ctx) => ctx.columns)
const useSelectedColumns = () => useConversionListContextSelector((ctx) => ctx.selectedColumns)
const useUpdateSelectedColumns = () => useConversionListContextSelector((ctx) => ctx.updateSelectedColumns)
const useLeftPinnedColumns = () => useConversionListContextSelector((ctx) => ctx.leftPinnedColumns)
const useUpdateLeftPinnedColumns = () => useConversionListContextSelector((ctx) => ctx.updateLeftPinnedColumns)
const useTimeRange = () => useConversionListContextSelector((ctx) => ctx.timeRange)
const useUpdateTimeRange = () => useConversionListContextSelector((ctx) => ctx.updateTimeRange)
const useSortSettings = () => useConversionListContextSelector((ctx) => ctx.sortSettings)
const useSetSortSettings = () => useConversionListContextSelector((ctx) => ctx.setSortSettings)
const useConversionTypeSelection = () => useConversionListContextSelector((ctx) => ctx.conversionTypeSelection)
const useFullOrSoftConversionSelection = () =>
    useConversionListContextSelector((ctx) => ctx.fullOrSoftConversionSelection)
const useUpdateFullOrSoftConversionSelection = () =>
    useConversionListContextSelector((ctx) => ctx.updateFullOrSoftConversionSelection)
const useUpdateConversionTypeSelection = () =>
    useConversionListContextSelector((ctx) => ctx.updateConversionTypeSelection)
const useTrackingStateSelection = () => useConversionListContextSelector((ctx) => ctx.trackingStateSelection)
const useUpdateTrackingStateSelection = () =>
    useConversionListContextSelector((ctx) => ctx.updateTrackingStateSelection)
const useCustomerJourneyConfig = () => useConversionListContextSelector((ctx) => ctx.customerJourneyConfig)
const useUpdateCustomerJourneyConfig = () => useConversionListContextSelector((ctx) => ctx.updateCustomerJourneyConfig)
const useResetGridColumnState = () => useConversionListContextSelector((ctx) => ctx.resetGridColumnState)
const useSearchConfig = () => useConversionListContextSelector((ctx) => ctx.searchConfig)
const useUpdateSearchConfig = () => useConversionListContextSelector((ctx) => ctx.updateSearchConfig)
const usePropertyFilterDialogOpen = () => useConversionListContextSelector((ctx) => ctx.propertyFilterDialogOpen)
const usePropertyFilterDialogMode = () => useConversionListContextSelector((ctx) => ctx.propertyFilterDialogMode)
const usePropertyFilterDialogId = () => useConversionListContextSelector((ctx) => ctx.propertyFilterDialogId)
const useOpenPropertyFilterDialog = () => useConversionListContextSelector((ctx) => ctx.openPropertyFilterDialog)
const useClosePropertyFilterDialog = () => useConversionListContextSelector((ctx) => ctx.closePropertyFilterDialog)
const useTouchpointPropertyFilters = () => useConversionListContextSelector((ctx) => ctx.touchpointPropertyFilters)
const useUpdateTouchpointPropertyFilters = () =>
    useConversionListContextSelector((ctx) => ctx.updateTouchpointPropertyFilters)
const useConversionStateSelection = () => useConversionListContextSelector((ctx) => ctx.conversionStateSelection)
const useUpdateConversionStateSelection = () =>
    useConversionListContextSelector((ctx) => ctx.updateConversionStateSelection)
const useConsentStateSelection = () => useConversionListContextSelector((ctx) => ctx.consentStateSelection)
const useUpdateConsentStateSelection = () => useConversionListContextSelector((ctx) => ctx.updateConsentStateSelection)

const useDimensionValuesQuery = (params: GetDimensionValuesHookParams) =>
    useConversionListExternalDependencyContextSelector((ctx) => ctx.useDimensionValuesQuery)(params)
const useLoadDataQuery = (params: LoadDataHookParams) =>
    useConversionListExternalDependencyContextSelector((ctx) => ctx.useLoadDataQuery)(params)
const useLoadCustomerJourneyPageConfigQuery = (params: LoadCustomerJourneyPageConfigHookParams) =>
    useConversionListExternalDependencyContextSelector((ctx) => ctx.useLoadCustomerJourneyPageConfigQuery)(params)
const useLoadCustomerJourneyQuery = (params: LoadCustomerJourneyHookParams) =>
    useConversionListExternalDependencyContextSelector((ctx) => ctx.useLoadCustomerJourneyQuery)(params)
const useLoadPixelQuery = (params: LoadPixelHookParams) =>
    useConversionListExternalDependencyContextSelector((ctx) => ctx.useLoadPixelQuery)(params)
const useLoadPaginationQuery = (params: LoadDataHookParams) =>
    useConversionListExternalDependencyContextSelector((ctx) => ctx.useLoadPaginationQuery)(params)
const useAppContext = () => useConversionListExternalDependencyContextSelector((ctx) => ctx.appContext)

export const ConversionListContextSelectors = {
    useCurrentQueryConfig,
    useHasUnappliedChanges,
    useApplyChanges,
    useConversionListType,
    useColumns,
    useSelectedColumns,
    useUpdateSelectedColumns,
    useLeftPinnedColumns,
    useUpdateLeftPinnedColumns,
    useTimeRange,
    useUpdateTimeRange,
    useSortSettings: useSortSettings,
    useSetSortSettings: useSetSortSettings,
    useConversionTypeSelection,
    useUpdateConversionTypeSelection,
    useFullOrSoftConversionSelection,
    useUpdateFullOrSoftConversionSelection,
    useTrackingStateSelection,
    useUpdateTrackingStateSelection,
    useCustomerJourneyConfig: useCustomerJourneyConfig,
    useUpdateCustomerJourneyConfig,
    useSearchConfig,
    useUpdateSearchConfig,
    useResetGridColumnState,
    usePropertyFilterDialogOpen,
    usePropertyFilterDialogMode,
    usePropertyFilterDialogId,
    useOpenPropertyFilterDialog,
    useClosePropertyFilterDialog,
    useTouchpointPropertyFilters,
    useUpdateTouchpointPropertyFilters,
    useConversionStateSelection,
    useUpdateConversionStateSelection,
    useDimensionValuesQuery,
    useLoadDataQuery,
    useLoadCustomerJourneyPageConfigQuery,
    useLoadCustomerJourneyQuery,
    useAppContext,
    useLoadPixelQuery,
    useLoadPaginationQuery,
    useConsentStateSelection,
    useUpdateConsentStateSelection,
}
