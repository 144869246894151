import UserService from "domain/user/service/user.service"
import { Dispatch } from "redux"
import { AppContextDTO, UserSettingsDTO } from "generated/models"
import { MenuRootDTO } from "generated/models"
import { log } from "shared/util/log"
import DimensionService from "domain/dimension/service/DimensionService"

import {
    loadMenu as loadMenuSuccess,
    loadTree as loadTreeSuccess,
    loadUserInfo as loadUserInfoSuccess,
} from "domain/user/redux/user.slice"
import { AppContextState, storeAppContextSuccess } from "domain/core/redux/appcontext.slice"
import store from "shared/redux/store"
import { UserSettingsService } from "domain/user/settings/service/UserSettingsService"
import { LightweightUserInfoDTO, SelectedNodeUserSettingsValueDTO, UserTreeDTO } from "generated/models"

export type UserConfigs = {
    tree?: UserTreeDTO
    menu?: MenuRootDTO
    userInfo?: LightweightUserInfoDTO
}

const loadUserMenu = (dispatch: Dispatch): Promise<MenuRootDTO> => {
    return UserService.loadMenu().then((menuRootDTO) => {
        dispatch(loadMenuSuccess({ menu: menuRootDTO }))
        return menuRootDTO
    })
}

const loadUserTree = (dispatch: Dispatch): Promise<UserTreeDTO> => {
    return UserService.loadTree().then((userTree: UserTreeDTO) => {
        dispatch(loadTreeSuccess({ tree: userTree }))

        return userTree
    })
}

const loadUserInfo = (dispatch: Dispatch): Promise<LightweightUserInfoDTO> => {
    return UserService.loadUserInfo().then((userInfo: LightweightUserInfoDTO) => {
        dispatch(loadUserInfoSuccess({ userInfo: userInfo }))

        return userInfo
    })
}

const createAndStoreAppContext = (
    tree: UserTreeDTO,
    userSettings: SelectedNodeUserSettingsValueDTO,
    dispatch: Dispatch,
): AppContextDTO => {
    if (tree) {
        try {
            let advertiserIdSelection: number = null
            let campaignIdSelection: number = null

            if (userSettings && userSettings.advertiserId) {
                advertiserIdSelection = userSettings.advertiserId
                campaignIdSelection = userSettings.campaignId
            } else {
                log.warn("no selected node in user settings")
            }

            const appContext: AppContextDTO = createAppContext(tree, advertiserIdSelection, campaignIdSelection)

            dispatch(storeAppContextSuccess({ appContext: appContext }))

            return appContext
        } catch (e) {
            log.debug("error trying to find selected campaign in user tree")
        }
    } else {
        throw new Error("no user tree")
    }
}

const createAppContext = (
    tree: UserTreeDTO,
    advertiserIdSelection?: number,
    campaignIdSelection?: number,
): AppContextDTO => {
    const { root } = tree
    if (root.length === 0) {
        return { advertiserId: undefined, advertiserName: undefined }
    }

    let advertiserNode = advertiserIdSelection
        ? root.find(
              (node) =>
                  node.dimensionIdentifier === DimensionService.getDimensionValueColumn("advertiser") &&
                  node.value === advertiserIdSelection,
          )
        : null

    if (!advertiserNode) {
        advertiserNode = root[0]
        log.warn(
            `Selected advertiser ${advertiserIdSelection} not found in advertiser tree, using first advertiser (${advertiserNode.value}) from the tree as fallback`,
        )
    }

    const appContext: AppContextDTO = {
        advertiserId: advertiserNode.value,
        advertiserName: advertiserNode.label,
    }

    if (campaignIdSelection && !advertiserNode.children) {
        log.warn(`Advertiser node doesn't have children, but campaign id ${campaignIdSelection} was selected`)
    }

    if (campaignIdSelection && advertiserNode.children) {
        const campaignNode = advertiserNode.children.find(
            (node) =>
                node.dimensionIdentifier === DimensionService.getDimensionValueColumn("campaign") &&
                node.value === campaignIdSelection,
        )

        if (campaignNode) {
            appContext["campaignId"] = campaignNode.value
            appContext["campaignName"] = campaignNode.label
        } else {
            log.warn(`no campaign node in user tree for id ${campaignIdSelection}`)
        }
    }

    return appContext
}

/**
 * Sets the appContext in the redux store and updates the user settings in the backend and the redux store
 *
 * @param appContext
 */
const storeAppContextAndUpdateUserSettings = (appContext: AppContextDTO) => (dispatch: Dispatch) => {
    dispatch(storeAppContextSuccess({ appContext: appContext }))

    const { advertiserId, campaignId } = appContext
    UserSettingsService.updateUserSettingDTO({
        id: "selectedNode",
        value: {
            type: "SelectedNodeUserSettingsValueDTO",
            advertiserId: advertiserId,
            campaignId: campaignId,
        },
    } satisfies UserSettingsDTO)
}

const updateSelectedAdvertiserCampaign = (advertiserId: number, campaignId?: number) => (dispatch: Dispatch) => {
    const appContextState: AppContextState = store.getState().appContext

    if (
        appContextState.appContext.advertiserId !== advertiserId ||
        appContextState.appContext.campaignId !== campaignId
    ) {
        const advertiserCampaignName = getAdvertiserCampaignNameFromTree(
            store.getState().user.tree,
            advertiserId,
            campaignId,
        )
        const appContext: AppContextDTO = {
            advertiserId,
            advertiserName: advertiserCampaignName.advertiserName,
            campaignId,
            campaignName: advertiserCampaignName.campaignName,
        }
        storeAppContextAndUpdateUserSettings(appContext)(dispatch)
    }
}

interface AdvertiserCampaignName {
    advertiserName?: string
    campaignName?: string
}

export const getAdvertiserCampaignNameFromTree = (
    advertiserTree: UserTreeDTO,
    advertiserId: number,
    campaignId?: number,
): AdvertiserCampaignName => {
    const { root } = advertiserTree

    const result: AdvertiserCampaignName = {}

    const advertiserNode = root?.find(
        (node) =>
            node.dimensionIdentifier === DimensionService.getDimensionValueColumn("advertiser") &&
            node.value === advertiserId,
    )
    if (!advertiserNode) {
        log.error(`no advertiser node in user tree for id ${advertiserId}`)
        return result
    }

    result.advertiserName = advertiserNode.label

    if (!campaignId) {
        return result
    }
    const campaignNode = advertiserNode?.children.find(
        (node) =>
            node.dimensionIdentifier === DimensionService.getDimensionValueColumn("campaign") &&
            node.value === campaignId,
    )
    if (!campaignNode) {
        log.error(`no campaign node in user tree for id ${campaignId}`)
        return result
    }

    result.campaignName = campaignNode.label

    return result
}

export const actions = {
    loadUserMenu: loadUserMenu,
    loadUserTree: loadUserTree,
    loadUserInfo: loadUserInfo,
    createAndStoreAppContext: createAndStoreAppContext,
    updateSelectedAdvertiserCampaign: updateSelectedAdvertiserCampaign,
}
