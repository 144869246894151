import React from "react"
import { Button, useMediaQuery, useTheme } from "@mui/material"
import { ButtonProps } from "@mui/material/Button/Button"

const ResponsiveButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    const className = `responsive-button ${props.className || ""}`.trim()
    return <Button size={isLargeScreen ? "large" : "small"} {...props} className={className} />
}

export default ResponsiveButton
