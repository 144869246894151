import React from "react"
import MaterialButton from "@mui/material/Button"
import SelectAllOutlinedIcon from "@mui/icons-material/SelectAllOutlined"
import DeselectOutlinedIcon from "@mui/icons-material/DeselectOutlined"
import { SvgIconComponent } from "@mui/icons-material"
import Badge from "@mui/material/Badge"

interface ButtonProps extends SelectDeselectAllButtonProps {
    IconComponent: SvgIconComponent
    text: string
}

export interface SelectDeselectAllButtonProps {
    onClick: () => void
    badgeContent?: number
}

const Button = ({ onClick, badgeContent, text, IconComponent }: ButtonProps): JSX.Element => {
    return (
        <div>
            <MaterialButton
                variant="contained"
                color={"primary"}
                onClick={onClick}
                startIcon={<IconComponent />}
                size={"small"}
                className={"metrics-action-button"}
            >
                {badgeContent ? (
                    <Badge
                        badgeContent={badgeContent}
                        color="primary"
                        className={"badge-border"}
                        sx={{ "& .MuiBadge-badge": { top: "-8px", right: "-7px" } }}
                    >
                        {text}
                    </Badge>
                ) : (
                    text
                )}
            </MaterialButton>
        </div>
    )
}

export const SelectAllButton = ({ onClick, badgeContent }: SelectDeselectAllButtonProps): JSX.Element => {
    return (
        <Button onClick={onClick} badgeContent={badgeContent} IconComponent={SelectAllOutlinedIcon} text="Select All" />
    )
}

export const DeselectAllButton = ({ onClick, badgeContent }: SelectDeselectAllButtonProps): JSX.Element => {
    return (
        <Button
            onClick={onClick}
            badgeContent={badgeContent}
            IconComponent={DeselectOutlinedIcon}
            text="Deselect All"
        />
    )
}
