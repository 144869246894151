import { QuerySettingsDTO } from "generated/models"
import { postCancellableData } from "shared/service/api"
import store from "shared/redux/store"
import { CancelTokenSource } from "axios"
import UrlService from "shared/service/url.service"
import { LoadResponseDTOReportingDataSetDTO } from "generated/models"
import UrlUtil from "shared/util/UrlUtil"
import { v4 as uuid } from "uuid"

/**
 * Loads dashboards loadData api
 *
 * @param querySettingsDTO
 * @param cancelTokenSource
 */
const loadData = (
    querySettingsDTO: QuerySettingsDTO,
    cancelTokenSource?: CancelTokenSource,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const settings = querySettingsDTO.appContext
        ? querySettingsDTO
        : { ...querySettingsDTO, appContext: store.getState().appContext.appContext }

    const settingsWithIdentifier = {
        ...settings,
        queryIdentifier: {
            value: uuid(),
        },
    } satisfies QuerySettingsDTO

    return postCancellableData<LoadResponseDTOReportingDataSetDTO>(
        UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards"),
        "/loadData",
        settingsWithIdentifier,
        {
            cancelToken: cancelTokenSource?.token,
        },
    )
}

const DashboardsLoadDataService = {
    loadData: loadData,
}

export default DashboardsLoadDataService
