import React, { useEffect, useLayoutEffect, useState } from "react"
import ContainerElement from "shared/component/layout/ContainerElement"
import PanelElement from "shared/component/layout/PanelElement"
import GridElement from "shared/component/layout/GridElement"
import { ContainerElementDTO, LayoutElementDTO } from "generated/models"
import HtmlContentElement from "shared/component/layout/HtmlContentElement"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"
import { useFormContext } from "shared/component/forms/FormContext"
import LayoutUtil from "shared/util/LayoutUtil"
import WidgetElement from "shared/component/layout/WidgetElement"
import ToolAwarePanelElement from "shared/component/layout/ToolAwarePanelElement"
import { FormElement } from "shared/component/forms/elements/FormElement"
import { produce } from "immer"
import ConversionListElement from "shared/component/layout/ConversionListElement"
import { FormElementDTO, LayoutElementProperties, LayoutElementType } from "domain/types"

const layoutComponents = {
    [LayoutElementType.CONTAINER]: ContainerElement,
    [LayoutElementType.PANEL]: PanelElement,
    [LayoutElementType.TAB]: PanelElement,
    [LayoutElementType.FORM_ELEMENT_INPUT]: FormElement,
    [LayoutElementType.FORM_ELEMENT_INPUT_PASSWORD]: FormElement,
    [LayoutElementType.NUMBER_FORM_ELEMENT_INPUT]: FormElement,
    [LayoutElementType.FORM_ELEMENT_SELECT]: FormElement,
    [LayoutElementType.FORM_ELEMENT_TEXTAREA]: FormElement,
    [LayoutElementType.FORM_ELEMENT_TOGGLE_BUTTON]: FormElement,
    [LayoutElementType.FORM_ELEMENT_CHECKBOX]: FormElement,
    [LayoutElementType.HTML_CONTENT]: HtmlContentElement,
    [LayoutElementType.GRID]: GridElement,
    [LayoutElementType.WIDGET_ELEMENT]: WidgetElement,
    [LayoutElementType.TOOL_AWARE_PANEL]: ToolAwarePanelElement,
    [LayoutElementType.CONVERSION_LIST_ELEMENT]: ConversionListElement,
}

const LayoutElement: React.FC<LayoutElementProperties> = ({
    layoutElementConfig,
    styleRules = {},
    additionalCssClasses = [],
}: LayoutElementProperties): JSX.Element => {
    const { elementSettings } = useRootElementContext()
    const formContext = useFormContext(false) // false = do not throw error if no context is found
    const [isVisible, setIsVisible] = useState(true)

    /**
     * Updates isVisible state once after the element is mounted
     */
    useLayoutEffect(() => {
        updateIsVisible()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Updates isVisible state after the context settings was changed
     */
    useEffect(() => {
        updateIsVisible()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSettings])

    /**
     * Updates isVisible state if the context settings match the element visibleOn conditions
     */
    const updateIsVisible = async () => {
        await LayoutUtil.evaluateElementSettingWithConditionThenUpdate(
            layoutElementConfig.renderSettings?.visibleOn,
            elementSettings,
            async (newIsVisible: boolean) => {
                if (isVisible !== newIsVisible && formContext) {
                    if (newIsVisible) {
                        forEachFormElement(layoutElementConfig, async (formElement) => {
                            await formContext.setFormElementValueAndUpdateContextSettingsForInitialValues(formElement)
                        })
                    }

                    // Update form validation state: we only want to validate visible fields
                    const updateValidationState = newIsVisible
                        ? formContext.enableValidation
                        : formContext.disableValidation
                    forEachFormElement(layoutElementConfig, (formElement) => {
                        updateValidationState(formElement.identifier)
                    })

                    // Update readonly state
                    forEachFormElement(layoutElementConfig, (formElement) => {
                        updateReadOnlyState(formElement.identifier, newIsVisible)
                    })
                }

                setIsVisible(newIsVisible)
            },
        )
    }

    const updateReadOnlyState = (identifier: string, isVisible: boolean) => {
        formContext.setReadOnlyElements(
            produce((draft) => {
                if (isVisible) {
                    draft.delete(identifier)
                } else {
                    draft.add(identifier)
                }
            }),
        )
    }

    additionalCssClasses.push("layout-element")
    additionalCssClasses.push("layout-element-" + layoutElementConfig.elementType.toLowerCase())

    const layoutMode = (layoutElementConfig as ContainerElementDTO)?.layoutConfig?.layoutMode
    if (layoutMode) {
        additionalCssClasses.push("layout-mode-" + layoutMode.toLowerCase())
    }

    // this is not a proper solution yet (flex-basis only applies to one axis, depending on flex direction) but was good enough for the time being
    if (layoutElementConfig.renderSettings?.width) {
        styleRules.minWidth = layoutElementConfig.renderSettings.width
        styleRules.flexBasis = layoutElementConfig.renderSettings.width
    }
    if (layoutElementConfig.renderSettings?.height) {
        styleRules.minHeight = layoutElementConfig.renderSettings.height
        styleRules.flexBasis = layoutElementConfig.renderSettings.height
    }

    const classList = additionalCssClasses.concat(
        Array.from(layoutElementConfig.renderSettings?.additionalCssClasses || []),
    )

    const Component = layoutComponents[layoutElementConfig.elementType]
    const componentProps = { layoutElementConfig, styleRules, additionalCssClasses }

    return (
        <>
            {isVisible && (
                <div style={styleRules} className={classList.join(" ")}>
                    <Component {...componentProps} />
                </div>
            )}
        </>
    )
}

const forEachFormElement = (layoutElement: LayoutElementDTO, callback: (formElement: FormElementDTO) => void) => {
    if (LayoutUtil.isFormElement(layoutElement)) {
        callback(layoutElement)
    } else {
        for (const formElement of LayoutUtil.findFormElements(layoutElement as ContainerElementDTO)) {
            callback(formElement)
        }
    }
}

export default LayoutElement
