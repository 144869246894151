import React from "react"
import { produce } from "immer"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { CJMode } from "domain/ConversionList/domain/touchpointType"

export const TouchpointTypeRadioButtonGroup = () => {
    const customerJourneyConfig = ConversionListContextSelectors.useCustomerJourneyConfig()
    const updateCustomerJourneyConfig = ConversionListContextSelectors.useUpdateCustomerJourneyConfig()

    const selectedValue = customerJourneyConfig.cjMode

    const handleChange = (_: React.MouseEvent<HTMLElement>, selection: CJMode | null) => {
        const newConfig = produce(customerJourneyConfig, (draft) => {
            if (selection !== null) {
                draft.cjMode = selection
            }
            if (draft.showBlockedTouchpoints && selection != "ALL") {
                draft.showBlockedTouchpoints = false
            }
        })
        updateCustomerJourneyConfig(newConfig)
    }

    return (
        <ToggleButtonGroup
            exclusive
            color="primary"
            value={selectedValue}
            onChange={handleChange}
            orientation="vertical"
            size="small"
        >
            <ToggleButton value={"ALL" satisfies CJMode}>All</ToggleButton>
            <ToggleButton value={"COMMISSION_RELEVANT" satisfies CJMode}>Commission-relevant</ToggleButton>
            <ToggleButton value={"ALL_WINNERS" satisfies CJMode}>All Winners</ToggleButton>
            <ToggleButton value={"LAST_WINNER" satisfies CJMode}>Last Winner</ToggleButton>
        </ToggleButtonGroup>
    )
}
