import { ReactFormApi, useField, useForm } from "@tanstack/react-form"
import { DashboardSettingsResponse } from "domain/../../stories/msw/handlers/dashboardSettings"
import React, { createContext, PropsWithChildren, Suspense, useContext } from "react"
import { useDashboardSettings } from "./DashboardSettingsContext"

type DashboardActionsProviderType = {
    form: ReturnType<
        typeof useForm<Pick<DashboardSettingsResponse, "metric" | "reportingMethod" | "reportingDimension">>
    >
}

const DashboardActionsContext = createContext<DashboardActionsProviderType | undefined>(undefined)

type DashboardActionsProviderProps = NonNullable<unknown>

export const DashboardActionsProvider = (props: PropsWithChildren<DashboardActionsProviderProps>) => {
    const { children } = props
    const { settings } = useDashboardSettings()

    const form = useForm({
        defaultValues: {
            metric: settings.metric,
            reportingMethod: settings.reportingMethod,
            reportingDimension: settings.reportingDimension,
        },
        onSubmit: async ({ value }) => {
            // Do something with form data
            console.log(value)
        },
    })

    return (
        <DashboardActionsContext.Provider value={{ form }}>
            <Suspense>{children}</Suspense>
        </DashboardActionsContext.Provider>
    )
}

export const useDashboardActions = () => {
    const context = useContext(DashboardActionsContext)
    if (context === undefined) {
        throw new Error("useDashboardActions must be used within a DashboardActionsProvider")
    }
    return context
}
