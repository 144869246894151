import { Autocomplete, InputAdornment, TextField, styled } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import React from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { produce } from "immer"
import { columnCategories, ColumnCategory } from "domain/ConversionList/domain/domain"
import { ClearableTextField } from "shared/component/mui/ClearableTextField"

export const Search = () => {
    const columns = ConversionListContextSelectors.useColumns()
    const searchConfig = ConversionListContextSelectors.useSearchConfig()
    const updateSearchConfig = ConversionListContextSelectors.useUpdateSearchConfig()

    const groupedSupportedSearchColumns = React.useMemo(() => {
        const searchColumns = columns.fieldNames
            .filter((fieldName) => columns.columnDetails[fieldName]!.supportsSearch)
            .map((fieldName) => ({
                fieldName: fieldName,
                label: columns.columnDetails[fieldName]!.columnConfigDTO.gridColumnProperties.columnHeader,
                columnCategory: columns.columnDetails[fieldName]!.columnCategory,
            }))

        searchColumns.sort((a, b) => {
            const categoryA = columns.columnDetails[a.fieldName]!.columnCategory
            const categoryB = columns.columnDetails[b.fieldName]!.columnCategory
            if (categoryA === categoryB) {
                return columns.columnDetails[
                    a.fieldName
                ]!.columnConfigDTO.gridColumnProperties.columnHeader.localeCompare(
                    columns.columnDetails[b.fieldName]!.columnConfigDTO.gridColumnProperties.columnHeader,
                )
            }
            return columnCategories[categoryA].sortOrder - columnCategories[categoryB].sortOrder
        })

        return searchColumns
    }, [columns])

    const currentAutocompleteValue = React.useMemo(
        () => groupedSupportedSearchColumns.find((column) => column.fieldName === searchConfig.fieldName),
        [searchConfig.fieldName, groupedSupportedSearchColumns],
    )

    const handleAutocompleteChange = (_event: any, newValue: { fieldName: string } | null) => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.fieldName = newValue?.fieldName
            }),
        )
    }

    const handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.searchTerm = event.target.value
            }),
        )
    }

    const handleClearSearch = () => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.searchTerm = undefined
            }),
        )
    }

    return (
        <>
            <Autocomplete
                options={groupedSupportedSearchColumns}
                value={currentAutocompleteValue}
                onChange={handleAutocompleteChange}
                groupBy={(option) => option.columnCategory}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{columnCategories[params.group as ColumnCategory].displayNamePlural}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label="Search column" variant="outlined" />}
                sx={{ width: 300, display: "inline-block", maxWidth: "100%", mb: 1 }}
                size="small"
            />

            <ClearableTextField
                variant="outlined"
                type="search"
                onChange={handleSearchFieldChange}
                onClear={handleClearSearch}
                sx={{ width: 300, maxWidth: "100%" }}
                size="small"
                value={searchConfig.searchTerm || ""}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
}))

const GroupItems = styled("ul")({
    padding: 0,
})
