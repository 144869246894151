/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import { GridColumnStateSettingsValueDTOFromJSONTyped } from "./GridColumnStateSettingsValueDTO"
import { SelectedNodeUserSettingsValueDTOFromJSONTyped } from "./SelectedNodeUserSettingsValueDTO"
/**
 *
 * @export
 * @interface UserSettingsValueDTO
 */
export interface UserSettingsValueDTO {
    /**
     *
     * @type {string}
     * @memberof UserSettingsValueDTO
     */
    type: string
}

/**
 * Check if a given object implements the UserSettingsValueDTO interface.
 */
export function instanceOfUserSettingsValueDTO(value: object): value is UserSettingsValueDTO {
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function UserSettingsValueDTOFromJSON(json: any): UserSettingsValueDTO {
    return UserSettingsValueDTOFromJSONTyped(json, false)
}

export function UserSettingsValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsValueDTO {
    if (json == null) {
        return json
    }
    if (!ignoreDiscriminator) {
        if (json["type"] === "GridColumnStateSettingsValueDTO") {
            return GridColumnStateSettingsValueDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "SelectedNodeUserSettingsValueDTO") {
            return SelectedNodeUserSettingsValueDTOFromJSONTyped(json, true)
        }
    }
    return {
        type: json["type"],
    }
}

export function UserSettingsValueDTOToJSON(value?: UserSettingsValueDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        type: value["type"],
    }
}
