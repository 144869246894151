import { FormElementProperties } from "domain/types"
import React from "react"
import { TextField } from "./primitives/TextField"

export const FormElementPasswordInput = ({
    layoutElementConfig,
    editable,
    isRequiredField,
}: FormElementProperties): JSX.Element => {
    const { formFieldConfig } = layoutElementConfig

    return (
        <TextField
            textFieldType={{ type: "password" }}
            name={formFieldConfig.dimensionIdentifier}
            label={formFieldConfig.displayName}
            disabled={!editable}
            required={isRequiredField}
        />
    )
}
