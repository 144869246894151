import React from "react"
import copy from "copy-to-clipboard"

export interface CopyToClipboardContextProps {
    showCopyToClipboardNotification: boolean
    copyToClipboard: (text: string) => void
}

export const CopyToClipboardContext = React.createContext<CopyToClipboardContextProps | undefined>(undefined)

export const useCopyToClipboardContext = (): CopyToClipboardContextProps => {
    const context = React.useContext(CopyToClipboardContext)

    if (context === undefined) {
        throw new Error("useCopyToClipboardContext must be used within a CopyToClipboardContextProvider")
    }

    return context
}

export const CopyToClipboardContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
    const [showCopyToClipboardNotification, setShowCopyToClipboardNotification] = React.useState<boolean>(false)

    return (
        <CopyToClipboardContext.Provider
            value={{
                showCopyToClipboardNotification: showCopyToClipboardNotification,
                copyToClipboard: (text: string) => {
                    setShowCopyToClipboardNotification(true)
                    copy(text)
                    // hide the copy-to-clipboard notification after some delay
                    setTimeout(() => {
                        setShowCopyToClipboardNotification(false)
                    }, 2000)
                },
            }}
        >
            {children}
        </CopyToClipboardContext.Provider>
    )
}
