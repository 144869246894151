import moment, { Moment } from "moment"
import { NOT_AVAILABLE } from "Constants"

const germanFormatter = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" })

const formatter = {
    unformat: (value) => {
        const decimal = ","
        const fallback = ","

        // Recursively unformat arrays:
        if (Array.isArray(value)) {
            return value.map(function (val) {
                return formatter.unformat(val)
            })
        }

        // Return the value as-is if it's already a number:
        if (typeof value === "number") return value

        // Build regex to strip out everything except digits, decimal point and minus sign:
        const regex = new RegExp("[^0-9-(-)-" + decimal + "]", "g")
        const unformattedValueString = ("" + value)
            .replace(regex, "") // strip out any cruft
            .replace(decimal, ".") // make sure decimal point is standard
            .replace(/\(([-]*\d*[^)]?\d+)\)/g, "-$1") // replace bracketed values with negatives
            .replace(/\((.*)\)/, "") // remove any brackets that do not have numeric value

        /**
         * Handling -ve number and bracket, eg.
         * (-100) = 100, -(100) = 100, --100 = 100
         */
        const negative = (unformattedValueString.match(/-/g)?.length || 2) % 2,
            absUnformatted = parseFloat(unformattedValueString.replace(/-/g, "")),
            unformatted = absUnformatted * (negative ? -1 : 1)

        // This will fail silently which may cause trouble, let's wait and see:
        return !isNaN(unformatted) ? unformatted : fallback
    },

    formatNumber: (round: number, num: number): string => {
        if (num === undefined) return ""

        return new Intl.NumberFormat("de-DE", { minimumFractionDigits: round }).format(Number(num.toFixed(round)))
    },

    parseCurrency: (text: string | null | undefined): number => {
        if (!text) return 0

        return formatter.unformat(text)
    },

    formatCurrency: (amount: number | string | null | undefined): any => {
        let num = 0

        if (typeof amount === "string" && amount) {
            num = Number(amount)
        } else if (typeof amount === "number") {
            num = amount
        }

        // return accounting.formatMoney(num, accounting.settings.currency)

        return germanFormatter.format(num)
    },

    applyPostfix: (value: number | string | boolean | null | undefined, postfix: string) =>
        value === null || value === undefined ? "" : `${value}${postfix}`,

    parseDate: (text: string, pattern: string = "YYYY-MM-DD"): Moment | null => {
        if (!text) return null

        return moment(text, pattern)
    },

    formatDate: (iso8601String: string | null | undefined | Date, pattern: string = "YYYY-MM-DD") => {
        if (!iso8601String) return ""
        if (iso8601String === NOT_AVAILABLE) return iso8601String

        const startDate = moment(iso8601String)
        return startDate.format(pattern)
    },

    formatDateTime: (iso8601String: string | null | undefined | Date, pattern: string = "YYYY-MM-DD HH:mm:ss") => {
        if (!iso8601String) return ""
        if (iso8601String === NOT_AVAILABLE) return iso8601String

        const startDate = moment(iso8601String)
        return startDate.format(pattern)
    },
}

export default formatter
