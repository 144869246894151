import { NewUiMenuLeafDTO } from "generated/models"
import React from "react"
import GenericPage from "domain/core/component/GenericPage"
import { GridColumnStateContextProvider } from "domain/user/settings/context/GridColumnStateContextProvider"

export interface NewUiPageProps {
    pageConfiguration: NewUiMenuLeafDTO
}

export const NewUiPage = ({ pageConfiguration }: NewUiPageProps) => {
    // At the moment, we render all pages using GenericPage
    return (
        <GridColumnStateContextProvider>
            <GenericPage pageConfiguration={pageConfiguration} />
        </GridColumnStateContextProvider>
    )
}
