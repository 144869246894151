import React from "react"
import { Button, Tooltip } from "antd"
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"

export const ScenarioListStandardIcons = {
    check: <CheckOutlined />,
    edit: <EditOutlined />,
    delete: <DeleteOutlined />,
}

export const ScenarioGridInlineMenu = ({ buttons, handleAction }) => {
    return (
        <Button.Group>
            {buttons &&
                buttons.length > 0 &&
                buttons.map((button, index) => (
                    <Button
                        key={index}
                        data-tip={button.action.name}
                        data-force-tooltip={true}
                        className={"btn-" + button.icon}
                        size="small"
                        htmlType="button"
                        onClick={() => handleAction(button)}
                    >
                        {button.icon && ScenarioListStandardIcons[button.icon]}
                    </Button>
                ))}
        </Button.Group>
    )
}

export default ScenarioGridInlineMenu
