import React from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Unstable_Grid2"
import Grow from "@mui/material/Grow"
import { DialogContentText } from "@mui/material"
import { useExportCenterContext } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import {
    ConversionListOptionalSettingsDTO,
    ConversionListOptionalSettingsDTOListTypeEnum,
    CsvExportSettingsDTO,
    ExcelExportSettingsDTO,
    ExportQuerySettingsDTO,
    QuerySettingsDTO,
} from "generated/models"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { mapFiltersToConditionClause } from "domain/ConversionList/domain/filters"
import { CustomDialog } from "layout/components/CustomDialog"
import { TimeSpanSelection } from "domain/ConversionList/components/ConfigPanel/TimeSpanSelection"
import { v4 as uuid } from "uuid"
import moment, { Moment } from "moment/moment"
import { match } from "ts-pattern"
import { TimeUtil } from "shared/util/TimeUtil"
import { useConversionListContextSelector } from "domain/ConversionList/context/ConversionListContext"
import { log } from "shared/util/log"

const TAB = "tab"
export const ExportDialog = ({ shown, onCloseDialog }: { shown: boolean; onCloseDialog: () => void }) => {
    const dataManagerIdentifier = useConversionListContextSelector((ctx) => ctx.dataManagerIdentifier)
    const appContext = ConversionListContextSelectors.useAppContext()
    const exportCenterContext = useExportCenterContext()
    const selectedDataColumnNames = ConversionListContextSelectors.useSelectedColumns()
    const customerJourneyConfig = ConversionListContextSelectors.useCustomerJourneyConfig()
    const conversionTypeSelection = ConversionListContextSelectors.useConversionTypeSelection()
    const conversionStateSelection = ConversionListContextSelectors.useConversionStateSelection()
    const consentStateSelection = ConversionListContextSelectors.useConsentStateSelection()
    const fullOrSoftConversionSelection = ConversionListContextSelectors.useFullOrSoftConversionSelection()
    const trackingStateSelection = ConversionListContextSelectors.useTrackingStateSelection()
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const searchConfig = ConversionListContextSelectors.useSearchConfig()
    const type = ConversionListContextSelectors.useConversionListType()
    const [start, end] = ConversionListContextSelectors.useTimeRange()
    const sortSettings = ConversionListContextSelectors.useSortSettings()

    const [selectedFormatValue, setSelectedFormatValue] = React.useState<"excel" | "csv">("excel")
    const [selectedCsvSeparator, setSelectedCsvSeparator] = React.useState(",")
    const [selectedCsvEnclosure, setSelectedCsvEnclosure] = React.useState('"')
    const [selectedCsvDateFormat, setSelectedCsvDateFormat] = React.useState("yyyy-MM-dd")
    const [selectedCsvPrintHeader, setSelectedCsvPrintHeader] = React.useState(false)
    const [showCsvSection, setShowCsvSection] = React.useState(false)

    const currentMinMaxTimeRange = (): [Moment, Moment] => {
        const now = moment()

        switch (type) {
            case "realtime": {
                const threeDaysAgo = now.clone().subtract(3, "day").startOf("day")
                const todayAtEndOfDay = now.clone().endOf("day")
                return [threeDaysAgo, todayAtEndOfDay]
            }
            case "historical": {
                const threeYearsAgoAtStartOfDay = now.clone().subtract(3, "year").startOf("day")
                const oneDayAgoAtEndOfDay = now.clone().subtract(1, "day").endOf("day")
                return [threeYearsAgoAtStartOfDay, oneDayAgoAtEndOfDay]
            }
        }
    }

    const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as "excel" | "csv"

        setSelectedFormatValue(value)
        setShowCsvSection(value === "csv")
    }

    const handleExport = () => {
        const enumType = match(type)
            .with("historical", () => ConversionListOptionalSettingsDTOListTypeEnum.HISTORICAL)
            .with("realtime", () => ConversionListOptionalSettingsDTOListTypeEnum.REAL_TIME)
            .exhaustive()
        const optionalSettings: ConversionListOptionalSettingsDTO = {
            showBlockedTouchpoints: customerJourneyConfig.showBlockedTouchpoints,
            type: "com.exactag.reporting.conversionlist.infrastructure.dto.ConversionListOptionalSettingsDTO",
            cjMode: customerJourneyConfig.cjMode,
            listType: enumType,
        }

        // We recreate the query config here, because the `currentQueryConfig` in our context gets only updated when
        // the apply button gets pressed. But we want to start the export with the current settings, so users don't
        // have to wait for an initial list display
        const filter = mapFiltersToConditionClause({
            sortSettings: sortSettings,
            timeRange: [start, end],
            conversionTypeSelection: conversionTypeSelection,
            conversionStateSelection: conversionStateSelection,
            fullOrSoftConversionSelection: fullOrSoftConversionSelection,
            trackingStateSelection: trackingStateSelection,
            customerJourneyConfig: customerJourneyConfig,
            touchpointPropertyFilters: touchpointPropertyFilters,
            searchConfig: searchConfig,
            consentStateSelection: consentStateSelection,
        })

        const querySettings: QuerySettingsDTO = {
            appContext: appContext,
            columnNames: [...selectedDataColumnNames],
            filter: filter,
            mode: undefined, // not used in this export
            sortSettings: sortSettings,
            timespanSettings: { start: TimeUtil.toISOLocalString(start!), end: TimeUtil.toISOLocalString(end!) },
            optionalSettings: optionalSettings,
            queryIdentifier: { value: uuid() },
        }

        const excelExportSettings = { type: "ExcelExportSettingsDTO", withHeaders: false } as ExcelExportSettingsDTO
        const csvExportSettings = {
            type: "CsvExportSettingsDTO",
            withHeaders: selectedCsvPrintHeader,
            separator: selectedCsvSeparator === TAB ? "\t" : selectedCsvSeparator,
            dateFormat: selectedCsvDateFormat,
            quoteChar: selectedCsvEnclosure,
        } as CsvExportSettingsDTO

        const exportSettings: ExportQuerySettingsDTO = {
            dataManagerIdentifier: dataManagerIdentifier,
            exportSettings: selectedFormatValue === "excel" ? excelExportSettings : csvExportSettings,
            querySettings: querySettings,
        }

        exportCenterContext.createExportTask("/api/reporting", exportSettings).then(() => log.info("Export started"))
        onCloseDialog()
    }

    return (
        <CustomDialog
            className={"conversion-list-export-dialog"}
            open={shown}
            header="Export"
            maxWidth={"md"}
            content={
                <DialogContentText>
                    <Box sx={{ marginBottom: 2 }}>
                        Choose download timespan {type == "realtime" ? "(up to 3 days)" : "(up to 36 months)"}:
                        <Box sx={{ mb: 2, mt: 1, display: "flex", alignItems: "center" }}>
                            <TimeSpanSelection
                                minDateTime={currentMinMaxTimeRange()[0]}
                                maxDateTime={currentMinMaxTimeRange()[1]}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                        <FormControl>
                            <FormLabel sx={{ marginBottom: 1 }} id="export-format-label">
                                Choose Format
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="export-format-label"
                                defaultValue="excel"
                                value={selectedFormatValue}
                                onChange={handleFormatChange}
                                name="radio-export-format"
                            >
                                <FormControlLabel value="excel" control={<Radio />} label="Export to Excel" />
                                <FormControlLabel value="csv" control={<Radio />} label="Export to CSV" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
                        <Grow unmountOnExit in={showCsvSection} style={{ transformOrigin: "0 0 0" }}>
                            <CsvControls
                                onSetCsvSeparator={setSelectedCsvSeparator}
                                onSetCsvDateFormat={setSelectedCsvDateFormat}
                                onSetCsvEnclosure={setSelectedCsvEnclosure}
                                onSetCsvPrintHeader={setSelectedCsvPrintHeader}
                                selectedSeparatorValue={selectedCsvSeparator}
                                selectedEnclosureValue={selectedCsvEnclosure}
                                selectedDateValue={selectedCsvDateFormat}
                                selectedShowHeader={selectedCsvPrintHeader}
                                style={undefined} // style will actually be filled by the outer Grow, we don't define it
                            />
                        </Grow>
                    </Box>
                </DialogContentText>
            }
            footer={{
                kind: "yesButton",
                yesText: "Start Export",
                onYesButtonClick: handleExport,
                submitting: false,
            }}
            onClose={onCloseDialog}
        ></CustomDialog>
    )
}

export const CsvControls = React.forwardRef(
    (
        props: {
            onSetCsvSeparator: (arg0: string) => void
            onSetCsvEnclosure: (arg0: string) => void
            onSetCsvDateFormat: (arg0: string) => void
            onSetCsvPrintHeader: (arg0: boolean) => void
            selectedSeparatorValue: string
            selectedEnclosureValue: string
            selectedDateValue: string
            selectedShowHeader: boolean
            style: any
        },
        ref,
    ) => {
        const handleSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvSeparator(event.target.value)
        }

        const handleEnclosureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvEnclosure(event.target.value)
        }

        const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvDateFormat(event.target.value)
        }

        const handleShowHeaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvPrintHeader(event.target.value == "true")
        }

        return (
            <Box
                sx={{
                    flexGrow: 1,
                    opacity: props.style.opacity,
                    transformOrigin: props.style.transformOrigin,
                    transform: props.style.transform,
                    visibility: props.style.visibility,
                }}
                ref={ref}
            >
                <FormLabel id="export-csv-options-label">CSV Export Options</FormLabel>
                <Grid container spacing={2} columns={5} sx={{ marginTop: 1 }}>
                    <RadioGroup
                        sx={{ width: "100%", flexDirection: "row" }}
                        value={props.selectedSeparatorValue}
                        name="separator"
                        onChange={handleSeparatorChange}
                    >
                        <Grid xs={5} md={1}>
                            Separator:
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="," control={<Radio />} label="Comma ," />
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value=";" control={<Radio />} label="Semicolon ;" />
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="|" control={<Radio />} label="Pipe | " />
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value={TAB} control={<Radio />} label="Tab " />
                        </Grid>
                    </RadioGroup>
                    <RadioGroup
                        sx={{ width: "100%", flexDirection: "row" }}
                        value={props.selectedEnclosureValue}
                        name="enclosure"
                        onChange={handleEnclosureChange}
                    >
                        <Grid xs={5} md={1}>
                            Enclosure:
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value='"' control={<Radio />} label='Quote "' />
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="'" control={<Radio />} label="Single Quote ' " />
                        </Grid>
                        <Grid xs={0} md={2}></Grid>
                    </RadioGroup>
                    <RadioGroup
                        sx={{ width: "100%", flexDirection: "row" }}
                        value={props.selectedDateValue}
                        name="date_format"
                        onChange={handleDateChange}
                    >
                        <Grid xs={5} md={1}>
                            Date Format:
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="yyyy-MM-dd" control={<Radio />} label="YYYY-MM-DD" />
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="dd.MM.yyyy" control={<Radio />} label="DD.MM.YYYY" />
                        </Grid>
                        <Grid xs={0} md={2}></Grid>
                    </RadioGroup>
                    <RadioGroup
                        sx={{ width: "100%", flexDirection: "row" }}
                        value={props.selectedShowHeader}
                        name="show_header"
                        onChange={handleShowHeaderChange}
                    >
                        <Grid xs={5} md={1}>
                            Show Header:
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        </Grid>
                        <Grid xs={5} md={1}>
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </Grid>
                        <Grid xs={0} md={2}></Grid>
                    </RadioGroup>
                </Grid>
            </Box>
        )
    },
)
