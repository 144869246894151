import React, { useEffect, useState } from "react"
import mfaService from "./service/mfaService"
import OtpCode from "domain/onetimepassword/OtpCode"
import RequestEmailCodeButton from "domain/onetimepassword/RequestEmailCodeButton"
import { Alert, AlertTitle, CircularProgress } from "@mui/material"
import { MfaFactorConfigDTO, OtpFormResponseDTO } from "generated/models"

type Props = {
    factorConfig: MfaFactorConfigDTO
    success: () => void
}

const EmailSetup: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [initiateSetupResponse, setInitiateSetupResponse] = useState(undefined)
    const [codeRequested, setCodeRequested] = useState(false)
    const [codeRejected, setCodeRejected] = useState(false)

    useEffect(() => {
        mfaService.EMAIL.initiateSetup().then(
            (setupResponse: OtpFormResponseDTO) => {
                setInitiateSetupResponse({
                    successful: setupResponse?.successful,
                    errorMessage: setupResponse?.successful ? undefined : (
                        <p>
                            Something went wrong when setting up your 2-factor authentication via email. If you already
                            set up 2-factor authentication via email, you can not do so again.
                            <br />
                            If you think you keep getting this error for other reasons, please contact our{" "}
                            <a href="mailto:support@exactag.com">support team</a>
                        </p>
                    ),
                })
            },
            (_) =>
                setInitiateSetupResponse({
                    successful: false,
                    errorMessage: <p>Please log in before trying to access this page.</p>,
                }),
        )
    }, [])

    return (
        <div style={{ textAlign: "center" }}>
            {!initiateSetupResponse && <CircularProgress />}

            {initiateSetupResponse?.successful === false && (
                <Alert className="form-response-alert" severity={"error"}>
                    <AlertTitle>{initiateSetupResponse?.errorMessage}</AlertTitle>
                </Alert>
            )}

            {initiateSetupResponse?.successful && (
                <>
                    {!codeRequested && (
                        <>
                            <h3>Set Up Your E-Mail Address for 2-Factor Authentication</h3>
                            <p>
                                Click below to receive a verification code via e-mail. This code will be sent to{" "}
                                {props?.factorConfig?.userName} and will be valid for 10 minutes.
                            </p>
                        </>
                    )}

                    {codeRequested && (
                        <div className={"mfa-form-segment"}>
                            <h3>Verify Setup</h3>
                            <p>
                                We sent an e-mail containing a 6-digit code to {props?.factorConfig?.userName}. Please
                                enter the code here to complete the setup:
                            </p>

                            {codeRejected && (
                                <Alert className="form-response-alert" severity="error">
                                    <AlertTitle>Something went wrong. Did you enter the wrong code?</AlertTitle>
                                </Alert>
                            )}
                            <OtpCode
                                codeCheck={mfaService.EMAIL.confirmSetup}
                                rememberMeDays={0}
                                onSuccess={props?.success}
                                onError={() => setCodeRejected(true)}
                            />
                        </div>
                    )}

                    <div className={"mfa-form-segment"}>
                        {codeRequested && <p>You didn't receive the previous e-mail?</p>}

                        <RequestEmailCodeButton
                            onSuccess={() => setCodeRequested(true)}
                            onError={() => setCodeRequested(false)}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default EmailSetup
