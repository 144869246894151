import { GridElementConfigDTO } from "generated/models"
import React from "react"
import { match } from "ts-pattern"
import { HistoricalConversionListPage } from "domain/ConversionList/HistoricalConversionListPage"
import { RealtimeConversionListPage } from "domain/ConversionList/RealtimeConversionListPage"
import { useGridColumnStateContext } from "domain/user/settings/context/GridColumnStateContext"
import { log } from "shared/util/log"
import { produce } from "immer"
import { ConversionListElementProperties, LayoutElementType } from "domain/types"
import { GridElementDTO, LayoutElementDTO } from "generated/models"

const ConversionListElement = ({
    layoutElementConfig: { conversionListType, children },
}: ConversionListElementProperties): JSX.Element => {
    const userSettingsContext = useGridColumnStateContext()
    const updateSelectedColumnIdentifiers = (selectedColumnIdentifiers: ReadonlyArray<string>) => {
        userSettingsContext.setGridColumnState(gridElementConfig.gridId, selectedColumnIdentifiers as string[])
    }

    const gridElementConfig = isGridElement(children[0]) ? children[0].elementConfig : undefined
    if (!gridElementConfig) {
        throw new Error("Expected a grid element as the first child of a conversion list element")
    }

    const resetGridColumnState = () => {
        userSettingsContext.deleteGridColumnState(gridElementConfig.gridId)
    }

    const selectedColumnIdentifiers =
        userSettingsContext.getGridColumnState(gridElementConfig.gridId)?.selectedColumnIdentifiers ??
        gridElementConfig.gridConfig.visiblePerDefaultColumns

    const ConversionListPage = match(conversionListType)
        .with("HISTORICAL", () => HistoricalConversionListPage)
        .with("REAL_TIME", () => RealtimeConversionListPage)
        .exhaustive()

    return (
        <ConversionListPage
            gridElementConfig={copyGridElementConfigWithSelectedColumns(gridElementConfig, selectedColumnIdentifiers)}
            updateSelectedColumnIdentifiers={updateSelectedColumnIdentifiers}
            resetGridColumnState={resetGridColumnState}
        />
    )
}

const copyGridElementConfigWithSelectedColumns = (
    gridElementConfig: GridElementConfigDTO,
    selectedColumnIdentifiers: string[],
): GridElementConfigDTO => {
    return produce(gridElementConfig, (draft) => {
        draft.gridConfig.visiblePerDefaultColumns = selectedColumnIdentifiers
            .map((columnIdentifier) => {
                const column = Array.from(gridElementConfig.gridConfig.supportedColumnConfigs || []).find(
                    (column) => column.columnIdentifier === columnIdentifier,
                )
                if (!column) {
                    log.warn(`Column with identifier ${columnIdentifier} not found in supported columns`)
                }
                return column?.columnIdentifier
            })
            .filter((column) => column !== undefined)
    })
}

export const isGridElement = (layoutElement?: LayoutElementDTO): layoutElement is GridElementDTO => {
    return layoutElement !== undefined && layoutElement.elementType === LayoutElementType.GRID
}

export default ConversionListElement
