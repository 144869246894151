import React from "react"

type CustomPopperProps = {
    anchorEl: HTMLElement
    submenu: HTMLElement
    children: React.ReactNode
}

export const CustomPopper = ({ anchorEl, submenu, children }: CustomPopperProps) => {
    const open = Boolean(anchorEl)
    const {
        x: anchorX,
        y: anchorY,
        width: anchorWidth,
    } = open ? anchorEl.getBoundingClientRect() : { x: 0, y: 0, width: 0 }
    const { height: childrenHeight } = submenu ? submenu.getBoundingClientRect() : { height: 0 }

    return (
        <div
            style={{
                position: "fixed",
                visibility: open ? "visible" : "hidden",
                zIndex: 2001,
                left: anchorX + anchorWidth,
                // Limit the top position to avoid the menu going out of the screen
                top: Math.max(anchorY + 9 - childrenHeight / 2, 39),
            }}
        >
            {children}
        </div>
    )
}
