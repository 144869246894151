import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"
import { post, postCancellableData } from "shared/service/api"
import {
    LoadConfigDTO,
    LoadResponseDTOReportingDataSetDTO,
    PaginationInfoDTO,
    QuerySettingsDTO,
} from "generated/models"
import { PageConfigDTO } from "generated/models"

const HISTORICAL_CONVERSION_LIST_BASE_PATH = UrlUtil.joinUrl(
    UrlService.getReportingServiceApiUrl(),
    "conversion-list",
    "historical",
)
const REALTIME_CONVERSION_LIST_BASE_PATH = UrlUtil.joinUrl(
    UrlService.getReportingServiceApiUrl(),
    "conversion-list",
    "real-time",
)
const CUSTOMER_JOURNEY_BASE_PATH = UrlUtil.joinUrl(
    UrlService.getReportingServiceApiUrl(),
    "conversion-list",
    "customer-journey",
)
const CONVERSION_PIXEL_BASE_PATH = UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "conversion-list", "pixel")

const LOADDATA_API = "loaddata"
const LOADPAGECONFIG_API = "loadpageconfig"

const loadHistoricalConversionListData = async (
    querySettingsDTO: QuerySettingsDTO,
    signal: AbortSignal,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    // Loading historical conversion list data from Impala can take a long time, so we set a high timeout value
    return postCancellableData(HISTORICAL_CONVERSION_LIST_BASE_PATH, LOADDATA_API, querySettingsDTO, {
        timeout: 120_000,
        signal: signal,
    })
}

const loadRealtimeConversionListData = async (
    querySettingsDTO: QuerySettingsDTO,
    signal: AbortSignal,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    return postCancellableData(REALTIME_CONVERSION_LIST_BASE_PATH, LOADDATA_API, querySettingsDTO, { signal: signal })
}

const loadCustomerJourneyPageConfig = async (
    loadConfigDTO: LoadConfigDTO,
    signal: AbortSignal,
): Promise<PageConfigDTO> => {
    return post(UrlUtil.joinUrl(CUSTOMER_JOURNEY_BASE_PATH, LOADPAGECONFIG_API), loadConfigDTO, { signal: signal })
}

const loadCustomerJourneyData = async (
    querySettingsDTO: QuerySettingsDTO,
    signal: AbortSignal,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    return postCancellableData(CUSTOMER_JOURNEY_BASE_PATH, LOADDATA_API, querySettingsDTO, {
        timeout: 120_000,
        signal: signal,
    })
}
const loadPixelData = async (
    querySettingsDTO: QuerySettingsDTO,
    signal: AbortSignal,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    return postCancellableData(CONVERSION_PIXEL_BASE_PATH, LOADDATA_API, querySettingsDTO, {
        timeout: 120_000,
        signal: signal,
    })
}

const loadHistoricalPaginationListData = async (
    querySettingsDTO: QuerySettingsDTO,
    signal: AbortSignal,
): Promise<PaginationInfoDTO> => {
    // Loading historical conversion list data from Impala can take a long time, so we set a high timeout value
    return postCancellableData(HISTORICAL_CONVERSION_LIST_BASE_PATH, "pagination", querySettingsDTO, {
        timeout: 120_000,
        signal: signal,
    })
}

const loadRealtimePaginationListData = async (
    querySettingsDTO: QuerySettingsDTO,
    signal: AbortSignal,
): Promise<PaginationInfoDTO> => {
    return postCancellableData(REALTIME_CONVERSION_LIST_BASE_PATH, "pagination", querySettingsDTO, {
        signal: signal,
    })
}

const ReportingServiceClient = {
    loadHistoricalConversionListData,
    loadRealtimeConversionListData,
    loadCustomerJourneyPageConfig,
    loadCustomerJourneyData,
    loadPixelData,
    loadHistoricalPaginationListData,
    loadRealtimePaginationListData,
}

export default ReportingServiceClient
