import { FormApi, ReactFormApi, useForm, Validator } from "@tanstack/react-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { usePrintHtml } from "domain/widget/hooks/usePrintHtml"
import { nanoid } from "nanoid"
import * as api from "shared/service"
import React, { createContext, PropsWithChildren, useContext } from "react"
import { DashboardLayoutItemProps } from "./DashboardLayoutItem"
import { useGridStackIntegration } from "./useGridStackIntegration"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import { type DashboardDTO } from "generated/models"
import { useDashboardSettings } from "domain/widget/DashboardSettings/DashboardSettingsContext"
import { WidgetMap, WidgetProps } from "domain/widget/WidgetRenderer"
import { triggerDownloadDialog } from "utils/download"
import { ColorManagementProvider } from "domain/widget/ColorManagementContext"

type Form<TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined> = FormApi<
    TFormData,
    TFormValidator
> &
    ReactFormApi<TFormData, TFormValidator>

type WidgetValues = Pick<DashboardDTO, "widgets">
type LayoutValues = Pick<DashboardDTO, "layout">
type WidgetForm = Form<WidgetValues>
type LayoutForm = Form<LayoutValues>

export type DashboardLayoutProviderType = ReturnType<typeof useGridStackIntegration> & {
    widgetForm: WidgetForm
    layoutForm: LayoutForm
    updateIsPending: boolean
    convertToLegacyIsPending: boolean
    onConvertToLegacy: () => void
    submit: () => void
    addWidget: <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => void
    removeWidget: (id: string) => void
    htmlToImage: ReturnType<typeof usePrintHtml>
    readOnly: boolean
}

const DashboardLayoutContext = createContext<DashboardLayoutProviderType | undefined>(undefined)

type GridStackConfig = {
    disableDrag?: boolean
    disableResize?: boolean
    readOnly?: boolean
}

type DashboardPostBody = WidgetValues & LayoutValues

type DashboardLayoutBaseProviderProps = GridStackConfig & {
    updateIsPending: boolean
    convertToLegacyIsPending: boolean
    widgetForm: WidgetForm
    layoutForm: LayoutForm
    onSubmit: (data: DashboardPostBody) => void
    onConvertToLegacy: (data: DashboardPostBody) => void
    setLayout: (value: DashboardLayoutItemProps[]) => void
    pushLayout: (value: DashboardLayoutItemProps) => void
    removeFromLayout: (id: string) => void
    readOnly?: boolean
}

export const DashboardLayoutBaseProvider = (props: PropsWithChildren<DashboardLayoutBaseProviderProps>) => {
    const {
        disableDrag,
        disableResize,
        readOnly,
        widgetForm,
        layoutForm,
        setLayout,
        pushLayout,
        removeFromLayout,
        children,
        onSubmit,
        onConvertToLegacy,
        updateIsPending,
        convertToLegacyIsPending,
    } = props

    const gridstackIntegration = useGridStackIntegration({
        onUpdate: setLayout,
        disableDrag,
        disableResize,
        readOnly: readOnly ?? true,
    })

    const addWidget = <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => {
        const id = nanoid(10)
        widgetForm.setFieldValue(`widgets.${id}`, { ...widget, id })
        pushLayout({
            id,
            h: 4,
            w: 4,
            x: 0,
            y: 0,
            ...options,
        })
    }

    const htmlToImage = usePrintHtml()

    return (
        <DashboardLayoutContext.Provider
            value={{
                ...gridstackIntegration,
                updateIsPending,
                convertToLegacyIsPending,
                widgetForm,
                layoutForm,
                addWidget,
                removeWidget: (id: string) => {
                    widgetForm.deleteField(`widgets.${id}`)
                    removeFromLayout(id)
                },
                onConvertToLegacy: () => {
                    const layout = layoutForm.state.values.layout
                    const widgets = widgetForm.state.values.widgets
                    onConvertToLegacy({ layout, widgets })
                },
                htmlToImage,
                submit: () => {
                    const layout = layoutForm.state.values.layout
                    const widgets = widgetForm.state.values.widgets
                    onSubmit({ layout, widgets })
                },
                readOnly: readOnly ?? true,
            }}
        >
            {children}
        </DashboardLayoutContext.Provider>
    )
}

const useDashboardLayoutForms = (data: Pick<DashboardDTO, "widgets" | "layout"> | undefined) => {
    const widgetForm = useForm<WidgetValues>({
        defaultValues: {
            widgets: data?.widgets ?? {},
        },
    })

    const layoutForm = useForm<LayoutValues>({
        defaultValues: {
            layout: data?.layout ?? [],
        },
    })
    return { widgetForm, layoutForm }
}

export const DashboardLayoutProvider = (props: PropsWithChildren<GridStackConfig>) => {
    const { children } = props
    const { baseId } = useDashboardSettings()
    const queryClient = useQueryClient()

    const { data: dashboardDTO, isPending: dashboardViewRequestIsPending } = useQuery({
        queryKey: ["/api/reporting/dashboards/view", baseId],
        queryFn: () => {
            return api.get(`/api/reporting/dashboards/view/${baseId}`) as Promise<DashboardDTO>
        },
        staleTime: Infinity, // https://tkdodo.eu/blog/react-query-and-forms
    })

    const { mutate: save, isPending: updateIsPending } = useMutation({
        mutationKey: ["/api/reporting/dashboards/update", baseId],
        mutationFn: (dashboard: DashboardPostBody) => {
            return api.post("/api/reporting/dashboards/update", dashboard) as Promise<DashboardDTO>
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["/api/reporting/dashboards/view", baseId], data)
        },
    })

    const { mutate: convertToLegacy, isPending: convertToLegacyIsPending } = useMutation({
        mutationKey: ["/api/reporting/dashboards/convertToLegacy", baseId],
        mutationFn: (dashboard: DashboardPostBody) => {
            return api.post("/api/reporting/dashboards/convertToLegacy", dashboard, {
                responseType: "blob",
            }) as Promise<Blob>
        },
        onSuccess: (data) => triggerDownloadDialog(data, `${baseId}.json`),
    })

    const { widgetForm, layoutForm } = useDashboardLayoutForms(dashboardDTO)

    return (
        <DashboardLayoutBaseProvider
            {...props}
            readOnly={dashboardDTO?.readOnly}
            updateIsPending={updateIsPending}
            convertToLegacyIsPending={convertToLegacyIsPending}
            onSubmit={save}
            onConvertToLegacy={convertToLegacy}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={(items) => layoutForm.setFieldValue("layout", items)}
            pushLayout={(item) => layoutForm.pushFieldValue("layout", item)}
            removeFromLayout={(id) => {
                const index = layoutForm.getFieldValue("layout").findIndex((item) => item.id === id)
                if (index === -1) {
                    console.error(`Could not remove widget with id "${id}" because it wasn't found`)
                    return
                }
                layoutForm.removeFieldValue("layout", index)
            }}
        >
            <ColorManagementProvider>
                {dashboardViewRequestIsPending && <MainContentAreaLoadingMask withAdditionalOffset={true} />}
                {!dashboardViewRequestIsPending && children}
            </ColorManagementProvider>
        </DashboardLayoutBaseProvider>
    )
}

export type DashboardLayoutNestedProviderProps = {
    layout: DashboardLayoutItemProps[]
    setLayout: (layout: DashboardLayoutItemProps[]) => void
    pushLayout: (layout: DashboardLayoutItemProps) => void
    removeFromLayout: (id: string) => void
    readOnly?: boolean
}

export const DashboardLayoutNestedProvider = (props: PropsWithChildren<DashboardLayoutNestedProviderProps>) => {
    const { layout, setLayout, pushLayout, removeFromLayout, readOnly } = props

    const parentContext = useContext(DashboardLayoutContext)

    const { widgetForm: nestedWidgetForm, layoutForm: nestedLayoutForm } = useDashboardLayoutForms({
        widgets: {},
        layout,
    })
    const widgetForm = parentContext?.widgetForm ?? nestedWidgetForm
    const layoutForm = parentContext?.layoutForm ?? nestedLayoutForm

    return (
        <DashboardLayoutBaseProvider
            {...props}
            readOnly={parentContext?.readOnly || readOnly}
            updateIsPending={false}
            convertToLegacyIsPending={false}
            onConvertToLegacy={() => {
                throw new Error("ConvertToLegacy cannot be called within a nested DashboardLayoutProvider")
            }}
            onSubmit={() => {
                throw new Error("Submit cannot be called within a nested DashboardLayoutProvider")
            }}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={setLayout}
            pushLayout={pushLayout}
            removeFromLayout={removeFromLayout}
        />
    )
}

export const useDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    if (context === undefined) {
        throw new Error("useDashboardLayout must be used within a DashboardLayoutProvider")
    }
    return context
}

export const useHasParentDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    return context !== undefined
}
