/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import type { UserSettingsDTO, UserSettingsResponseDTO } from "../models/index"
import {
    UserSettingsDTOFromJSON,
    UserSettingsDTOToJSON,
    UserSettingsResponseDTOFromJSON,
    UserSettingsResponseDTOToJSON,
} from "../models/index"

export interface SettingsDeleteIdDeleteRequest {
    id: string
}

export interface SettingsUpdatePutRequest {
    userSettingsDTO: UserSettingsDTO
}

/**
 *
 */
export class UserSettingsApiControllerApi extends runtime.BaseAPI {
    /**
     */
    async settingsDeleteIdDeleteRaw(
        requestParameters: SettingsDeleteIdDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<any>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling settingsDeleteIdDelete().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/settings/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters["id"]))),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        if (this.isJsonMime(response.headers.get("content-type"))) {
            return new runtime.JSONApiResponse<any>(response)
        } else {
            return new runtime.TextApiResponse(response) as any
        }
    }

    /**
     */
    async settingsDeleteIdDelete(
        requestParameters: SettingsDeleteIdDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<any> {
        const response = await this.settingsDeleteIdDeleteRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async settingsLoaddataGetRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<UserSettingsResponseDTO>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/settings/loaddata`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsResponseDTOFromJSON(jsonValue))
    }

    /**
     */
    async settingsLoaddataGet(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<UserSettingsResponseDTO> {
        const response = await this.settingsLoaddataGetRaw(initOverrides)
        return await response.value()
    }

    /**
     */
    async settingsUpdatePutRaw(
        requestParameters: SettingsUpdatePutRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<any>> {
        if (requestParameters["userSettingsDTO"] == null) {
            throw new runtime.RequiredError(
                "userSettingsDTO",
                'Required parameter "userSettingsDTO" was null or undefined when calling settingsUpdatePut().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters["Content-Type"] = "application/json"

        const response = await this.request(
            {
                path: `/settings/update`,
                method: "PUT",
                headers: headerParameters,
                query: queryParameters,
                body: UserSettingsDTOToJSON(requestParameters["userSettingsDTO"]),
            },
            initOverrides,
        )

        if (this.isJsonMime(response.headers.get("content-type"))) {
            return new runtime.JSONApiResponse<any>(response)
        } else {
            return new runtime.TextApiResponse(response) as any
        }
    }

    /**
     */
    async settingsUpdatePut(
        requestParameters: SettingsUpdatePutRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<any> {
        const response = await this.settingsUpdatePutRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
