import { createSvgIcon } from "@mui/material"
import React from "react"

const ReloadIcon = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.33334 14H4.16667V18.1666M11.6667 7.33329H15.8333V3.16663M3.81918 8.16946C4.28642 7.01301 5.06871 6.01083 6.07713 5.27682C7.08556 4.5428 8.28072 4.10623 9.52484 4.01705C10.7689 3.92788 12.0123 4.18946 13.1151 4.77213C14.2179 5.35481 15.1343 6.23541 15.7618 7.31339M16.1813 13.1642C15.7141 14.3206 14.9318 15.3228 13.9233 16.0568C12.9149 16.7909 11.721 17.2269 10.4769 17.316C9.23284 17.4052 7.98839 17.1437 6.88559 16.561C5.78278 15.9783 4.86569 15.0979 4.23829 14.0199"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>,
    "Reload",
)

export default ReloadIcon
