import React from "react"
import { Stack } from "@mui/system"
import { produce } from "immer"
import { PropertyFilterChip } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterChip"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { TOUCHPOINT_CUSTOMER_JOURNEY_ROLES } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TOUCHPOINT_FILTER_DIMENSIONS } from "domain/ConversionList/domain/touchpointFilterDimension"

export const CurrentPropertyFiltersList = () => {
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const updateTouchpointPropertyFilters = ConversionListContextSelectors.useUpdateTouchpointPropertyFilters()
    const openPropertyFilterDialog = ConversionListContextSelectors.useOpenPropertyFilterDialog()

    return (
        <Stack direction="row" spacing={1} sx={{ display: "inline", mb: 1 }}>
            {touchpointPropertyFilters.map((filter, index) => {
                const roleLabel = TOUCHPOINT_CUSTOMER_JOURNEY_ROLES[filter.customerJourneyRole].displayName
                const filterDimensionLabel = TOUCHPOINT_FILTER_DIMENSIONS[filter.filterDimension].displayName
                const label = `${roleLabel} is ${filterDimensionLabel} ${filter.values.map((v) => v.name).join(", ")}`

                const handleDelete = () => {
                    const newFilters = produce(touchpointPropertyFilters, (draft) => {
                        draft.splice(index, 1)
                    })
                    updateTouchpointPropertyFilters(newFilters)
                }

                const handleEdit = () => {
                    openPropertyFilterDialog({ type: "edit", id: filter.id })
                }

                return <PropertyFilterChip key={filter.id} label={label} onDelete={handleDelete} onEdit={handleEdit} />
            })}
        </Stack>
    )
}
