import React from "react"

interface SetDocumentTitleProps {
    pageTitle: string
    children?: React.ReactNode
}

export const SetDocumentTitle = ({ children, pageTitle }: SetDocumentTitleProps) => {
    React.useEffect(() => {
        document.title = makeDocumentTitle(pageTitle)
    }, [pageTitle])

    return <>{children}</>
}

declare const environment: string
const makeEnvironmentPrefix = (): string => {
    return environment !== "prod" && environment !== "production" ? `[${environment.toUpperCase()}] ` : ""
}

const makeDocumentTitle = (pageTitle: string): string => {
    const envPrefix = makeEnvironmentPrefix()
    const pageTitleSuffix = pageTitle == "" ? "" : ` - ${pageTitle}`
    return `${envPrefix}Exactag${pageTitleSuffix}`
}
