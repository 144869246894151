import React, { useMemo } from "react"
import { ConversionListContextProps, Provider } from "./ConversionListContext"
import {
    ConversionListType,
    makeConversionListColumns,
    QueryConfig,
    SearchConfig,
    TimeRange,
    TouchpointPropertyFilter,
    TouchpointPropertyFilterDialogMode,
} from "domain/ConversionList/domain/domain"
import { v4 as uuid } from "uuid"
import { ConversionListExternalDependencyContextProvider } from "domain/ConversionList/context/ConversionListExternalDependencyContextProvider"
import { useChangeDetection } from "shared/hooks"
import { GridElementConfigDTO, SortSettingsDTO } from "generated/models"
import { ConversionTypeSelection, DEFAULT_CONVERSION_TYPE_SELECTION } from "domain/ConversionList/domain/conversionType"
import {
    defaultFullOrSoftConversionSelection,
    FullOrSoftConversionSelection,
} from "domain/ConversionList/domain/fullOrSoftConversion"
import {
    CustomerJourneyConfig,
    DEFAULT_CUSTOMER_JOURNEY_CONFIG,
} from "domain/ConversionList/domain/customerJourneyConfig"
import { DEFAULT_TRACKING_STATE_SELECTION, TrackingStateSelection } from "domain/ConversionList/domain/trackingState"
import {
    ConversionStateSelection,
    DEFAULT_CONVERSION_STATE_SELECTION,
} from "domain/ConversionList/domain/conversionState"
import { ConsentStateSelection, DEFAULT_CONSENT_STATE_SELECTION } from "../domain/consentState"

export interface ConversionListContextProviderProps {
    type: ConversionListType
    initialTimeRange: TimeRange
    gridElementConfig: GridElementConfigDTO
    updateSelectedColumnIdentifiers: (selectedColumnIdentifiers: ReadonlyArray<string>) => void
    resetGridColumnState: () => void
    ConversionListExternalDependencyContextOverride?: typeof ConversionListExternalDependencyContextProvider
    children: React.ReactNode
}

export const ConversionListContextProvider = ({
    type,
    initialTimeRange,
    gridElementConfig,
    updateSelectedColumnIdentifiers,
    ConversionListExternalDependencyContextOverride,
    resetGridColumnState,
    children,
}: ConversionListContextProviderProps): JSX.Element => {
    const columns = useMemo(() => makeConversionListColumns(gridElementConfig), [gridElementConfig])
    const selectedColumns = gridElementConfig.gridConfig.visiblePerDefaultColumns

    const [leftPinnedColumns, setLeftPinnedColumns] = React.useState<ReadonlyArray<string>>([])

    const [sortSettings, setSortSettings] = React.useState<SortSettingsDTO>(
        gridElementConfig.gridConfig.defaultSortSettings,
    )
    const [timeRange, setTimeRange] = React.useState<TimeRange>(initialTimeRange)
    const [conversionTypeSelection, setConversionTypeSelection] = React.useState<ConversionTypeSelection>(
        DEFAULT_CONVERSION_TYPE_SELECTION,
    )
    const [fullOrSoftConversionSelection, setFullOrSoftConversionSelection] =
        React.useState<FullOrSoftConversionSelection>(defaultFullOrSoftConversionSelection(type))
    const [trackingStateSelection, setTrackingStateSelection] = React.useState<TrackingStateSelection>(
        DEFAULT_TRACKING_STATE_SELECTION,
    )
    const [customerJourneyConfig, setCustomerJourneyConfig] = React.useState<CustomerJourneyConfig>(
        DEFAULT_CUSTOMER_JOURNEY_CONFIG,
    )
    const [searchConfig, setSearchConfig] = React.useState<SearchConfig>(() => ({
        fieldName: undefined,
        searchTerm: undefined,
    }))
    const [propertyFilterDialogOpen, setPropertyFilterDialogOpen] = React.useState<boolean>(false)
    const [propertyFilterDialogMode, setPropertyFilterDialogMode] = React.useState<TouchpointPropertyFilterDialogMode>({
        type: "add",
    })
    const [propertyFilterDialogId, setPropertyFilterDialogId] = React.useState<string>(() => uuid())
    const [touchpointPropertyFilters, setTouchpointPropertyFilters] = React.useState<
        ReadonlyArray<TouchpointPropertyFilter>
    >([])
    const [conversionStateSelection, setConversionStateSelection] = React.useState<ConversionStateSelection>(
        DEFAULT_CONVERSION_STATE_SELECTION,
    )
    const [consentStateSelection, setConsentStateSelection] = React.useState<ConsentStateSelection>(
        DEFAULT_CONSENT_STATE_SELECTION,
    )

    const [hasUnappliedChanges, resetHasUnappliedChanges] = useChangeDetection([
        timeRange,
        conversionTypeSelection,
        conversionStateSelection,
        consentStateSelection,
        fullOrSoftConversionSelection,
        trackingStateSelection,
        customerJourneyConfig,
        touchpointPropertyFilters,
        searchConfig,
    ])

    const [currentQueryConfig, setCurrentQueryConfig] = React.useState<QueryConfig>(() => {
        return {
            sortSettings,
            timeRange,
            conversionTypeSelection,
            fullOrSoftConversionSelection,
            trackingStateSelection,
            customerJourneyConfig,
            touchpointPropertyFilters,
            searchConfig,
            conversionStateSelection,
            consentStateSelection,
        }
    })

    const applyChanges = () => {
        setCurrentQueryConfig({
            sortSettings,
            timeRange,
            conversionTypeSelection,
            fullOrSoftConversionSelection,
            trackingStateSelection,
            customerJourneyConfig,
            touchpointPropertyFilters,
            searchConfig,
            conversionStateSelection,
            consentStateSelection,
        })
        resetHasUnappliedChanges()
    }

    const openPropertyFilterDialog = React.useCallback((mode: TouchpointPropertyFilterDialogMode) => {
        setPropertyFilterDialogMode(mode)
        setPropertyFilterDialogId(uuid())
        setPropertyFilterDialogOpen(true)
    }, [])

    const closePropertyFilterDialog = React.useCallback(() => {
        setPropertyFilterDialogOpen(false)
    }, [])

    const value: ConversionListContextProps = {
        dataManagerIdentifier: gridElementConfig.gridConfig.dataManagerIdentifier,
        currentQueryConfig,
        hasUnappliedChanges,
        applyChanges,
        type,
        columns,
        selectedColumns,
        updateSelectedColumns: updateSelectedColumnIdentifiers,
        leftPinnedColumns,
        updateLeftPinnedColumns: setLeftPinnedColumns,
        sortSettings: sortSettings,
        setSortSettings: setSortSettings,
        timeRange,
        updateTimeRange: setTimeRange,
        conversionTypeSelection,
        updateConversionTypeSelection: setConversionTypeSelection,
        fullOrSoftConversionSelection,
        updateFullOrSoftConversionSelection: setFullOrSoftConversionSelection,
        trackingStateSelection,
        updateTrackingStateSelection: setTrackingStateSelection,
        customerJourneyConfig: customerJourneyConfig,
        updateCustomerJourneyConfig: setCustomerJourneyConfig,
        searchConfig,
        updateSearchConfig: setSearchConfig,
        propertyFilterDialogOpen,
        propertyFilterDialogMode,
        propertyFilterDialogId,
        openPropertyFilterDialog,
        closePropertyFilterDialog,
        touchpointPropertyFilters,
        updateTouchpointPropertyFilters: setTouchpointPropertyFilters,
        resetGridColumnState: resetGridColumnState,
        conversionStateSelection: conversionStateSelection,
        updateConversionStateSelection: setConversionStateSelection,
        consentStateSelection: consentStateSelection,
        updateConsentStateSelection: setConsentStateSelection,
    }

    const ExternalDependencyProvider =
        ConversionListExternalDependencyContextOverride ?? ConversionListExternalDependencyContextProvider
    return (
        <Provider value={value}>
            <ExternalDependencyProvider>{children}</ExternalDependencyProvider>
        </Provider>
    )
}
