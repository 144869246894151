import { createContext } from "use-context-selector"
import { LoadResponseDTOReportingDataSetDTO, PaginationInfoDTO } from "generated/models"
import { SortSettingsDTO, AppContextDTO, GridElementDTO } from "generated/models"
import { DimensionValue, PaginationSettings, QueryConfig } from "domain/ConversionList/domain/domain"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"
import { useSafeContextSelector } from "shared/hooks"
import type { UseQueryResult } from "@tanstack/react-query"

export type GetDimensionValuesHookParams = {
    readonly touchpointFilterDimension: TouchpointFilterDimension
}
export type GetDimensionValuesHookResult = UseQueryResult<DimensionValue[], Error>
export type GetDimensionValuesHook = (params: GetDimensionValuesHookParams) => GetDimensionValuesHookResult

export type LoadDataHookParams = {
    readonly queryConfig: QueryConfig
    readonly paginationSettings: PaginationSettings
    readonly sortSettings: SortSettingsDTO
}
export type LoadDataHookResult = UseQueryResult<LoadResponseDTOReportingDataSetDTO, Error>
export type LoadDataHook = (params: LoadDataHookParams) => LoadDataHookResult
export type LoadPaginationHook = (params: LoadDataHookParams) => UseQueryResult<PaginationInfoDTO, Error>

export type LoadCustomerJourneyPageConfigHookParams = {
    readonly appContext: AppContextDTO
}
// We don't return the full PageConfigDTO, because we only need the single  container element and want to avoid type casting later
export type LoadCustomerJourneyPageConfigHookResult = UseQueryResult<GridElementDTO, Error>
export type LoadCustomerJourneyPageConfigHook = (
    params: LoadCustomerJourneyPageConfigHookParams,
) => LoadCustomerJourneyPageConfigHookResult

export type LoadCustomerJourneyHookParams = {
    readonly queryConfig: QueryConfig
    readonly transactionUid: string
}

export type LoadPixelHookParams = {
    readonly transactionUid: string
    readonly timestamp: string
}
export type LoadCustomerJourneyHookResult = UseQueryResult<LoadResponseDTOReportingDataSetDTO, Error>
export type LoadPixelHookResult = UseQueryResult<LoadResponseDTOReportingDataSetDTO, Error>
export type LoadCustomerJourneyHook = (params: LoadCustomerJourneyHookParams) => LoadCustomerJourneyHookResult
export type LoadPixelHook = (params: LoadPixelHookParams) => LoadPixelHookResult

export interface ConversionListExternalDependencyContextProps {
    // Provided by Redux store
    readonly appContext: AppContextDTO

    // Provided by network request
    readonly useDimensionValuesQuery: GetDimensionValuesHook
    readonly useLoadDataQuery: LoadDataHook
    readonly useLoadCustomerJourneyPageConfigQuery: LoadCustomerJourneyPageConfigHook
    readonly useLoadCustomerJourneyQuery: LoadCustomerJourneyHook
    readonly useLoadPixelQuery: LoadPixelHook
    readonly useLoadPaginationQuery: LoadPaginationHook
}

const ConversionListExternalDependencyContext = createContext<ConversionListExternalDependencyContextProps | undefined>(
    undefined,
)
export const Provider = ConversionListExternalDependencyContext.Provider

export const useConversionListExternalDependencyContextSelector = <Selected,>(
    selector: (value: ConversionListExternalDependencyContextProps) => Selected,
) => {
    return useSafeContextSelector(ConversionListExternalDependencyContext, selector)
}
