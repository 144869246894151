import React from "react"
import { CustomDialog } from "layout/components/CustomDialog"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { DialogContentText } from "@mui/material"
import { DimensionValue } from "domain/ConversionList/domain/domain"
import { InfoText } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/InfoText"
import { TouchpointCustomerJourneyRoleSelect } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/TouchpointCustomerJourneyRoleSelect"
import { TouchpointDimensionFilterSelect } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/TouchpointDimensionFilterSelect"
import { TouchpointDimensionFilterValueAutocomplete } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/TouchpointDimensionFilterValueAutocomplete"
import { TouchpointCustomerJourneyRole } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"

export interface PropertyFilterDialogBaseProps {
    selectedTouchpointCustomerJourneyRole: TouchpointCustomerJourneyRole
    updateSelectedTouchpointCustomerJourneyRole: (role: TouchpointCustomerJourneyRole) => void
    selectedTouchpointFilterDimension: TouchpointFilterDimension
    updateSelectedTouchpointFilterDimension: (dimension: TouchpointFilterDimension) => void
    selectedTouchpointFilterDimensionValues: DimensionValue[]
    updateSelectedTouchpointFilterDimensionValues: (dimensionValues: DimensionValue[]) => void
    applyButtonText: string
    onApplyButtonClick: () => void
}

export const PropertyFilterDialogBase = ({
    selectedTouchpointCustomerJourneyRole,
    updateSelectedTouchpointCustomerJourneyRole,
    selectedTouchpointFilterDimension,
    updateSelectedTouchpointFilterDimension,
    selectedTouchpointFilterDimensionValues,
    updateSelectedTouchpointFilterDimensionValues,
    applyButtonText,
    onApplyButtonClick,
}: PropertyFilterDialogBaseProps): JSX.Element => {
    const conversionListType = ConversionListContextSelectors.useConversionListType()
    const propertyFilterDialogOpen = ConversionListContextSelectors.usePropertyFilterDialogOpen()
    const closePropertyFilterDialog = ConversionListContextSelectors.useClosePropertyFilterDialog()

    const handleSelectedTouchpointFilterDimensionChange = (dimension: TouchpointFilterDimension) => {
        updateSelectedTouchpointFilterDimension(dimension)
        updateSelectedTouchpointFilterDimensionValues([])
    }

    const content = (
        <DialogContentText component="div">
            <TouchpointCustomerJourneyRoleSelect
                selectedTouchpointCustomerJourneyRole={selectedTouchpointCustomerJourneyRole}
                updateSelectedTouchpointCustomerJourneyRole={updateSelectedTouchpointCustomerJourneyRole}
            />{" "}
            is{" "}
            <TouchpointDimensionFilterSelect
                selectedTouchpointFilterDimension={selectedTouchpointFilterDimension}
                updateSelectedTouchpointFilterDimension={handleSelectedTouchpointFilterDimensionChange}
            />
            <TouchpointDimensionFilterValueAutocomplete
                key={selectedTouchpointFilterDimension}
                selectedTouchpointFilterDimension={selectedTouchpointFilterDimension}
                selectedTouchpointFilterDimensionValues={selectedTouchpointFilterDimensionValues}
                updateSelectedTouchpointFilterDimensionValues={updateSelectedTouchpointFilterDimensionValues}
            />
            <InfoText
                conversionListType={conversionListType}
                touchpointCustomerJourneyRole={selectedTouchpointCustomerJourneyRole}
            />
        </DialogContentText>
    )

    return (
        <CustomDialog
            open={propertyFilterDialogOpen}
            header={"Touchpoint Property Filter"}
            content={content}
            footer={{
                kind: "yesNoButton",
                noText: "Cancel",
                yesText: applyButtonText,
                onYesButtonClick: () => {
                    onApplyButtonClick()
                    closePropertyFilterDialog()
                },
                onNoButtonClick: () => {
                    closePropertyFilterDialog()
                },
                submitting: false,
                isYesButtonDisabled: selectedTouchpointFilterDimensionValues === null,
            }}
        />
    )
}
