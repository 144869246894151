import * as React from "react"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { FilterState } from "domain/types"
import { Box, Chip, CircularProgress, TextField } from "@mui/material"
import { ArrowDropDownOutlined } from "@mui/icons-material"
import { clsx } from "clsx"
import { VirtualizedAutocomplete } from "shared/component/mui/VirtualizedAutocomplete"
import { useMemo } from "react"

type Props = {
    filter: FilterState
    label: string
    onChange?: (identifier: string, value: string | number | boolean | string[] | number[] | boolean[]) => void
    size?: any
    componentStyle?: any
    selectStyle?: any
    additionalCssClass?: string
    enableClearButton?: boolean
    multiple: boolean
}

export const SelectComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const { filter, label, multiple } = props

    const filterIdentifier = FilterComponentUtil.getFilterFormValueColumn(filter)

    const handleChange = (value: string | number | boolean | string[] | number[] | boolean[]) => {
        const { onChange } = props
        if (onChange) onChange(filterIdentifier, value)
    }

    const hasSelectEntries =
        filter.filterEntries && filter.filterEntries.entries && filter.filterEntries.entries.length > 0
    const showIcon = useMemo(
        () => filter.filterEntries?.entries?.some((row) => row.icon) === true,
        [filter.filterEntries],
    )
    const isLoading = !hasSelectEntries

    const options = useMemo(
        () => (isLoading ? new Map<OptionIdentifier, Option>() : createOptionMap(filter)),
        [filter, isLoading],
    )
    const optionIdentifiers = useMemo(() => Array.from(options.keys()), [options])

    const getInitialLoadingValue = () => {
        return multiple ? [] : null
    }

    /**
     * Get the selected option for the filter value
     */
    const getSelectedValue = (): string | number | string[] | number[] => {
        if (isLoading) {
            return getInitialLoadingValue()
        } else {
            return filter.value === null || filter.value === undefined
                ? getInitialLoadingValue()
                : Array.isArray(filter.value)
                  ? multiple
                      ? filter.value
                      : filter.value.length > 0
                        ? filter.value[0]
                        : null
                  : filter.value
        }
    }

    const checkHasValue = (value: string | number | string[] | number[]): boolean => {
        if (value == null) {
            return false
        }

        if (Array.isArray(value)) {
            return value.length > 0
        }

        // If value is a string or number, it will be considered having a value if it's not empty or 0.
        // Adapt accordingly if this is not the desired behavior.
        return Boolean(value)
    }

    const selectedValue = getSelectedValue()
    const hasSelectedValue = checkHasValue(selectedValue)

    return (
        <div
            className={clsx(
                "filter-container",
                props.additionalCssClass,
                hasSelectedValue ? "has-value" : "no-value",
                "container-" + filterIdentifier,
            )}
            style={props.componentStyle || {}}
        >
            <Box className={"filter-wrapper " + (showIcon ? "has-icon" : "") + " " + filterIdentifier}>
                <VirtualizedAutocomplete
                    multiple={multiple}
                    size={"small"}
                    id={filterIdentifier}
                    value={selectedValue}
                    loading={isLoading}
                    disabled={isLoading}
                    onChange={(_, value) =>
                        handleChange(value as string | number | boolean | string[] | number[] | boolean[])
                    }
                    options={optionIdentifiers}
                    getOptionLabel={(optionIdentifierValue) => options.get(optionIdentifierValue).label}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => {
                        // TODO: It might be possible to simplify this when this is fixed: https://github.com/mui/material-ui/issues/39833
                        const { key, ...rest } = props as React.HTMLAttributes<HTMLLIElement> & { key: string }
                        const id = options.get(option).value
                        return (
                            <li key={id} {...rest}>
                                {getLabelComponent(options, option)}
                            </li>
                        )
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index })
                            const labelComponent = getLabelComponent(options, option)
                            return (
                                <Chip variant="filled" size={"small"} label={labelComponent} key={key} {...tagProps} />
                            )
                        })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={label}
                            name={filterIdentifier}
                            size={"small"}
                        />
                    )}
                    popupIcon={
                        isLoading ? <CircularProgress size={15} sx={{ marginTop: "5px" }} /> : <ArrowDropDownOutlined />
                    }
                />
            </Box>
        </div>
    )
})

const getLabelComponent = (options: Map<OptionIdentifier, Option>, value: OptionIdentifier): JSX.Element => {
    const { label, icon } = options.get(value)

    return (
        <>
            {icon && (
                <div className={"icon-wrapper"}>
                    <div className={`icon ${icon}`}>&nbsp;</div>
                </div>
            )}
            <span className={"option-name"}>{label}</span>
        </>
    )
}

const createOptionMap = (filter: FilterState): Map<OptionIdentifier, Option> => {
    const map = new Map<OptionIdentifier, Option>()
    for (let i = 0; i < filter.filterEntries?.entries?.length; i++) {
        const option = filter.filterEntries.entries[i]
        map.set(option.value, {
            label: FilterComponentUtil.getFilterValue(option),
            value: option.value,
            icon: option.icon,
        })
    }
    return map
}

type OptionIdentifier = string | number | boolean
type Option = {
    label: string
    value: any
    icon?: string
}
