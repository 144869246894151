import React from "react"
import { TimeRange } from "domain/ConversionList/domain/domain"
import { ConversionListContainer } from "domain/ConversionList/components/ConversionListContainer"
import moment from "moment"
import { ConversionListPageProps } from "domain/ConversionList/ConversionListPageProps"

export interface HistoricalConversionListPageProps extends ConversionListPageProps {}

export const HistoricalConversionListPage = ({
    ConversionListExternalDependencyContextOverride,
    gridElementConfig,
    updateSelectedColumnIdentifiers,
    resetGridColumnState,
}: HistoricalConversionListPageProps) => {
    return (
        <div className="historical-conversions-page">
            <ConversionListContainer
                type="historical"
                initialTimeRange={yesterday}
                gridElementConfig={gridElementConfig}
                updateSelectedColumnIdentifiers={updateSelectedColumnIdentifiers}
                resetGridColumnState={resetGridColumnState}
                ConversionListExternalDependencyContextOverride={ConversionListExternalDependencyContextOverride}
            />
        </div>
    )
}

const yesterday: TimeRange = [moment().subtract(1, "day").startOf("day"), moment().subtract(1, "day").endOf("day")]
