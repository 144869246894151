import React from "react"
import { useTheme } from "@mui/material"

export type HeaderProperties = {
    header?: string
}

const ElementHeader: React.FC<HeaderProperties> = ({ header }: HeaderProperties): JSX.Element => {
    const theme = useTheme()
    return (
        <>
            {header && (
                <h3
                    className={"element-header"}
                    style={{ margin: `${theme.spacing(3)} 0 ${theme.spacing(2)} 0` }}
                    dangerouslySetInnerHTML={{ __html: header }}
                />
            )}
        </>
    )
}
export default ElementHeader
