import { CJMode } from "domain/ConversionList/domain/touchpointType"

export type CustomerJourneyConfig = Readonly<{
    cjMode: CJMode
    showBlockedTouchpoints: boolean
}>

export const DEFAULT_CUSTOMER_JOURNEY_CONFIG: CustomerJourneyConfig = {
    cjMode: "ALL",
    showBlockedTouchpoints: false,
}
