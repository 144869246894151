import React from "react"
import { LayoutElementDTO } from "generated/models"
import LayoutElement from "shared/component/layout/LayoutElement"
import { RowLayoutConfigDTO } from "domain/types"

type Props = {
    layoutConfig: RowLayoutConfigDTO
    children: LayoutElementDTO[]
}

const ChildrenRenderer: React.FC<Props> = ({ layoutConfig, children }: Props): JSX.Element => {
    return (
        <>
            {children.map((child: LayoutElementDTO, index) => (
                <LayoutElement layoutElementConfig={child} key={index} />
            ))}
        </>
    )
}

export default ChildrenRenderer
