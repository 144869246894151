import React from "react"
import { DialogContentText } from "@mui/material"
import { CustomDialog } from "layout/components/CustomDialog"

export interface LogoutConfirmationDialogProps {
    open: boolean
    onConfirmLogoutButtonClick: () => void
    onCancelLogoutButtonClick: () => void
}

export const LogoutConfirmationDialog = ({
    open,
    onConfirmLogoutButtonClick,
    onCancelLogoutButtonClick,
}: LogoutConfirmationDialogProps) => {
    return (
        <>
            <CustomDialog
                className={"logout-confirmation-dialog"}
                open={open}
                modalWidth={450}
                onClose={onCancelLogoutButtonClick}
                closeButtonDisabled={false}
                header="Confirm Logout"
                footer={{
                    kind: "yesNoButton",
                    yesText: "Logout",
                    onYesButtonClick: onConfirmLogoutButtonClick,
                    noText: "Stay logged in",
                    onNoButtonClick: onCancelLogoutButtonClick,
                    submitting: false,
                }}
                content={<DialogContentText>Do you really want to log out?</DialogContentText>}
            />
        </>
    )
}
