import React from "react"
import { ColumnCategory } from "domain/ConversionList/domain/domain"

export type ColumnHeaderProps = {
    label: string
    columnCategory?: ColumnCategory
}

export const ColumnHeader = ({ label, columnCategory }: ColumnHeaderProps) => {
    const columnCategoryLabel = makeColumnCategoryLabel(columnCategory)

    // TODO: Proper styling and CSS organization
    return (
        <>
            <div
                style={{
                    display: "block",
                    marginRight: "5px",
                    fontWeight: 500,
                }}
            >
                {label}
            </div>
            {columnCategory && (
                <div
                    style={{
                        display: "block",
                        marginRight: "5px",
                        padding: "0px 5px",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        fontSize: "11px",
                    }}
                >
                    {columnCategoryLabel}
                </div>
            )}
        </>
    )
}

const makeColumnCategoryLabel = (columnCategory: ColumnCategory | undefined) => {
    switch (columnCategory) {
        case "conversion":
            return "Conversion"
        case "winningTouchpoint":
            return "Winning TP"
        case "customParameter":
            return "Custom"
        case undefined:
            return null
    }
}
