import React from "react"
import { FilterState } from "domain/types"
import { SelectComponent } from "domain/filter/component/ant/SelectComponent"

type Props = {
    filter: FilterState
    label: string
    onChange?: (identifier: string, value: string | number | string[] | number[]) => void
    size?: any
    componentStyle?: any
    selectStyle?: any
    additionalCssClass?: string
    enableClearButton?: boolean
}

export const MultiSelectComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    return <SelectComponent {...props} mode={"multiple"} />
})
