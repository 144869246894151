import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type PasswordExpirationState = {
    passwordExpirationDate: string
}
const initialState: PasswordExpirationState = {
    passwordExpirationDate: undefined,
}

export const passwordExpirationSlice = createSlice({
    name: "passwordExpiration",
    initialState,
    reducers: {
        setPasswordExpirationDate(state: PasswordExpirationState, action: PayloadAction<string>): void {
            state.passwordExpirationDate = action.payload
        },
        closePasswordExpirationDialog(state: PasswordExpirationState): void {
            state.passwordExpirationDate = undefined
        },
    },
})

export const {
    setPasswordExpirationDate: setPasswordExpirationDate,
    closePasswordExpirationDialog: closePasswordExpirationDialog,
} = passwordExpirationSlice.actions

export const passwordExpirationSliceReducer = passwordExpirationSlice.reducer
