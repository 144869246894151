import React, { ReactElement, useState } from "react"
import { CustomDialog } from "layout/components/CustomDialog"
import { styled } from "@mui/material/styles"
import { DialogContentText } from "@mui/material"

interface ContentDialogTriggerProps {
    children: ReactElement // The clickable component passed as a child
    infoContent: ReactElement // Content to be displayed in the dialog
    dialogHeader: string | JSX.Element
}

const StyledCustomDialog = styled(CustomDialog)(({ theme }) => ({
    // workaround to make sure that info dialog is positioned above tooltips in CoCo
    zIndex: 30000,
}))

const ContentDialogTrigger: React.FC<ContentDialogTriggerProps> = ({ children, infoContent, dialogHeader }) => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <div onClick={handleClickOpen} style={{ cursor: "pointer" }}>
                {children}
            </div>
            <StyledCustomDialog
                maxWidth="md"
                open={open}
                header={dialogHeader}
                content={<DialogContentText>{infoContent}</DialogContentText>}
                onClose={handleClose}
                footer={{
                    kind: "none",
                }}
            />
        </>
    )
}

export default ContentDialogTrigger
