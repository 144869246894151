import { UserSettingsContext } from "./UserSettingsContext"
import React from "react"
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import { UserSettingsService } from "domain/user/settings/service/UserSettingsService"
import { CircularProgress } from "@mui/material"
import {
    GridColumnStateSettingsValueDTO,
    SelectedNodeUserSettingsValueDTO,
    UserSettingsDTO,
    UserSettingsResponseDTO,
} from "generated/models"

export type UserSettingsContextProviderProps = Readonly<{
    children: React.ReactNode
}>

export const UserSettingsContextProvider = ({ children }: UserSettingsContextProviderProps): JSX.Element => {
    const { user, loggedInViaSecondFactor } = useSelector((state: RootState) => state.authentication)

    const queryClient = useQueryClient()
    const getQueryKey = () => ["/api/user/settings/loaddata", user?.id]

    /**
     * Update the query data with the new setting so that the UI can be updated immediately
     * without waiting for the server response.
     *
     * @param setting
     */
    const updateQueryData = (setting: UserSettingsDTO) => {
        queryClient.setQueryData(getQueryKey(), (oldData: UserSettingsResponseDTO) => {
            const otherSettings = oldData?.settings?.filter((oldSetting) => oldSetting.id !== setting.id) ?? []

            return {
                settings: [...otherSettings, setting],
            } satisfies UserSettingsResponseDTO
        })
    }

    /**
     * Removes the setting with the given id from the query data so that the UI can be updated immediately
     * without waiting for the server response.
     *
     * @param id
     */
    const removeSettingFromQueryData = (id: string) => {
        queryClient.setQueryData(getQueryKey(), (oldData: UserSettingsResponseDTO) => {
            const otherSettings = oldData?.settings?.filter((oldSetting) => oldSetting.id !== id) ?? []

            return {
                settings: otherSettings,
            } satisfies UserSettingsResponseDTO
        })
    }

    const { data, isLoading } = useQuery({
        queryKey: getQueryKey(),
        queryFn: UserSettingsService.loadAllUserSettings,
        enabled: user?.id !== undefined && loggedInViaSecondFactor,
        placeholderData: keepPreviousData,
    })
    const mutation = useMutation<void, Error, UserSettingsDTO>({
        mutationFn: UserSettingsService.updateUserSettingDTO,
    })
    const deleteMutation = useMutation<void, Error, string>({
        mutationFn: UserSettingsService.deleteUserSettingById,
    })

    const getSetting = (id: string): GridColumnStateSettingsValueDTO | SelectedNodeUserSettingsValueDTO | null => {
        return data?.settings?.find((setting) => setting.id === id)?.value
    }
    const setSetting = (setting: UserSettingsDTO) => {
        updateQueryData(setting)

        mutation.mutate(setting, { onSuccess: invalidateQuery })
    }
    const deleteSettingById = (id: string) => {
        removeSettingFromQueryData(id)

        deleteMutation.mutate(id, { onSuccess: invalidateQuery })
    }

    /**
     * Invalidate the query to refetch the data
     */
    const invalidateQuery = () => {
        queryClient.removeQueries({ queryKey: getQueryKey() })
    }

    if (isLoading) {
        return (
            <div className="screen-centered">
                <CircularProgress />
            </div>
        )
    } else {
        return (
            <UserSettingsContext.Provider
                value={{
                    getSetting: getSetting,
                    setSetting: setSetting,
                    deleteSettingById: deleteSettingById,
                }}
            >
                {children}
            </UserSettingsContext.Provider>
        )
    }
}
