import {
    ASONewUiMenuLeafDTO,
    ClassicUiEmbeddedMenuLeafDTO,
    DashboardsMenuLeafDTO,
    LegacyDashboardsEmbeddedMenuLeafDTO,
    MenuDTO,
    NewUiMenuLeafDTO,
} from "generated/models"

export enum ContentType {
    HELP = "HELP",
    NEWS = "NEWS",
}

export interface MenuLeafDTO {
    title: string
    path: string
    icon?: string
    treeType:
        | "NewUiMenuLeafDTO"
        | "LegacyDashboardsEmbeddedMenuLeafDTO"
        | "DashboardsMenuLeafDTO"
        | "ClassicUiEmbeddedMenuLeafDTO"
        | "ASONewUiMenuLeafDTO"
    campaignSelectionRequired: boolean
    hidden: boolean
}

export const isNewUiMenuLeaf = (menuDTO: MenuDTO): menuDTO is NewUiMenuLeafDTO => {
    return menuDTO.treeType === "NewUiMenuLeafDTO"
}
export const isASONewUiMenuLeaf = (menuDTO: MenuDTO): menuDTO is ASONewUiMenuLeafDTO => {
    return menuDTO.treeType === "ASONewUiMenuLeafDTO"
}

export const isLegacyDashboardsEmbeddedMenuLeaf = (
    menuDTO: MenuDTO,
): menuDTO is LegacyDashboardsEmbeddedMenuLeafDTO => {
    return menuDTO.treeType === "LegacyDashboardsEmbeddedMenuLeafDTO"
}

export const isDashboardsMenuLeaf = (menuDTO: MenuDTO): menuDTO is DashboardsMenuLeafDTO => {
    return menuDTO.treeType === "DashboardsMenuLeafDTO"
}

export const isClassicUiEmbeddedMenuLeaf = (menuDTO: MenuDTO): menuDTO is ClassicUiEmbeddedMenuLeafDTO => {
    return menuDTO.treeType === "ClassicUiEmbeddedMenuLeafDTO"
}

export enum ActionIdentifier {
    CREATE = "CREATE",
    EDIT = "EDIT",
    DELETE = "DELETE",
    DEACTIVATE = "DEACTIVATE",
    ACTIVATE = "ACTIVATE",
    DOWNLOAD = "DOWNLOAD",
}
