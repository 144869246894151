import React, { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from "react"
import cloneDeep from "lodash/cloneDeep"
import { exactagTheme } from "styles/theme/echart/exactagTheme"

export const COLORS = exactagTheme.color

// Define the dimensions for which the colors are managed
type Dimension = "channel" | "mmx_channel" | "mmx_strategy"
const PREDEFINED_COLORS: Record<Dimension, ColorMap> = {
    channel: {
        160: "#000000", // Mark onsite channels black

        131: COLORS[0], // Direct Type-in
        57: COLORS[1], // SEO
        56: COLORS[2], // SEA
        132: COLORS[3], // Direct Referred
        5: COLORS[4], // Affiliate
        72: COLORS[5], // Price Search
        65: COLORS[6], // Email
        87: COLORS[7], // Retargeting
        55: COLORS[8], // Display
        73: COLORS[9], // Cashback
        0: COLORS[10], // Direct
    },
    mmx_channel: {
        Baseline: COLORS[10],

        Display: COLORS[0],
        Email: COLORS[1],
        OOH: COLORS[2],
        Fixnet: COLORS[2],
        Mobile: COLORS[3],
        SEA: COLORS[4],
        SEO: COLORS[5],
    },
    mmx_strategy: {
        Baseline: COLORS[10],

        "External Effects": COLORS[0],
        Seasonal: COLORS[1],
    },
}

type ColorMap = { [dimensionValue: string]: string }

type ColorManagementContextType = {
    getColorForDimensionValue: (dimension: string, dimensionValue: string) => string
}

const ColorManagementContext = createContext<ColorManagementContextType | undefined>(undefined)

type ColorManagementProviderProps = {
    children: ReactNode
}

export const ColorManagementProvider: React.FC<ColorManagementProviderProps> = ({ children }) => {
    const colorMapsRef = useRef<{ [dimension: string]: ColorMap }>(cloneDeep(PREDEFINED_COLORS))

    const getColorForDimensionValue = useCallback((dimension: string, dimensionValue: string): string => {
        if (!colorMapsRef.current[dimension]) {
            colorMapsRef.current[dimension] = {}
        }

        const dimensionMap = colorMapsRef.current[dimension]

        if (!dimensionMap[dimensionValue]) {
            dimensionMap[dimensionValue] = COLORS[Object.keys(dimensionMap).length % COLORS.length]
        }

        return dimensionMap[dimensionValue]
    }, [])

    const contextValue = useMemo(
        () => ({
            getColorForDimensionValue,
        }),
        [getColorForDimensionValue],
    )

    return <ColorManagementContext.Provider value={contextValue}>{children}</ColorManagementContext.Provider>
}

export const useColorManagement = (): ColorManagementContextType => {
    const context = useContext(ColorManagementContext)
    if (!context) {
        throw new Error("useColorManagement must be used within a ColorManagementProvider")
    }
    return context
}
