import React from "react"
import { useMousePosition } from "./useMousePosition"

export const useMouseMoving = () => {
    const { x: mouseX, y: mouseY } = useMousePosition()
    const [previousMouseCoordinates, setPreviousMouseCoordinates] = React.useState({ x: mouseX, y: mouseY })
    const [isMouseMovingRight, setIsMouseMovingRight] = React.useState(false)

    React.useEffect(() => {
        setIsMouseMovingRight(mouseX >= previousMouseCoordinates.x)
        setPreviousMouseCoordinates({ x: mouseX, y: mouseY })
    }, [mouseX, mouseY, previousMouseCoordinates])

    return { isMouseMovingRight }
}
