import * as React from "react"
import { Input } from "antd"
import { FilterState } from "domain/types"

type Props = {
    filter: FilterState
    onChange?: (identifier: string, value: string | number | string[] | number[]) => void
}

export class InputComponent extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = (event: any) => {
        const { filter, onChange } = this.props
        onChange(filter.inputConfig.valueColumn, event.target.value)
    }

    render() {
        const { filter } = this.props
        const value = filter.value ? `${filter.value}` : undefined

        return (
            <div className="filter-container">
                <label className="filter-label">{filter.inputConfig.label}</label>
                <Input className="filter-input" defaultValue={value} size={"small"} onPressEnter={this.handleChange} />
            </div>
        )
    }
}
