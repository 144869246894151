import React from "react"
import { ConversionListColumnConfiguratorAdapter } from "domain/ConversionList/components/ConversionListColumnConfiguratorAdapter"
import { ConfigPanel } from "domain/ConversionList/components/ConfigPanel/ConfigPanel"
import { GridPanel } from "domain/ConversionList/components/GridPanel/GridPanel"
import { Box } from "@mui/system"

export const ConversionList = () => {
    return (
        <Box className={"conversion-list"}>
            <ConversionListColumnConfiguratorAdapter>
                <ConfigPanel />
                <GridPanel />
            </ConversionListColumnConfiguratorAdapter>
        </Box>
    )
}
