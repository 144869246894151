import React, { useCallback, useMemo } from "react"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import {
    ColumnConfiguratorLabels,
    ColumnSetting,
    ColumnUniqueName,
    DataGroupUniqueName,
    DEFAULT_COLUMN_CONFIGURATOR_LABELS,
    MetricsFrontendGroupUniqueName,
    OriginalValueType,
    ReturnedValueType,
    SupportedModel,
    SupportedModels,
    ToCnvAttributionTiming,
    ToTPAttributionTiming,
    UnsupportedModel,
    UpdatedValueType,
    WidgetStaticConfiguration,
} from "domain/ColumnConfigurator/types"
import {
    ColumnSelectAction,
    DimensionIdentifiableSettings,
    IdentifiableSettings,
    MetricIdentifiableSettings,
    SelectedState,
} from "domain/ColumnConfigurator/components/types"
import { ColumnUtil } from "domain/ColumnConfigurator/components/ColumnUtil"
import { calculateDimensionMetricCompatibility } from "domain/ColumnConfigurator/DimensionMetricCompatibility"
import ArrayUtil from "shared/util/ArrayUtil"
import { run } from "shared/util/FunctionUtil"
import { assertExhaustive } from "shared/util/TypeUtil"
import { log } from "shared/util/log"
import {
    ColumnConfiguratorContext,
    ColumnConfiguratorContextProps,
} from "domain/ColumnConfigurator/context/ColumnConfiguratorContext"
import { produce } from "immer"
import { Tag, TagsUtil } from "domain/ColumnConfigurator/TagsUtil"

export interface ColumnConfiguratorInitialState {
    /**
     * Whether to show dimensions. If false, dimensions will not be shown in the column configurator.
     * Else, initially selected dimensions must be provided.
     *
     * @default []
     */
    readonly initialSelectedDimensions?: DimensionIdentifiableSettings[]

    /**
     * Initially selected metrics.
     *
     * @default []
     */
    readonly initialSelectedMetrics?: MetricIdentifiableSettings[]
}

export interface ColumnConfiguratorContextProviderProps {
    readonly initialState?: ColumnConfiguratorInitialState

    readonly initialDialogStateValue?: "open" | "closed" | "applied"

    /**
     * Only the dimensions and metrics from the provided data groups will be shown in the column configurator.
     *
     * @default []
     */
    readonly supportedDataGroups?: DataGroupUniqueName[]
    readonly initialMaxDimensions?: number | undefined

    /**
     * Labels for the column configurator we might want to override in some cases.
     */
    readonly labels?: ColumnConfiguratorLabels
}

export const ColumnConfiguratorContextProvider = ({
    initialState = {},
    supportedDataGroups = [],
    initialDialogStateValue = "closed",
    initialMaxDimensions = 10,
    labels = DEFAULT_COLUMN_CONFIGURATOR_LABELS,
    children,
}: React.PropsWithChildren<ColumnConfiguratorContextProviderProps>): JSX.Element => {
    const { initialSelectedDimensions = [], initialSelectedMetrics = [] } = initialState

    const {
        dataDefinitions,
        helpers: { getColumn },
    } = useReportingConfigurationContext()

    const [dialogState, setDialogState] = React.useState<"open" | "closed" | "applied">(initialDialogStateValue)
    const [widgetStaticConfiguration, setWidgetStaticConfiguration] = React.useState<WidgetStaticConfiguration>(
        () =>
            new WidgetStaticConfiguration({
                supportedDataGroups: supportedDataGroups,
                maxDimensions: initialMaxDimensions,
                supportedColumnSettings: [ColumnSetting.SHOW_BARS],
            }),
    )

    const [selectedState, setSelectedState] = React.useState<SelectedState>(
        new SelectedState({
            widgetId: 0,
            selectedDimensions: initialSelectedDimensions,
            selectedMetrics: initialSelectedMetrics,
            leftPinnedMetrics: [],
        }),
    )

    const [expandedGroups, setExpandedGroups] = React.useState<Set<MetricsFrontendGroupUniqueName>>(new Set())

    const supportedDimensions = useMemo(
        () =>
            ColumnUtil.getSupportedColumns(
                widgetStaticConfiguration.supportedDataGroups,
                dataDefinitions.dataGroups,
                (dataGroup) => dataGroup.dimensions,
            ),
        [widgetStaticConfiguration.supportedDataGroups, dataDefinitions.dataGroups],
    )
    const supportedMetrics = useMemo(
        () =>
            ColumnUtil.getSupportedColumns(
                widgetStaticConfiguration.supportedDataGroups,
                dataDefinitions.dataGroups,
                (dataGroup) => dataGroup.metrics,
            ),
        [widgetStaticConfiguration.supportedDataGroups, dataDefinitions.dataGroups],
    )

    const supportedModels: SupportedModels = useMemo(() => {
        const tags = Array.from(supportedMetrics).map((metric) => TagsUtil.getTags(metric))

        const tocnv: SupportedModel | UnsupportedModel = run(() => {
            const isSupported = tags.some((tag) => tag.includes(Tag.TOCNV))

            return {
                isSupported: isSupported,
                [UpdatedValueType.instance.id]:
                    isSupported && tags.some((tag) => tag.includes(Tag.TOCNV) && tag.includes(Tag.UPDATED)),
                [OriginalValueType.instance.id]:
                    isSupported && tags.some((tag) => tag.includes(Tag.TOCNV) && tag.includes(Tag.ORIGINAL)),
                [ReturnedValueType.instance.id]:
                    isSupported && tags.some((tag) => tag.includes(Tag.TOCNV) && tag.includes(Tag.RETURNED)),
            }
        })

        const totp: SupportedModel | UnsupportedModel = run(() => {
            const isSupported = tags.some((tag) => tag.includes(Tag.TOTP))

            return {
                isSupported: isSupported,
                [UpdatedValueType.instance.id]:
                    isSupported && tags.some((tag) => tag.includes(Tag.TOTP) && tag.includes(Tag.UPDATED)),
                [OriginalValueType.instance.id]:
                    isSupported && tags.some((tag) => tag.includes(Tag.TOTP) && tag.includes(Tag.ORIGINAL)),
                [ReturnedValueType.instance.id]:
                    isSupported && tags.some((tag) => tag.includes(Tag.TOTP) && tag.includes(Tag.RETURNED)),
            }
        })

        return {
            [ToCnvAttributionTiming.instance.id]: tocnv,
            [ToTPAttributionTiming.instance.id]: totp,
        }
    }, [supportedMetrics])

    const dimensionMetricCompatibility = useMemo(
        () =>
            calculateDimensionMetricCompatibility(
                dataDefinitions.metrics,
                dataDefinitions.dataGroups,
                new Set(ColumnUtil.getIdentifiers(selectedState.selectedDimensions)),
            ),
        [dataDefinitions.metrics, dataDefinitions.dataGroups, selectedState.selectedDimensions],
    )

    /**
     * Checks whether the current state is valid.
     */
    const isSelectedStateValid = useCallback(() => {
        return widgetStaticConfiguration.isSelectedStateValid(selectedState, dimensionMetricCompatibility)
    }, [widgetStaticConfiguration, selectedState, dimensionMetricCompatibility])

    /**
     * Updates the selected state by adjusting the list of selected dimensions or metrics.
     *
     * @param previousSelectedState
     * @param columnIdentifier
     * @param adjustAction Adjusts the passed list. Must return a new array and not mutate the parameter.
     */
    const updateSelectedStateColumnList = useCallback(
        (
            previousSelectedState: SelectedState,
            columnIdentifier: ColumnUniqueName,
            adjustAction: (index: number, list: IdentifiableSettings[]) => IdentifiableSettings[],
        ): SelectedState => {
            let result = previousSelectedState
            const column = getColumn(columnIdentifier)
            if (column) {
                const selectedListName: keyof SelectedState = run(() => {
                    const columnType = column.columnType
                    switch (columnType) {
                        case "dimension":
                            return "selectedDimensions"
                        case "metric":
                            return "selectedMetrics"
                        default:
                            return assertExhaustive(columnType)
                    }
                })

                const selectedList = previousSelectedState[selectedListName]
                const index = selectedList.findIndex((item) => item.identifier === columnIdentifier)

                const adjustedList = adjustAction(index, selectedList)

                result = new SelectedState({
                    ...previousSelectedState,
                    [selectedListName]: adjustedList,
                })
            }

            return result
        },
        [getColumn],
    )

    /**
     * Adds or removes the column from the selected state.
     *
     * @param columnIdentifier
     * @param action
     */
    const onColumnSelectAction = useCallback(
        (columnIdentifier: ColumnUniqueName, action: ColumnSelectAction) => {
            setSelectedState((previousWidgetState) =>
                updateSelectedStateColumnList(previousWidgetState, columnIdentifier, (index, list) => {
                    return produce(list, (draft) => {
                        if (index >= 0) {
                            if (action === "delete" || action === "toggle") {
                                draft.splice(index, 1)
                            }
                        } else {
                            if (action === "add" || action === "toggle") {
                                draft.push({ identifier: columnIdentifier })
                            }
                        }
                    })
                }),
            )
        },
        [setSelectedState, updateSelectedStateColumnList],
    )

    /**
     * Updates the column settings for the column.
     *
     * @param columnSettings
     */
    const onColumnSettingsChanged = useCallback(
        (columnSettings: IdentifiableSettings) => {
            setSelectedState((previousWidgetState) =>
                updateSelectedStateColumnList(previousWidgetState, columnSettings.identifier, (index, list) => {
                    if (index >= 0) {
                        return produce(list, (draft) => {
                            draft[index] = columnSettings
                        })
                    } else {
                        return list
                    }
                }),
            )
        },
        [setSelectedState, updateSelectedStateColumnList],
    )

    /**
     * Moves the column to the new position.
     *
     * @param columnIdentifier
     * @param newPosition
     */
    const onColumnMoved = useCallback(
        (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => {
            setSelectedState((previousWidgetState) =>
                updateSelectedStateColumnList(previousWidgetState, columnIdentifier, (index, list) => {
                    return produce(list, (draft) => {
                        if (newPosition === "end") {
                            ArrayUtil.move(draft, index, list.length - 1)
                            return draft
                        } else if (index >= 0) {
                            ArrayUtil.move(draft, index, newPosition)
                            return draft
                        } else {
                            return draft
                        }
                    })
                }),
            )
        },
        [setSelectedState, updateSelectedStateColumnList],
    )

    const open = useCallback(
        (selectedState: SelectedState, widgetStaticConfiguration: WidgetStaticConfiguration) => {
            setSelectedState(selectedState)
            setWidgetStaticConfiguration(widgetStaticConfiguration)
            setDialogState("open")
            setExpandedGroups(widgetStaticConfiguration.initiallyExpandedGroups)
        },
        [setSelectedState, setWidgetStaticConfiguration, setDialogState],
    )

    const close = useCallback(() => {
        setDialogState("closed")
    }, [setDialogState])

    const applyColumnConfiguratorSettings = useCallback(() => {
        if (isSelectedStateValid()) {
            setDialogState("applied")
        } else {
            log.info("invalid settings")
        }
    }, [isSelectedStateValid, setDialogState])

    const getColumnConfiguratorOutputConfiguration = useCallback(() => {
        return selectedState.getSupportedState(dimensionMetricCompatibility)
    }, [selectedState, dimensionMetricCompatibility])

    const contextValue: ColumnConfiguratorContextProps = React.useMemo(() => {
        return {
            open: open,
            close: close,
            applyColumnConfiguratorSettings: applyColumnConfiguratorSettings,
            isSelectedStateValid: isSelectedStateValid,
            getColumnConfiguratorOutputConfiguration: getColumnConfiguratorOutputConfiguration,
            dialogState: dialogState,
            widgetId: selectedState.widgetId,
            selectedDimensions: selectedState.selectedDimensions,
            selectedMetrics: selectedState.selectedMetrics,
            leftPinnedMetrics: selectedState.leftPinnedMetrics,
            expandedGroups: expandedGroups,
            onExpandedGroupsChanged: setExpandedGroups,
            widgetStaticConfiguration: widgetStaticConfiguration,
            onColumnSelectAction: onColumnSelectAction,
            onColumnSettingsChanged: onColumnSettingsChanged,
            onColumnMoved: onColumnMoved,
            supportedDimensions: supportedDimensions,
            supportedMetrics: supportedMetrics,
            supportedModels: supportedModels,
            dimensionMetricCompatibility: dimensionMetricCompatibility,
            labels: labels,
        }
    }, [
        open,
        close,
        applyColumnConfiguratorSettings,
        isSelectedStateValid,
        getColumnConfiguratorOutputConfiguration,
        dialogState,
        selectedState.widgetId,
        selectedState.selectedDimensions,
        selectedState.selectedMetrics,
        selectedState.leftPinnedMetrics,
        expandedGroups,
        widgetStaticConfiguration,
        onColumnSelectAction,
        onColumnSettingsChanged,
        onColumnMoved,
        supportedDimensions,
        supportedMetrics,
        supportedModels,
        dimensionMetricCompatibility,
        labels,
    ])

    return <ColumnConfiguratorContext.Provider value={contextValue}>{children}</ColumnConfiguratorContext.Provider>
}
