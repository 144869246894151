import React from "react"
import { Box, Container, IconButton, linearProgressClasses, Popover, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import LinearProgress from "@mui/material/LinearProgress"
import { assertExhaustive } from "shared/util/TypeUtil"
import { ExportTaskService } from "domain/exporttask/ExportTaskService"
import { useExportCenterContext } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import { styled } from "@mui/material/styles"
import Stack from "@mui/system/Stack"
import { TopBarTooltip } from "layout/MainLayout/TopBar/TopBarTooltip"
import TrashIcon from "shared/component/icons/TrashIcon"
import DownloadIcon from "shared/component/icons/DownloadIcon"
import ReloadIcon from "shared/component/icons/ReloadIcon"
import CircleAlertIcon from "shared/component/icons/CircleAlertIcon"
import CircleCheckIcon from "shared/component/icons/CircleCheckIcon"
import { ErrorExportTaskDTO, ExportTaskDTO } from "generated/models"
import { ExportTaskState } from "domain/exporttask/types"
import {
    FONT_SIZE_BODY_MEDIUM,
    FONT_SIZE_BODY_X_SMALL,
    FONT_WEIGHT_MEDIUM,
    FONT_WEIGHT_SEMI_BOLD,
} from "styles/theme/constants"
import CsvIcon from "shared/component/icons/file/CsvIcon"
import XlsxIcon from "shared/component/icons/file/XlsxIcon"
import formatter from "shared/util/formatter"
import CircleXIcon from "shared/component/icons/CircleXIcon"

type Props = {
    anchorEl: HTMLElement | null
    handleClose: () => void
}

export const ExportTaskList = ({ anchorEl, handleClose }: Props): JSX.Element => {
    const exportCenterContext = useExportCenterContext()

    const { tasks } = exportCenterContext

    const cancelTask = (task: ExportTaskDTO) => {
        exportCenterContext.cancelExportTask(task)
    }

    const deleteTask = (task: ExportTaskDTO) => {
        exportCenterContext.deleteExportTask(task)
    }

    const downloadTaskResult = (task: ExportTaskDTO) => {
        ExportTaskService.downloadExportTaskResult(task)
    }

    const recreateTask = (task: ExportTaskDTO) => {
        exportCenterContext.recreateExportTask(task)
    }

    const dialogContent = (
        <StyledBox className={"export-task-list"} sx={{ p: 2 }}>
            {/* We disable the gutters and set different margins for the headline and the list below to manage the visual
            differences of the icon button, which has its own large padding */}
            <Container
                className={"header"}
                sx={{ px: 1, display: "flex", justifyContent: "space-between", mr: 1 }}
                disableGutters={true}
            >
                <Typography variant="h2">Downloads</Typography>
                <IconButton edge="end" aria-label="Close" onClick={handleClose} size={"small"}>
                    <CloseIcon />
                </IconButton>
            </Container>
            <Container sx={{ flexGrow: 1, overflow: "hidden", px: 1, mr: 2 }} disableGutters={true}>
                {tasks.map((task) => {
                    let taskStateInfo: JSX.Element
                    let statusText: JSX.Element | string
                    let statusIcon: JSX.Element
                    let deleteIcon = (
                        <TopBarTooltip title={"Delete"}>
                            <IconButton edge="end" aria-label="Delete" onClick={() => deleteTask(task)}>
                                <TrashIcon />
                            </IconButton>
                        </TopBarTooltip>
                    )

                    switch (task.state) {
                        case "PENDING":
                            taskStateInfo = (
                                <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} textAlign="left">
                                    <LinearProgress variant="determinate" value={0} sx={{ width: "100%", mr: 2 }} />
                                </Box>
                            )
                            deleteIcon = (
                                <TopBarTooltip title={"Cancel"}>
                                    <IconButton edge="end" aria-label="Cancel" onClick={() => cancelTask(task)}>
                                        <TrashIcon />
                                    </IconButton>
                                </TopBarTooltip>
                            )

                            statusText = "Waiting..."
                            statusIcon = <></>
                            break
                        case "IN_PROGRESS":
                            taskStateInfo = (
                                <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} textAlign="left">
                                    <LinearProgress sx={{ width: "100%", mr: 2 }} />
                                </Box>
                            )
                            deleteIcon = (
                                <TopBarTooltip title={"Cancel"}>
                                    <IconButton edge="end" aria-label="Cancel" onClick={() => cancelTask(task)}>
                                        <TrashIcon />
                                    </IconButton>
                                </TopBarTooltip>
                            )
                            statusText = <Box sx={{ whiteSpace: "nowrap" }}>Export in progress...</Box>
                            statusIcon = <></>
                            break
                        case "CANCELLED":
                            taskStateInfo = <CircleXIcon />
                            statusText = "Export was cancelled"
                            statusIcon = (
                                <TopBarTooltip title={"Retry"}>
                                    <IconButton edge="end" aria-label="Retry" onClick={() => recreateTask(task)}>
                                        <ReloadIcon />
                                    </IconButton>
                                </TopBarTooltip>
                            )
                            break
                        case "ERROR":
                            taskStateInfo = <CircleAlertIcon />
                            statusText =
                                (task as ErrorExportTaskDTO).longErrorMessage ??
                                (task as ErrorExportTaskDTO).shortErrorMessage
                            statusIcon = (
                                <TopBarTooltip title={"Retry"}>
                                    <IconButton edge="end" aria-label="Retry" onClick={() => recreateTask(task)}>
                                        <ReloadIcon />
                                    </IconButton>
                                </TopBarTooltip>
                            )
                            break
                        case "COMPLETED":
                            taskStateInfo = <CircleCheckIcon />
                            statusText = "Ready to download"
                            statusIcon = (
                                <TopBarTooltip title={"Download again"}>
                                    <IconButton
                                        edge="end"
                                        aria-label="Dowload again"
                                        onClick={() => downloadTaskResult(task)}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </TopBarTooltip>
                            )
                            break
                        default:
                            assertExhaustive(task.state)
                    }

                    return (
                        <Box key={task.id} sx={{ my: 1, p: 2 }} className={`item item-${task.state}`}>
                            {getExportTaskBlock(task, taskStateInfo, statusText, statusIcon, deleteIcon)}
                        </Box>
                    )
                })}
            </Container>
        </StyledBox>
    )

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            {dialogContent}
        </Popover>
    )
}

const getExportTaskBlock = (
    task: ExportTaskDTO,
    taskStateInfo: JSX.Element,
    statusText: JSX.Element | string,
    statusIcon: JSX.Element,
    deleteIcon: JSX.Element,
): JSX.Element => {
    let fileExtensionIcon: JSX.Element
    switch (task.extension) {
        case "CSV":
            fileExtensionIcon = <CsvIcon />
            break
        case "XLSX":
            fileExtensionIcon = <XlsxIcon />
            break
        default:
            assertExhaustive(task.extension)
    }

    return (
        <Box sx={{ display: "flex" }} className={"export-task"}>
            <Box className={"file-icon"}>{fileExtensionIcon}</Box>
            <Stack spacing={0} sx={{ flexGrow: 1, ml: 1 }} direction="column" className={"task-content"}>
                <Stack direction="row" justifyContent="space-between" className={"task-header"}>
                    <Typography variant={"h6"} align={"left"}>
                        {" "}
                        {task.title}
                        {task.timespanSettings && (
                            <>
                                {" "}
                                {parseDate(task.timespanSettings.start)} - {parseDate(task.timespanSettings.end)}
                            </>
                        )}
                    </Typography>
                    <Stack direction="row" className={"action-icons"}>
                        {statusIcon}
                        {deleteIcon}
                    </Stack>
                </Stack>

                <Typography variant={"body1"} align={"left"} className={"meta-data"}>
                    Started at {formatCreatedAt(task)}
                </Typography>

                <Stack justifyContent="left" direction="row" className="task-state-info">
                    {taskStateInfo}
                    {statusText && (
                        <Typography variant="caption" align="left" sx={{ marginLeft: "5px" }}>
                            {statusText}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}

/**
 * Formats the createdAt field of the task to e.g. 24.11.2021 20:34
 *
 * @param task
 */
const formatCreatedAt = (task: ExportTaskDTO): string => {
    const date = new Date(task.createdAt)
    return formatter.formatDateTime(date)
}

const parseDate = (date: string): string => {
    return date.split("T")[0]
}

const StyledBox = styled(Box)(({ theme }) => ({
    width: "600px",
    h2: {
        margin: `${theme.spacing(1)} 0 ${theme.spacing(2)} 0`,
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
        fontSize: "20px",
    },
    h6: {
        lineHeight: "100%",
        fontWeight: FONT_WEIGHT_MEDIUM,
        fontSize: FONT_SIZE_BODY_MEDIUM,
    },
    p: {
        fontSize: FONT_SIZE_BODY_X_SMALL,
    },
    // ------- small variant ------------------------------------------------
    "& .MuiIconButton-sizeSmall ": {
        padding: 0,
        "& .MuiSvgIcon-root": {
            width: "20px",
        },
    },
    "& .MuiLinearProgress-root": {
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
        },
    },
    "& .header .MuiIconButton-root": {
        height: "16px",
        marginTop: "16px",
    },
    "&  .MuiIconButton-root": {
        padding: 0,
        margin: 0,
        "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
        },
    },
    "& .item": {
        backgroundColor: "#fff",
        textAlign: "center",
        color: theme.palette.grey[700],
        borderRadius: 15,
        borderColor: theme.palette.grey[200],
        borderStyle: "solid",
        borderWidth: 1,

        [`&.item-${ExportTaskState.IN_PROGRESS}, &.item-${ExportTaskState.PENDING}`]: {
            background: theme.palette.grey[50],
            borderColor: theme.palette.grey[200],
        },
        [`&.item-${ExportTaskState.ERROR}`]: {
            background: theme.palette.red[50],
            borderColor: theme.palette.red[200],
            "& *": {
                color: `${theme.palette.red[600]}!important`,
            },
        },
        "& .task-state-info": {
            "& .MuiSvgIcon-root": {
                marginTop: "2px",
                height: "16px",
                width: "16px",
            },
        },
        "& .file-icon": {
            color: theme.palette.primary.main,
            "& .MuiSvgIcon-root": {
                height: "36px",
                width: "36px",
            },
        },
    },
}))
