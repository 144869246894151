import { FilterConfigDTO } from "generated/models"
import { InputComponent, SelectComponent } from "domain/filter/component/index"
import * as React from "react"
import { useEffect, useState } from "react"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { assertExhaustive } from "shared/util/TypeUtil"
import { FilterState, FilterType } from "domain/types"

type Props = {
    filter: FilterConfigDTO
    onChange: (identifier: string, value: string | number | string[] | number[]) => void
}
export const FilterElement = (props: Props) => {
    const { filter, onChange } = props
    const [filterState, setFilterState] = useState<FilterState>(FilterComponentUtil.createInitialFilterState(filter))
    const { displayName } = filterState.selectFormElement.formFieldConfig

    useEffect(() => {
        setFilterState(FilterComponentUtil.createInitialFilterState(filter))
        FilterComponentUtil.loadFilterValuesAsync(filter).then((filterEntries) => {
            setFilterState((prev) => {
                return {
                    ...prev,
                    filterEntries: filterEntries.dimensionValueDTO,
                    value:
                        prev.value === null || prev.value === undefined
                            ? Array.from(filter.selectedFilterValues || [])
                            : prev.value,
                }
            })
        })
    }, [filter])

    const handleChange = (identifier: string, value: string | number | string[] | number[]) => {
        setFilterState((prev: FilterState) => {
            return {
                ...prev,
                value: value,
            }
        })

        onChange(identifier, value)
    }

    const filterType = FilterComponentUtil.getFilterType(filterState)

    switch (filterType) {
        case FilterType.SINGLE_SELECT:
        case FilterType.MULTI_SELECT:
            return (
                <SelectComponent
                    filter={filterState}
                    label={displayName}
                    onChange={handleChange}
                    multiple={filterType === FilterType.MULTI_SELECT}
                />
            )
        case FilterType.TEXT:
            return <InputComponent filter={filterState} onChange={handleChange} />
        // TODO: why does assertExhaustive not work?
        // default:
        //     assertExhaustive(filterType)
    }
}
