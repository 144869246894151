import { ListItemIcon, ListItemText, MenuItem, SvgIconProps } from "@mui/material"
import React from "react"

export type CustomColumnMenuItemProps = {
    label: string
    onClick: () => void
    IconComponent: React.JSXElementConstructor<SvgIconProps>
}

export const CustomColumnMenuItem = ({ label, onClick, IconComponent }: CustomColumnMenuItemProps) => {
    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>
                <IconComponent fontSize="small" />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}
