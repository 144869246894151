import React from "react"
import ReactDOM from "react-dom/client"
// import { render } from "react-dom"
import App from "App"
import { Provider } from "react-redux"
import store from "shared/redux/store"
import { BrowserRouter } from "react-router-dom"
import { enableMapSet } from "immer"
import { LicenseInfo } from "@mui/x-license"
import { isLocalEnvironment } from "shared/util/env"
import { log } from "shared/util/log"

LicenseInfo.setLicenseKey(
    "cc8ddfea5fd0e8114ddda9c320aae8b4Tz05MjY1NCxFPTE3NTAyNTc0NzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
)

// Enable Map/Set in immer
enableMapSet()

log.setDefaultLevel(isLocalEnvironment() ? "debug" : "silent")

// React 18 mode
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
)

// React 17 mode
// render(
//     <Provider store={store}>
//         <BrowserRouter>
//             <App />
//         </BrowserRouter>
//     </Provider>,
//     document.getElementById("root"),
// )
