/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import type { OptionDTO } from "./OptionDTO"
import { OptionDTOFromJSON, OptionDTOFromJSONTyped, OptionDTOToJSON } from "./OptionDTO"

/**
 *
 * @export
 * @interface DashboardSettingsOptionsDTO
 */
export interface DashboardSettingsOptionsDTO {
    /**
     *
     * @type {Array<OptionDTO>}
     * @memberof DashboardSettingsOptionsDTO
     */
    metrics: Array<OptionDTO>
    /**
     *
     * @type {Array<OptionDTO>}
     * @memberof DashboardSettingsOptionsDTO
     */
    reportingDimensions: Array<OptionDTO>
    /**
     *
     * @type {Array<OptionDTO>}
     * @memberof DashboardSettingsOptionsDTO
     */
    reportingMethods: Array<OptionDTO>
}

/**
 * Check if a given object implements the DashboardSettingsOptionsDTO interface.
 */
export function instanceOfDashboardSettingsOptionsDTO(value: object): value is DashboardSettingsOptionsDTO {
    if (!("metrics" in value) || value["metrics"] === undefined) return false
    if (!("reportingDimensions" in value) || value["reportingDimensions"] === undefined) return false
    if (!("reportingMethods" in value) || value["reportingMethods"] === undefined) return false
    return true
}

export function DashboardSettingsOptionsDTOFromJSON(json: any): DashboardSettingsOptionsDTO {
    return DashboardSettingsOptionsDTOFromJSONTyped(json, false)
}

export function DashboardSettingsOptionsDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): DashboardSettingsOptionsDTO {
    if (json == null) {
        return json
    }
    return {
        metrics: (json["metrics"] as Array<any>).map(OptionDTOFromJSON),
        reportingDimensions: (json["reportingDimensions"] as Array<any>).map(OptionDTOFromJSON),
        reportingMethods: (json["reportingMethods"] as Array<any>).map(OptionDTOFromJSON),
    }
}

export function DashboardSettingsOptionsDTOToJSON(value?: DashboardSettingsOptionsDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        metrics: (value["metrics"] as Array<any>).map(OptionDTOToJSON),
        reportingDimensions: (value["reportingDimensions"] as Array<any>).map(OptionDTOToJSON),
        reportingMethods: (value["reportingMethods"] as Array<any>).map(OptionDTOToJSON),
    }
}
