import React from "react"
import { match } from "ts-pattern"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"

export const InfoText = () => {
    const type = ConversionListContextSelectors.useConversionListType()

    const text = match(type)
        .with(
            "historical",
            () =>
                "The historical list contains up to 36 months of conversion data. The full Customer Journey is available.",
        )
        .with(
            "realtime",
            () =>
                "The real-time list shows the last three days of conversion data. During this preliminary state the Customer Journey only displays Winning Touchpoints.",
        )
        .exhaustive()

    return <>{text}</>
}
