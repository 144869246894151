import { Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import { TouchpointTypeRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionDisplayOptions/TouchpointTypeRadioButtonGroup"

import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"

export const CustomerJourneyConfigForm = () => {
    const customerJourneyConfig = ConversionListContextSelectors.useCustomerJourneyConfig()
    const updateCustomerJourneyConfig = ConversionListContextSelectors.useUpdateCustomerJourneyConfig()

    const handleShowBlockedTouchpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newConfig = {
            ...customerJourneyConfig,
            showBlockedTouchpoints: event.target.checked,
        }
        updateCustomerJourneyConfig(newConfig)
    }

    return (
        <>
            <TouchpointTypeRadioButtonGroup />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={handleShowBlockedTouchpointsChange}
                        checked={customerJourneyConfig.showBlockedTouchpoints}
                        disabled={customerJourneyConfig.cjMode !== "ALL"}
                    />
                }
                label="Show Blocked Touchpoints"
            />
        </>
    )
}
