import numbro from "numbro"

numbro.registerLanguage({
    ...numbro.languageData("en-US"),
    languageTag: "en-DE",
    delimiters: {
        thousands: ".",
        decimal: ",",
    },
    currency: {
        symbol: "€",
        position: "postfix",
        code: "EUR",
    },
})
numbro.setLanguage("en-DE")
numbro.zeroFormat("0")

const NumberFormatter = {
    /**
     * Formats number with the thousand and more suffix
     * (e.g 2500 -> 2,5k)
     *
     * @param number
     * @param digits
     */
    shortenNumber: (number: number, digits: number): string => {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "m" },
            { value: 1e9, symbol: "b" },
            { value: 1e12, symbol: "t" },
            { value: 1e15, symbol: "p" },
            { value: 1e18, symbol: "e" },
        ]
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
        const item = lookup
            .slice()
            .reverse()
            .find((item) => Math.abs(number) >= item.value)
        return item ? (number / item.value).toFixed(digits).replace(rx, "$1").replace(".", ",") + item.symbol : "0"
    },

    formatNumberPercent: (round: number, value: any) => {
        return numbro(value || 0).format({ output: "percent", mantissa: round })
    },
}

export default NumberFormatter
