import React from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"

export const ConversionTypeRadioButtonGroup = () => {
    const conversionTypeSelection = ConversionListContextSelectors.useConversionTypeSelection()
    const updateConversionTypeSelection = ConversionListContextSelectors.useUpdateConversionTypeSelection()

    const selectedValues = Object.entries(conversionTypeSelection)
        .filter(([, value]) => value)
        .map(([key]) => key)

    const handleChange = (_: React.MouseEvent<HTMLElement>, conversionTypes: string[]) => {
        if (conversionTypes.length > 0) {
            updateConversionTypeSelection({
                sale: conversionTypes.includes("sale"),
                lead: conversionTypes.includes("lead"),
            })
        }
    }

    return (
        <ToggleButtonGroup color="primary" value={selectedValues} onChange={handleChange} size="small">
            <ToggleButton value="sale" sx={{ flexBasis: "40%" }}>
                Sales
            </ToggleButton>
            <ToggleButton value="lead" sx={{ flexBasis: "40%" }}>
                Leads
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
