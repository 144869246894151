import React from "react"
import { useContextSelector } from "use-context-selector"
import {
    ColumnConfiguratorLabels,
    ColumnUniqueName,
    MetricsFrontendGroupUniqueName,
    SupportedModels,
    WidgetStaticConfiguration,
} from "domain/ColumnConfigurator/types"
import {
    ColumnSelectAction,
    DimensionIdentifiableSettings,
    IdentifiableSettings,
    MetricIdentifiableSettings,
    SelectedState,
} from "domain/ColumnConfigurator/components/types"
import { DimensionMetricCompatibility } from "domain/ColumnConfigurator/DimensionMetricCompatibility"
import { ColumnConfiguratorContext } from "./ColumnConfiguratorContext"

/**
 * The ColumnConfiguratorContext object contains a lot of information that is not needed in all components. However,
 * every change to the context object will cause all dependent components to re-render. To avoid unnecessary re-renders,
 * the context object is split into slices, which should be used in the components that need them.
 */
const useSelectedDimensions = (): DimensionIdentifiableSettings[] => {
    const selectedDimensions = useContextSelector(ColumnConfiguratorContext, (context) => context?.selectedDimensions)

    if (selectedDimensions === undefined) {
        throw new Error("useSelectedDimensions must be used within a ColumnConfiguratorContextProvider")
    }

    return selectedDimensions
}

const useSelectedMetrics = (): MetricIdentifiableSettings[] => {
    const selectedMetrics = useContextSelector(ColumnConfiguratorContext, (context) => context?.selectedMetrics)

    if (selectedMetrics === undefined) {
        throw new Error("useSelectedMetrics must be used within a ColumnConfiguratorContextProvider")
    }

    return selectedMetrics
}

const usePinnedMetrics = (): {
    leftPinnedMetrics: ColumnUniqueName[]
} => {
    const leftPinnedMetrics = useContextSelector(ColumnConfiguratorContext, (context) => context?.leftPinnedMetrics)

    if (leftPinnedMetrics === undefined) {
        throw new Error("usePinnedMetrics must be used within a ColumnConfiguratorContextProvider")
    }

    return React.useMemo(
        () => ({
            leftPinnedMetrics,
        }),
        [leftPinnedMetrics],
    )
}

const useExpandedGroups = (): {
    expandedGroups: Set<MetricsFrontendGroupUniqueName>
    onExpandedGroupsChanged: (expandedGroups: Set<MetricsFrontendGroupUniqueName>) => void
} => {
    const expandedGroups = useContextSelector(ColumnConfiguratorContext, (context) => context?.expandedGroups)
    const onExpandedGroupsChanged = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.onExpandedGroupsChanged,
    )

    if (expandedGroups === undefined || onExpandedGroupsChanged === undefined) {
        throw new Error("useExpandedGroups must be used within a ColumnConfiguratorContextProvider")
    }

    return React.useMemo(() => {
        return {
            expandedGroups: expandedGroups,
            onExpandedGroupsChanged: onExpandedGroupsChanged,
        }
    }, [expandedGroups, onExpandedGroupsChanged])
}

const useSupportedColumns = (): {
    supportedDimensions: Set<ColumnUniqueName>
    supportedMetrics: Set<ColumnUniqueName>
    supportedModels: SupportedModels
} => {
    const supportedDimensions = useContextSelector(ColumnConfiguratorContext, (context) => context?.supportedDimensions)
    const supportedMetrics = useContextSelector(ColumnConfiguratorContext, (context) => context?.supportedMetrics)
    const supportedModels = useContextSelector(ColumnConfiguratorContext, (context) => context?.supportedModels)

    if (supportedDimensions === undefined || supportedMetrics === undefined || supportedModels === undefined) {
        throw new Error("useSupportedColumns must be used within a ColumnConfiguratorContextProvider")
    }

    return React.useMemo(() => {
        return {
            supportedDimensions: supportedDimensions,
            supportedMetrics: supportedMetrics,
            supportedModels: supportedModels,
        }
    }, [supportedDimensions, supportedMetrics, supportedModels])
}

const useDimensionMetricCompatibility = (): DimensionMetricCompatibility => {
    const dimensionMetricCompatibility = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.dimensionMetricCompatibility,
    )

    if (dimensionMetricCompatibility === undefined) {
        throw new Error("useDimensionMetricCompatibility must be used within a ColumnConfiguratorContextProvider")
    }

    return dimensionMetricCompatibility
}

const useWidgetState = (): {
    dialogState: "open" | "closed" | "applied"
    open: (selectedState: SelectedState, widgetStaticConfiguration: WidgetStaticConfiguration) => void
    close: () => void
    applyColumnConfiguratorSettings: () => void
    isSelectedStateValid: () => boolean
    getColumnConfiguratorOutputConfiguration: () => SelectedState
} => {
    const dialogState = useContextSelector(ColumnConfiguratorContext, (context) => context?.dialogState)
    const open = useContextSelector(ColumnConfiguratorContext, (context) => context?.open)
    const close = useContextSelector(ColumnConfiguratorContext, (context) => context?.close)
    const applyColumnConfiguratorSettings = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.applyColumnConfiguratorSettings,
    )
    const isSelectedStateValid = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.isSelectedStateValid,
    )
    const getColumnConfiguratorOutputConfiguration = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.getColumnConfiguratorOutputConfiguration,
    )

    if (
        dialogState === undefined ||
        open === undefined ||
        close === undefined ||
        applyColumnConfiguratorSettings === undefined ||
        isSelectedStateValid === undefined ||
        getColumnConfiguratorOutputConfiguration === undefined
    ) {
        throw new Error("useWidgetState must be used within a ColumnConfiguratorContextProvider")
    }

    return React.useMemo(() => {
        return {
            dialogState: dialogState,
            open: open,
            close: close,
            applyColumnConfiguratorSettings: applyColumnConfiguratorSettings,
            isSelectedStateValid: isSelectedStateValid,
            getColumnConfiguratorOutputConfiguration: getColumnConfiguratorOutputConfiguration,
        }
    }, [
        dialogState,
        open,
        close,
        applyColumnConfiguratorSettings,
        isSelectedStateValid,
        getColumnConfiguratorOutputConfiguration,
    ])
}

const useWidgetStaticConfiguration = (): WidgetStaticConfiguration => {
    const widgetStaticConfiguration = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.widgetStaticConfiguration,
    )

    if (widgetStaticConfiguration === undefined) {
        throw new Error("useWidgetStaticConfiguration must be used within a ColumnConfiguratorContextProvider")
    }

    return widgetStaticConfiguration
}

const useColumnSettingsChangeHandlers = (): {
    onColumnSettingsChanged: (columnSettings: IdentifiableSettings) => void
    onColumnSelectAction: (columnIdentifier: ColumnUniqueName, action: ColumnSelectAction) => void
    onColumnMoved: (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => void
} => {
    const onColumnSettingsChanged = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.onColumnSettingsChanged,
    )
    const onColumnSelectAction = useContextSelector(
        ColumnConfiguratorContext,
        (context) => context?.onColumnSelectAction,
    )
    const onColumnMoved = useContextSelector(ColumnConfiguratorContext, (context) => context?.onColumnMoved)

    if (onColumnSettingsChanged === undefined || onColumnSelectAction === undefined || onColumnMoved === undefined) {
        throw new Error("useColumnSettingsChangeHandlers must be used within a ColumnConfiguratorContextProvider")
    }

    return React.useMemo(() => {
        return {
            onColumnSettingsChanged: onColumnSettingsChanged,
            onColumnSelectAction: onColumnSelectAction,
            onColumnMoved: onColumnMoved,
        }
    }, [onColumnSettingsChanged, onColumnSelectAction, onColumnMoved])
}

export const useLabels = (): ColumnConfiguratorLabels => {
    const labels = useContextSelector(ColumnConfiguratorContext, (context) => context?.labels)

    if (labels === undefined) {
        throw new Error("useLabels must be used within a ColumnConfiguratorContextProvider")
    }

    return labels
}

export const ColumnConfiguratorContextSlices = {
    useSelectedDimensions,
    useSelectedMetrics,
    usePinnedMetrics,
    useExpandedGroups,
    useSupportedColumns,
    useDimensionMetricCompatibility,
    useWidgetState,
    useWidgetStaticConfiguration,
    useColumnSettingsChangeHandlers,
    useLabels,
}
