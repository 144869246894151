import React from "react"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import * as Yup from "yup"
import { Formik } from "formik"
import { Link } from "react-router-dom"
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from "@mui/material"
import LoginIcon from "@mui/icons-material/Login"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import AnimateButton from "login/AnimateButton"
import AuthenticationService from "domain/authentication/service/authentication.service"
import { useNavigationContext } from "shared/NavigationContext"
import { LoadingButton } from "@mui/lab"
import { FormikErrors, FormikTouched } from "formik/dist/types"
import { AuthLoginErrorDialog } from "login/AuthLoginErrorDialog"
import { isSuccessfulLoginResponseDTO } from "domain/types/backend/login.types"
import PublicLayout from "layout/PublicLayout/PublicLayout"
import { assertExhaustive } from "shared/util/TypeUtil"
import { PasswordExpiredLoginErrorDialog } from "login/PasswordExpiredLoginErrorDialog"
import { loggingIn } from "domain/authentication/redux/authentication.slice"
import { useAppDispatch } from "shared/redux/store"
import { LoginResponseDTOTypeEnum, RedirectLoginResponseDTO } from "generated/models"

const customInput = {
    marginTop: 1,
    marginBottom: 1,
    "& > label": {
        top: 23,
        left: 0,
        color: "ff0000",
        '&[data-shrink="false"]': {
            top: 5,
        },
    },
    "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
    },
    "& legend": {
        display: "none",
    },
    "& fieldset": {
        top: 0,
    },
}

type LoadingState = {
    errorDialog: ErrorDialogType | false
    showLoadingScreen: boolean
    submittingLogin: boolean
}

type AuthLoginProps = {
    initialErrors?: FormikErrors<{ username: string; password: string; submit: string }>
    initialTouched?: FormikTouched<{ username: string; password: string; submit: string }>
}

enum ErrorDialogType {
    REGULAR = "REGULAR",
    PASSWORD_EXPIRED = "PASSWORD_EXPIRED",
}

export const AuthLogin = ({ initialErrors, initialTouched }: AuthLoginProps) => {
    const navigationContext = useNavigationContext()
    const analyticsService = useAnalytics()
    const dispatch = useAppDispatch()

    const [errorState, setErrorState] = React.useState<"start" | "first-error" | "further-error">("start")
    const [showPassword, setShowPassword] = React.useState(false)

    const initialLoadingState: LoadingState = { errorDialog: false, showLoadingScreen: false, submittingLogin: false }
    const errorLoadingState: LoadingState = {
        errorDialog: ErrorDialogType.REGULAR,
        showLoadingScreen: false,
        submittingLogin: false,
    }
    const [loadingState, setLoadingState] = React.useState<LoadingState>(initialLoadingState)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const handleMouseDownPassword = (event: React.MouseEvent) => {
        event.preventDefault()
    }

    const splashScreenClass = loadingState.showLoadingScreen ? "visible" : "hidden"

    const navigateToPasswordReset = () => {
        navigationContext.navigate("/ui/password/request-reset")
    }

    return (
        <PublicLayout showLogo={true} showFooter={true}>
            {
                <div className={`splash-screen ${splashScreenClass}`}>
                    <div className="screen-centered">
                        <CircularProgress />
                    </div>
                </div>
            }
            <div className="login-form logo-dark">
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        submit: null,
                    }}
                    initialErrors={initialErrors}
                    initialTouched={initialTouched}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().max(255).required("Username is required"),
                        password: Yup.string().max(255).required("Password is required"),
                    })}
                    onSubmit={async ({ username, password }, { setStatus }) => {
                        setLoadingState({ errorDialog: false, showLoadingScreen: false, submittingLogin: true })

                        setTimeout(() => {
                            setLoadingState((prev) => {
                                if (prev.submittingLogin) {
                                    return { ...prev, showLoadingScreen: true }
                                } else {
                                    return prev
                                }
                            })
                        }, 500)

                        const loginPromise = AuthenticationService.login(username, password)
                            .then((loginResponseDTO) => {
                                dispatch(loggingIn())
                                setStatus({ success: isSuccessfulLoginResponseDTO(loginResponseDTO) })

                                switch (loginResponseDTO.type) {
                                    case LoginResponseDTOTypeEnum.SUCCESSFUL_LOGIN_RESPONSE_DTO:
                                        navigationContext.redirectToReferrer(false)
                                        break
                                    case LoginResponseDTOTypeEnum.PASSWORD_EXPIRED_LOGIN_RESPONSE_DTO:
                                        setLoadingState({
                                            ...initialLoadingState,
                                            errorDialog: ErrorDialogType.PASSWORD_EXPIRED,
                                        })
                                        setErrorState((prev) => {
                                            if (prev === "start") {
                                                return "first-error"
                                            } else {
                                                return "further-error"
                                            }
                                        })
                                        break
                                    case LoginResponseDTOTypeEnum.FAILED_LOGIN_RESPONSE_DTO:
                                        setLoadingState(errorLoadingState)
                                        setErrorState((prev) => {
                                            if (prev === "start") {
                                                return "first-error"
                                            } else {
                                                return "further-error"
                                            }
                                        })
                                        break
                                    case LoginResponseDTOTypeEnum.REDIRECT_LOGIN_RESPONSE_DTO:
                                        window.location.href = (
                                            loginResponseDTO as RedirectLoginResponseDTO
                                        ).redirectUrl
                                        break
                                    default:
                                        assertExhaustive(loginResponseDTO.type)
                                }
                            })
                            .catch(() => {
                                setLoadingState(errorLoadingState)
                            })

                        await loginPromise
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit} className={"auth-login-form"} method={"post"}>
                                <Typography sx={{ mb: 1 }}>Welcome! Please enter your login credentials:</Typography>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.username && errors.username)}
                                    sx={{ ...customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-username-login">Username</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-username-login"
                                        type="username"
                                        value={values.username}
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.username && errors.username && (
                                        <FormHelperText error id="standard-weight-helper-text-username-login">
                                            {errors.username}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.password && errors.password)}
                                    sx={{ ...customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password-login"
                                        type={showPassword ? "text" : "password"}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{}}
                                        label="Password"
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography
                                            component={Link}
                                            to={"/ui/password/request-reset"}
                                            color="primary"
                                            sx={{ textDecoration: "none" }}
                                        >
                                            Forgot Password?
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <LoadingButton
                                            color="primary"
                                            loading={isSubmitting}
                                            loadingPosition="start"
                                            startIcon={<LoginIcon />}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Login
                                        </LoadingButton>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )
                    }}
                </Formik>

                {loadingState.errorDialog == ErrorDialogType.PASSWORD_EXPIRED && (
                    <PasswordExpiredLoginErrorDialog
                        errorState={errorState}
                        open={true}
                        onClose={() => setLoadingState(initialLoadingState)}
                        navigateToPasswordReset={navigateToPasswordReset}
                    />
                )}
                {loadingState.errorDialog == ErrorDialogType.REGULAR && (
                    <AuthLoginErrorDialog
                        errorState={errorState}
                        open={true}
                        onClose={() => setLoadingState(initialLoadingState)}
                        navigateToPasswordReset={navigateToPasswordReset}
                    />
                )}
            </div>
        </PublicLayout>
    )
}
