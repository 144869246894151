import React from "react"
import { useUserSettingsContext } from "domain/user/settings/context/UserSettingsContext"
import { GridColumnStateContext } from "domain/user/settings/context/GridColumnStateContext"
import { GridColumnStateSettingsValueDTO } from "generated/models"

export type GridColumnStateContextProviderProps = Readonly<{
    children: React.ReactNode
}>

export const GridColumnStateContextProvider = ({ children }: GridColumnStateContextProviderProps): JSX.Element => {
    const userSettingsContext = useUserSettingsContext()

    function getGridColumnState(gridId: string): GridColumnStateSettingsValueDTO | null {
        return userSettingsContext.getSetting(getId(gridId)) as GridColumnStateSettingsValueDTO | null
    }
    const setGridColumnState = (gridId: string, selectedColumnIdentifiers: string[]) => {
        userSettingsContext.setSetting({
            id: getId(gridId),
            value: {
                type: "GridColumnStateSettingsValueDTO",
                selectedColumnIdentifiers: selectedColumnIdentifiers,
            } satisfies GridColumnStateSettingsValueDTO,
        })
    }

    const deleteGridColumnState = (gridId: string) => {
        userSettingsContext.deleteSettingById(getId(gridId))
    }

    const getId = (gridId: string) => `grid_column_state__${gridId}`

    return (
        <GridColumnStateContext.Provider
            value={{
                getGridColumnState: getGridColumnState,
                setGridColumnState: setGridColumnState,
                deleteGridColumnState: deleteGridColumnState,
            }}
        >
            {children}
        </GridColumnStateContext.Provider>
    )
}
