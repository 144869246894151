import { AppContextDTO } from "generated/models"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "shared/redux/store"

const SLICE_NAME = "appContext"

export type AppContextState = {
    // TODO: AppContextDTO contains advertiser and campaign names which probably should not be stored in the redux store, as they can be
    //  derived from the tree. Also see: https://redux.js.org/usage/deriving-data-selectors#calculating-derived-data-with-selectors
    appContext: AppContextDTO
}

const initialState: AppContextState = { appContext: {} as AppContextDTO }

const appContextSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        storeAppContextSuccess(state: AppContextState, action: PayloadAction<AppContextState>) {
            state.appContext = action.payload.appContext
        },
    },
})

export const selectors = {
    hasAppContext: (state: RootState): boolean => state[SLICE_NAME]?.appContext !== undefined,
    getAppContext: (state: RootState): AppContextDTO | undefined => state[SLICE_NAME]?.appContext,
}

export const { storeAppContextSuccess } = appContextSlice.actions
export default appContextSlice.reducer
