import { ActionPopupConfig, ModalConfig, UIFormConfig } from "domain/types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { log } from "shared/util/log"
import React from "react"
import store from "shared/redux/store"
import { ModalAction } from "shared/actions/modalAction"
import { GenericFormWrapper } from "shared/component/forms/GenericForm"

export type ModalState = ModalConfig[]

const initialState: ModalState = []

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        modalOpen(state: ModalState, action: PayloadAction<{ popupConfig: ActionPopupConfig }>): void {
            const config = action.payload.popupConfig

            const modalConfig: ModalConfig = defaultModalConfig(config)

            if (contains(state, config.identifier)) {
                log.warn("modalOpen: modal with identifier already exists", config.identifier)
                modalUpdateAux(state, { ...action, payload: modalConfig })
            } else {
                state.push(modalConfig)
            }
        },
        modalClose: modalCloseAux,
        modalCancel(state: ModalState, action: PayloadAction<{ identifier: string; config: ActionPopupConfig }>): void {
            const {
                identifier,
                config: { onAbort },
            } = action.payload
            if (onAbort && typeof onAbort === "function") onAbort()
            modalCloseAux(state, { ...action, payload: { identifier } })
        },
        modalLoadingState(state: ModalState, action: PayloadAction<{ identifier: string; loading: boolean }>): void {
            const { identifier, loading } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, contentLoading: loading } })
        },
        modalContent(
            state: ModalState,
            action: PayloadAction<{ identifier: string; content: UIFormConfig; popupConfig: ActionPopupConfig }>,
        ): void {
            const { identifier, content, popupConfig } = action.payload
            const genericForm = <GenericFormWrapper uiFormConfig={content} popupConfig={popupConfig} />
            const { title, subtitle } = popupConfig
            modalUpdateAux(state, { ...action, payload: { identifier, content: genericForm, title, subtitle } })
        },
        modalStartSubmitting(state: ModalState, action) {
            const { identifier } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, submitting: true } })
        },
        modalStopSubmitting(state: ModalState, action) {
            const { identifier } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, submitting: false } })
        },
    },
})

function modalUpdateAux(state: ModalState, action: PayloadAction<Partial<ModalConfig>>): void {
    const idx = getIdx(state, action.payload.identifier)
    if (idx >= 0) {
        state[idx] = { ...state[idx], ...action.payload }
    } else {
        log.warn(
            "Modal window with identifier " + action.payload.identifier + " not found. It could be already closed.",
        )
    }
}

function modalCloseAux(state: ModalState, action: PayloadAction<Partial<{ identifier: string }>>): void {
    const idx = getIdx(state, action.payload.identifier)
    if (idx >= 0) state.splice(idx, 1)
}

function defaultModalConfig(config: ActionPopupConfig): ModalConfig {
    const modalConfig: ModalConfig = {
        identifier: config.identifier,
        title: config.title,
        visible: true,
        submitting: false,
        onCancel: () => store.dispatch(modalCancel({ identifier: config.identifier, config })),
        onOk: (): ModalAction => store.dispatch(modalStartSubmitting({ identifier: config.identifier })),
        content: undefined,
        contentLoading: undefined,
        modalWidth: config.modalWidth,
        modalMinHeight: config.modalMinHeight,
        additionalFooterElements: config.additionalFooterElements,
        additionalFilters: config.additionalFilters,
    } as ModalConfig

    if (config.subtitle) modalConfig.subtitle = config.subtitle

    return modalConfig
}

function getIdx(state: ModalState, id: string): number {
    return state.findIndex((modal) => modal.identifier === id)
}

function contains(state: ModalState, id: string): boolean {
    return getIdx(state, id) >= 0
}

export const {
    modalOpen: modalOpen,
    modalClose: modalClose,
    modalCancel: modalCancel,
    modalStartSubmitting: modalStartSubmitting,
    modalStopSubmitting: modalStopSubmitting,
    modalLoadingState: modalLoadingState,
    modalContent: modalContent,
} = modalsSlice.actions

export default modalsSlice.reducer
