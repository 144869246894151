import React, { ChangeEvent } from "react"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Grid from "@mui/material/Unstable_Grid2"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Typography from "@mui/material/Typography"

import { TransitionGroup } from "react-transition-group"
import Collapse from "@mui/material/Collapse"
import InputAdornment from "@mui/material/InputAdornment"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { CircularProgress, DialogContentText } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { GridDataRowDTO } from "domain/types"
import { CustomDialog } from "layout/components/CustomDialog"
import { Container } from "@mui/system"

type Pixel = {
    idPixel: number
    pixelName: string
    isGlobal: boolean
    publisherName: string
    blockedByRule: number
    ruleHistory: string
    pixelCode: string
}

export const ShowPixelsDialog = ({
    shown,
    onCloseDialog,
    transactionUid,
    timestamp,
}: {
    shown: boolean
    onCloseDialog: () => void
    transactionUid: string
    timestamp: string
}) => {
    const { isPending, error, data } = ConversionListContextSelectors.useLoadPixelQuery({ transactionUid, timestamp })

    const pixels = data?.dataSet.rows.map((item) => convertToPixel(item))

    function convertToPixel(item: GridDataRowDTO): Pixel {
        return {
            idPixel: item["id_pixel"]?.value as number,
            blockedByRule: item["blocked_by_rule"]?.value as number,
            isGlobal: item["is_global"]?.value as boolean,
            pixelCode: item["pixel_code"]?.value as string,
            pixelName: item["pixel_name"]?.value as string,
            publisherName: item["publisher_name"]?.value as string,
            ruleHistory: item["rule_history"]?.value as string,
        }
    }

    type PixelType = "all" | "global" | "publisher"
    const [pixelType, setPixelType] = React.useState<PixelType>("all")
    const [search, setSearch] = React.useState("")

    const handleFilterChange = (event: SelectChangeEvent) => {
        setPixelType(event.target.value as PixelType)
    }

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value as string)
    }

    const handleReset = () => {
        setSearch("")
    }

    const filteredPixels = pixels?.filter(
        (pixel) =>
            (pixelType == "all" ||
                (pixelType == "global" && pixel.isGlobal) ||
                (pixelType == "publisher" && !pixel.isGlobal)) &&
            (search == "" ||
                pixel.pixelName.toLowerCase().includes(search.toLowerCase()) ||
                pixel.pixelCode.toLowerCase().includes(search.toLowerCase()) ||
                pixel.ruleHistory.toLowerCase().includes(search.toLowerCase())),
    )

    return (
        <CustomDialog
            open={shown}
            header="Show Pixels"
            maxWidth={"sm"}
            content={
                <DialogContentText component="div" className={"dialog-content"}>
                    <FormControl sx={{ marginRight: 1, minWidth: 120 }} variant="outlined">
                        <InputLabel htmlFor="filter-type-search">Search</InputLabel>
                        <OutlinedInput
                            type="text"
                            id="filter-type-search"
                            value={search}
                            onChange={handleSearchChange}
                            endAdornment={
                                search ? (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="reset search filter" onClick={handleReset} edge="end">
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="reset search filter" edge="end" disabled>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                            label="Search"
                        />
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="filter-type-label">Pixel Type</InputLabel>
                        <Select
                            labelId="filter-type-label"
                            value={pixelType}
                            label="Pixel Type"
                            onChange={handleFilterChange}
                        >
                            <MenuItem value={"all" satisfies PixelType}>All</MenuItem>
                            <MenuItem value={"publisher" satisfies PixelType}>Publisher Pixel</MenuItem>
                            <MenuItem value={"global" satisfies PixelType}>Global Pixel</MenuItem>
                        </Select>
                    </FormControl>
                    {isPending ? (
                        <Container fixed sx={{ textAlign: "center", padding: 3 }}>
                            <CircularProgress />
                        </Container>
                    ) : (
                        ""
                    )}
                    {error ? <div>"An error has occurred: " + error.message</div> : ""}
                    {isPending || error ? (
                        <></>
                    ) : (
                        <Container
                            sx={{
                                maxHeight: 800,
                                overflowY: "auto",
                                pb: 1, // the bottom needs some padding for the bottom border and shadow not to be cut off
                                pl: "3px", // visually, this padding gets eaten by the box shadow, so the box still looks aligned
                                pr: 1, // to the right we need more space for when the scrollbar is visible
                            }}
                            disableGutters={true}
                        >
                            {pixels?.length == 0 ? (
                                <Container fixed sx={{ textAlign: "center", padding: 3 }}>
                                    No Pixels have been delivered for this conversion
                                </Container>
                            ) : (
                                <TransitionGroup>
                                    {filteredPixels?.map((pixel) => (
                                        <Collapse key={pixel.idPixel}>
                                            <Pixel pixel={pixel} />
                                        </Collapse>
                                    ))}
                                    {filteredPixels?.length == 0 ? (
                                        <Collapse key={"noSearchResult"}>
                                            <Container fixed sx={{ textAlign: "center", padding: 3 }}>
                                                No pixels match your search criteria
                                            </Container>
                                        </Collapse>
                                    ) : (
                                        <></>
                                    )}
                                </TransitionGroup>
                            )}
                        </Container>
                    )}
                </DialogContentText>
            }
            footer={{
                kind: "none",
            }}
            onClose={onCloseDialog}
        ></CustomDialog>
    )
}

export const Pixel = ({ pixel }: { pixel: Pixel }) => {
    // Note: This has to be a Box at top level, with a paper the collapse outer layer does not work,
    //       nothing would be shown
    return (
        <Box>
            <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={3}>
                        Pixel Type:
                    </Grid>
                    <Grid xs={12} md={9}>
                        {pixel.isGlobal ? "Global" : "Publisher"}
                    </Grid>
                    <Grid xs={12} md={3}>
                        Pixel Name:
                    </Grid>
                    <Grid xs={12} md={9}>
                        {pixel.pixelName}
                    </Grid>
                    <Grid xs={12} md={3}>
                        Pixel Code:
                    </Grid>
                    <Grid xs={12} md={9}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            defaultValue={pixel.pixelCode}
                            inputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Accordion elevation={2}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"panel" + pixel.idPixel + "-content"}
                                id={"panel" + pixel.idPixel + "-header"}
                            >
                                <Typography>Pixel Rule Checks</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    defaultValue={pixel.ruleHistory}
                                    inputProps={{ readOnly: true }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
