import React from "react"
import { GridColumnStateSettingsValueDTO, SelectedNodeUserSettingsValueDTO, UserSettingsDTO } from "generated/models"

export type UserSettingsContextProps = Readonly<{
    getSetting: (id: string) => GridColumnStateSettingsValueDTO | SelectedNodeUserSettingsValueDTO | null
    setSetting: (setting: UserSettingsDTO) => void
    deleteSettingById: (id: string) => void
}>

export const UserSettingsContext = React.createContext<UserSettingsContextProps | undefined>(undefined)

export const useUserSettingsContext = (): UserSettingsContextProps => {
    const context = React.useContext(UserSettingsContext)
    if (!context) {
        throw new Error("useUserSettingsContext must be used within a UserSettingsContextProvider")
    }
    return context
}
