import React from "react"
import { Button, Col, Row } from "antd"

type Props = Record<string, never>

type State = {
    showDataGroupsDialog?: boolean
}

/**
 * Renders the settings toolbar for the DataGridWidget
 */
class DataGridSettingsToolbar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = { showDataGroupsDialog: false }
    }

    showDataGroupsDialog = () => {
        this.setState({ showDataGroupsDialog: true })
    }

    render() {
        return (
            <React.Fragment>
                <Row justify="end" style={{ marginBottom: 6 }}>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button onClick={this.showDataGroupsDialog}>Configure Columns</Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default DataGridSettingsToolbar
