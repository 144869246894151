import { Checkbox as MuiCheckbox, FormControlLabel, useTheme } from "@mui/material"
import React from "react"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"
import { useFormContext } from "shared/component/forms/FormContext"

export interface CheckboxProps {
    name: string
    label: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    required?: boolean
}

export const Checkbox = ({ name, label, onChange: onChangeProp, required = false }: CheckboxProps) => {
    const { current: id } = React.useRef(getUniqueId(name))

    const { formik } = useFormContext()

    const fieldProps = formik.getFieldProps(name)
    const theme = useTheme()

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        fieldProps.onChange(event)
        if (onChangeProp) {
            onChangeProp(event)
        }
    }

    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    id={id}
                    className={fieldProps.name + " form-field form-field-checkbox"}
                    name={fieldProps.name}
                    checked={Boolean(fieldProps.value)}
                    onChange={onChange}
                    onBlur={fieldProps.onBlur}
                    size={"small"}
                    required={required}
                />
            }
            label={label}
        />
    )
}
