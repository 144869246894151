import React, { useEffect, useState } from "react"
import mfaService from "./service/mfaService"
import { LoadingButton } from "@mui/lab"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import { OtpFormResponseDTO } from "generated/models"

type Props = {
    sendOnMount?: boolean
    onSuccess: () => void
    onError: () => void
}

const RequestEmailCodeButton: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [codeGenerationResponse, setCodeGenerationResponse] = useState<OtpFormResponseDTO>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props?.sendOnMount === true) requestCode()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sendOnMount])

    const setDelayedIsLoading = (isLoading: boolean) => {
        isLoading ? setIsLoading(isLoading) : setTimeout(() => setIsLoading(isLoading), 500)
    }

    const requestCode = () => {
        setDelayedIsLoading(true)
        mfaService.EMAIL.requestCode().then((response: OtpFormResponseDTO) => {
            setDelayedIsLoading(false)
            setCodeGenerationResponse(response)
            response.successful ? props?.onSuccess() : props?.onError()
        })
    }

    return (
        <LoadingButton onClick={requestCode} startIcon={<MailOutlineIcon />} loading={isLoading} variant={"outlined"}>
            {props?.sendOnMount || codeGenerationResponse ? "Re-Send Code" : "Send Code"}
        </LoadingButton>
    )
}

export default RequestEmailCodeButton
