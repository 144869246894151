import React from "react"
import { CssBaseline, GlobalStyles, PaletteMode, PaletteOptions, ThemeProvider } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { ThemeOptions } from "@mui/material/styles/createTheme"
import componentStyleOverrides from "styles/theme/componentStyleOverrides"
import fontSizeOverrides from "styles/theme/fontSizeOverrides"
import type { PartialDeep } from "type-fest"
import ObjectUtil from "shared/util/ObjectUtil"

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        primaryShades: ColorPartial
        red: ColorPartial
        pageBackgroundColor?: string
        box: { backgroundColor: string }
        chart: {
            blue: string
            darkBlue: string
            indigo: string
            purple: string
            pink: string
            red: string
            orange: string
            yellow: string
            green: string
            teal: string
            cyan: string
            gray: string
            lightGray: string
            darkGray: string
            black: string
            white: string
        }
        topBar: { backgroundColor: string; color: string }
        border: { subtle: string }
    }

    interface PaletteOptions extends PartialDeep<Palette> {}
}

const getThemeProps = (mode: PaletteMode): ThemeOptions => {
    const primaryColors = {
        light: "#63b4f6",
        main: "#3562E3", // RGB: 53 98 227
        dark: "#1045a1",
        contrastText: "#fff",
    }

    return {
        palette: {
            pageBackgroundColor: mode === "dark" ? "#000" : "#FCFCFE",
            background: {
                paper: mode === "dark" ? "#000" : "#fff",
                default: mode === "dark" ? "#000" : "#fff",
            },
            divider: mode === "light" ? "#f0f0f0" : "#303030",
            // divider: grey[300],
            mode: mode,
            primary: primaryColors,
            primaryShades: {
                50: "#F0F3FD",
                100: "#d7e0f9",
                200: "#aec0f4",
                300: "#86a1ee",
                400: "#5d81e9",
                500: "#3562e3",
                600: "#2a4eb6",
                700: "#203b88",
                800: "#15275b",
                900: "#0b142d",
            },
            grey: {
                50: "#F9FAFB",
                100: "#F2F4F7",
                200: "#EAECF0",
                300: "#D0D5DD",
                400: "#98A2B3",
                500: "#667085",
                600: "#475467",
                700: "#344054",
                800: "#1D2939",
                900: "#101828",
            },
            red: {
                50: "#FFFBFA",
                100: "#FEE4E2",
                200: "#FECDCA",
                300: "#FDA29B",
                400: "#F97066",
                500: "#F04438",
                600: "#D92D20",
                700: "#B42318",
                800: "#912018",
                900: "#7A271A",
            },
            info: primaryColors,
            success: {
                light: "#4caf50",
                main: "#4caf50",
                dark: "#1b5e20",
                contrastText: "#fff",
            },
            error: {
                light: "#FECDCA",
                main: "#D92D20",
                dark: "#912018",
                contrastText: "#fff",
            },
            secondary: {
                light: "#D0D5DD",
                main: "#667085",
                dark: "#344054",
                contrastText: "#fff",
            },
            border: {
                subtle: mode === "light" ? "#f0f0f0" : "#303030",
            },
            box: {
                backgroundColor: mode === "dark" ? "#000" : "rgb(243, 246, 249)",
            },
            topBar: {
                backgroundColor: "#191919",
                color: "#fff",
            },
            chart: {
                blue: "#3562E3",
                darkBlue: "#3071a9",
                indigo: "#663399",
                purple: "#913D88",
                pink: "#e83e8c",
                red: "#9D2933",
                orange: "#ff9800",
                yellow: "#F5D76E",
                green: "#6B9362",
                teal: "#20c997",
                cyan: "#17a2b8",
                lightGray: "#98A2B3",
                gray: "#667085",
                darkGray: "#1D2939",
                black: "#000",
                white: "#fff",
            },
        },
        typography: {
            fontFamily: "Inter, sans-serif",
        },
    }
}

type CustomThemeProps = {
    paletteMode?: PaletteMode
    paletteOptionsOverrides?: PaletteOptions
    disableCssBaseline?: boolean
    children: React.ReactNode
}

const CustomTheme: React.FC<CustomThemeProps> = ({
    paletteMode = "light",
    paletteOptionsOverrides = {},
    disableCssBaseline = false,
    children,
}: CustomThemeProps): JSX.Element => {
    const theme = React.useMemo(() => {
        const theme = createTheme(getThemeProps(paletteMode))

        theme.components = componentStyleOverrides(theme)
        theme.typography = fontSizeOverrides(theme)

        ObjectUtil.updateObjectDeep(theme.palette, paletteOptionsOverrides)

        return theme
    }, [paletteMode, paletteOptionsOverrides])

    return (
        <ThemeProvider theme={theme}>
            {!disableCssBaseline && <CssBaseline />}
            {children}
        </ThemeProvider>
    )
}

export default CustomTheme
