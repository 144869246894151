import { columnCategories } from "domain/ConversionList/domain/domain"
import React from "react"
import { CONVERSION_LIST_DATA_GROUP } from "domain/ConversionList/domain/columnConfigurator"
import { MetricIdentifiableSettings, SelectedState } from "domain/ColumnConfigurator/components/types"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { WidgetStaticConfiguration } from "domain/ColumnConfigurator/types"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { ColumnConfiguratorDialog } from "domain/ColumnConfigurator/components/ColumnConfiguratorDialog"
import { Button } from "@mui/material"

export const ColumnConfiguration = () => {
    const {
        open: openColumnConfigurator,
        dialogState: columnConfiguratorDialogState,
        getColumnConfiguratorOutputConfiguration,
        close: closeColumnConfigurator,
    } = ColumnConfiguratorContextSlices.useWidgetState()

    const selectedColumns = ConversionListContextSelectors.useSelectedColumns()
    const updateSelectedColumns = ConversionListContextSelectors.useUpdateSelectedColumns()
    const resetGridColumnState = ConversionListContextSelectors.useResetGridColumnState()

    const leftPinnedColumns = ConversionListContextSelectors.useLeftPinnedColumns()

    const handleClick = () => {
        const selectedMetrics: MetricIdentifiableSettings[] = selectedColumns.map((column) => ({
            identifier: column,
            showBars: false,
        }))

        const selectedState: SelectedState = new SelectedState({
            selectedDimensions: [],
            selectedMetrics: selectedMetrics,
            widgetId: undefined,
            leftPinnedMetrics: [...leftPinnedColumns],
        })

        const widgetStaticConfiguration = new WidgetStaticConfiguration({
            supportedColumnSettings: [],
            maxDimensions: 0,
            supportedDataGroups: [CONVERSION_LIST_DATA_GROUP],
            initiallyExpandedGroups: new Set(Object.keys(columnCategories)),
        })

        openColumnConfigurator(selectedState, widgetStaticConfiguration)
    }

    React.useEffect(() => {
        if (columnConfiguratorDialogState === "applied") {
            const output = getColumnConfiguratorOutputConfiguration()
            updateSelectedColumns(output.selectedMetrics.map((column) => column.identifier))
            closeColumnConfigurator()
        }
    }, [
        columnConfiguratorDialogState,
        getColumnConfiguratorOutputConfiguration,
        closeColumnConfigurator,
        updateSelectedColumns,
    ])

    return (
        <>
            <Button className="column-configurator-configure-columns-button" variant="outlined" onClick={handleClick}>
                Configure Columns
            </Button>
            <Button variant="outlined" onClick={resetGridColumnState} style={{ marginLeft: "10px" }}>
                Reset
            </Button>
            <ColumnConfiguratorDialog />
        </>
    )
}
