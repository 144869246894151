import React from "react"
import { animation, Item, Menu } from "react-contexify"
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"

type Props = {
    menuId: string
    handleSelect: (id: number | string) => void
    handleEdit: (id: number | string) => void
    handleDelete: (id: number | string) => void
}

const ScenarioGridContextMenu = ({ menuId, handleSelect, handleEdit, handleDelete }: Props) => {
    return (
        <Menu id={menuId} animation={animation.zoom} style={{ zIndex: 101 }}>
            <Item onClick={({ props }) => handleSelect(props["item"])}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckOutlined style={{ marginRight: 5 }} />
                    <span>Select this Scenario for Comparison</span>
                </div>
            </Item>
            <Item onClick={({ props }) => handleEdit(props["item"])}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <EditOutlined style={{ marginRight: 5 }} />
                    <span>Edit</span>
                </div>
            </Item>
            <Item onClick={({ props }) => handleDelete(props["item"])}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteOutlined style={{ marginRight: 5 }} />
                    <span>Delete</span>
                </div>
            </Item>
        </Menu>
    )
}

export default ScenarioGridContextMenu
