import { EChartsOption } from "echarts-for-react/src/types"
import { BarSeriesOption } from "echarts"

const WidgetSettingsUtil = {
    /**
     * Activates stacked mode in the bar settings
     *
     * @param seriesUiSettings
     * @param showDecalPattern
     */
    applyDecalPattern: (seriesUiSettings: EChartsOption, showDecalPattern: boolean): EChartsOption => {
        return {
            ...seriesUiSettings,
            aria: {
                ...seriesUiSettings.aria,
                decal: { ...seriesUiSettings.aria.decal, show: showDecalPattern },
            },
        }
    },

    /**
     * Adds to each series the [uiSettings]
     *
     * @param seriesOptions
     * @param uiSettings
     */
    enrichSeriesWithUiSettings: (seriesOptions: any[], uiSettings: any): any[] => {
        return seriesOptions.map((seriesOption) => {
            return { ...seriesOption, ...uiSettings }
        })
    },
}

export default WidgetSettingsUtil
