import React from "react"
import { ArrowRightOutlined } from "@ant-design/icons"
import formatter from "shared/util/formatter"
import Popover from "antd/lib/popover"
import { ScenarioComparisonData } from "domain/types"
import NumberFormatter from "shared/util/NumberFormatter"

/**
 * We are calculating the relative change prefix based on the displayed and roundded values to avoid cases like "+0%" if the change
 * was so small that it becomes invisible due to rounding
 */
const formatRelativeChange = (relativeChange: number): string => {
    const changeDirection = getChangeDirection(relativeChange)
    const prefix = changeDirection == "UP" ? "+" : changeDirection == "DOWN" ? "-" : "+/-"

    // always round to one decimal for consistency
    const percentageRound = 1
    const formattedChange = NumberFormatter.formatNumberPercent(percentageRound, relativeChange)

    // formattedChange[0] == prefix  makes sure to avoid values like  "--8%"
    return formattedChange[0] == "+" || formattedChange[0] == "-" ? formattedChange : prefix + formattedChange
}

const formatNumber = (valueType: string, value: number, decimals: number): string => {
    switch (valueType) {
        case "PERCENT":
            return NumberFormatter.formatNumberPercent(decimals, value)
        case "NUMBER":
            return formatter.formatNumber(decimals, value)
        case "CURRENCY":
            return formatter.formatNumber(decimals, value) + " €"
        default:
            return value + ""
    }
}

const getChangeDirection = (relativeChange: number): string => {
    // we have to round the numbers first so that we don't say something has e.g. increased
    // although there was no visible change in the displayed values
    const percentageRound = 1
    const formattedChange = formatter.formatNumber(percentageRound, relativeChange * 100)
    const formattedChangeUnsigned = isNaN(Number(formattedChange[0])) ? formattedChange.substr(1) : formattedChange

    return "0,0" === formattedChangeUnsigned ? "NO_CHANGE" : relativeChange > 0 ? "UP" : "DOWN"
}

const getChangeCssClasses = (relativeChange: number, goodChangeDirection: string): string => {
    let cssClasses = ""

    const changeDirection = getChangeDirection(relativeChange)

    if (goodChangeDirection === "HAS_NO_DIRECTION") {
        cssClasses = "neutral-change has-no-change-direction"
    } else {
        switch (changeDirection) {
            case "UP":
                cssClasses = goodChangeDirection === "UP" ? "good-change" : "bad-change"
                break
            case "DOWN":
                cssClasses = goodChangeDirection === "UP" ? "bad-change" : "good-change"
                break
            default:
                cssClasses = "neutral-change"
        }
    }

    cssClasses += " change change-" + changeDirection
    return cssClasses
}

export const valueComparisonRenderer: React.FunctionComponent<any> = (config: ScenarioComparisonData) => {
    const { values, change, valueType, round, comparisonItem1Name, comparisonItem2Name, label, goodChangeDirection } =
        config
    const relativeChange = change.relative?.value ? change.relative?.value : null
    const changeDirection = getChangeDirection(relativeChange)
    const additionalCssClasses = getChangeCssClasses(relativeChange, goodChangeDirection)
    const formattedRelativeChange = formatRelativeChange(relativeChange)

    const value1 = values[0] ? values[0] : 0
    const value2 = values[1] ? values[1] : 0

    return (
        <Popover
            content={
                <div className={"grid-popover"}>
                    <h3>{label}</h3>
                    <strong>{comparisonItem1Name}</strong>: {formatNumber(valueType, value1, round)}
                    <br />
                    <strong> {comparisonItem2Name}</strong>: {formatNumber(valueType, value2, round)}
                    {changeDirection != "NO_CHANGE" && <p>This is a change of {formattedRelativeChange}</p>}
                </div>
            }
        >
            <div className={additionalCssClasses}>
                <div className={"relative-change"}>
                    <span>{formattedRelativeChange}</span>
                </div>
                <div className={"absolute-change"}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <span style={{ textAlign: "right" }}>{formatNumber(valueType, value1, round)}</span>
                        <ArrowRightOutlined style={{ margin: "1px 3px", width: 20, textAlign: "center" }} />
                        <span style={{ textAlign: "left" }}>{formatNumber(valueType, value2, round)}</span>
                    </div>
                </div>
            </div>
        </Popover>
    )
}
