import { createSvgIcon } from "@mui/material"
import React from "react"

const CircleXIcon = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.89583 19.0208C14.4982 19.0208 18.2292 15.2898 18.2292 10.6875C18.2292 6.08509 14.4982 2.35413 9.89583 2.35413C5.29346 2.35413 1.5625 6.08509 1.5625 10.6875C1.5625 15.2898 5.29346 19.0208 9.89583 19.0208Z"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.94788 13.8542L12.8646 7.9375"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.94781 7.9375L12.8645 13.8542"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>,
    "Circle X",
)

export default CircleXIcon
