import { UpdaterFn } from "@tanstack/react-form"
import { WidgetDTOTypeEnum } from "generated/models"
import React, { ComponentProps, lazy } from "react"

export type WidgetSettings<Settings extends NonNullable<unknown> = NonNullable<unknown>> = {
    settings: Settings
    setSettings?: UpdaterFn<Settings, void>
}

const WidgetMap = {
    [WidgetDTOTypeEnum.CONTAINER_WIDGET]: lazy(() => import("./ContainerWidget/ContainerWidget")),
    [WidgetDTOTypeEnum.TEXT_WIDGET]: lazy(() => import("../widget/TextWidget/TextWidget")),
    [WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET]: lazy(() => import("./BarChart/MultiBarChart")),
    [WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET]: lazy(() => import("./BarChart/TimeBasedBarChart")),
    [WidgetDTOTypeEnum.LINE_CHART_WIDGET]: lazy(() => import("./LineChart/LineChartWidget")),
    [WidgetDTOTypeEnum.TABBED_WIDGET]: lazy(() => import("./TabbedWidgetChart/TabbedWidgetChart")),
    [WidgetDTOTypeEnum.PERIOD_WIDGET]: lazy(() => import("./periods/PeriodWidget")),
    [WidgetDTOTypeEnum.PREVIOUS_PERIOD_WIDGET]: lazy(() => import("./periods/PreviousPeriodWidget")),
    [WidgetDTOTypeEnum.METRIC_TOGGLE_WIDGET]: lazy(() => import("./DashboardSettings/MetricToggleWidget")),
    [WidgetDTOTypeEnum.REPORTING_METHOD_TOGGLE_WIDGET]: lazy(
        () => import("./DashboardSettings/ReportingMethodToggleWidget"),
    ),
    [WidgetDTOTypeEnum.REPORTING_DIMENSION_TOGGLE_WIDGET]: lazy(
        () => import("./DashboardSettings/ReportingDimensionToggleWidget"),
    ),
    [WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET]: lazy(
        () => import("./HistoricalComparison/HistoricalComparisonBarChart/HistoricalComparisonBarChartWidget"),
    ),
    [WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET]: lazy(
        () => import("./HistoricalComparison/HistoricalComparisonCard/HistoricalComparisonCardWidget"),
    ),
} satisfies Record<WidgetDTOTypeEnum, React.LazyExoticComponent<(props: WidgetSettings<any>) => JSX.Element>>

export type WidgetMap = typeof WidgetMap

export type WidgetProps<Type extends keyof WidgetMap, Additional extends Record<string, any> = NonNullable<unknown>> = {
    [K in Type]: {
        type: Type
    } & ComponentProps<WidgetMap[K]> &
        Additional
}[Type]

export const WidgetRenderer = <Type extends keyof typeof WidgetMap>(props: WidgetProps<Type>) => {
    if (props.type) {
        const Widget = WidgetMap[props.type]

        // TypeScript isn't smart enough but at usage it is checked correctly
        return <Widget {...(props as any)} />
    } else {
        return <React.Fragment />
    }
}
