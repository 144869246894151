import React from "react"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import ModalManager from "shared/component/modals/ModalManager"
import { ActionModal } from "shared/component/modals/action/ActionModal"
import { ActionModalContextProvider } from "shared/component/modals/action/ActionModalContext"
import { TOP_BAR_HEIGHT_LARGE_SCREEN, TOP_BAR_HEIGHT_SMALL_SCREEN } from "layout/MainLayout/constants"
import { useResponsiveProperty } from "styles/theme/useResponsiveProperty"

export const Main = () => {
    const responsiveProperty = useResponsiveProperty<CSSStyleDeclaration>()

    return (
        <Box
            className={"main"}
            component="main"
            sx={{
                flexGrow: 1,
                backgroundColor: "background.default",
                ...responsiveProperty({
                    property: "marginTop",
                    largeScreenValue: TOP_BAR_HEIGHT_LARGE_SCREEN,
                    smallScreenValue: TOP_BAR_HEIGHT_SMALL_SCREEN,
                }),
            }}
        >
            <ActionModalContextProvider>
                <ActionModal />
                <ModalManager />
                <Outlet />
            </ActionModalContextProvider>
        </Box>
    )
}

export default Main
