import Badge from "@mui/material/Badge"
import { IconButton } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "shared/redux/store"
import { notificationActions } from "domain/notification/redux/load.notification.actions"
import { useNavigationContext } from "shared/NavigationContext"
import { NotificationType } from "domain/notification/NotificationService"
import BellIcon from "shared/component/icons/BellIcon"
import { TopBarTooltip } from "layout/MainLayout/TopBar/TopBarTooltip"
import UrlService from "shared/service/url.service"
import { useAnalytics } from "shared/analytics/AnalyticsContext"

export const AlertNotification = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const navigationContext = useNavigationContext()

    const notificationState = useSelector((state: RootState) => state.notifications)
    const alertsNotification = notificationState.notifications.find(
        (notification) => notification.type == NotificationType.ALERTS,
    )

    const analyticsService = useAnalytics()

    const onClick = () => {
        notificationActions.dismissNotification(NotificationType.ALERTS)(dispatch)
        navigationContext.navigate(`${UrlService.getUiContextPath()}/reporting/alerts`)
        analyticsService.trackButtonClick("Alerts Notification")
    }

    return (
        <TopBarTooltip title={"Alerts"}>
            <IconButton onClick={onClick}>
                <Badge badgeContent={alertsNotification?.count} color="primary" variant={"standard"}>
                    <BellIcon fontSize={"medium"} />
                </Badge>
            </IconButton>
        </TopBarTooltip>
    )
}
