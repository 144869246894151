import { ColumnHeader } from "domain/ConversionList/components/GridPanel/ColumnHeader"
import { ColumnConfigDTO } from "generated/models"
import { SortSettingsDTO } from "generated/models"
import React, { useMemo, useState } from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { Box, PopoverPosition } from "@mui/material"
import { CustomizedDataGrid } from "shared/component/mui/datagrid/CustomizedDataGrid"
import { CustomerJourneyPanel } from "domain/ConversionList/components/GridPanel/CustomerJourneyPanel"
import {
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridColDef,
    GridColumnHeaderParams,
    GridColumnOrderChangeParams,
    GridPinnedColumnFields,
    GridRowParams,
} from "@mui/x-data-grid-pro"
import type { GridSortModel } from "@mui/x-data-grid-pro"
import {
    CONVERSION_LIST_TP_TTC,
    TRANSACTION_TS,
    TRANSACTION_UID,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import DimensionService from "domain/dimension/service/DimensionService"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { ShowPixelsDialog } from "domain/ConversionList/components/ShowPixels/ShowPixelsDialog"
import { produce } from "immer"
import moment from "moment"

const DEFAULT_SORT_SETTINGS: Readonly<SortSettingsDTO> = {
    sortProperties: [`${TRANSACTION_TS}.value`],
    sortAscending: false,
}

export const GridPanel = () => {
    const [paginationSettings, setPaginationSettings] = React.useState({
        page: 0,
        pageSize: 50,
    })

    const queryConfig = ConversionListContextSelectors.useCurrentQueryConfig()

    const sortSettings = ConversionListContextSelectors.useSortSettings()
    const setSortSettings = ConversionListContextSelectors.useSetSortSettings()

    const columns = ConversionListContextSelectors.useColumns()
    const selectedColumns = ConversionListContextSelectors.useSelectedColumns()
    const updateSelectedColumns = ConversionListContextSelectors.useUpdateSelectedColumns()
    const columnConfigs = useMemo(
        () => selectedColumns.map((fieldName) => columns.columnDetails[fieldName]!.columnConfigDTO),
        [columns, selectedColumns],
    )

    const leftPinnedColumns = ConversionListContextSelectors.useLeftPinnedColumns()
    const updateLeftPinnedColumns = ConversionListContextSelectors.useUpdateLeftPinnedColumns()

    const queryResult = ConversionListContextSelectors.useLoadDataQuery({
        queryConfig,
        paginationSettings,
        sortSettings,
    })

    const paginationQueryResult = ConversionListContextSelectors.useLoadPaginationQuery({
        queryConfig,
        paginationSettings,
        sortSettings,
    })

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            if (sortModel.length === 0 || sortModel[0] === undefined) {
                setSortSettings(DEFAULT_SORT_SETTINGS)
            } else {
                setSortSettings({
                    sortProperties: [sortModel[0].field],
                    sortAscending: sortModel[0].sort === "asc",
                })
            }
        },
        [setSortSettings],
    )

    const type = ConversionListContextSelectors.useConversionListType()
    const [shownDialog, setShownDialog] = useState(false)
    const [transactionUid, setTransactionUid] = useState("")
    const [timestamp, setTimestamp] = useState("")
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number
        mouseY: number
    } | null>(null)

    const handleContextMenu = React.useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault()
            const rowId = Number(event.currentTarget.getAttribute("data-id"))
            setTransactionUid((queryResult.data?.dataSet.rows[rowId] as Record<string, any>)[TRANSACTION_UID].value)
            setTimestamp((queryResult.data?.dataSet.rows[rowId] as Record<string, any>)[TRANSACTION_TS].value)
            setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null)
        },
        [contextMenu, queryResult.data?.dataSet.rows],
    )

    const handleClose = () => {
        setContextMenu(null)
    }

    const handlePixelDialogClose = () => {
        setShownDialog(!shownDialog)
        setContextMenu(null)
    }

    const handlePixelDialogClick = () => {
        setContextMenu(null)
        setShownDialog(true)
    }

    const customerJourneyRenderer = React.useCallback(
        ({ row }: GridRowParams) => {
            const transactionUid = (row as Record<string, any>)[
                DimensionService.getDimensionValueColumn(TRANSACTION_UID)
            ]
            return (
                <CustomerJourneyPanel
                    transactionUid={transactionUid}
                    onContextMenu={type == "historical" ? handleContextMenu : undefined}
                />
            )
        },
        [handleContextMenu, type],
    )

    const makeGridColDef = (dataColumn: ColumnConfigDTO): GridColDef => ({
        field: dataColumn.columnIdentifier,
        width: dataColumn.gridColumnProperties.width || 150,
        sortable: dataColumn.gridColumnProperties.sortable,
        renderHeader: (_: GridColumnHeaderParams) => (
            <ColumnHeader
                label={dataColumn.gridColumnProperties.columnHeader}
                columnCategory={columns.columnDetails[dataColumn.columnIdentifier]?.columnCategory}
            />
        ),
        valueFormatter: (value?: number) => {
            if (value == null) {
                return ""
            }
            if (dataColumn.columnIdentifier == `${TRANSACTION_TS}.value`) {
                return moment(value, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss")
            }
            if (dataColumn.columnIdentifier == `${CONVERSION_LIST_TP_TTC}.value`) {
                if (Number(value) > 0) {
                    const days = Math.floor(Number(value) / 86400)
                    const hours = Math.floor((value % 86400) / 3600)
                    const minutes = Math.floor((value % 3600) / 60)
                    const seconds = Math.floor(value % 60)
                    return `${days > 0 ? days + "d " : ""}${hours > 0 ? hours + "h " : ""}${
                        minutes > 0 ? minutes + "m " : ""
                    }${seconds > 0 ? seconds + "s" : ""}`
                }
            }
            return value
        },
    })

    const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
        updateSelectedColumns(
            produce(selectedColumns, (draft) => {
                draft.splice(params.oldIndex - 1, 1)
                draft.splice(params.targetIndex - 1, 0, params.column.field)
            }),
        )
    }

    const pinnedColumns: GridPinnedColumnFields = {
        left: [...leftPinnedColumns],
    }

    const handlePinnedColumnsChange = (pinnedColumns: GridPinnedColumnFields) => {
        if (pinnedColumns.left) {
            if (pinnedColumns?.left[0] != GRID_DETAIL_PANEL_TOGGLE_FIELD) {
                // By pinning the detail field it stays to the left of all pinned columns
                pinnedColumns.left = [GRID_DETAIL_PANEL_TOGGLE_FIELD].concat(pinnedColumns.left)
            }
            if (pinnedColumns.left.length == 1 && pinnedColumns?.left[0] == GRID_DETAIL_PANEL_TOGGLE_FIELD) {
                pinnedColumns.left = []
            }
            updateLeftPinnedColumns(pinnedColumns.left)
        }
    }

    // TODO: proper sizing and positioning
    return (
        <Box sx={{ width: "100%", height: "600px" }}>
            <CustomizedDataGrid
                visibleColumns={columnConfigs}
                queryResult={queryResult}
                onPageChange={(page, pageSize) => {
                    const newPage = paginationSettings.pageSize === pageSize ? page : 0
                    setPaginationSettings({ page: newPage, pageSize: pageSize })
                }}
                muiDataGridProps={{
                    // Referenced function must have a stable identity
                    getDetailPanelHeight: autoPanelHeight,
                    // Referenced function must have a stable identity
                    getDetailPanelContent: customerJourneyRenderer,

                    sortingMode: "server",
                    sortingOrder: ["asc", "desc"],
                    onSortModelChange: handleSortModelChange,
                    disableColumnSelector: true,
                    onColumnOrderChange: handleColumnOrderChange,
                    pinnedColumns: pinnedColumns,
                    onPinnedColumnsChange: handlePinnedColumnsChange,
                    initialState: {
                        sorting: {
                            sortModel: sortSettings.sortProperties?.map((field) => {
                                return { field: field, sort: sortSettings.sortAscending ? "asc" : "desc" }
                            }),
                        },
                    },
                }}
                onContextMenu={type == "historical" ? handleContextMenu : undefined}
                makeGridColDef={makeGridColDef}
                pinning="left"
                paginationQueryResult={
                    paginationQueryResult.isSuccess
                        ? {
                              ...paginationQueryResult,
                              data: {
                                  pageSize: paginationQueryResult.data.pageSize,
                                  pages: paginationQueryResult.data.pages,
                                  totalEntities: paginationQueryResult.data.totalEntities,
                                  page: paginationSettings.page,
                              },
                          }
                        : paginationQueryResult
                }
            />
            {queryResult.isSuccess && type == "historical" && (
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? ({ top: contextMenu.mouseY, left: contextMenu.mouseX } as PopoverPosition)
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault()
                                if (!shownDialog) {
                                    handleClose()
                                }
                            },
                        },
                    }}
                >
                    <MenuItem onClick={handlePixelDialogClick}>Show Pixels</MenuItem>
                </Menu>
            )}
            {shownDialog && (
                <ShowPixelsDialog
                    shown={shownDialog}
                    onCloseDialog={handlePixelDialogClose}
                    transactionUid={transactionUid}
                    timestamp={timestamp}
                />
            )}
        </Box>
    )
}

const autoPanelHeight = () => "auto" as const
