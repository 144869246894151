import { Button } from "@mui/material"
import React from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"

export const UpdateButton = () => {
    const hasUnappliedChanges = ConversionListContextSelectors.useHasUnappliedChanges()
    const applyChanges = ConversionListContextSelectors.useApplyChanges()

    return (
        <Button
            className="conversion-list-show-result-button"
            disabled={!hasUnappliedChanges}
            onClick={(_) => applyChanges()}
            variant="contained"
            sx={{ transition: "background-color 1s cubic-bezier(.68,-0.55,.15,1.97)" }}
        >
            Show Result
        </Button>
    )
}
