import moment from "moment"
import React, { createContext, PropsWithChildren, Suspense, useCallback, useContext, useState } from "react"
import { getDatesFromTimeRange, getPreviousPeriodExact, TimeRange } from "utils/time-range-utils"

type PeriodsContextProviderType = {
    period: TimeRange
    previousPeriod: TimeRange
    setPeriod: React.Dispatch<React.SetStateAction<TimeRange>>
    setPreviousPeriod: React.Dispatch<React.SetStateAction<TimeRange>>
}

const PeriodsContext = createContext<PeriodsContextProviderType | undefined>(undefined)

type PeriodsContextProviderProps = {
    initialPeriod?: TimeRange
}

const last30Days: TimeRange = {
    type: "custom",
    dates: [moment().subtract(30, "days"), moment().subtract(1, "days")],
}

export const PeriodsContextProvider = (props: PropsWithChildren<PeriodsContextProviderProps>) => {
    const { children, initialPeriod } = props

    const [period, setPeriod] = useState<TimeRange>(initialPeriod ?? last30Days)
    const [start, end] = getDatesFromTimeRange(period)

    const [previousPeriod, setPreviousPeriod] = useState<TimeRange>(getPreviousPeriodExact(start, end))
    const [hasPreviousPeriodChanged, setHasPreviousPeriodChanged] = useState(false)

    const setPeriodSync: React.Dispatch<React.SetStateAction<TimeRange>> = useCallback(
        (value) => {
            const newRange = typeof value === "function" ? value(period) : value
            setPeriod(newRange)
            const [start, end] = getDatesFromTimeRange(newRange)
            if (!hasPreviousPeriodChanged) {
                setPreviousPeriod(getPreviousPeriodExact(start, end))
            }
        },
        [hasPreviousPeriodChanged, period, setPreviousPeriod, setPeriod],
    )

    const setPreviousPeriodSync: React.Dispatch<React.SetStateAction<TimeRange>> = useCallback((value) => {
        setPreviousPeriod(value)
        setHasPreviousPeriodChanged(true)
    }, [])

    return (
        <PeriodsContext.Provider
            value={{
                period,
                previousPeriod: previousPeriod,
                setPeriod: (value) => {
                    setPeriodSync(value)
                },
                setPreviousPeriod: (value) => {
                    setPreviousPeriodSync(value)
                },
            }}
        >
            <Suspense>{children}</Suspense>
        </PeriodsContext.Provider>
    )
}

export const usePeriodsContext = () => {
    const context = useContext(PeriodsContext)
    if (context === undefined) {
        throw new Error("usePeriodsContext must be used within a PeriodsContextProvider")
    }
    return context
}
