import { IdentifiableSettings } from "domain/ColumnConfigurator/components/types"
import { ColumnUniqueName, DataGroup, DataGroups, DataGroupUniqueName } from "domain/ColumnConfigurator/types"
import { flatten } from "shared/util/FunctionUtil"
import { log } from "shared/util/log"

export const ColumnUtil = {
    getIdentifiers: (list: IdentifiableSettings[]): ColumnUniqueName[] => {
        return list.map((item) => item.identifier)
    },
    /**
     * Returns the set of column unique names that are supported by the provided data groups.
     *
     * @param supportedDataGroups
     * @param allDataGroups
     * @param getList
     */
    getSupportedColumns: (
        supportedDataGroups: DataGroupUniqueName[],
        allDataGroups: DataGroups,
        getList: (dataGroup: DataGroup) => ReadonlySet<ColumnUniqueName>,
    ) => {
        return new Set<ColumnUniqueName>(
            flatten(
                supportedDataGroups.map((supportedDataGroupName) => {
                    const dataGroup = allDataGroups.get(supportedDataGroupName)
                    if (!dataGroup) {
                        log.warn(`Data group ${supportedDataGroupName} not found`)
                        return []
                    }
                    return Array.from(getList(dataGroup))
                }),
            ),
        )
    },
}
