import Menu from "@mui/material/Menu"
import React from "react"
import { Row } from "domain/datagrid/component/DataGrid"
import { InlineButtonDTO } from "domain/actions/InlineButtonDTO"
import { getActionConfig } from "shared/component/renderers/inline-buttons.renderer"
import MenuItem from "@mui/material/MenuItem"
import { v1 as uuid } from "uuid"
import { ActionDTOIdentifierEnum } from "generated/models"

type DataGridContextMenuProps = {
    contextMenuPosition: ContextMenuPosition
    contextMenuRows: Row[]
    closeContextMenu: () => void
    onContextMenuActionClick: (actionIdentifier: ActionDTOIdentifierEnum, rowIndices: number[]) => void
}

export const DataGridContextMenu = ({
    contextMenuPosition,
    contextMenuRows,
    closeContextMenu,
    onContextMenuActionClick,
}: DataGridContextMenuProps): JSX.Element => {
    const getMenuItems = (contextMenuRows: Row[]) => {
        let actions: InlineButtonDTO[] = []

        // Iterate over all invoked rows and collect same buttons.
        // If some button is missing in one row, then don't show the button for all rows.
        // If a button is disabled in some row, then it must be disabled for all rows.
        contextMenuRows.forEach((row) => {
            if (actions.length == 0) {
                actions = row.rowActions?.actions
            } else {
                actions = actions
                    .filter((action) => {
                        return row.rowActions?.actions?.some(
                            (rowAction) => rowAction.actionIdentifier === action.actionIdentifier,
                        )
                    })
                    .map((inlineButton) => {
                        const button = row.rowActions.actions.find(
                            (b) => b.actionIdentifier === inlineButton.actionIdentifier,
                        )

                        if (button && button.disabled) {
                            return { ...inlineButton, disabled: true }
                        } else {
                            return { ...inlineButton }
                        }
                    })
            }
        })

        const rowIndices = contextMenuRows.map((row) => row.rowIndex)
        const itemsCountText = rowIndices.length > 1 ? ` (${rowIndices.length} Items)` : ""

        return actions?.map((action) => {
            const actionIdentifier = action.actionIdentifier
            const actionConfig = getActionConfig(actionIdentifier)

            return (
                <MenuItem
                    key={uuid()}
                    onClick={() => onContextMenuActionClick(actionIdentifier, rowIndices)}
                    disabled={action.disabled}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {actionConfig.icon}
                        {actionConfig?.name}
                        {itemsCountText}
                    </div>
                </MenuItem>
            )
        })
    }

    const menuItems = getMenuItems(contextMenuRows)
    if (menuItems) {
        return (
            <Menu
                open={contextMenuPosition !== null}
                onClose={closeContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenuPosition !== null
                        ? { top: contextMenuPosition.mouseY, left: contextMenuPosition.mouseX }
                        : undefined
                }
                slotProps={{
                    root: {
                        onContextMenu: (e) => {
                            e.preventDefault()
                            closeContextMenu()
                        },
                    },
                }}
            >
                {menuItems}
            </Menu>
        )
    } else {
        return <></>
    }
}

export type ContextMenuPosition = {
    mouseX: number
    mouseY: number
}
