import { FullOrSoftConversionSelection } from "domain/ConversionList/domain/fullOrSoftConversion"
import {
    BooleanClauseDTO,
    CompositeDimensionClauseDTO,
    StringSetClauseDTO,
    StringValueClauseDTO,
    BooleanValueClauseDTO,
    NumberSetClauseDTO,
    NumberValueClauseDTO,
} from "generated/models"
import DimensionService from "domain/dimension/service/DimensionService"
import {
    CONSENT_STATUS,
    CONVERSION_STATE,
    DIMENSION_CONVERSION_TYPE,
    IS_DIRECT_CONVERSION,
    IS_FULL_CONVERSION,
    IS_SOFT_CONVERSION,
    IS_TRACKED_CONVERSION,
    TRANSACTION_UID,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import { QueryConfig, SearchConfig, TouchpointPropertyFilter } from "domain/ConversionList/domain/domain"
import { TOUCHPOINT_FILTER_DIMENSIONS } from "domain/ConversionList/domain/touchpointFilterDimension"
import { TOUCHPOINT_CUSTOMER_JOURNEY_ROLES } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TrackingStateSelection } from "domain/ConversionList/domain/trackingState"
import { CONVERSION_TYPES, ConversionType, ConversionTypeSelection } from "domain/ConversionList/domain/conversionType"
import { BooleanOperator, ClauseType, ConditionClauseType, FalseClauseDTO, TrueClauseDTO } from "domain/types"
import { ConversionState } from "domain/ConversionList/domain/conversionState"
import { ConsentState } from "domain/ConversionList/domain/consentState"

export const mapFiltersToConditionClause = (queryConfig: QueryConfig): BooleanClauseDTO => {
    return {
        clauseType: ConditionClauseType.BOOLEAN,
        operator: BooleanOperator.AND,
        clauses: [
            mapConversionTypeSelectionToConditionClause(queryConfig.conversionTypeSelection),
            mapConversionStateSelectionToConditionClause(queryConfig.conversionStateSelection),
            mapConsentStateSelectionToConditionClause(queryConfig.consentStateSelection),
            mapTouchpointPropertyFiltersToConditionClause(queryConfig.touchpointPropertyFilters),
            mapTrackingStateSelectionToConditionClause(queryConfig.trackingStateSelection),
            mapFullOrSoftConversionTypeSelectionToConditionClause(queryConfig.fullOrSoftConversionSelection),
            mapSearchConfigToConditionClause(queryConfig.searchConfig),
        ].filter((clause) => clause !== null),
    } satisfies BooleanClauseDTO
}

export const mapCustomerJourneyFiltersToConditionClause = (
    queryConfig: QueryConfig,
    transactionId: string,
): BooleanClauseDTO => {
    return {
        clauseType: ConditionClauseType.BOOLEAN,
        operator: BooleanOperator.AND,
        clauses: [
            mapConversionTypeSelectionToConditionClause(queryConfig.conversionTypeSelection),
            mapTouchpointPropertyFiltersToConditionClause(queryConfig.touchpointPropertyFilters),
            mapTrackingStateSelectionToConditionClause(queryConfig.trackingStateSelection),
            mapFullOrSoftConversionTypeSelectionToConditionClause(queryConfig.fullOrSoftConversionSelection),
            mapSearchConfigToConditionClause(queryConfig.searchConfig),
            mapTransactionUidFilterToConditionClause(transactionId),
        ],
    } satisfies BooleanClauseDTO
}

const mapTouchpointPropertyFilterToConditionClause = (
    filter: TouchpointPropertyFilter,
): CompositeDimensionClauseDTO => {
    return {
        clauseType: ConditionClauseType.COMPOSITE_DIMENSION,
        clauses: [
            {
                clauseType: ConditionClauseType.NUMBER_SET,
                columnName: DimensionService.getDimensionValueColumn(
                    TOUCHPOINT_FILTER_DIMENSIONS[filter.filterDimension].dimensionIdentifier,
                ),
                values: filter.values.map((value) => value.value),
                type: ClauseType.IN,
            } as NumberSetClauseDTO,
            {
                clauseType: ConditionClauseType.BOOLEAN_VALUE,
                columnName: DimensionService.getDimensionValueColumn(
                    TOUCHPOINT_CUSTOMER_JOURNEY_ROLES[filter.customerJourneyRole].dimensionIdentifier,
                ),
                value: true,
                type: ClauseType.EQUALS,
            } as BooleanValueClauseDTO,
        ],
    }
}

const mapTouchpointPropertyFiltersToConditionClause = (
    filters: ReadonlyArray<TouchpointPropertyFilter>,
): TrueClauseDTO | BooleanClauseDTO => {
    if (filters.length === 0) {
        return {
            clauseType: ConditionClauseType.TRUE,
        }
    }

    return {
        clauseType: ConditionClauseType.BOOLEAN,
        operator: BooleanOperator.AND,
        clauses: filters.map(mapTouchpointPropertyFilterToConditionClause),
    }
}

const mapConversionTypeSelectionToConditionClause = (
    conversionTypeSelection: ConversionTypeSelection,
): FalseClauseDTO | StringSetClauseDTO => {
    // filter true values in conversionTypeSelection
    const conversionTypesToInclude = Object.entries(conversionTypeSelection)
        .filter(([, value]) => value)
        .map(([key]) => key) as ConversionType[]

    if (conversionTypesToInclude.length === 0) {
        return {
            clauseType: ConditionClauseType.FALSE,
        }
    }

    const conversionTypeFilterValues = conversionTypesToInclude.map(
        (conversionType) => CONVERSION_TYPES[conversionType].id,
    )

    return {
        clauseType: ConditionClauseType.STRING_SET,
        columnName: DimensionService.getDimensionValueColumn(DIMENSION_CONVERSION_TYPE),
        type: ClauseType.IN,
        values: conversionTypeFilterValues,
    } as StringSetClauseDTO
}

const mapTrackingStateSelectionToConditionClause = (
    trackingStateSelection: TrackingStateSelection,
): BooleanClauseDTO => {
    return {
        clauseType: ConditionClauseType.BOOLEAN,
        operator: BooleanOperator.OR,
        clauses: [
            {
                clauseType: ConditionClauseType.BOOLEAN_VALUE,
                columnName: DimensionService.getDimensionValueColumn(IS_DIRECT_CONVERSION),
                value: trackingStateSelection["n/a"],
                type: ClauseType.EQUALS,
            } as BooleanValueClauseDTO,
            {
                clauseType: ConditionClauseType.BOOLEAN_VALUE,
                columnName: DimensionService.getDimensionValueColumn(IS_TRACKED_CONVERSION),
                value: trackingStateSelection.tracked,
                type: ClauseType.EQUALS,
            },
        ],
    }
}

const mapFullOrSoftConversionTypeSelectionToConditionClause = (
    fullOrSoftConversionSelection: FullOrSoftConversionSelection,
): BooleanClauseDTO => {
    return {
        clauseType: ConditionClauseType.BOOLEAN,
        operator: BooleanOperator.OR,
        clauses: [
            {
                clauseType: ConditionClauseType.BOOLEAN_VALUE,
                columnName: DimensionService.getDimensionValueColumn(IS_FULL_CONVERSION),
                value: fullOrSoftConversionSelection.full,
                type: ClauseType.EQUALS,
            } as BooleanValueClauseDTO,
            {
                clauseType: ConditionClauseType.BOOLEAN_VALUE,
                columnName: DimensionService.getDimensionValueColumn(IS_SOFT_CONVERSION),
                value: fullOrSoftConversionSelection.soft,
                type: ClauseType.EQUALS,
            },
        ],
    }
}

const mapSearchConfigToConditionClause = (searchConfig: SearchConfig): StringValueClauseDTO | TrueClauseDTO => {
    if (!searchConfig.fieldName || !searchConfig.searchTerm) {
        return {
            clauseType: ConditionClauseType.TRUE,
        }
    }
    return {
        clauseType: ConditionClauseType.STRING,
        columnName: searchConfig.fieldName,
        value: searchConfig.searchTerm,
        type: ClauseType.CONTAINS,
    }
}

const mapTransactionUidFilterToConditionClause = (transactionId: string): StringValueClauseDTO => {
    return {
        clauseType: ConditionClauseType.STRING,
        columnName: DimensionService.getDimensionValueColumn(TRANSACTION_UID),
        value: transactionId,
        type: ClauseType.EQUALS,
    }
}
const mapConversionStateSelectionToConditionClause = (conversionState: ConversionState): StringValueClauseDTO => {
    return {
        clauseType: ConditionClauseType.STRING,
        columnName: DimensionService.getDimensionValueColumn(CONVERSION_STATE),
        value: conversionState,
        type: ClauseType.EQUALS,
    }
}
const mapConsentStateSelectionToConditionClause = (consentState: ConsentState): NumberValueClauseDTO | null => {
    return isNumber(consentState)
        ? {
              clauseType: ConditionClauseType.NUMBER,
              columnName: DimensionService.getDimensionValueColumn(CONSENT_STATUS),
              value: parseInt(consentState),
              type: ClauseType.EQUALS,
          }
        : null
}

const isNumber = (str: string) => !isNaN(parseFloat(str)) && isFinite(+str)
