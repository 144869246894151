import { Autocomplete, TextField } from "@mui/material"
import React from "react"
import FormControl from "@mui/material/FormControl"
import { ConsentState, ConsentStateSelection } from "domain/ConversionList/domain/consentState"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"

type Entry = {
    key: ConsentState
    value: string
}

const consentStatusOptions: Entry[] = [
    { key: "1", value: "Opt-Out" },
    { key: "0", value: "Opt-In" },
]

export const ConsentStateFilter = () => {
    const consentStatusSelection = ConversionListContextSelectors.useConsentStateSelection()
    const updateConsentStatusSelection = ConversionListContextSelectors.useUpdateConsentStateSelection()

    const handleChange = (_: React.SyntheticEvent, value: Entry | null) => {
        updateConsentStatusSelection(value?.key as ConsentStateSelection)
    }

    return (
        <FormControl sx={{ mt: 4, minWidth: 120 }}>
            <Autocomplete
                value={{
                    key: consentStatusSelection,
                    value: consentStatusOptions.find((option) => option.key === consentStatusSelection)?.value ?? "",
                }}
                sx={{ width: 300, display: "inline-block", maxWidth: "100%", mb: 1 }}
                size="small"
                options={consentStatusOptions}
                getOptionLabel={(option: Entry) => option.value}
                onChange={handleChange}
                isOptionEqualToValue={(option: Entry, value: Entry) => option.key === value.key}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        label="Select Consent State"
                    />
                )}
            />
        </FormControl>
    )
}
