import React from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { TrackingStateSelection } from "domain/ConversionList/domain/trackingState"

export const TrackingStateRadioButtonGroup = () => {
    const trackingStateSelection = ConversionListContextSelectors.useTrackingStateSelection()
    const updateTrackingStateSelection = ConversionListContextSelectors.useUpdateTrackingStateSelection()

    const selectedValues = Object.entries(trackingStateSelection)
        .filter(([, value]) => value)
        .map(([key]) => key)

    const handleChange = (_: React.MouseEvent<HTMLElement>, trackingStates: string[]) => {
        if (trackingStates.length > 0) {
            updateTrackingStateSelection({
                tracked: trackingStates.includes("tracked"),
                "n/a": trackingStates.includes("n/a"),
            } satisfies TrackingStateSelection)
        }
    }

    return (
        <ToggleButtonGroup color="primary" value={selectedValues} onChange={handleChange} size="small">
            <ToggleButton value="tracked" sx={{ flexBasis: "40%" }}>
                Tracked Con&shy;ver&shy;sions
            </ToggleButton>
            <ToggleButton value="n/a" sx={{ flexBasis: "40%" }}>
                N/A Con&shy;ver&shy;sions
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
