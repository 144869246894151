import { get, post } from "shared/service"
import {
    AppContextDTO,
    LoadResponseDTOReportingDataSetDTO,
    MenuRootDTO,
    NotificationCountResponseDTO,
    NotificationDismissRequestDTO,
    ResponseDTO,
    QuerySettingsDTO,
} from "generated/models"
import UrlService from "shared/service/url.service"

export enum NotificationType {
    ALERTS = "ALERTS",
    NEWS = "NEWS",
}

const loadAlertsNotificationCount = (): Promise<NotificationCountResponseDTO> => {
    return loadNotificationCount(NotificationType.ALERTS)
}
const loadNewsNotificationCount = (): Promise<NotificationCountResponseDTO> => {
    return loadNotificationCount(NotificationType.NEWS)
}
const loadNotificationCount = (type: NotificationType): Promise<NotificationCountResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/notification/getNotificationsCount/${type}`)
}

const loadAlerts = (appContext: AppContextDTO): Promise<LoadResponseDTOReportingDataSetDTO> => {
    return post(`${UrlService.getUiServiceApiUrl()}/alert-history/loaddata`, {
        columnNames: ["alert_history.value", "alert_history.name"],
        paginationSettings: { page: 0, pageSize: 100 },
        sortSettings: { sortAscending: false, sortProperties: ["alert_history.value"] },
        appContext: appContext,
    } as QuerySettingsDTO)
}

const loadNews = (): Promise<MenuRootDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/static/getArticleMenu/NEWS`)
}

const dismissNotification = (type: NotificationType): Promise<ResponseDTO> => {
    return post(`${UrlService.getUiServiceApiUrl()}/notification/dismiss`, {
        type: type,
    } as NotificationDismissRequestDTO)
}

export const NotificationService = {
    loadAlerts: loadAlerts,
    dismissNotification: dismissNotification,
    loadNews: loadNews,
    loadAlertsNotificationCount: loadAlertsNotificationCount,
    loadNewsNotificationCount: loadNewsNotificationCount,
    loadNotificationCount: loadNotificationCount,
}
