import React from "react"
import numbro from "numbro"
import _includes from "lodash/includes"

import { Alert, Col, Row } from "antd"
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons"
import { ContentBox } from "domain/core/component/ContentBox"
import ScenarioSelectDropdown from "domain/adspend-optimizer/component/ScenarioSelectDropdown"
import KPIEntry from "domain/adspend-optimizer/component/overview/KPIEntry"
import { Scenario } from "domain/types"
import { NOT_AVAILABLE } from "Constants"
import { SliceData } from "domain/adspend-optimizer/context/AdSpendOptimizerContext"

type Props = {
    className?: string
    introText?: string
    scenarioName?: string
    scenarioJobStatus?: string
    scenarioPeriodInfo?: string
    scenarioTotalData?: SliceData
    scenarios: Scenario[]
    scenarioSelector: (dimensionId: string, scenarioId: number) => void
}

// see http://numbrojs.com/format.html and https://github.com/BenjaminVanRyseghem/numbro
export const formatNumber = (num: number, additionalConfigs?: any): string =>
    num === null
        ? NOT_AVAILABLE
        : numbro(num).format({ average: true, mantissa: 1, trimMantissa: true, ...additionalConfigs })

export const ScenarioInfoBox = (props: Props) => {
    const loadingState = _includes(["unprocessed", "queued", "scheduled", "in_progress"], props.scenarioJobStatus)
    const errorState = _includes(["failed", "disabled"], props.scenarioJobStatus)

    const additionalClass = loadingState ? "loading" : "" || errorState ? "error" : ""

    return (
        <React.Fragment>
            {props && (
                <ContentBox className={"scenario-info-box subtle-edit " + props.className + " " + additionalClass}>
                    <Row>
                        <Col span={16}>
                            <div className={"meta-data"}>
                                <div className={"intro-text"}>{props.introText}</div>
                                <ScenarioSelectDropdown
                                    style={{ display: "inline" }}
                                    size={"large"}
                                    selectStyle={{ marginTop: -1, height: 40 }}
                                    initValue={props.scenarioName}
                                    callback={props.scenarioSelector}
                                    scenarios={props.scenarios}
                                />
                                <div className={"period-info"}>{props.scenarioPeriodInfo}</div>
                            </div>
                        </Col>
                        <KPIEntry
                            formattedNumber={
                                props.scenarioTotalData
                                    ? formatNumber(props.scenarioTotalData.data.adspend_roas?.value as number)
                                    : ""
                            }
                            label={"ROAS"}
                            additionalCssClass={"main-kpi"}
                        />
                    </Row>

                    <Row className={"row-kpis"}>
                        <KPIEntry
                            formattedNumber={
                                props.scenarioTotalData
                                    ? formatNumber(props.scenarioTotalData.data.adspend_costs?.value as number)
                                    : ""
                            }
                            icon={"coins-out"}
                            label={"Budget"}
                            currencyString={"€"}
                        />

                        <KPIEntry
                            formattedNumber={
                                props.scenarioTotalData
                                    ? formatNumber(props.scenarioTotalData.data.adspend_total_price?.value as number)
                                    : ""
                            }
                            icon={"coins-in"}
                            label={"Total Price"}
                            currencyString={"€"}
                        />

                        <KPIEntry
                            formattedNumber={
                                props.scenarioTotalData
                                    ? formatNumber(props.scenarioTotalData.data.adspend_conversions?.value as number)
                                    : ""
                            }
                            icon={"conversions"}
                            label={"Conversions"}
                        />
                    </Row>

                    {loadingState && (
                        <Alert
                            icon={<LoadingOutlined />}
                            showIcon
                            message="This scenario is currently being calculated."
                            type="info"
                        />
                    )}
                    {errorState && (
                        <Alert
                            icon={<WarningOutlined />}
                            showIcon
                            message="An error occurred while calculating this scenario."
                            type="error"
                        />
                    )}
                </ContentBox>
            )}
        </React.Fragment>
    )
}
