import React, { createContext, PropsWithChildren, useContext, useState } from "react"
import { produce } from "immer"

type FloatingMenuContextProps = {
    hoveredMenuEntry: string[]
    isPathHovered: (path: string) => boolean
    addMenuPathToHoveredList: (path: string, level: number) => void
    removeMenuPathFromHoveredList: (level: number) => void
}

const FloatingMenuContext = createContext<FloatingMenuContextProps>(undefined)

type FloatingMenuContextProviderProps = {
    children: React.ReactNode
}

export const FloatingMenuContextProvider = (props: FloatingMenuContextProviderProps) => {
    const [hoveredMenuPaths, setHoveredMenuPaths] = useState<string[]>([])

    return (
        <FloatingMenuContext.Provider
            value={{
                hoveredMenuEntry: hoveredMenuPaths,
                isPathHovered: (path: string) => hoveredMenuPaths.includes(path),
                addMenuPathToHoveredList: (path: string, level: number) =>
                    setHoveredMenuPaths(
                        produce(hoveredMenuPaths, (draft) => {
                            draft = draft.slice(0, level)
                            draft.push(path)
                            return draft
                        }),
                    ),
                removeMenuPathFromHoveredList: (level: number) =>
                    setHoveredMenuPaths(
                        produce(hoveredMenuPaths, (draft) => {
                            return draft.slice(0, level)
                        }),
                    ),
            }}
        >
            {props.children}
        </FloatingMenuContext.Provider>
    )
}

export const useFloatingMenuContext = () => {
    const context = useContext(FloatingMenuContext)
    if (context === undefined) {
        throw new Error("useFloatingMenuContext must be used within a FloatingMenuContextProvider")
    }

    return context
}
