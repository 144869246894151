import React from "react"
import { FormElementProperties } from "domain/types"
import { Switch } from "./primitives/Switch"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"
import { useFormContext } from "shared/component/forms/FormContext"
import { UncontrolledSwitch } from "shared/component/forms/elements/primitives/UncontrolledSwitch"

export const FormElementToggleButton = ({
    layoutElementConfig,
    isRequiredField,
}: FormElementProperties): JSX.Element => {
    const { updateElementSettings } = useRootElementContext()
    const isInsideFormContext = Boolean(useFormContext(false))

    const { useAsSetting, formFieldConfig } = layoutElementConfig

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (useAsSetting) {
            updateElementSettings({ key: formFieldConfig.dimensionIdentifier, value: event.target.checked })
        }
    }

    const SwitchToRender = isInsideFormContext ? Switch : UncontrolledSwitch

    return (
        <SwitchToRender
            name={formFieldConfig.dimensionIdentifier}
            label={formFieldConfig.displayName}
            onChange={onChange}
            required={isRequiredField}
        />
    )
}
