/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import type { DashboardSettingsOptionsDTO } from "./DashboardSettingsOptionsDTO"
import {
    DashboardSettingsOptionsDTOFromJSON,
    DashboardSettingsOptionsDTOFromJSONTyped,
    DashboardSettingsOptionsDTOToJSON,
} from "./DashboardSettingsOptionsDTO"

/**
 *
 * @export
 * @interface DashboardSettingsDTO
 */
export interface DashboardSettingsDTO {
    /**
     *
     * @type {string}
     * @memberof DashboardSettingsDTO
     */
    metric: string
    /**
     *
     * @type {DashboardSettingsOptionsDTO}
     * @memberof DashboardSettingsDTO
     */
    options: DashboardSettingsOptionsDTO
    /**
     *
     * @type {string}
     * @memberof DashboardSettingsDTO
     */
    reportingDimension: string
    /**
     *
     * @type {string}
     * @memberof DashboardSettingsDTO
     */
    reportingMethod: string
}

/**
 * Check if a given object implements the DashboardSettingsDTO interface.
 */
export function instanceOfDashboardSettingsDTO(value: object): value is DashboardSettingsDTO {
    if (!("metric" in value) || value["metric"] === undefined) return false
    if (!("options" in value) || value["options"] === undefined) return false
    if (!("reportingDimension" in value) || value["reportingDimension"] === undefined) return false
    if (!("reportingMethod" in value) || value["reportingMethod"] === undefined) return false
    return true
}

export function DashboardSettingsDTOFromJSON(json: any): DashboardSettingsDTO {
    return DashboardSettingsDTOFromJSONTyped(json, false)
}

export function DashboardSettingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardSettingsDTO {
    if (json == null) {
        return json
    }
    return {
        metric: json["metric"],
        options: DashboardSettingsOptionsDTOFromJSON(json["options"]),
        reportingDimension: json["reportingDimension"],
        reportingMethod: json["reportingMethod"],
    }
}

export function DashboardSettingsDTOToJSON(value?: DashboardSettingsDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        metric: value["metric"],
        options: DashboardSettingsOptionsDTOToJSON(value["options"]),
        reportingDimension: value["reportingDimension"],
        reportingMethod: value["reportingMethod"],
    }
}
