/* eslint-disable */
export const insertCustifyTag = (custifyProductKey) => {
    var _ctrack = _ctrack || []
    ;(function () {
        for (
            var t = [
                    "identify",
                    "track",
                    "setInstance",
                    "setAccount",
                    "trackTime",
                    "stopTrackTime",
                    "debug",
                    "setOptions",
                    "triggerPendingAction",
                    "closePendingAction",
                ],
                a = function (t) {
                    return function () {
                        _ctrack.push([t].concat(Array.prototype.slice.call(arguments, 0)))
                    }
                },
                n = 0;
            n < t.length;
            n++
        )
            _ctrack[t[n]] = a(t[n])
        var d = document,
            g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0]
        g.type = "text/javascript"
        g.async = true
        g.defer = true
        g.src = "https://assets.custify.com/assets/track.min.js"
        s.parentNode.insertBefore(g, s)
    })()

    _ctrack.setAccount(custifyProductKey)
    _ctrack.setOptions({
        createOrUpdateEntities: true,
    })
}
