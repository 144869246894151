import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { MenuRootDTO } from "generated/models"
import { ArticleService } from "domain/content/ArticleService"
import { ContentType } from "domain/types"

export type ArticleLayoutContextProperties = {
    openMenuWithFileName?: (filename: string) => void
    showArticlePage?: boolean
    setShowArticlePage?: (showArticlePage: boolean) => void
    path?: string
    setPath?: (path: string) => void
    menu?: MenuRootDTO
}
export const ArticleLayoutContext = createContext<ArticleLayoutContextProperties>({ showArticlePage: false })

type ArticleLayoutContextProviderProps = {
    children?: ReactNode
    path?: string
    showArticlePage?: boolean
}

export const ArticleLayoutContextProvider: React.FC<ArticleLayoutContextProviderProps> = (
    props: ArticleLayoutContextProviderProps,
): JSX.Element => {
    const [showArticlePage, setShowArticlePage] = useState(props.showArticlePage === true)
    const [path, setPath] = useState<string>(props.path)
    const [menu, setMenu] = useState<MenuRootDTO>({ root: [] })

    useEffect(() => fetchMenu(), [])

    /**
     * Loads article menu configuration of type HELP and saves it in the [menu] state
     */
    const fetchMenu = () => {
        ArticleService.loadMenu(ContentType.HELP).then((data: MenuRootDTO) => {
            setMenu(data)
        })
    }

    const context = {
        /**
         * Returns true, if the article was found in the menu configuration
         *
         * @param filename
         */
        openMenuWithFileName: (filename: string) => {
            setPath(filename.split("/").pop())
            setShowArticlePage(true)
        },
        showArticlePage: showArticlePage,
        setShowArticlePage: setShowArticlePage,
        path: path,
        setPath: (path: string) => {
            setPath(path.split("/").pop())
        },
        menu: menu,
    } as ArticleLayoutContextProperties

    return <ArticleLayoutContext.Provider value={context}>{props.children}</ArticleLayoutContext.Provider>
}

export const useArticleLayoutContext = () => {
    return useContext(ArticleLayoutContext)
}
