import React from "react"

import { Navigate, useLocation } from "react-router-dom"
import { AuthenticationState } from "domain/authentication/redux/authentication.slice"
import UrlService from "shared/service/url.service"

type Props = {
    authentication: AuthenticationState
    children?: React.ReactNode
}

/**
 * Renders the children if the user is authenticated, else redirects to the logon page.
 *
 * @param authentication
 * @param children
 * @constructor
 */
export const PrivateRoute: React.FC<Props> = ({ authentication, children }: Props): JSX.Element => {
    const location = useLocation()

    if (authentication?.loggedInViaFirstFactor) {
        return <>{children}</>
    } else {
        const referrer = [location.pathname, location.search].filter((val) => val).join("")

        return <Navigate to="/ui/logon" replace state={{ referrer: referrer }} />
    }
}
