import * as React from "react"
import { Layout } from "antd"
import { ArticleMenu } from "shared/component/ArticleMenu"
import { ArticlePage } from "domain/content/ArticlePage"
import MenuUtil from "shared/service/MenuUtil"
import { useArticleLayoutContext } from "domain/content/ArticleLayoutContext"
import { AppBar, Box, Grid, IconButton, Modal, Toolbar, Typography, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { MenuDTO, MenuRootDTO } from "generated/models"
import { log } from "shared/util/log"
import Spinner from "shared/component/Spinner"
import Paper from "@mui/material/Paper"
import { BORDER_RADIUS } from "styles/theme/constants"
import UrlService from "shared/service/url.service"

const style = {
    position: "absolute",
    width: "1200px",
    left: "50%",
    right: "auto",
    top: "10px",
    transform: "translate(-50%)",
    boxShadow: 24,
    height: "98%",
    borderRadius: BORDER_RADIUS,
    maxHeight: "90vh",
    maxWidth: "90vw",
    overflow: "hidden",
}
export const ArticleLayout = (): JSX.Element => {
    const articleLayoutContext = useArticleLayoutContext()
    const { showArticlePage, path, menu } = articleLayoutContext
    const theme = useTheme()

    const handleClose = () => {
        articleLayoutContext.setShowArticlePage(false)
    }

    const getMenuNode = (path: string, menu: MenuRootDTO): MenuDTO => {
        const nodeEndingWith = MenuUtil.getMenuNodeEndingWith(path, menu)
        return nodeEndingWith ?? MenuUtil.getMenuNodeForIdentifier(path, menu)
    }

    const onMenuClick = (path: string): boolean => {
        const menuNodeFound = getMenuNode(path, menu) != undefined

        if (menuNodeFound) {
            articleLayoutContext.setPath(path)
        } else {
            log.debug(`Article with path ${path} not found`)
        }

        return menuNodeFound
    }

    const menuNode = getMenuNode(path, menu)

    let content
    if (menuNode) {
        // remove "/ui" prefix from the menu node path
        const articlePath = menuNode.path.replace(new RegExp("^\\" + UrlService.getUiContextPath()), "")

        content = (
            <Layout className="article-layout">
                <ArticleMenu
                    showLogo={false}
                    firstEntryPath={menuNode.path}
                    entries={menu.root}
                    onMenuClick={onMenuClick}
                    inlineIndent={19}
                />
                <ArticlePage path={articlePath} title={menuNode?.title} onClick={onMenuClick} />
            </Layout>
        )
    }

    return (
        <Modal
            className={"article-layout"}
            open={showArticlePage && menuNode != undefined}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper variant={"elevation"} elevation={24} sx={style}>
                <AppBar
                    component="nav"
                    elevation={0}
                    sx={{ backgroundColor: "#fff", borderBottom: `1px solid ${theme.palette.border.subtle}` }}
                >
                    <Toolbar style={{ minHeight: 40, padding: "0 15px" }}>
                        <Typography
                            variant="h6"
                            component="div"
                            align="left"
                            sx={{ whiteSpace: "nowrap", color: "#000" }}
                        >
                            Help Center
                        </Typography>
                        <Grid container justifyContent="flex-end">
                            <IconButton sx={{ color: theme.palette.text.primary }} onClick={handleClose}>
                                <CloseIcon fontSize={"small"} />
                            </IconButton>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Box component="main" sx={{ paddingTop: "40px", overflow: "scroll", height: "100%" }}>
                    <Spinner spinning={menu.root.length == 0}>{content}</Spinner>
                </Box>
            </Paper>
        </Modal>
    )
}
