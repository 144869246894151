import * as React from "react"
import { SystemService } from "domain/core/service"
import { Polling } from "shared/component"
import { PingDTO } from "generated/models"
import UrlService from "shared/service/url.service"
import { log } from "shared/util/log"

/**
 * Introduces a polling mechanism which keeps the backend session alive (see UI-79).
 */
export class BackendSessionKeepAlive extends React.Component {
    render() {
        return (
            <Polling
                ms={55000}
                initialDelay={55000}
                api={() => SystemService.ping()}
                onData={(pingDto: PingDTO) => {
                    if (pingDto.successful === false) {
                        // UrlService.redirectToUrlPath()
                    }
                }}
                onError={(error) => {
                    log.error("Error while polling backend session keep alive", error)
                }}
            />
        )
    }
}
