import React from "react"
import { ExpandCollapseButtonState } from "../types"
import { SearchInput } from "./SearchInput/SearchInput"
import { ExpandCollapseButton } from "./ExpandCollapseButton/ExpandCollapseButton"
import Grid from "@mui/material/Unstable_Grid2"
import { MetricsFrontendGroupUniqueName } from "domain/ColumnConfigurator/types"
import {
    DeselectAllButton,
    SelectAllButton,
} from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionPanelTopbar/SelectedDeselectAllButton/SelectedDeselectAllButton"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { TileUtil } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/TileUtil"

export interface MetricSelectionPanelTopbarProps {
    expandedGroups: Set<MetricsFrontendGroupUniqueName>
    onExpandedGroupsChanged: (groups: Set<MetricsFrontendGroupUniqueName>) => void
    searchQuery: string
    onSearchQueryChanged: (query: string) => void
    searchResultsCount: number
    onSelectAllClick: () => void
    onDeselectAllClick: () => void
}

export const MetricSelectionPanelTopbar = ({
    expandedGroups,
    onExpandedGroupsChanged,
    searchQuery,
    onSearchQueryChanged,
    searchResultsCount,
    onSelectAllClick,
    onDeselectAllClick,
}: MetricSelectionPanelTopbarProps): JSX.Element => {
    const {
        dataDefinitions: { metricsFrontendGroups },
    } = useReportingConfigurationContext()

    const tiles = TileUtil.getTiles(metricsFrontendGroups)

    const expandAll = () => {
        onExpandedGroupsChanged(new Set(Array.from(tiles.keys()).map((tile) => tile.uniqueName)))
    }

    const collapseAll = () => {
        onExpandedGroupsChanged(new Set())
    }

    const [expandCollapseButtonState, onExpandCollapseButtonClick] =
        expandedGroups.size === 0
            ? [ExpandCollapseButtonState.COLLAPSED, expandAll]
            : [ExpandCollapseButtonState.EXPANDED, collapseAll]

    const deselectAllBadgeContent = searchQuery.length > 0 ? searchResultsCount : undefined

    return (
        <div>
            <Grid container spacing={1} alignItems="start" columns={16}>
                <Grid xs={12} sm={6}>
                    <SearchInput onQueryChange={onSearchQueryChanged} query={searchQuery} />
                </Grid>
                <Grid>
                    {searchQuery.length > 0 && (
                        <SelectAllButton onClick={onSelectAllClick} badgeContent={searchResultsCount} />
                    )}
                </Grid>
                <Grid>
                    <DeselectAllButton onClick={onDeselectAllClick} badgeContent={deselectAllBadgeContent} />
                </Grid>
                {
                    // Only show the expand/collapse button if there are groups to expand/collapse
                    tiles.size > 1 && (
                        <Grid sm display="flex" justifyContent="right">
                            <ExpandCollapseButton
                                onClick={onExpandCollapseButtonClick}
                                buttonState={expandCollapseButtonState}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </div>
    )
}
