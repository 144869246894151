import { GridStack as GridStackJS } from "gridstack"
import { useCallback, useState } from "react"
import { DashboardLayoutItemProps } from "./DashboardLayoutItem"

type UseGridStackIntegrationProps = {
    onUpdate?: (layout: DashboardLayoutItemProps[]) => void
    disableDrag?: boolean
    disableResize?: boolean
    readOnly: boolean
}

export const useGridStackIntegration = (props: UseGridStackIntegrationProps) => {
    const { disableDrag, disableResize, readOnly, onUpdate } = props

    const [gridStack, setGridStack] = useState<GridStackJS | undefined>()
    const [isDraggable, setIsDraggable] = useState(!disableDrag)
    const [isResizable, setIsResizable] = useState(!disableResize)

    const update = useCallback(() => {
        if (!gridStack) return
        const items = gridStack.save(false) as DashboardLayoutItemProps[]
        onUpdate?.(items)
    }, [gridStack, onUpdate])

    const registerWidget = useCallback(
        (id: string) => {
            gridStack?.makeWidget(`.grid-stack-item[gs-id="${id}"]`)

            return {
                unregisterWidget: () => {
                    gridStack?.removeWidget(`.grid-stack-item[gs-id="${id}"]`, false)
                },
            }
        },
        [gridStack],
    )

    const init = useCallback(
        (el: HTMLElement | undefined) => {
            if (!el) return
            const gridStack = GridStackJS.init(
                {
                    cellHeight: 10,
                    disableDrag,
                    staticGrid: readOnly,
                    auto: false,
                    float: true,
                    styleInHead: true,
                    margin: 0,
                    marginRight: 4,
                    marginLeft: 4,
                },
                el,
            )
            gridStack.on("added", update)
            gridStack.on("change", update)
            gridStack.on("removed", update)
            setGridStack(gridStack)
            setTimeout(() => gridStack.float(false), 400)
        },
        [disableDrag, update, readOnly],
    )

    const toggleDrag = () => {
        if (!gridStack) return
        gridStack.enableMove(!isDraggable)
        setIsDraggable(!isDraggable)
    }

    const toggleResize = () => {
        if (!gridStack) return
        gridStack.enableResize(!isResizable)
        setIsResizable(!isResizable)
    }

    const setFloat = (value: boolean) => {
        if (!gridStack) return
        gridStack.float(value)
    }

    return {
        init,
        toggleDrag,
        toggleResize,
        registerWidget,
        setFloat,
        isDraggable,
        isResizable,
    }
}
