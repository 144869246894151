import React from "react"
import { styled } from "@mui/material/styles"

interface SeparatorProps {
    className?: string
}

const StyledHr = styled("hr")(({ theme }) => ({
    border: "none",
    height: "0px",
    margin: `${theme.spacing(2)} 0`,
    backgroundColor: "transparent",
    borderTop: `2px dashed ${theme.palette.border.subtle}`,
}))

const Separator: React.FC<SeparatorProps> = ({ className = "" }) => {
    return <StyledHr className={className} />
}

export default Separator
