import { Alert, IconButton, Snackbar } from "@mui/material"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import { AlertColor } from "@mui/material/Alert/Alert"
import { SnackbarProps } from "@mui/material/Snackbar/Snackbar"

interface ClosableSnackbarProps extends SnackbarProps {
    severity?: AlertColor
}

export const ClosableSnackbar: React.FC<ClosableSnackbarProps> = (props: ClosableSnackbarProps): JSX.Element => {
    const [open, setOpen] = React.useState(true)

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return
        }
        setOpen(false)
    }

    return (
        <Snackbar {...props} open={open} anchorOrigin={{ horizontal: "center", vertical: "top" }} onClose={handleClose}>
            <Alert severity={props.severity}>
                {props.message}
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>{" "}
            </Alert>
        </Snackbar>
    )
}
