import React from "react"
import { ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from "@mui/material"
import { MenuNodeDTO } from "generated/models"
import { assertExhaustive } from "shared/util/TypeUtil"
import { MenuIcon } from "layout/MainLayout/Menu/MenuIcon"
import { IconChevronRight } from "@tabler/icons"
import { MenuLeafDTO } from "domain/types"

export interface NodeListItemProps {
    type: "node"
    menuNodeDTO: MenuNodeDTO
    level: number
    isExpanded: boolean
    onClick: () => void
}

export interface LeafListItemProps {
    type: "leaf"
    menuLeafDTO: MenuLeafDTO
    level: number
    isSelected: boolean
    onClick: (event: React.MouseEvent) => void
}

export type MenuListItemProps = NodeListItemProps | LeafListItemProps

export const MenuListItem = (props: MenuListItemProps) => {
    switch (props.type) {
        case "node": {
            return <NodeListItem {...props} />
        }
        case "leaf": {
            return <LeafListItem {...props} />
        }
        default: {
            assertExhaustive(props)
        }
    }
}

function getItemPaddingLeft(level: number, isLargeScreen: boolean) {
    const basePadding = isLargeScreen ? 28 : 28
    const paddingPerLevel = isLargeScreen ? 15 : 15
    return isZeroLevel(level) ? "15px" : basePadding + paddingPerLevel * level + "px"
}

function collapseIcon(level: number, isExpanded: boolean): React.ReactNode {
    if (level > 0) {
        return (
            <div
                style={{
                    height: "16px",
                    width: "16px",
                    transition: "all 0.2s ease-in-out",
                    marginTop: isExpanded ? "0px" : "-4px",
                    transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                }}
            >
                <IconChevronRight stroke={1.5} size="16px" />
            </div>
        )
    } else {
        return null
    }
}

const isZeroLevel = (level: number) => level === 0

const NodeListItem = ({ menuNodeDTO, level, isExpanded, onClick }: NodeListItemProps) => {
    const { title, path, icon } = menuNodeDTO

    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    return (
        <ListItem key={path} className={"menu-list-item menu-list-item-node level-" + level} disablePadding>
            <ListItemButton
                selected={false}
                disableRipple={true}
                onClick={onClick}
                sx={getItemSx(level, isLargeScreen)}
            >
                {icon && (
                    <ListItemIcon>
                        <MenuIcon icon={menuNodeDTO.icon} />
                    </ListItemIcon>
                )}
                <ListItemText primary={title} />
                {collapseIcon(level, isExpanded)}
            </ListItemButton>
        </ListItem>
    )
}

const LeafListItem = ({ menuLeafDTO, level, isSelected, onClick }: LeafListItemProps) => {
    const { title, path } = menuLeafDTO
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    return menuLeafDTO.hidden ? (
        <div key={path} />
    ) : (
        <ListItem
            key={path}
            disablePadding
            className={"menu-list-item menu-list-item-leaf" + (isSelected ? " selected" : "") + " level-" + level}
        >
            <ListItemButton
                selected={isSelected}
                component={"a"}
                href={path}
                disableRipple={true}
                onClick={onClick}
                sx={getItemSx(level, isLargeScreen)}
            >
                <ListItemText primary={title} />
            </ListItemButton>
        </ListItem>
    )
}

const getItemSx = (level: number, isLargeScreen: boolean) => {
    return {
        paddingLeft: getItemPaddingLeft(level, isLargeScreen),
        height: isZeroLevel(level) ? "40px" : "28.5px",
        ":hover": {
            backgroundColor: "#e6ecfc",
            color: "black",
        },
    }
}
