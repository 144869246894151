import { TextField } from "@mui/material"
import { DimensionValue } from "domain/ConversionList/domain/domain"
import React from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import {
    TOUCHPOINT_FILTER_DIMENSIONS,
    TouchpointFilterDimension,
} from "domain/ConversionList/domain/touchpointFilterDimension"
import { VirtualizedAutocomplete } from "shared/component/mui/VirtualizedAutocomplete"

export interface TouchpointDimensionFilterValueAutocompleteProps {
    selectedTouchpointFilterDimension: TouchpointFilterDimension
    selectedTouchpointFilterDimensionValues: DimensionValue[]
    updateSelectedTouchpointFilterDimensionValues: (dimensionValues: DimensionValue[]) => void
}

export const TouchpointDimensionFilterValueAutocomplete = ({
    selectedTouchpointFilterDimension,
    selectedTouchpointFilterDimensionValues,
    updateSelectedTouchpointFilterDimensionValues,
}: TouchpointDimensionFilterValueAutocompleteProps) => {
    const values = ConversionListContextSelectors.useDimensionValuesQuery({
        touchpointFilterDimension: selectedTouchpointFilterDimension,
    })

    // TODO: Handle values.isError state

    return (
        <VirtualizedAutocomplete
            multiple={true}
            loading={values.isPending}
            options={values.data || []}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={TOUCHPOINT_FILTER_DIMENSIONS[selectedTouchpointFilterDimension].displayName}
                />
            )}
            value={selectedTouchpointFilterDimensionValues}
            onChange={(_, value) => updateSelectedTouchpointFilterDimensionValues(value)}
        />
    )
}
