import React, { createContext, ReactNode, useContext, useState } from "react"

export interface NotificationContextProperties {
    children?: ReactNode
    showNews: boolean
    setShowNews: (show: boolean) => void
}
export interface NotificationContextProviderProperties {
    children?: ReactNode
    showNews?: boolean
}

const NotificationContext = createContext<NotificationContextProperties>(undefined)
export const NotificationContextProvider = (props: NotificationContextProviderProperties) => {
    const [showNews, setShowNews] = useState(props.showNews ?? false)

    return (
        <NotificationContext.Provider
            value={{
                showNews: showNews,
                setShowNews: setShowNews,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    )
}

export const useNotificationContext = () => {
    return useContext(NotificationContext)
}
