declare const buildEnvironment: string

export const isDev = () => {
    if (buildEnvironment === "local") return true

    return process.env.NODE_ENV === "development"
}

export const isProd = () => {
    if (buildEnvironment === "server") return true

    return process.env.NODE_ENV === "production"
}

export const isTest = () => {
    return process.env.NODE_ENV === "test"
}

export const isLocalEnvironment = () => isDev() || isTest()
