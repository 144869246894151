import * as React from "react"
import { Box, IconButton, Pagination as MuiPagination, TablePagination, Typography } from "@mui/material"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { GoToPagination } from "shared/component/pagination/GoToPagination"

type Props = {
    /**
     * The current page number, 0-based.
     */
    page: number
    pageSize: number
    /**
     * The total number of entities. A value of -1 indicates that the total number of entities is unknown and infinite pagination should be used.
     */
    totalEntities: number
    /**
     * The number of entities on the current page. This is used for infinite pagination to display the range of items on the current page.
     */
    entitiesOnPage: number
    /**
     * Callback function that is called when the page number or page size changes.
     * @param newPage The new page number, 0-based.
     * @param newPageSize
     */
    onPageChange: (newPage: number, newPageSize: number) => void
    disabled?: boolean
}

const INFINITE_PAGINATION_TOTAL_ENTITIES = -1

export const Pagination = ({
    page,
    pageSize,
    totalEntities,
    entitiesOnPage,
    onPageChange,
    disabled = false,
}: Props): JSX.Element => {
    return totalEntities === INFINITE_PAGINATION_TOTAL_ENTITIES ? (
        <InfinitePagination
            page={page}
            pageSize={pageSize}
            entitiesOnPage={entitiesOnPage}
            onPageChange={onPageChange}
            disabled={disabled}
        />
    ) : (
        <StandardPagination
            page={page}
            pageSize={pageSize}
            totalEntities={totalEntities}
            onPageChange={onPageChange}
            disabled={disabled}
        />
    )
}

const StandardPagination = ({
    page,
    pageSize,
    totalEntities,
    onPageChange,
    disabled = false,
}: Omit<Props, "entitiesOnPage">): JSX.Element => {
    const allPages = totalEntities >= 0 && pageSize > 0 ? Math.ceil(totalEntities / pageSize) : 0

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onPageChange(0, parseInt(event.target.value, 10))
    }

    return (
        <Box className="standard-pagination" sx={{ display: "inline-flex", marginTop: "10px" }}>
            <MuiPagination
                count={allPages}
                // MUI pagination uses 1-based indexing, while our API uses 0-based indexing
                page={page + 1}
                onChange={(_, newPage) => onPageChange(newPage - 1, pageSize)}
                disabled={disabled}
            />
            <TablePagination
                style={{ marginTop: "1px" }}
                component={"div"}
                count={totalEntities >= 0 ? totalEntities : 0}
                // While MUI pagination uses 1-based indexing, MUI table pagination uses 0-based indexing, as we do ¯\_(ツ)_/¯
                page={page}
                onPageChange={(_, newPage) => onPageChange(newPage, pageSize)}
                rowsPerPage={pageSize}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} items`}
                labelRowsPerPage={""}
                rowsPerPageOptions={[
                    { value: 10, label: "10 / page" },
                    { value: 25, label: "25 / page" },
                    { value: 50, label: "50 / page" },
                    { value: 100, label: "100 / page" },
                    { value: 250, label: "250 / page" },
                ]}
                onRowsPerPageChange={onRowsPerPageChange}
                ActionsComponent={() => null}
                disabled={disabled}
            />
            <GoToPagination
                totalPages={allPages}
                onPageChange={(page) => onPageChange(page - 1, pageSize)}
                disabled={disabled}
            />
        </Box>
    )
}

/**
 * Infinite pagination is used for grids where it is not practical to query the total number of entities. It displays
 * the range of items on the current page and allows the user to navigate to the previous or next page.
 */
const InfinitePagination = ({
    page,
    pageSize,
    entitiesOnPage,
    onPageChange,
    disabled,
}: Omit<Props, "totalEntities">): JSX.Element => {
    let itemsText = "No more items"
    if (entitiesOnPage > 0) {
        const from = page * pageSize + 1
        const to = page * pageSize + entitiesOnPage
        itemsText = `Items ${from} to ${to}`
    }

    return (
        <Box className="infinite-pagination" sx={{ display: "inline-flex", marginTop: "10px", alignItems: "center" }}>
            <IconButton disabled={disabled || page === 0} onClick={() => onPageChange(page - 1, pageSize)}>
                <NavigateBeforeIcon />
            </IconButton>
            <Typography variant="body1" component="p">
                {itemsText}
            </Typography>
            <IconButton
                disabled={disabled || entitiesOnPage !== pageSize}
                onClick={() => onPageChange(page + 1, pageSize)}
            >
                <NavigateNextIcon />
            </IconButton>
        </Box>
    )
}
