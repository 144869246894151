/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import type { OptionalSettingsDTO } from "./OptionalSettingsDTO"
import {
    OptionalSettingsDTOFromJSON,
    OptionalSettingsDTOFromJSONTyped,
    OptionalSettingsDTOToJSON,
} from "./OptionalSettingsDTO"

/**
 *
 * @export
 * @interface ConversionListOptionalSettingsDTO
 */
export interface ConversionListOptionalSettingsDTO extends OptionalSettingsDTO {
    /**
     *
     * @type {string}
     * @memberof ConversionListOptionalSettingsDTO
     */
    type: string
    /**
     *
     * @type {string}
     * @memberof ConversionListOptionalSettingsDTO
     */
    cjMode?: ConversionListOptionalSettingsDTOCjModeEnum
    /**
     *
     * @type {string}
     * @memberof ConversionListOptionalSettingsDTO
     */
    dateFormat?: string
    /**
     *
     * @type {string}
     * @memberof ConversionListOptionalSettingsDTO
     */
    listType: ConversionListOptionalSettingsDTOListTypeEnum
    /**
     *
     * @type {boolean}
     * @memberof ConversionListOptionalSettingsDTO
     */
    showBlockedTouchpoints: boolean
}

/**
 * @export
 */
export const ConversionListOptionalSettingsDTOCjModeEnum = {
    ALL: "ALL",
    COMMISSION_RELEVANT: "COMMISSION_RELEVANT",
    ALL_WINNERS: "ALL_WINNERS",
    LAST_WINNER: "LAST_WINNER",
} as const
export type ConversionListOptionalSettingsDTOCjModeEnum =
    (typeof ConversionListOptionalSettingsDTOCjModeEnum)[keyof typeof ConversionListOptionalSettingsDTOCjModeEnum]

/**
 * @export
 */
export const ConversionListOptionalSettingsDTOListTypeEnum = {
    REAL_TIME: "REAL_TIME",
    HISTORICAL: "HISTORICAL",
} as const
export type ConversionListOptionalSettingsDTOListTypeEnum =
    (typeof ConversionListOptionalSettingsDTOListTypeEnum)[keyof typeof ConversionListOptionalSettingsDTOListTypeEnum]

/**
 * Check if a given object implements the ConversionListOptionalSettingsDTO interface.
 */
export function instanceOfConversionListOptionalSettingsDTO(value: object): value is ConversionListOptionalSettingsDTO {
    if (!("type" in value) || value["type"] === undefined) return false
    if (!("listType" in value) || value["listType"] === undefined) return false
    if (!("showBlockedTouchpoints" in value) || value["showBlockedTouchpoints"] === undefined) return false
    return true
}

export function ConversionListOptionalSettingsDTOFromJSON(json: any): ConversionListOptionalSettingsDTO {
    return ConversionListOptionalSettingsDTOFromJSONTyped(json, false)
}

export function ConversionListOptionalSettingsDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ConversionListOptionalSettingsDTO {
    if (json == null) {
        return json
    }
    if (!ignoreDiscriminator) {
    }
    return {
        ...OptionalSettingsDTOFromJSONTyped(json, ignoreDiscriminator),
        type: json["type"],
        cjMode: json["cjMode"] == null ? undefined : json["cjMode"],
        dateFormat: json["dateFormat"] == null ? undefined : json["dateFormat"],
        listType: json["listType"],
        showBlockedTouchpoints: json["showBlockedTouchpoints"],
    }
}

export function ConversionListOptionalSettingsDTOToJSON(value?: ConversionListOptionalSettingsDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        ...OptionalSettingsDTOToJSON(value),
        type: value["type"],
        cjMode: value["cjMode"],
        dateFormat: value["dateFormat"],
        listType: value["listType"],
        showBlockedTouchpoints: value["showBlockedTouchpoints"],
    }
}
