import { notification } from "antd"

const error = (title: string, msg: string) => {
    notification["error"]({
        message: title,
        description: msg,
    })
}

const warn = (title: string, msg: string) => {
    notification["warning"]({
        message: title,
        description: msg,
    })
}

const info = (title: string, msg: string) => {
    notification["info"]({
        message: title,
        description: msg,
    })
}

const Notification = { info, warn, error }

export default Notification
