import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import React from "react"
import { IconButton, Stack } from "@mui/material"
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded"
import { AlertNotification } from "layout/MainLayout/UserMenu/Notification/AlertNotification"
import { NewsNotification } from "layout/MainLayout/UserMenu/Notification/NewsNotification"
import { UserMenuPopover } from "layout/MainLayout/UserMenu/UserMenuPopover"
import { MenuDependant } from "shared/component/MenuDependant"
import { Support } from "./Support/Support"
import { ExportCenterNotification } from "layout/MainLayout/ExportCenter/ExportCenterNotification"

export interface UserMenuProps {
    name: string
    loginName: string
}

export const UserMenu = (props: UserMenuProps) => {
    const menu = useLayoutContext()?.userConfigs?.menu ?? { root: [] }
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<null | HTMLElement>(null)
    const iconRef = React.useRef()

    const onMoreButtonClick = () => {
        setPopoverAnchorEl(iconRef.current)
    }

    const onPopoverClose = () => {
        setPopoverAnchorEl(null)
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <ExportCenterNotification />
            <MenuDependant menu={menu} route={"/reporting/alerts"}>
                <AlertNotification />
            </MenuDependant>

            <NewsNotification />
            <Support />
            <div
                style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    maxWidth: "300px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
                onClick={onMoreButtonClick}
                className={"user-menu-logged-in-user-full-name"}
            >
                {props.name}
            </div>
            <div style={{ display: "none" }} className={"user-menu-logged-in-user-login-name"}>
                {props.loginName}
            </div>
            <IconButton size="small" color="inherit" onClick={onMoreButtonClick} className={"user-menu-trigger"}>
                <MoreHorizRoundedIcon ref={iconRef} />
            </IconButton>
            <UserMenuPopover popoverAnchorEl={popoverAnchorEl} onPopoverClose={onPopoverClose} />
        </Stack>
    )
}

export default UserMenu
