import React from "react"

import { log } from "shared/util/log"
import { Alert } from "@mui/material"

type Props = {
    children: React.ReactNode
}

type State = {
    hasError: boolean
}

/**
 * Defines an error boundary, see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * Only exceptions thrown from child components can be catched
 *
 * Be aware that only uncatched exceptions from lifecycle methods will be catched by error boundaries. Exceptions from
 * event handlers, async code, server side rendering and errors in the error boundary itself will NOT be catched.
 */
class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        log.error("ErrorBoundary caught an unexpected error:", error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <Alert severity="error">An error occurred.</Alert>
        }

        return this.props.children
    }
}

export default ErrorBoundary
