import React from "react"
import MenuUtil from "shared/service/MenuUtil"
import { MenuRootDTO } from "generated/models"

type Props = {
    menu: MenuRootDTO
    route: string
    children: React.ReactNode
}

/**
 * Children are only rendered if the route is part of the menu
 *
 * @param userConfigs
 * @param children
 * @param route
 * @constructor
 */
export const MenuDependant = ({ menu, children, route }: Props): JSX.Element => {
    const pathFound = MenuUtil.getMenuNodeEndingWith(route, menu) !== null

    return <>{pathFound && children}</>
}
