import * as React from "react"
import { FilterElement } from "domain/filter/component/FilterElement"
import { FilterConfigDTO } from "generated/models"

type Props = {
    filters?: FilterConfigDTO[]
    filterOnChange?: (filterIdentifier: string, value: string | number | string[] | number[]) => void
}

export const FilterContainer = (props: Props) => {
    const { filters, filterOnChange } = props

    return (
        <React.Fragment>
            {filters &&
                filters.map((filter, index) => {
                    return <FilterElement key={`filter_${index}`} filter={filter} onChange={filterOnChange} />
                })}
        </React.Fragment>
    )
}
