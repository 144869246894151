import { EChartsOption } from "echarts-for-react/src/types"
import { Size, SizeCategory } from "domain/types/frontend/dimension.types"
import ResponsiveSizeUtil from "domain/legacy/widget/ResponsiveSizeUtil"
import _cloneDeep from "lodash/cloneDeep"
import { ResponsiveWidgetSettings } from "domain/types/backend/widget.types"
import { log } from "shared/util/log"

/**
 * Computes the distance between the chart and the top edge of the widget that is necessary to show the full legend (if legend is in plain
 * instead of scroll mode); will depend on the amount of items we have to show in the legend
 *
 * @param containerWidth
 * @param eChartsOption
 */
const computeGridTopDistance = (containerWidth: number, eChartsOption: EChartsOption): number => {
    const estimatedRowCount = Math.ceil(estimateLegendTotalLength(eChartsOption) / containerWidth)
    return estimatedRowCount * 35
}

/**
 * Estimates the total length of the legend block
 *
 * @param eChartsOption
 */
const estimateLegendTotalLength = (eChartsOption: EChartsOption): number => {
    if (!eChartsOption.legend.data) {
        log.error("No eChartsOption.legend.data found for eChartsOption", eChartsOption)
        return 0
    }
    return eChartsOption.legend.data.reduce((acc, currentValue) => {
        const characterWidth = 7
        return acc + currentValue.length * characterWidth + 50
    }, 0)
}

export const DEFAULT_SIZE_BREAKPOINTS = [
    {
        category: SizeCategory.X_SMALL,
        max: 250,
    },
    {
        category: SizeCategory.SMALL,
        min: 251,
        max: 500,
    },
    {
        category: SizeCategory.MEDIUM,
        min: 501,
        max: 700,
    },
    {
        category: SizeCategory.LARGE,
        min: 701,
    },
]

const ResponsiveWidgetSettingsUtil = {
    getResponsiveWidgetSettings: (
        containerSize: Size,
        echartsOption: EChartsOption,
        isLegendExpanded: boolean,
        hasLegend: boolean,
    ): ResponsiveWidgetSettings => {
        const newEchartsOption: EChartsOption = _cloneDeep(echartsOption)
        const defaultMargin = "3%"

        // get category (S/M/L) for width and height
        const sizeCategories = ResponsiveSizeUtil.getSizeCategories(containerSize, DEFAULT_SIZE_BREAKPOINTS)

        // additional CSS classes
        newEchartsOption.tooltip.className +=
            " " + ResponsiveSizeUtil.getClassNameStringForSizeCategories(sizeCategories)

        // legend: on small widget width legend can be toggled on and off; medium widget width: scrollable legend on top;
        // large width: scrollable legend on the right side
        const isLegendHorizontal =
            sizeCategories.width == SizeCategory.X_SMALL ||
            sizeCategories.width == SizeCategory.SMALL ||
            sizeCategories.width == SizeCategory.MEDIUM
        const isLegendExpandable =
            sizeCategories.width == SizeCategory.X_SMALL || sizeCategories.width == SizeCategory.SMALL

        if (hasLegend) {
            // legend is either always visible if widget is large enough or if widget is small then it is only visible if legend is toggeld on (=expanded)
            newEchartsOption.legend.orient = isLegendHorizontal ? "horizontal" : "vertical"
            newEchartsOption.legend.show = true
            newEchartsOption.legend.backgroundColor = "rgba(255,255,255, 0.8)"

            // assumption: if legend can be toggled so that it is either hidden or large then we don't need scrolling
            newEchartsOption.legend.type = "scroll"
            newEchartsOption.legend.itemWidth = 14
            newEchartsOption.legend.itemHeight = 14
            newEchartsOption.legend.top = 10
            newEchartsOption.legend.right = 10
            // newEchartsOption.legend.bottom = 20
            newEchartsOption.legend.left = isLegendHorizontal ? 10 : "auto"
            newEchartsOption.legend.textStyle = {
                width: isLegendHorizontal ? null : 145,
                // if the legend is expandable: we don't want to waste any space by showing multi line items because there is not a lot of space anyway
                overflow: isLegendExpandable ? "truncate" : "breakAll",
                fontSize: isLegendHorizontal ? 11 : 12,
            }
            // grid layout: leave space for legend where needed
            newEchartsOption.grid.top = isLegendHorizontal ? 70 : 40
            newEchartsOption.grid.right = isLegendHorizontal ? defaultMargin : 200
        } else {
            newEchartsOption.grid.top = defaultMargin
            newEchartsOption.grid.right = defaultMargin
        }

        newEchartsOption.grid.top = 0
        newEchartsOption.grid.left = defaultMargin
        newEchartsOption.grid.containLabel = true

        if (hasLegend && isLegendExpandable) {
            if (isLegendExpanded) {
                newEchartsOption.grid.top = computeGridTopDistance(containerSize.width, newEchartsOption) + 10
                newEchartsOption.legend.type = "plain"
            } else {
                newEchartsOption.legend.height = 20
                // workaround since we were not able to get a single lgend row that is cut off at the end: legend is type "scroll" but
                // we style it in a way so that the scroller is not visible
                newEchartsOption.legend.pageFormatter = " "
                newEchartsOption.legend.pageIconSize = 0
                newEchartsOption.grid.top = 70
            }
        }

        switch (sizeCategories.width) {
            case SizeCategory.X_SMALL:
                newEchartsOption.yAxis.axisLabel = {
                    ...(newEchartsOption.yAxis.axisLabel ? newEchartsOption.yAxis.axisLabel : {}),
                    show: true,
                }
                newEchartsOption.grid.left = 0
                newEchartsOption.grid.right = 0
                break
            case SizeCategory.SMALL:
                break
            case SizeCategory.MEDIUM:
                newEchartsOption.grid.top = 70
                break
            case SizeCategory.LARGE:
                newEchartsOption.grid.top = 40
                break
            default:
                throw new Error(`Found unexpected SizeCategory for width: '${sizeCategories.width}'`)
        }

        if (sizeCategories.height == SizeCategory.X_SMALL) {
            // on tiny charts: expanded legend will lie above the chart; it doesn't make sense to shrink the chart down, it would only look
            // very small and weird
            newEchartsOption.grid.top = 65
            // on tiny charts: hide labels, there is not enough space
            newEchartsOption.xAxis.axisLabel = {
                ...(newEchartsOption.xAxis.axisLabel ? newEchartsOption.xAxis.axisLabel : {}),
                show: false,
            }
        }

        if (!hasLegend) {
            newEchartsOption.grid.top = defaultMargin
        }

        // this is supposed to leave room for rotated labels
        if (newEchartsOption.xAxis?.axisLabel?.rotate) {
            newEchartsOption.grid.bottom = 45
        } else {
            newEchartsOption.grid.bottom = defaultMargin

            // when there is a title (as a workaround instead of using an axis label because that casued other layout issues) on the bottom then move grid up a little bit
            if (newEchartsOption.title?.subtext && newEchartsOption.title.bottom == 0) {
                newEchartsOption.grid.bottom = 18
            }
        }

        const estimatedLegendLength = estimateLegendTotalLength(newEchartsOption)
        // don't show the legend button if the legend is less wide than the container width
        const isLegendButtonVisible = isLegendExpandable && estimatedLegendLength >= containerSize.width

        return {
            echartsOption: newEchartsOption,
            isLegendButtonVisible: isLegendButtonVisible,
            isLegendHorizontal: isLegendHorizontal,
        }
    },
}

export default ResponsiveWidgetSettingsUtil
