import React, { ReactNode } from "react"
import ErrorBoundary from "shared/component/ErrorBoundary"
import { RowActions } from "domain/actions/RowActions"
import { v1 as uuid } from "uuid"
import CreateRoundedIcon from "@mui/icons-material/CreateRounded"
import AddRoundedIcon from "@mui/icons-material/AddRounded"
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded"
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded"
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded"
import { ActionDTOIdentifierEnum } from "generated/models"

export type ActionConfig = {
    name: string
    icon: ReactNode
}

const standardActions = {
    [ActionDTOIdentifierEnum.CREATE]: {
        name: "Create",
        icon: <AddRoundedIcon />,
    } as ActionConfig,
    [ActionDTOIdentifierEnum.EDIT]: {
        name: "Edit",
        icon: <CreateRoundedIcon />,
    } as ActionConfig,
    [ActionDTOIdentifierEnum.DEACTIVATE]: {
        name: "Deactivate",
        icon: <VisibilityOffRoundedIcon />,
    } as ActionConfig,
    [ActionDTOIdentifierEnum.ACTIVATE]: {
        name: "Activate",
        icon: <VisibilityRoundedIcon />,
    } as ActionConfig,
    [ActionDTOIdentifierEnum.DELETE]: {
        name: "Delete",
        icon: <DeleteForeverRoundedIcon />,
    } as ActionConfig,
}

/**
 * Gets the action config for the actionIdentifier from the standardActions list
 *
 * @param actionIdentifier
 */
export const getActionConfig = (actionIdentifier: ActionDTOIdentifierEnum): ActionConfig => {
    if (standardActions[actionIdentifier]) {
        return standardActions[actionIdentifier]
    }

    new Error("actionIdentifier " + actionIdentifier + " is not configured as standard action")
}

type Props = { rowActions: RowActions }

const InlineButtonsRenderer: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const handleClick = (actionIdentifier: ActionDTOIdentifierEnum, event: any) => {
        event.stopPropagation()
        props.rowActions.invokeActionHandler(actionIdentifier)
    }

    const actions = props.rowActions?.actions

    return (
        <ErrorBoundary>
            <span className="datagrid-inline-buttons">
                {actions?.length > 0 &&
                    actions.map((action, index) => {
                        const tooltip = action.disabled
                            ? action.disabledTooltip
                            : standardActions[action.actionIdentifier]?.name

                        return (
                            <button
                                key={uuid()}
                                type={"button"}
                                className={"inline-button"}
                                data-tip={tooltip}
                                data-force-tooltip={true}
                                disabled={action.disabled}
                                onClick={(event) => {
                                    handleClick(action.actionIdentifier, event)
                                }}
                            >
                                {standardActions[action.actionIdentifier]?.icon}
                            </button>
                        )
                    })}
            </span>
        </ErrorBoundary>
    )
})

export default InlineButtonsRenderer
