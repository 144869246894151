import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"

export const FullOrSoftConversionRadioButtonGroup = () => {
    const conversionListType = ConversionListContextSelectors.useConversionListType()
    const fullOrSoftConversionSelection = ConversionListContextSelectors.useFullOrSoftConversionSelection()
    const updateFullOrSoftConversionSelection = ConversionListContextSelectors.useUpdateFullOrSoftConversionSelection()

    if (conversionListType === "realtime") {
        return <React.Fragment />
    }

    const selectedValues = Object.entries(fullOrSoftConversionSelection)
        .filter(([, value]) => value)
        .map(([key]) => key)

    const handleChange = (_: React.MouseEvent<HTMLElement>, fullOrSoft: string[]) => {
        if (fullOrSoft.length > 0) {
            updateFullOrSoftConversionSelection({
                full: fullOrSoft.includes("full"),
                soft: fullOrSoft.includes("soft"),
            })
        }
    }

    return (
        <ToggleButtonGroup color="primary" value={selectedValues} onChange={handleChange} size="small">
            <ToggleButton value="full" sx={{ flexBasis: "40%" }}>
                Full Con&shy;ver&shy;sions
            </ToggleButton>
            <ToggleButton value="soft" sx={{ flexBasis: "40%" }}>
                Soft Con&shy;ver&shy;sions
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
