import { Add } from "@mui/icons-material"
import {
    Box,
    ButtonBase,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material"
import { WidgetMap } from "domain/widget/WidgetRenderer"
import { WidgetDTOTypeEnum } from "generated/models"
import React, { useState } from "react"
import { useDashboardLayout } from "./DashboardLayoutProvider"

const WidgetConfig = {
    [WidgetDTOTypeEnum.CONTAINER_WIDGET]: {
        label: "Container Widget",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.TEXT_WIDGET]: {
        label: "Text widget",
        src: "/ui/widgets/text-widget.jpeg",
    },
    [WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET]: {
        label: "Multi Bar chart",
        src: "/ui/widgets/bar-chart.png",
    },
    [WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET]: {
        label: "Time-based Bar chart",
        src: "/ui/widgets/bar-chart.png",
    },
    [WidgetDTOTypeEnum.LINE_CHART_WIDGET]: {
        label: "Line chart",
        src: "/ui/widgets/line-chart.png",
    },
    [WidgetDTOTypeEnum.TABBED_WIDGET]: {
        label: "Tabbed widget",
        src: "/ui/widgets/tabbed-widget.png",
    },
    [WidgetDTOTypeEnum.PERIOD_WIDGET]: {
        label: "Period",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.PREVIOUS_PERIOD_WIDGET]: {
        label: "Previous period",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.METRIC_TOGGLE_WIDGET]: {
        label: "Metric toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.REPORTING_DIMENSION_TOGGLE_WIDGET]: {
        label: "Reporting dimension toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.REPORTING_METHOD_TOGGLE_WIDGET]: {
        label: "Reporting method toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET]: {
        label: "Historical Comparison Bar chart",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET]: {
        label: "Historical Comparison Card",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
} satisfies Record<WidgetDTOTypeEnum, any>

type WidgetItemProps<Type extends keyof WidgetMap> = {
    type: Type
    onClick: (type: Type) => void
}

const WidgetItem = <Type extends keyof WidgetMap>(props: WidgetItemProps<Type>) => {
    const { type, onClick } = props
    const { label, src } = WidgetConfig[type]

    return (
        <ButtonBase
            onClick={() => onClick(type)}
            sx={{
                display: "flex",
            }}
        >
            <Card
                elevation={1}
                sx={{
                    flex: 1,
                }}
            >
                <CardMedia sx={{ height: 140 }} image={src} />
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Typography gutterBottom variant="button" fontWeight={500} component="div">
                        {label}
                    </Typography>
                </CardContent>
            </Card>
        </ButtonBase>
    )
}

type AddWidgetModalProps = {
    enabledWidgets?: Array<keyof WidgetMap>
}

// TODO<Firat>: Where to get initial values?
const AddWidgetModal = (props: AddWidgetModalProps) => {
    const { enabledWidgets } = props
    const [open, setOpen] = useState(false)
    const { addWidget } = useDashboardLayout()

    const renderWidget = (type: keyof WidgetMap) => {
        return !enabledWidgets || enabledWidgets.includes(type)
    }

    return (
        <>
            <IconButton sx={{ zIndex: 10000 }} onClick={() => setOpen(true)}>
                <Add />
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" PaperProps={{ sx: { width: "100%" } }}>
                <DialogTitle>Add widget</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                            rowGap: 2,
                            columnGap: 2,
                            px: 4,
                        }}
                    >
                        {renderWidget(WidgetDTOTypeEnum.CONTAINER_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.CONTAINER_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                layout: [],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.TEXT_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.TEXT_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                htmlContent: "",
                                            },
                                        },
                                        {
                                            w: 6,
                                            h: 30,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                orientation: "horizontal",
                                                primaryDimension: "device_type",
                                                secondaryDimensions: ["clicks"],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                orientation: "horizontal",
                                                primaryDimension: "device_type",
                                                secondaryDimensions: ["clicks"],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                metricIdentifier: "clicks",
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {/* TODO: Why doesn't this typecheck?
                        {renderWidget(WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                metricIdentifier: "clicks",
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}*/}
                        {renderWidget(WidgetDTOTypeEnum.LINE_CHART_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.LINE_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                primaryDimension: "daily",
                                                secondaryDimensions: [
                                                    "bounce_visits",
                                                    "quality_visits",
                                                    "failed_reach_visits",
                                                ],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.TABBED_WIDGET) && (
                            <WidgetItem
                                type={WidgetDTOTypeEnum.TABBED_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                tabs: [
                                                    {
                                                        metricIdentifier: "imps",
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: "clicks",
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: "contacts_totp",
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: "pi",
                                                        layout: [],
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            w: 6,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {(
                            [
                                WidgetDTOTypeEnum.PERIOD_WIDGET,
                                WidgetDTOTypeEnum.PREVIOUS_PERIOD_WIDGET,
                                WidgetDTOTypeEnum.METRIC_TOGGLE_WIDGET,
                                WidgetDTOTypeEnum.REPORTING_DIMENSION_TOGGLE_WIDGET,
                                WidgetDTOTypeEnum.REPORTING_METHOD_TOGGLE_WIDGET,
                            ] as const
                        ).map(
                            (w) =>
                                renderWidget(w) && (
                                    <WidgetItem
                                        key={w}
                                        type={w}
                                        onClick={(type) => {
                                            addWidget(
                                                {
                                                    type,
                                                    settings: {},
                                                },
                                                {
                                                    w: 3,
                                                    h: 8,
                                                },
                                            )
                                            setOpen(false)
                                        }}
                                    />
                                ),
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddWidgetModal
