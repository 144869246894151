import ChartBarIcon from "shared/component/icons/ChartBarIcon"
import CogIcon from "shared/component/icons/CogIcon"
import WrenchIcon from "shared/component/icons/WrenchIcon"
import ChartUpIcon from "shared/component/icons/ChartUpIcon"
import BookInfoIcon from "shared/component/icons/BookInfoIcon"
import { SvgIconProps } from "@mui/material/SvgIcon/SvgIcon"
import UsersIcon from "shared/component/icons/UsersIcon"
import FolderLockIcon from "shared/component/icons/FolderLockIcon"
import React from "react"
import CirclesIcon from "shared/component/icons/CirclesIcon"

export interface MenuIconProps extends SvgIconProps {
    icon?: string
}

export const MenuIcon = (props: MenuIconProps): JSX.Element => {
    switch (props.icon) {
        case "Admin": {
            return <WrenchIcon />
        }
        case "Audiences": {
            return <UsersIcon />
        }
        case "MMM": {
            return <CirclesIcon />
        }
        case "Optimization": {
            return <ChartUpIcon />
        }
        case "Reporting": {
            return <ChartBarIcon />
        }
        case "Resources": {
            return <BookInfoIcon />
        }
        case "Setup": {
            return <CogIcon />
        }
        case "Support": {
            return <FolderLockIcon />
        }
        default: {
            return <> </>
        }
    }
}
