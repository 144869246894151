import React from "react"
import { v4 as uuid } from "uuid"
import { PropertyFilterDialogBase } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/PropertyFilterDialogBase"
import { DimensionValue, TouchpointPropertyFilter } from "domain/ConversionList/domain/domain"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { TouchpointCustomerJourneyRole } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"

export const AddPropertyFilterDialog = () => {
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const updateTouchpointPropertyFilters = ConversionListContextSelectors.useUpdateTouchpointPropertyFilters()

    const [selectedTouchpointCustomerJourneyRole, setSelectedTouchpointCustomerJourneyRole] =
        React.useState<TouchpointCustomerJourneyRole>("winner")
    const [selectedTouchpointFilterDimension, setSelectedTouchpointFilterDimension] =
        React.useState<TouchpointFilterDimension>("channel")
    const [selectedTouchpointFilterDimensionValues, setSelectedTouchpointFilterDimensionValues] = React.useState<
        DimensionValue[]
    >([])

    const handleApplyButtonClick = () => {
        if (selectedTouchpointFilterDimensionValues === null) {
            // TODO: Handle case
            console.error("selectedTouchpointFilterDimensionValue is null")
            return
        }

        const touchpointPropertyFilter: TouchpointPropertyFilter = {
            id: uuid(),
            customerJourneyRole: selectedTouchpointCustomerJourneyRole,
            filterDimension: selectedTouchpointFilterDimension,
            values: selectedTouchpointFilterDimensionValues,
        }

        updateTouchpointPropertyFilters([...touchpointPropertyFilters, touchpointPropertyFilter])
    }

    return (
        <PropertyFilterDialogBase
            selectedTouchpointCustomerJourneyRole={selectedTouchpointCustomerJourneyRole}
            updateSelectedTouchpointCustomerJourneyRole={setSelectedTouchpointCustomerJourneyRole}
            selectedTouchpointFilterDimension={selectedTouchpointFilterDimension}
            updateSelectedTouchpointFilterDimension={setSelectedTouchpointFilterDimension}
            selectedTouchpointFilterDimensionValues={selectedTouchpointFilterDimensionValues}
            updateSelectedTouchpointFilterDimensionValues={setSelectedTouchpointFilterDimensionValues}
            applyButtonText="Add Property Filter"
            onApplyButtonClick={handleApplyButtonClick}
        />
    )
}
