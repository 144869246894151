import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NotificationType } from "domain/notification/NotificationService"

export type Notification = {
    type: NotificationType
    count: number
}

export type NotificationState = {
    notifications: Notification[]
}
const initialState: NotificationState = {
    notifications: [],
}

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotification(state: NotificationState, action: PayloadAction<Notification>): void {
            state.notifications.find((notification) => notification.type === action.payload.type)
            const index = state.notifications.findIndex((notification) => notification.type === action.payload.type)

            if (index >= 0) {
                state.notifications[index].count = action.payload.count
            } else {
                state.notifications.push(action.payload)
            }
        },
    },
})

export const { addNotification: addNotification } = notificationsSlice.actions

export const notificationsSliceReducer = notificationsSlice.reducer
