import React from "react"
import { Tag, TagsUtil } from "domain/ColumnConfigurator/TagsUtil"

export interface MetricTagIndicatorListProps {
    tags: ReadonlySet<Tag>
}

export const MetricTagIndicatorList = ({ tags }: MetricTagIndicatorListProps): JSX.Element => {
    const items = Array.from(tags)
        .filter(shouldTagBeShown)
        .map((tag) => (
            <div key={tag} className={`tag ${TagsUtil.getTagKey(tag)}`}>
                {tag}
            </div>
        ))

    return <>{items}</>
}

const shouldTagBeShown = (tag: Tag): boolean => {
    return tag !== Tag.UPDATED
}
