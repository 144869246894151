import React from "react"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { ColumnUniqueName } from "domain/ColumnConfigurator/types"
import { TileUtil } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/TileUtil"
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ColumnUtil } from "domain/ColumnConfigurator/components/ColumnUtil"
import { MemoizedMetricCheckboxWithTooltip } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/MetricCheckboxWithTooltip"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { IdentityWrapper, Wrapper } from "shared/util/Wrapper"

export interface ListMetricsTileProps {
    name: string
    frontendGroupMetrics: ReadonlySet<ColumnUniqueName>
    onCheckboxChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    Wrapper?: Wrapper
}

export const ListMetricsTile = ({
    name,
    frontendGroupMetrics,
    onCheckboxChangeHandler,
    Wrapper = IdentityWrapper,
}: ListMetricsTileProps): JSX.Element | null => {
    const {
        dataDefinitions: { metrics },
    } = useReportingConfigurationContext()

    const selectedMetrics = ColumnConfiguratorContextSlices.useSelectedMetrics()
    const dimensionMetricCompatibility = ColumnConfiguratorContextSlices.useDimensionMetricCompatibility()

    const columns = React.useMemo(
        () => TileUtil.getColumnMap(frontendGroupMetrics, metrics),
        [frontendGroupMetrics, metrics],
    )
    const columnCheckboxStates = React.useMemo(
        () =>
            TileUtil.getMetricsFrontendGroupCheckboxState(
                frontendGroupMetrics,
                dimensionMetricCompatibility,
                ColumnUtil.getIdentifiers(selectedMetrics),
            ),
        [frontendGroupMetrics, dimensionMetricCompatibility, selectedMetrics],
    )

    const checkboxes = []
    let renderContent = false
    for (const [columnName, column] of columns.entries()) {
        const checkboxState = columnCheckboxStates.get(columnName)

        if (!checkboxState || checkboxState.visibility === "invisible") {
            continue
        }
        renderContent = true

        const checkbox = (
            <MemoizedMetricCheckboxWithTooltip
                key={columnName}
                columnUniqueName={columnName}
                columnDisplayName={column.displayName}
                withCheckboxLabel={true}
                isDisabled={checkboxState.visibility === "disabled"}
                checked={checkboxState.checked}
                onChange={onCheckboxChangeHandler}
            />
        )

        checkboxes.push(checkbox)
    }

    return renderContent ? (
        <Wrapper>
            <StyledBox
                key={name}
                className={"tile list-metrics-tile"}
                sx={{ columnCount: 1, display: "flex", flexDirection: "column" }}
            >
                {checkboxes}
            </StyledBox>
        </Wrapper>
    ) : null
}

const StyledBox = styled(Box)(({ theme }) => ({
    "& .MuiFormControlLabel-root": {
        display: "flex",
        alignItems: "flex-start",
        marginRight: 0,
        marginBottom: "7px",
        [theme.breakpoints.down("lg")]: {
            marginBottom: "4px",
        },
    },
    "& .MuiFormControlLabel-label": {
        fontSize: "13px!important",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px!important",
        },
    },
    "& .MuiCheckbox-root": {
        padding: "0 9px 5px 9px",
        [theme.breakpoints.down("md")]: {
            padding: "0 5px 3px 1px",
        },
    },
}))
