import React from "react"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "react-contexify/dist/ReactContexify.min.css"
import "app.less"
import { useSelector } from "react-redux"
import { CustifyAnalyticsContextProvider } from "shared/analytics/CustifyAnalyticsService"
import { BackendSessionKeepAlive } from "shared/component"
import AppRouter from "AppRouter"
import ReactHint from "domain/tooltip/ReactHint"
import moment from "moment"
import { NavigationContextProvider } from "shared/NavigationContext"
import { RootState } from "shared/redux/store"
import { Alert, AlertTitle, Snackbar } from "@mui/material"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BackendProvidedCustomizedTheme } from "shared/component/BackendProvidedCustomizedTheme"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true, we disable this cause for UX it's a very bad experience when perfectly fine data gets reloaded just because of a focus event
            retry: false, // default: 3, we disable this cause it's better to see a failing query right away, especially when the query is slow
        },
    },
})

export const App = () => {
    // set first day of the week to monday
    moment.updateLocale("en", {
        week: {
            // Monday is the first day of the week
            dow: 1,
            // ISO 8601 week numbering
            doy: 4,
        },
    })

    const authentication = useSelector((state: RootState) => state.authentication)
    const menuConfigs = useSelector((state: RootState) => state.user?.menu)
    const menu = menuConfigs?.root ?? []

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <BackendProvidedCustomizedTheme>
                    {authentication.loggedInViaFirstFactor && <BackendSessionKeepAlive />}
                    {authentication && authentication.loginError && (
                        <Snackbar open={true}>
                            <Alert severity="error" className="automatic-login-alert">
                                <AlertTitle>An error occurred while logging you in.</AlertTitle>
                                Please contact our support team
                            </Alert>
                        </Snackbar>
                    )}

                    {/* this tag is used to render the tooltip */}
                    <ReactHint position="bottom" />

                    <NavigationContextProvider>
                        <CustifyAnalyticsContextProvider>
                            <AppRouter authentication={authentication} menu={menu} />
                        </CustifyAnalyticsContextProvider>
                    </NavigationContextProvider>
                </BackendProvidedCustomizedTheme>
            </QueryClientProvider>
        </>
    )
}

export default App
