import { post } from "shared/service/api"
import { AppContextDTO, LoadConfigDTO } from "generated/models"
import { PageConfigDTO } from "generated/models"

const loadPageConfig = async (path: string, appContext: AppContextDTO, baseURL?: string): Promise<PageConfigDTO> => {
    const payload: LoadConfigDTO = {
        appContext,
    }
    return await post<any>(path, payload, { baseURL })
}

const PageService = { loadPageConfig }
export default PageService
