import React from "react"

import LayoutRenderer from "shared/component/layout/renderers/LayoutRenderer"
import { FormContextProvider, useFormContext } from "./FormContext"
import { ActionPopupConfig, UIFormConfig } from "domain/types"

export const GenericForm = (): JSX.Element => {
    const formContext = useFormContext()

    const { formik } = formContext

    const mainDimension = formContext.uiFormConfig.formConfig.mainDimension.identifier
    const formType = formContext.uiFormConfig.formConfig.type
    const cssClasses = ["generic-form-wrapper", `${mainDimension}-form`, `${mainDimension}-${formType}`].join(" ")

    return (
        <form onReset={formik.handleReset} onSubmit={formik.handleSubmit} className={cssClasses}>
            <LayoutRenderer
                layoutElementConfig={formContext.uiFormConfig.formConfig.layoutConfig}
                additionalCssClasses={["generic-form"]}
            />
        </form>
    )
}

export interface GenericFormWrapperProps {
    uiFormConfig: UIFormConfig
    popupConfig: ActionPopupConfig
}

export const GenericFormWrapper = ({ uiFormConfig, popupConfig }: GenericFormWrapperProps): JSX.Element => {
    return (
        <FormContextProvider uiFormConfig={uiFormConfig} popupConfig={popupConfig}>
            <GenericForm />
        </FormContextProvider>
    )
}
