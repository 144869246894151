import React, { useState } from "react"
import Chip from "@mui/material/Chip"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { styled } from "@mui/material"

interface PropertyFilterChipProps {
    label: string
    onDelete: () => void
    onEdit: () => void
}

export const PropertyFilterChip: React.FC<PropertyFilterChipProps> = ({ label, onDelete, onEdit }) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <Chip
            variant="outlined"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            label={label}
            // We use the deleteIcon prop to render the edit and delete icons
            deleteIcon={
                <>
                    <HoverIconButton
                        hovered={isHovered}
                        size="small"
                        onClick={onEdit}
                        sx={{ marginRight: "4px", padding: 0 }}
                    >
                        <EditIcon fontSize="small" />
                    </HoverIconButton>
                    <HoverIconButton
                        hovered={isHovered}
                        size="small"
                        onClick={onDelete}
                        sx={{ marginRight: "4px", padding: 0 }}
                    >
                        <DeleteIcon fontSize="small" />
                    </HoverIconButton>
                </>
            }
            // We only need to set the onDelete prop so the deleteIcon is rendered. The onClick handlers in the
            // deleteIcon prop handle the actual deletion functionality.
            onDelete={() => {}}
            sx={{ paddingRight: "4px" }} // Adjust padding for visual alignment
        />
    )
}

interface HoverIconButtonProps extends IconButtonProps {
    hovered: boolean
}

const HoverIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "hovered",
})<HoverIconButtonProps>(({ theme, hovered }) => ({
    // TODO: I just used these palette colors to get it working, other definitions might make more sense semantically
    color: hovered ? theme.palette.text.secondary : theme.palette.text.disabled,
}))
