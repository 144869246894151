import { Dispatch } from "redux"
import { PasswordExpirationService } from "domain/password/service/PasswordExpirationService"
import {
    closePasswordExpirationDialog,
    setPasswordExpirationDate,
} from "domain/password/redux/password.expiration.slice"
import { log } from "shared/util/log"
import { PasswordExpirationDTO } from "generated/models"

/**
 * Loads password expiration date for regular users and stores it in the redux store only if the password expires in lower than 7 days
 */
const loadPasswordExpiration =
    (isRegularUser: boolean) =>
    async (dispatch: Dispatch): Promise<PasswordExpirationDTO | undefined> => {
        if (isRegularUser) {
            const response = await PasswordExpirationService.loadPasswordExpiration()

            // only show the dialog if the password expires in lower than 7 days
            if (response.daysUntilExpire < 7) {
                dispatch(setPasswordExpirationDate(response.passwordExpirationDate))
            }

            return response
        } else {
            log.debug("Don't load password expiration for internal users")
            return undefined
        }
    }

/**
 * Closes the password expiration dialog
 */
const closeDialog =
    () =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(closePasswordExpirationDialog())
    }

export const passwordExpirationActions = {
    loadPasswordExpiration: loadPasswordExpiration,
    closeDialog: closeDialog,
}
