import { Button, DialogActions, DialogContentText } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount"
import React from "react"
import { UserSwitchState } from "layout/MainLayout/UserMenu/UserMenuPopover"
import { CustomDialog } from "layout/components/CustomDialog"
import { RegularUserDTO } from "generated/models"

export interface UserSwitchConfirmationDialogProps {
    userSwitchState: UserSwitchState
    onConfirmUserSwitchButtonClick: (user: RegularUserDTO) => () => void
    onCancelUserSwitchButtonClick: () => void
}

export const UserSwitchConfirmationDialog = ({
    userSwitchState,
    onConfirmUserSwitchButtonClick,
    onCancelUserSwitchButtonClick,
}: UserSwitchConfirmationDialogProps) => {
    // When the user switch is in progress, the backdrop click and escape key events should be ignored
    const onClose = (event: React.SyntheticEvent, reason: string) => {
        if (userSwitchState.type === "inProgress" && (reason === "escapeKeyDown" || reason === "backdropClick")) {
            return
        }
        onCancelUserSwitchButtonClick()
    }

    return (
        <>
            {(userSwitchState.type === "confirmationDialogOpen" || userSwitchState.type === "inProgress") && (
                <CustomDialog
                    className={"user-switch-confirmation-dialog"}
                    open={userSwitchState.type === "confirmationDialogOpen" || userSwitchState.type === "inProgress"}
                    onClose={onClose}
                    closeButtonDisabled={userSwitchState.type === "inProgress"}
                    header="Confirm User Switch"
                    footer={{
                        kind: "custom",
                        content: (
                            <>
                                <DialogActions>
                                    <Button
                                        color="info"
                                        variant="outlined"
                                        size="large"
                                        onClick={onCancelUserSwitchButtonClick}
                                        disabled={userSwitchState.type === "inProgress"}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        color="info"
                                        variant="contained"
                                        size="large"
                                        onClick={onConfirmUserSwitchButtonClick(userSwitchState.user)}
                                        endIcon={<SwitchAccountIcon />}
                                        loading={userSwitchState.type === "inProgress"}
                                        loadingPosition="end"
                                        autoFocus
                                    >
                                        <span>Switch</span>
                                    </LoadingButton>
                                </DialogActions>
                            </>
                        ),
                    }}
                    content={
                        <DialogContentText>
                            Are you sure you want to switch to user <strong>{userSwitchState.user.loginName}</strong>?
                        </DialogContentText>
                    }
                />
            )}
        </>
    )
}
