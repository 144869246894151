import { DashboardSettingsContextProvider } from "domain/widget/DashboardSettings/DashboardSettingsContext"
import { DashboardLayoutProvider, useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutProvider"
import { Button, Stack } from "@mui/material"
import { DashboardLayoutGrid } from "domain/dashboard/DashboardLayout/DashboardLayoutGrid"
import React from "react"
import AddWidgetModal from "domain/dashboard/DashboardLayout/AddWidgetModal"
import LoadingButton from "@mui/lab/LoadingButton"
import { DownloadButton } from "domain/dashboard/DashboardLayout/DownloadButton"

type DashboardPageProps = {
    baseDashboardId: string
}

export const DashboardPage = (props: DashboardPageProps) => {
    const { baseDashboardId } = props

    return (
        <DashboardSettingsContextProvider
            key={baseDashboardId}
            baseId={baseDashboardId}
            // uuid={"some-dashboard-uuid"}
        >
            <DashboardLayoutProvider>
                <Stack gap={2}>
                    <DashboardLayoutActions />
                    <DashboardLayoutGrid />
                </Stack>
            </DashboardLayoutProvider>
        </DashboardSettingsContextProvider>
    )
}

const DashboardLayoutActions = () => {
    const { readOnly, submit, onConvertToLegacy, updateIsPending, convertToLegacyIsPending } = useDashboardLayout()
    return (
        <Stack direction="row" gap={1} justifyContent="flex-end">
            {!readOnly && (
                <>
                    <AddWidgetModal />
                    <LoadingButton variant="contained" onClick={submit} loading={updateIsPending}>
                        Save (WIP)
                    </LoadingButton>
                    <LoadingButton variant="contained" onClick={onConvertToLegacy} loading={convertToLegacyIsPending}>
                        Download json
                    </LoadingButton>
                </>
            )}
            <DownloadButton />
        </Stack>
    )
}
