import * as React from "react"
import { useMemo } from "react"
import { Select } from "antd"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { FilterState } from "domain/types"

type Props = {
    filter: FilterState
    label: string
    onChange?: (identifier: string, value: string | number | string[] | number[]) => void
    size?: any
    componentStyle?: any
    selectStyle?: any
    additionalCssClass?: string
    enableClearButton?: boolean
    mode?: "multiple" | "tags"
}

export const SelectComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const handleChange = (value: string | number | string[] | number[]) => {
        const { filter, onChange } = props
        if (onChange) onChange(filter.selectFormElement.formFieldConfig?.dimensionIdentifier, value)
    }

    const { filter, label, enableClearButton = true } = props
    const hasSelectEntries =
        filter.filterEntries && filter.filterEntries.entries && filter.filterEntries.entries.length > 0
    const showIcon = filter.filterEntries?.entries?.some((row) => row.icon) === true

    const elementProperties = {
        className: "filter-input",
        size: props.size ? props.size : "small",
        onChange: handleChange,
        style: props.selectStyle ? props.selectStyle : {},
        showSearch: true,
        allowClear: enableClearButton,
        disabled: !hasSelectEntries,
        dropdownClassName:
            filter.selectFormElement?.formFieldConfig?.dimensionIdentifier + (showIcon ? " has-icon" : ""),
        loading: !hasSelectEntries,
        // has to be consistent with whatever was generated in FilterComponentUtil.createOptionList
        optionFilterProp: "label",
        // if true, when using search as you type then filter by user input, using the "optionFilterProp" field
        filterOption: true,
        value: undefined as string | number | string[] | number[] | undefined,
        mode: undefined as "multiple" | "tags" | undefined,
    }
    if (hasSelectEntries) {
        // Set value only when filter dimension values are loaded.
        // This way we avoid showing ids without mappings.
        elementProperties.value = filter.value
        if (props.mode) {
            elementProperties.mode = props.mode
        }
    }

    // Collect select entries in the list.
    // useMemo is used to avoid rebuilding expensive long lists
    const selectList = useMemo((): any[] => {
        return createOptionList(filter)
    }, [filter])

    return (
        <div className={"filter-container " + (props.additionalCssClass || "")} style={props.componentStyle || {}}>
            <label className="filter-label">{label}</label>
            {/*This additional div is needed to avoid display:flex from breaking the calculated dropdown position*/}
            <div
                className={
                    "filter-wrapper " +
                    (showIcon ? "has-icon" : "") +
                    " " +
                    filter.selectFormElement?.formFieldConfig?.dimensionIdentifier
                }
            >
                <Select {...elementProperties}>{selectList}</Select>
            </div>
        </div>
    )
})

const createOptionList = (filter: FilterState): any[] => {
    const showIcon = filter.filterEntries?.entries?.some((row) => row.icon) === true

    return (
        filter.filterEntries?.entries?.map((option, index) => {
            const label = FilterComponentUtil.getFilterValue(option)
            return (
                <Select.Option key={`idx_${index}_${option.value}`} value={option.value} label={label}>
                    {showIcon && (
                        <div className={"icon-wrapper"}>
                            <div className={`icon ${option.icon}`}>&nbsp;</div>
                        </div>
                    )}
                    <span className={"option-name"}>{label}</span>
                </Select.Option>
            )
        }) || []
    )
}
