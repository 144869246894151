import React, { ReactNode, useContext, useState } from "react"
import _includes from "lodash/includes"
import { SELECTED_FIELD } from "domain/datagrid/component/DataGrid"
import { ReportingDataSetDTO } from "generated/models"

export type DataGridContextProps = {
    getRows: () => ReportingDataSetDTO
    updateRows: (newRows: ReportingDataSetDTO) => void
    getSelectedRowIndices: () => number[]
    updateSelectedRowIndices: (newSelectedRowIndices: number[]) => void

    resetSelectedRowIndices: () => void

    onSelect: (selectedRows: number[]) => void
}
export const DataGridContext = React.createContext<DataGridContextProps | undefined>(undefined)

export type DataGridContextProviderProps = {
    getRows?: () => ReportingDataSetDTO
    children: ReactNode
}

export const DataGridContextProvider = (props: DataGridContextProviderProps) => {
    const [rows, setRows] = useState(props.getRows ? props.getRows() : undefined)
    const [selectedRowIndices, setSelectedRowIndices] = useState([] as number[])

    const onSelect = (selectedIndices: number[]) => {
        if (rows) {
            const rowsCopy = {
                ...rows,
                rows: [...rows.rows],
            } as ReportingDataSetDTO

            rowsCopy.rows.forEach((_, index) => {
                rowsCopy.rows[index] = { ...rows.rows[index] }
                rowsCopy.rows[index][SELECTED_FIELD] = { value: !!_includes(selectedIndices, index) }
            })

            setRows(rowsCopy)
            setSelectedRowIndices(selectedIndices)
        }
    }

    return (
        <DataGridContext.Provider
            value={{
                getRows: () => rows,
                updateRows: (newRows: ReportingDataSetDTO) => {
                    setRows(newRows)
                },
                getSelectedRowIndices: () => selectedRowIndices,
                updateSelectedRowIndices: (newSelectedRowIndices: number[]) => {
                    setSelectedRowIndices(newSelectedRowIndices)
                },
                onSelect: (selectedRows: number[]) => {
                    onSelect(selectedRows)
                },
                resetSelectedRowIndices: () => {
                    onSelect([])
                },
            }}
        >
            {props.children}
        </DataGridContext.Provider>
    )
}

export const useDataGridContext = (): DataGridContextProps => {
    const context = useContext(DataGridContext)
    if (!context) {
        throw new Error("Missing DataGridContext in its parent.")
    }

    return context
}
