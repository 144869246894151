import React, { useId } from "react"
import { InfoText } from "domain/ConversionList/components/ConfigPanel/InfoText"
import { TimeSpanSelection } from "domain/ConversionList/components/ConfigPanel/TimeSpanSelection"
import { DownloadButton } from "domain/ConversionList/components/ConfigPanel/DownloadButton"
import { UpdateButton } from "domain/ConversionList/components/ConfigPanel/UpdateButton"
import { AddPropertyFilterButton } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/AddPropertyFilterButton"
import { ConversionTypeRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionProperties/ConversionTypeRadioButtonGroup"
import { TrackingStateRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionProperties/TrackingStateRadioButtonGroup"
import { CustomerJourneyConfigForm } from "domain/ConversionList/components/ConfigPanel/ConversionDisplayOptions/CustomerJourneyConfigForm"
import { CurrentPropertyFiltersList } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/CurrentPropertyFiltersList"
import { PropertyFilterDialog } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/PropertyFilterDialog"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { FullOrSoftConversionRadioButtonGroup } from "domain/ConversionList/components/ConfigPanel/ConversionProperties/FullOrSoftConversionRadioButtonGroup"
import { Search } from "domain/ConversionList/components/ConfigPanel/Search"
import { ColumnConfiguration } from "domain/ConversionList/components/ConfigPanel/ColumnConfiguration"
import { Box, Typography } from "@mui/material"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp"
import AccordionDetails from "@mui/material/AccordionDetails"
import IconButton from "@mui/material/IconButton"
import moment, { Moment } from "moment"
import { ConversionStateFilter } from "domain/ConversionList/components/ConfigPanel/ConversionStateFilter"
import { ConsentStateFilter } from "domain/ConversionList/components/ConfigPanel/ConsentStateFilter"

export const ConfigPanel = () => {
    const propertyFilterDialogId = ConversionListContextSelectors.usePropertyFilterDialogId()
    const subsetSectionId = useId()
    const cjSectionId = useId()
    const searchSectionId = useId()
    const touchpointSectionId = useId()

    const [expandedSubset, setExpandedSubset] = React.useState(false)
    const [expandedCj, setExpandedCj] = React.useState(false)
    const [expandedSearch, setExpandedSearch] = React.useState(false)
    const [expandedTouchpoint, setExpandedTouchpoint] = React.useState(false)

    function handleChangeSubset() {
        setExpandedSubset(!expandedSubset)
    }

    function handleChangeCj() {
        setExpandedCj(!expandedCj)
    }

    function handleChangeSearch() {
        setExpandedSearch(!expandedSearch)
    }

    function handleChangeTouchpoint() {
        setExpandedTouchpoint(!expandedTouchpoint)
    }

    const type = ConversionListContextSelectors.useConversionListType()

    const currentMinMaxTimeRange = (): [Moment, Moment] => {
        const now = moment()

        switch (type) {
            case "realtime": {
                const threeDaysAgo = now.clone().subtract(3, "day").startOf("day")
                const todayAtEndOfDay = now.clone().endOf("day")
                return [threeDaysAgo, todayAtEndOfDay]
            }
            case "historical": {
                const threeYearsAgoAtStartOfDay = now.clone().subtract(3, "year").startOf("day")
                const oneDayAgoAtEndOfDay = now.clone().subtract(1, "day").endOf("day")
                return [threeYearsAgoAtStartOfDay, oneDayAgoAtEndOfDay]
            }
        }
    }

    /**
     * Open all or close all acccordion section, and also sync them to have the same state
     */
    function handleChangeAll() {
        if ([expandedSubset, expandedCj, expandedSearch, expandedTouchpoint].includes(false)) {
            setExpandedSubset(true)
            setExpandedCj(true)
            setExpandedSearch(true)
            setExpandedTouchpoint(true)
        } else {
            setExpandedSubset(false)
            setExpandedCj(false)
            setExpandedSearch(false)
            setExpandedTouchpoint(false)
        }
    }

    return (
        <Box
            className={"config-panel"}
            sx={{
                "& .MuiAccordionSummary-content": {
                    margin: 0,
                    padding: 0,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                },
                "& .MuiAccordion-root.Mui-expanded": {
                    margin: 0,
                },
                "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "auto",
                    mb: "8px",
                },
                "& .MuiAccordionDetails-root": {
                    paddingTop: 0,
                },

                "& .MuiAccordion-root": {
                    backgroundColor: "transparent !important",
                },
            }}
        >
            <InfoText />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #f0f0f0",
                    borderRadius: 2,
                    padding: 1,
                    mt: 1,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }} className={"time-span"}>
                    <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD, mr: 1 }}>
                        Displayed Timespan (up to 3 months):
                    </Typography>{" "}
                    <TimeSpanSelection
                        minDateTime={currentMinMaxTimeRange()[0]}
                        maxDateTime={currentMinMaxTimeRange()[1]}
                        maxDuration={91}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: 1, ml: 1 }}>
                    <DownloadButton />
                    <UpdateButton />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "grid",
                    border: "1px solid #f0f0f0",
                    borderRadius: 2,
                    padding: 1,
                    mt: 1,
                    mb: 1,
                    gridTemplateColumns: "25% 25% 25% 24% 1%",
                }}
            >
                <Accordion
                    elevation={0}
                    sx={{ "&:before": { height: "0px" } }}
                    expanded={expandedSubset}
                    onChange={handleChangeSubset}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${subsetSectionId}-content`}
                        id={subsetSectionId}
                        sx={{
                            flexDirection: "row-reverse",
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                            Select the subset of conversions you want to analyze
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
                            <TrackingStateRadioButtonGroup />
                            <ConversionTypeRadioButtonGroup />
                            <FullOrSoftConversionRadioButtonGroup />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    elevation={0}
                    sx={{ "&:before": { height: "0px" } }}
                    expanded={expandedCj}
                    onChange={handleChangeCj}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${cjSectionId}-content`}
                        id={cjSectionId}
                        sx={{
                            flexDirection: "row-reverse",
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                            Choose how Customer Journey data should be displayed
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                        <CustomerJourneyConfigForm />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    elevation={0}
                    sx={{ "&:before": { height: "0px" } }}
                    expanded={expandedSearch}
                    onChange={handleChangeSearch}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${searchSectionId}-content`}
                        id={searchSectionId}
                        sx={{
                            flexDirection: "row-reverse",
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>Filter and search</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ width: "100%" }}>
                            <Search />
                            <ConversionStateFilter />
                            <ConsentStateFilter />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    elevation={0}
                    sx={{ "&:before": { height: "0px" } }}
                    expanded={expandedTouchpoint}
                    onChange={handleChangeTouchpoint}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${touchpointSectionId}-content`}
                        id={touchpointSectionId}
                        sx={{
                            flexDirection: "row-reverse",
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                            Add conversion filters based on touchpoint properties
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <CurrentPropertyFiltersList />
                            <AddPropertyFilterButton />
                            <PropertyFilterDialog key={propertyFilterDialogId} />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <IconButton onClick={handleChangeAll} sx={{ alignSelf: "flex-start" }}>
                    {expandedSubset && expandedCj && expandedSearch && expandedTouchpoint ? (
                        <KeyboardDoubleArrowUpIcon />
                    ) : (
                        <KeyboardDoubleArrowDownIcon />
                    )}
                </IconButton>
            </Box>
            <ColumnConfiguration />
        </Box>
    )
}
