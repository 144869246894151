import Badge from "@mui/material/Badge"
import { Box, IconButton } from "@mui/material"
import React, { useEffect } from "react"
import { TopBarTooltip } from "layout/MainLayout/TopBar/TopBarTooltip"
import { ExportTaskList } from "layout/MainLayout/ExportCenter/ExportTaskList"
import { useExportCenterContext } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import CircularProgress from "@mui/material/CircularProgress"
import { assertExhaustive } from "shared/util/TypeUtil"
import { ExportTaskState } from "domain/exporttask/types"
import SmallClockIcon from "shared/component/icons/small/SmallClockIcon"
import SmallCheckmarkIcon from "shared/component/icons/small/SmallCheckmarkIcon"
import SmallErrorIcon from "shared/component/icons/small/SmallErrorIcon"
import { styled } from "@mui/material/styles"
import DownloadIcon from "shared/component/icons/DownloadIcon"

const StyledBox = styled(Box)(({ theme }) => ({
    "& .MuiBadge-badge .MuiSvgIcon-root": {
        width: "11px",
    },
    "& .MuiBadge-badge": {
        padding: "0 3px",
    },
}))

export const ExportCenterNotification = (): JSX.Element => {
    const ref = React.useRef()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const exportCenterContext = useExportCenterContext()
    const { tasks, exportTaskCreatedCounter } = exportCenterContext
    const taskCount = tasks.length

    useEffect(() => {
        if (taskCount === 0) {
            handleClose()
        }
    }, [taskCount])

    useEffect(() => {
        // Open the export center when a new task is created
        setAnchorEl(ref.current)
    }, [exportTaskCreatedCounter])

    const onClick = () => {
        setAnchorEl(ref.current)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    if (taskCount > 0) {
        const mostRecentTask = tasks[0]
        let badgeContent: JSX.Element
        switch (mostRecentTask.state) {
            case "PENDING":
                badgeContent = <SmallClockIcon />
                break
            case "IN_PROGRESS":
                badgeContent = <CircularProgress size={10} color="inherit" />
                break
            case "COMPLETED":
                badgeContent = <SmallCheckmarkIcon />
                break
            case "ERROR":
                badgeContent = <SmallErrorIcon />
                break
            case "CANCELLED":
                badgeContent = null
                break
            default:
                assertExhaustive(mostRecentTask.state)
        }

        return (
            <StyledBox className={"export-center-notification"}>
                <TopBarTooltip title={"Downloads"}>
                    <IconButton ref={ref} onClick={onClick}>
                        <Badge badgeContent={badgeContent} color="primary" variant={"standard"}>
                            <DownloadIcon />
                        </Badge>
                    </IconButton>
                </TopBarTooltip>
                <ExportTaskList anchorEl={anchorEl} handleClose={handleClose} />
            </StyledBox>
        )
    } else {
        return <React.Fragment />
    }
}
