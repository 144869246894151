import {
    ColumnConfiguratorLabels,
    ColumnUniqueName,
    MetricsFrontendGroupUniqueName,
    SupportedModels,
    WidgetStaticConfiguration,
} from "domain/ColumnConfigurator/types"
import {
    ColumnSelectAction,
    DimensionIdentifiableSettings,
    IdentifiableSettings,
    MetricIdentifiableSettings,
    SelectedState,
} from "domain/ColumnConfigurator/components/types"
import { DimensionMetricCompatibility } from "domain/ColumnConfigurator/DimensionMetricCompatibility"
import { createContext, useContext } from "use-context-selector"

/*
 * Context shape definition
 */
export interface ColumnConfiguratorContextProps {
    open(state: SelectedState, config: WidgetStaticConfiguration): void

    close(): void

    applyColumnConfiguratorSettings(): void

    isSelectedStateValid(): boolean

    getColumnConfiguratorOutputConfiguration(): SelectedState

    readonly labels: ColumnConfiguratorLabels
    readonly dialogState: "open" | "closed" | "applied"
    readonly widgetId: number | undefined
    readonly selectedDimensions: DimensionIdentifiableSettings[]
    readonly selectedMetrics: MetricIdentifiableSettings[]
    readonly leftPinnedMetrics: ColumnUniqueName[]
    readonly expandedGroups: Set<MetricsFrontendGroupUniqueName>
    readonly onExpandedGroupsChanged: (expandedGroups: Set<MetricsFrontendGroupUniqueName>) => void
    readonly widgetStaticConfiguration: WidgetStaticConfiguration
    readonly supportedDimensions: Set<ColumnUniqueName>
    readonly supportedMetrics: Set<ColumnUniqueName>
    readonly supportedModels: SupportedModels
    readonly dimensionMetricCompatibility: DimensionMetricCompatibility
    readonly onColumnSettingsChanged: (columnSettings: IdentifiableSettings) => void
    readonly onColumnSelectAction: (columnIdentifier: ColumnUniqueName, action: ColumnSelectAction) => void
    readonly onColumnMoved: (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => void
}

export const ColumnConfiguratorContext = createContext<ColumnConfiguratorContextProps | undefined>(undefined)

export const useColumnConfiguratorContext = (): ColumnConfiguratorContextProps => {
    const context = useContext(ColumnConfiguratorContext)

    if (context === undefined) {
        throw new Error("useColumnConfiguratorContext must be used within a ColumnConfiguratorContextProvider")
    }

    return context
}
