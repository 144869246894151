import { DateRange, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"
import React, { useState } from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import moment from "moment"
import { Popover, Typography } from "@mui/material"

/**
 * A time range selector widget for the conversion list
 * @param minDateTime Earliest selectable date, the start
 * @param maxDateTime Latest selectable date, the end
 * @param maxDuration optional. If set enforces a maximum duration of the selected time range, in days
 */
export const TimeSpanSelection = ({
    minDateTime,
    maxDateTime,
    // maximum duration of the selectable time range, in days
    maxDuration,
}: {
    minDateTime: moment.Moment
    maxDateTime: moment.Moment
    maxDuration?: number | undefined
}) => {
    const [start, end] = ConversionListContextSelectors.useTimeRange()
    const updateTimeRange = ConversionListContextSelectors.useUpdateTimeRange()
    const messageAnchor = React.useRef<HTMLDivElement>(null)

    const [openRangeWarning, setOpenRangeWarning] = useState(false)
    const [rangeWarningMessage, setRangeWarningMessage] = useState("")

    const [startError, setStartError] = useState(false)
    const [endError, setEndError] = useState(false)

    const handleRangeValidation = (timeRange: DateRange<moment.Moment>) => {
        if (timeRange[0] != null && timeRange[1] != null) {
            if (maxDuration && timeRange[1].diff(timeRange[0], "days") > maxDuration) {
                if (timeRange[0].isSame(start, "seconds")) {
                    // The start did not change, so the end changed. In that case we change the start to enable the new end
                    timeRange[0] = timeRange[1].clone().subtract(maxDuration, "days")
                    timeRange[0].hour(start?.hour() ?? 0)
                    timeRange[0].minutes(start?.minutes() ?? 0)
                    timeRange[0].seconds(start?.seconds() ?? 0)
                }
                if (timeRange[1].isSame(end, "seconds")) {
                    // The end did not change, so the start changed. In that case we change the end to enable the new start
                    let validEndDate = timeRange[0].clone().add(maxDuration, "days")
                    if (validEndDate.isAfter(maxDateTime, "days")) {
                        validEndDate = maxDateTime
                    }
                    timeRange[1] = validEndDate
                    timeRange[1].hour(end?.hour() ?? 0)
                    timeRange[1].minutes(end?.minutes() ?? 0)
                    timeRange[1].seconds(end?.seconds() ?? 0)
                }
                openAutoCloseMessage(`The maximum allowable time span is ${maxDuration} days`)
            }
        }

        updateTimeRange(timeRange)
    }

    const handleStartChange = (timeRange: DateRange<moment.Moment>): void => {
        setStartError(false)
        // Don't allow null
        if (timeRange[0] == null || timeRange[0].isValid() == false) {
            timeRange[0] = start // This avoids for the input to go to complete empty after one backspace
            // Setting a corrected date here does not work always, it seems to conflict with internal state of MUI.
            // So instead we move the input into an error state, to at least inform the user
            setStartError(true)
        }

        // Start can't stay after end, so we change end
        if (timeRange[0]?.isAfter(timeRange[1], "seconds")) {
            timeRange[1] = timeRange[0]!.clone().endOf("day")

            openAutoCloseMessage(`End date changed to be after start`)
        }

        updateTimeRange(timeRange)
        handleRangeValidation(timeRange)
    }

    const handleEndChange = (timeRange: DateRange<moment.Moment>): void => {
        setEndError(false)
        // Don't allow null
        if (timeRange[1] == null || timeRange[1].isValid() == false) {
            setEndError(true)
        }

        // End can't stay before the start, so we change start
        if (timeRange[1]?.isBefore(timeRange[0], "seconds")) {
            timeRange[0] = timeRange[1]!.clone().startOf("day")

            openAutoCloseMessage(`Start date changed to be after end`)
        }

        updateTimeRange(timeRange)
        handleRangeValidation(timeRange)
    }

    const openAutoCloseMessage = (message: string) => {
        setRangeWarningMessage(message)
        setOpenRangeWarning(true)
        setTimeout(() => {
            setOpenRangeWarning(false)
        }, 3000)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateTimePicker
                className={"start-date"}
                ref={messageAnchor}
                disableHighlightToday
                value={start?.clone()}
                minDateTime={minDateTime}
                maxDateTime={maxDateTime}
                onChange={(value, _) => handleStartChange([value, end])}
                format="DD.MM.YYYY HH:mm:ss"
                label={"Start"}
                ampm={false}
                viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                }}
                onViewChange={(view) => {
                    if (view == "hours") {
                        // Immediately selecting the hour collides with the main use flow of selecting start and then end date.
                        setTimeout(() => {
                            // But since the new focus is not controllable, the unselecting needs to be put into a timeout
                            if (document.activeElement instanceof HTMLElement) {
                                document.activeElement.blur()
                            }
                            // a timeout of 30ms disables the focus fast enough to avoid flickering
                        }, 30)
                    }
                }}
                slotProps={{
                    textField: {
                        error: startError,
                        size: "small",
                        sx: {
                            width: "26ch",
                        },
                    },
                    popper: {
                        sx: {
                            "& .MuiDateRangeCalendar-root": {
                                px: 4,
                            },
                            "& .MuiPickersArrowSwitcher-root .MuiSvgIcon-root": {
                                height: "1.5em",
                                width: "1.5em",
                            },
                        },
                    },
                }}
            />
            <Typography sx={{ mx: 1 }}>–</Typography>
            <DateTimePicker
                className={"end-date"}
                disableHighlightToday
                value={end?.clone()}
                minDateTime={minDateTime}
                maxDateTime={maxDateTime}
                onChange={(value, _) => handleEndChange([start, value])}
                format="DD.MM.YYYY HH:mm:ss"
                label={"End"}
                ampm={false}
                viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                }}
                onViewChange={(view) => {
                    if (view == "hours") {
                        // Immediately selecting the hour collides with the main use flow of selecting start and then end date.
                        setTimeout(() => {
                            // But since the new focus is not controllable, the unselecting needs to be put into a timeout
                            if (document.activeElement instanceof HTMLElement) {
                                document.activeElement.blur()
                            }
                            // a timeout of 30ms disables the focus fast enough to avoid flickering
                        }, 30)
                    }
                }}
                sx={{ ml: 1 }}
                slotProps={{
                    textField: {
                        error: endError,
                        size: "small",
                        sx: {
                            width: "26ch",
                        },
                    },
                    popper: {
                        sx: {
                            "& .MuiDateRangeCalendar-root": {
                                px: 4,
                            },
                            "& .MuiPickersArrowSwitcher-root .MuiSvgIcon-root": {
                                height: "1.5em",
                                width: "1.5em",
                            },
                        },
                    },
                }}
            />
            <Popover
                sx={{
                    pointerEvents: "none",
                }}
                open={openRangeWarning}
                anchorEl={messageAnchor.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                disableRestoreFocus
                disableAutoFocus={true}
            >
                <Typography sx={{ p: 1 }}>{rangeWarningMessage}</Typography>
            </Popover>
        </LocalizationProvider>
    )
}
