/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import type { UserSettingsDTOValue } from "./UserSettingsDTOValue"
import {
    UserSettingsDTOValueFromJSON,
    UserSettingsDTOValueFromJSONTyped,
    UserSettingsDTOValueToJSON,
} from "./UserSettingsDTOValue"

/**
 *
 * @export
 * @interface UserSettingsDTO
 */
export interface UserSettingsDTO {
    /**
     *
     * @type {string}
     * @memberof UserSettingsDTO
     */
    id: string
    /**
     *
     * @type {UserSettingsDTOValue}
     * @memberof UserSettingsDTO
     */
    value: UserSettingsDTOValue
}

/**
 * Check if a given object implements the UserSettingsDTO interface.
 */
export function instanceOfUserSettingsDTO(value: object): value is UserSettingsDTO {
    if (!("id" in value) || value["id"] === undefined) return false
    if (!("value" in value) || value["value"] === undefined) return false
    return true
}

export function UserSettingsDTOFromJSON(json: any): UserSettingsDTO {
    return UserSettingsDTOFromJSONTyped(json, false)
}

export function UserSettingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsDTO {
    if (json == null) {
        return json
    }
    return {
        id: json["id"],
        value: UserSettingsDTOValueFromJSON(json["value"]),
    }
}

export function UserSettingsDTOToJSON(value?: UserSettingsDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value["id"],
        value: UserSettingsDTOValueToJSON(value["value"]),
    }
}
