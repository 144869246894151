import { Badge, Tab, TabProps } from "@mui/material"
import React from "react"
import CustomTooltip from "layout/components/CustomTooltip"
import Typography from "@mui/material/Typography"

interface TooltipTabProps extends TabProps {
    tooltip: React.ReactNode
    disableTooltipInteractive?: boolean
    badgeContent?: number
}

const TooltipTab: React.FC<TooltipTabProps> = ({
    tooltip,
    disableTooltipInteractive = false,
    badgeContent,
    label,
    ...tabProps
}) => {
    const labelComponent = (
        <CustomTooltip
            customWidth={700}
            title={tooltip}
            disableInteractive={disableTooltipInteractive}
            placement="top"
            className={"info-tooltip"}
        >
            <Badge color={"primary"} className={"badge-border"} badgeContent={badgeContent} showZero>
                <Typography component="div">{label}</Typography>
            </Badge>
        </CustomTooltip>
    )

    return <Tab {...tabProps} label={labelComponent} />
}

export default TooltipTab
