import React, { useState } from "react"
import { Button } from "@mui/material"
import { ExportDialog } from "domain/ConversionList/components/Exports/ExportDialog"

export const DownloadButton = () => {
    const [shownDialog, setShownDialog] = useState(false)

    const handleClick = () => {
        setShownDialog(!shownDialog)
    }

    return (
        <>
            <Button variant="outlined" onClick={handleClick}>
                Download
            </Button>
            <ExportDialog shown={shownDialog} onCloseDialog={handleClick} />
        </>
    )
}
