import React from "react"
import { match } from "ts-pattern"
import { ConversionListType } from "domain/ConversionList/domain/domain"
import { TouchpointCustomerJourneyRole } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"

interface InfoTextProps {
    conversionListType: ConversionListType
    touchpointCustomerJourneyRole: TouchpointCustomerJourneyRole
}

export const InfoText = ({ conversionListType, touchpointCustomerJourneyRole }: InfoTextProps) => {
    const text = match<ConversionListType, JSX.Element>(conversionListType)
        .with("historical", () => {
            return match<TouchpointCustomerJourneyRole, JSX.Element>(touchpointCustomerJourneyRole)
                .with("winner", () => {
                    return <p>Historical winner blabla.</p>
                })
                .with("closer", () => {
                    return <p>Historical loser blabla.</p>
                })
                .with("influencer", () => {
                    return <p>Historical influencer blabla.</p>
                })
                .with("introducer", () => {
                    return <p>Historical introducer blabla.</p>
                })
                .with("involved", () => {
                    return <p>Historical involved blabla.</p>
                })
                .exhaustive()
        })
        .with("realtime", () => {
            return <p>Real-time only winner blabla.</p>
        })
        .exhaustive()

    return <>{text}</>
}
