import { DimensionDTO } from "generated/models"
import { GridDataRowDTO } from "domain/types"

/**
 * Creates title from mainDimension
 *
 * @param items
 * @param mainDimension
 */
const getMainDimensionTitle = (items: GridDataRowDTO[], mainDimension: DimensionDTO): string => {
    return items.length === 1 ? mainDimension.displayName : getDimensionPlural(mainDimension)
}

/**
 * Returns displayNamePlural if it exists, otherwise displayName plus 's'
 * @param dimension
 */
const getDimensionPlural = (dimension: DimensionDTO): string => {
    return dimension.displayNamePlural ? dimension.displayNamePlural : dimension.displayName + "s"
}

const DimensionUtil = {
    getMainDimensionTitle: getMainDimensionTitle,
    getDimensionPlural: getDimensionPlural,
}

export default DimensionUtil
