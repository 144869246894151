import React, { useEffect, useState } from "react"
import AuthenticationService from "domain/authentication/service/authentication.service"
import { consentService } from "domain/consent/service"
import { useNavigationContext } from "shared/NavigationContext"
import { Alert, Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Link } from "react-router-dom"
import PublicLayout from "layout/PublicLayout/PublicLayout"
import { ArrowBack } from "@mui/icons-material"
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded"
import { UiConsentFormDTO } from "generated/models"

const ConsentForm: React.FC = (): JSX.Element => {
    const navigationContext = useNavigationContext()

    const [consentState, setConsentState] = useState<UiConsentFormDTO>(undefined)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        consentService.getConsentState().then((consent) => {
            setConsentState(consent)
            consent.privacyPolicyAccepted && onConsentComplete()
        })
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const abortLogin = () => {
        AuthenticationService.logout().then(() => {
            navigationContext.redirectToLogin()
        })
    }

    const onConsentComplete = () => navigationContext.redirectToReferrer(true)

    const onFinish = () => {
        setIsSubmitting(true)
        consentService
            .submitConsent(checked)
            .then((consentFormResponse: UiConsentFormDTO) => {
                setConsentState(consentFormResponse)
                consentFormResponse.privacyPolicyAccepted && onConsentComplete()
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setChecked(checked)
    }

    return (
        <PublicLayout>
            {consentState && (
                <div className={"content-body standalone-form consent-form"}>
                    {!consentState?.privacyPolicyAccepted && (
                        <>
                            <h2>Please Review Our Privacy Policy</h2>
                            {!consentState?.privacyPolicyAccepted && (
                                <Box>
                                    <Link
                                        to={"https://exactag.com/privacy-policy-for-ui-and-help-center/"}
                                        target={"_blank"}
                                        style={{
                                            textDecoration: "underline",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <OpenInNewRoundedIcon sx={{ mr: 1 }} />
                                        Privacy Policy
                                    </Link>
                                    <FormControlLabel
                                        sx={{ mt: 2 }}
                                        control={<Checkbox checked={checked} onChange={onCheckboxChange} />}
                                        label={
                                            "By checking this box, I confirm that I've read and accept the privacy policy"
                                        }
                                    />
                                </Box>
                            )}
                            <br />
                            <LoadingButton
                                style={{ width: "180px" }}
                                variant="contained"
                                startIcon={<ArrowForwardIcon />}
                                loading={isSubmitting}
                                size={"large"}
                                onClick={onFinish}
                                disabled={!checked}
                            >
                                Continue
                            </LoadingButton>
                            <br />

                            <Typography
                                variant={"body1"}
                                sx={{ fontStyle: "italic", fontSize: "12px!important", margin: "30px 0" }}
                            >
                                Please note: Your use of this UI and the Exactag Help Center is subject to strict
                                confidentiality as agreed in the contract with Exactag GmbH. Please ensure you respect
                                these confidentiality obligations when using the UI and the Exactag Help Center.
                            </Typography>
                        </>
                    )}

                    {consentState?.privacyPolicyAccepted && (
                        <>
                            <h3>Thank you for reviewing our privacy policy</h3>
                            <Alert severity={"success"}>
                                <Typography>You will be logged in shortly</Typography>
                            </Alert>
                        </>
                    )}

                    <div className={"footer"}>
                        <a onClick={abortLogin}>
                            <ArrowBack />
                            Back to Login
                        </a>
                    </div>
                </div>
            )}
        </PublicLayout>
    )
}

export default ConsentForm
