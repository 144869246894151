import React, { Fragment } from "react"
import { SelectComponent } from "domain/filter/component/ant/SelectComponent"
import { ColumnResponseDTO } from "generated/models"
import DimensionService from "domain/dimension/service/DimensionService"
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    HISTORIC_SCENARIO_ID,
    RECOMMENDED_SCENARIO_ID,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { FilterState, Scenario } from "domain/types"

type Props = {
    initValue: number | string
    scenarios: Scenario[]
    callback: (dimensionId: string, scenarioId: number) => void
    size?: string
    style?: any
    selectStyle?: any
}

export default (props: Props) => {
    const defaultScenarios: ColumnResponseDTO[] = [
        { name: "Historic Performance", value: HISTORIC_SCENARIO_ID },
        { name: "Recommended Scenario", value: RECOMMENDED_SCENARIO_ID },
    ]

    const scenario = {
        value: props.initValue,
        selectFormElement: {
            formFieldConfig: {
                dimensionIdentifier: DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
            },
        },
        filterEntries: {
            entries: [...defaultScenarios],
        },
    } as FilterState

    if (props.scenarios && props.scenarios.length) {
        scenario.filterEntries.entries = [
            ...defaultScenarios,
            ...props.scenarios.map(
                (scenario) =>
                    ({
                        value: scenario.adspend_scenario_id,
                        name: scenario.adspend_scenario_name,
                    }) as ColumnResponseDTO,
            ),
        ]
    }

    return (
        <Fragment>
            <SelectComponent
                size={props.size ? props.size : "small"}
                componentStyle={props.style}
                selectStyle={props.selectStyle}
                filter={scenario}
                label={""}
                enableClearButton={false}
                onChange={props.callback}
            />
        </Fragment>
    )
}
