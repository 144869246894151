import { QuerySettingsDTO } from "generated/models"
import { EChartsOption } from "echarts-for-react/src/types"
import { ComputationType, MetricType } from "domain/types"

export type NewUiWidgetDataColumn = {
    identifier: string
    displayName: string
}
export type NewUiWidgetMetric = NewUiWidgetDataColumn & {
    computationType: ComputationType
    round: number
    type: MetricType
    unitPostfix?: string
    unitPrefix?: string
}

export type NewUiWidgetDimension = NewUiWidgetDataColumn & {
    displayNamePlural: string
    nameColumn?: string
}

export enum WidgetType {
    DATA_GRID_WIDGET = "DATA_GRID_WIDGET",
    DATA_LIST_WIDGET = "DATA_LIST_WIDGET",
    TOP_N_WIDGET = "TOP_N_WIDGET",
    TIME_LINE_WIDGET = "TIME_LINE_WIDGET",
    TIME_SPAN_WIDGET = "TIME_SPAN_WIDGET",
    HTML_WIDGET = "HTML_WIDGET",
}

// Common widget settings shared across more specific
// widget setting types
export type WidgetSettingsDTO = QueryWidgetSettingsDTO | HtmlWidgetSettingsDTO
export type HtmlWidgetSettingsDTO = {
    type: WidgetType.HTML_WIDGET
    htmlContent: string
    dashboardUUID: string
    isEditMode: boolean
}

export const isQueryWidgetSettingsDTO = (
    widgetSettings: WidgetSettingsDTO,
): widgetSettings is QueryWidgetSettingsDTO => {
    return (widgetSettings as QueryWidgetSettingsDTO).querySettings !== undefined
}

export type QueryWidgetSettingsDTO = {
    type?: WidgetType
    title?: string
    axisConfigurations?: AxisConfigurationDTO[]

    // e.g. "dev/jenkinsstatus" to load data from a custom datamanager, will default to load dashboard data via reporting data manager
    apiPath?: string

    // override for various kinds of query settings that could be overridden on a per-widget basis?
    querySettings?: QuerySettingsDTO
    // the DataColumn definitions for the columns that are used by this widget; this will later be removed and replaced by a call that loads
    // all available DataColumns from the backend because widgets will need this information to e.g. show dropdown values in the widget settings
    // toolbar
    dataColumns?: (NewUiWidgetDimension | NewUiWidgetMetric)[]

    settingsPanelVisible?: boolean
    theme?: ColorTheme
}

export type AxisConfigurationDTO = {
    axisType: AxisType
    axisPropertyName: string
    displayName?: string
    columnIdentifiers?: string[]
}

export enum AxisType {
    X_AXIS = "X_AXIS",
    Y_AXIS = "Y_AXIS",
}

export type DataGridWidgetSettingsDTO = QueryWidgetSettingsDTO & {
    dimension: string

    kpis: Array<string>
    kpiFrontendGroups: Array<string>
}

export type DataListWidgetSettingsDTO = QueryWidgetSettingsDTO & {
    listHeader?: string
    listFooter?: string
}

export type TopNWidgetSettingsDTO = {
    topNWidgetType: TopNWidgetType
    // Number of entries to show in this widget, e.g. "show only top 10 entries"
    topNElements: number
    // if true then there will be n + 1 entries; the additional entry will summarize all "other" entries that are not within the top N entries
    showOthers: boolean
    showDecalPattern: boolean
    customColors: { [key: string]: string }
} & QueryWidgetSettingsDTO

export type TopNBarWidgetSettingsDTO = {
    barDirection: BarDirection
    stacked: boolean
    showValues: boolean
} & TopNWidgetSettingsDTO

export enum BarDirection {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal",
}

export enum ColorTheme {
    MULTICOLOR = "exactag",
    MONOCHROME_GREEN = "monochrome_green",
    MONOCHROME_BLUE = "monochrome_blue",
    MONOCHROME_RED = "monochrome_red",
    MONOCHROME_ORANGE = "monochrome_orange",
    MONOCHROME_YELLOW = "monochrome_yellow",
    MONOCHROME_TURQUOISE = "monochrome_turqouise",
    MONOCHROME_PURPLE = "monochrome_purple",
    MONOCHROME_GRAY = "monochrome_gray",
}

export enum TopNWidgetType {
    BAR = "bar",
    LINE = "line",
    PIE = "pie",
}

export type ResponsiveWidgetSettings = {
    echartsOption: EChartsOption
    isLegendHorizontal: boolean
    isLegendButtonVisible: boolean
}
