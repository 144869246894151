import { MfaFactor } from "domain/types/backend/mfa.types"
import { Button, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material"
import React, { useEffect, useState } from "react"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { MfaFactorConfigDTO } from "generated/models"

type Props = {
    factorConfigs: MfaFactorConfigDTO[]
    select: (MfaFactor) => void
}

const MfaFactorSelectionForm: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [selectedFactor, setSelectedFactor] = useState<"AUTHENTICATOR" | "EMAIL">(undefined)

    const factorDisplayName = (factor: "AUTHENTICATOR" | "EMAIL") =>
        ({
            [MfaFactor.Authenticator]: "Authenticator App (recommended)",
            [MfaFactor.Email]: "E-Mail",
        })[factor]

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setSelectedFactor(value as "AUTHENTICATOR" | "EMAIL")
    }

    useEffect(() => {
        if (props?.factorConfigs?.length === 1) setSelectedFactor(props?.factorConfigs[0].factor)
    }, [props.factorConfigs])

    return (
        <>
            <div className={"mfa-form-segment"}>
                <h3>
                    <strong>Set Up Two-Factor Authentication</strong>
                </h3>
                <p>
                    To keep your account secure, your organisation requires you to set up a second authentication
                    factor.
                </p>

                {props?.factorConfigs?.length > 1 && (
                    <FormControl>
                        <RadioGroup onChange={onChange}>
                            {props?.factorConfigs?.map((config, index) => (
                                <FormControlLabel
                                    value={config.factor}
                                    key={"factor_" + index}
                                    control={<Radio />}
                                    label={factorDisplayName(config.factor)}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )}
            </div>

            {selectedFactor && (
                <div className={"mfa-form-segment"}>
                    {selectedFactor === MfaFactor.Authenticator && (
                        <>
                            <h3>Step 1: Install Authenticator App</h3>
                            <p>
                                Popular choices include{" "}
                                <a
                                    href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345"
                                    target="_blank"
                                >
                                    Google Authenticator
                                </a>{" "}
                                (iOS, Android) and{" "}
                                <a href="https://authy.com/download/" target="_blank">
                                    Authy
                                </a>{" "}
                                (iOS, Android, desktop).
                            </p>
                        </>
                    )}

                    {selectedFactor === MfaFactor.Email && (
                        <p>The e-mail address associated with your account will be used for authentication.</p>
                    )}

                    <Button
                        onClick={() => props?.select(selectedFactor)}
                        startIcon={<ArrowForwardIcon />}
                        style={{ width: "180px" }}
                        variant="outlined"
                        size={"large"}
                    >
                        Continue
                    </Button>
                </div>
            )}
        </>
    )
}

export default MfaFactorSelectionForm
