import { GridDataRowDTO } from "domain/types"

export enum DimensionFieldType {
    VALUE = "value",
    NAME = "name",
}

export type DimensionField = {
    identifier: string
    fieldType: DimensionFieldType
}

/**
 * "channel.value" -> DimensionField(identifier = "channel", fieldType = DimensionFieldType.VALUE)
 */
const recognizeDimensionField = (text: string): DimensionField => {
    const tokens = text.split(".")

    return {
        identifier: tokens[0],
        fieldType: DimensionFieldType[tokens[1]?.toUpperCase()],
    }
}

const DimensionService = {
    getDimensionValueColumn: (identifier: string): string => identifier + ".value",
    getDimensionNameColumn: (identifier: string): string => identifier + ".name",
    recognizeDimensionField: recognizeDimensionField,

    /**
     * Extracts name data of the dimensionIdentifier. If not found then value data.
     *
     * @param row
     * @param dimensionIdentifier
     */
    getDimensionFieldNameOrValue: (row: GridDataRowDTO, dimensionIdentifier: string): any => {
        const columnData = row[dimensionIdentifier]
        if (!columnData) {
            return null
        }

        return columnData[DimensionFieldType.NAME]
            ? columnData[DimensionFieldType.NAME]
            : columnData[DimensionFieldType.VALUE]
    },
}

export default DimensionService
