import React from "react"

import { TextField as MuiTextField, useTheme } from "@mui/material"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"
import { useFormContext } from "shared/component/forms/FormContext"

export type TextFieldType = TextFieldTextType | TextFieldPasswordType | TextFieldNumberType

export interface TextFieldTextType {
    type: "text"
    multiline?: boolean
}

export interface TextFieldPasswordType {
    type: "password"
}

export interface TextFieldNumberType {
    type: "number"
    min?: number
    max?: number
}

export interface TextFieldProps {
    name: string
    label: string
    textFieldType: TextFieldType
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    required?: boolean
}

export const TextField = ({
    name,
    label,
    textFieldType,
    onChange: onChangeProp,
    disabled = false,
    required = false,
}: TextFieldProps) => {
    const { current: id } = React.useRef(getUniqueId(name))

    const { formik } = useFormContext()

    const fieldProps = formik.getFieldProps(name)
    const fieldMeta = formik.getFieldMeta(name)
    const theme = useTheme()

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        fieldProps.onChange(event)
        if (onChangeProp) {
            onChangeProp(event)
        }
    }

    return (
        <>
            <MuiTextField
                id={id}
                label={label}
                className={fieldProps.name + " form-field form-field-text"}
                sx={{ marginTop: theme.spacing(1) }}
                name={fieldProps.name}
                value={fieldProps.value}
                onChange={onChange}
                onBlur={fieldProps.onBlur}
                error={fieldMeta.touched && Boolean(fieldMeta.error)}
                helperText={fieldMeta.touched && fieldMeta.error}
                size={"medium"}
                type={textFieldType.type}
                multiline={textFieldType.type === "text" && textFieldType.multiline}
                inputProps={
                    textFieldType.type === "number" ? { min: textFieldType.min, max: textFieldType.max } : undefined
                }
                disabled={disabled}
                required={required}
            />
        </>
    )
}
